import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as SentIcon } from 'Assets/icons/sent.svg';
import { ReactComponent as ViewsIcon } from 'Assets/icons/statsViews.svg';
import { ReactComponent as FingerIcon } from 'Assets/icons/finger.svg';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';
import { ReactComponent as Rename } from 'Assets/icons/rename.svg';
import { ReactComponent as SmartListIcon } from 'Assets/icons/textEditor/list-ul-solid.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/clone.svg';
import { ReactComponent as ClockSvg } from 'Assets/icons/clockNotFill.svg';
import { ReactComponent as CancelIcon } from 'Assets/icons/cancel.svg';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { createSocket, getToken } from 'utils/Utils';
import { IBroadcast } from 'types';
import moment from 'moment';
import queries from 'graphql/queries';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';

import styles from './BroadcastItem.module.scss';

enum MenuItems {
  DELETE,
  EDIT,
  CLONE,
  VIEW,
  RENAME,
  CANCEL_SCHEDULE,
}

interface IBroadcastItemProps {
  item: IBroadcast;
  stats: { delivered: number; opened: number; clicked: number; sent: number };
  fetchBroadcasts: () => void;
  updateBroadcastStatus: (id: string, status: string) => void;
}

const BroadcastItem = (props: IBroadcastItemProps) => {
  const [url, setUrl] = useState<string>('');
  const [itemName, setItemName] = useState(props.item.name);
  const [isRename, setIsRename] = useState<boolean>(false);
  const [archiveFunnelOpen, setArchiveFunnelOpen] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(
    !!props.item.image
  );
  const history = useHistory();

  const handleNavigate = () => {
    if (props.item.status === 'IDLE') {
      history.push(`/console/broadcasts/${props.item._id}/edit`);
    }
  };

  useEffect(() => {
    if (props.item._id && props.item.status === 'WAITING') {
      const socket = createSocket();

      socket.on('connect', () => {
        socket.emit('get-broadcast-status', {
          id: props.item._id,
          token: getToken(),
        });
      });

      socket.on('broadcast-status-response', (res) => {
        if (res.status !== 'props.item.status') {
          props.updateBroadcastStatus(res.broadcastId, res.status);
        }
      });

      socket.on('error', (err) => {
        console.error('Socket error:', err);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [props.item._id, props.item.status]);

  const deleteBroadcast = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_BROADCAST,
        values: {
          id: props.item._id,
        },
        headerType: 'USER-AUTH',
      });
      props.fetchBroadcasts();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateName = async () => {
    setIsRename(false);
    if (itemName === props.item.name) return;

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_BROADCAST_MUTATION,
      values: {
        id: props.item._id,
        name: itemName,
      },
      headerType: 'USER-AUTH',
    });
  };

  const cancelSchedule = useCallback(async () => {
    if (
      props.item.status === 'WAITING' &&
      Number(props.item.sendAt) * 1000 > Date.now()
    ) {
      try {
        await graphQlCall({
          queryTemplateObject: queries.CANCEL_BROADCAST_MUTATION,
          values: {
            id: props.item._id,
          },
          headerType: 'USER-AUTH',
        });
        props.fetchBroadcasts();
      } catch (error) {
        console.error(error);
      }
    }
  }, [props.item, props.fetchBroadcasts]);

  const handleMenuClick = useCallback(
    (e: any) => {
      const cloneBroadcast = async () => {
        try {
          await graphQlCall({
            queryTemplateObject: queries.CLONE_BROADCAST,
            values: {
              id: props.item._id,
            },
            headerType: 'USER-AUTH',
          });
          props.fetchBroadcasts();
        } catch (error) {
          console.error(error);
        }
      };

      e.domEvent.stopPropagation();
      switch (+e.key) {
        case MenuItems.DELETE: {
          setArchiveFunnelOpen(true);
          break;
        }
        case MenuItems.CLONE: {
          cloneBroadcast();
          break;
        }
        case MenuItems.RENAME: {
          setIsRename(true);
          break;
        }
        case MenuItems.CANCEL_SCHEDULE: {
          cancelSchedule();
          break;
        }
      }
    },
    [props.item]
  );

  const menuItems = useMemo(
    () => [
      {
        label: 'Rename',
        key: MenuItems.RENAME,
        icon: <Rename />,
      },
      {
        label: 'Clone',
        key: MenuItems.CLONE,
        icon: <DuplicateIcon />,
      },
      {
        label: 'Delete',
        key: MenuItems.DELETE,
        icon: <DeleteIcon />,
      },
      ...(props.item.status === 'WAITING' &&
      Number(props.item.sendAt) * 1000 > Date.now()
        ? [
            {
              label: 'Cancel Schedule',
              key: MenuItems.CANCEL_SCHEDULE,
              icon: <CancelIcon />,
            },
          ]
        : []),
    ],
    [props.item]
  );

  const menuProps = useMemo(() => {
    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick, menuItems]);

  return (
    <>
      <ConfirmArchivationPopup
        open={archiveFunnelOpen}
        description="Are you sure you want to delete this broadcast?"
        onDonePressed={deleteBroadcast}
        onClose={() => setArchiveFunnelOpen(false)}
      />

      <div className={styles.container} onClick={handleNavigate}>
        <div className={styles.image}>
          {props.item.image ? (
            <img
              className={styles.broadcastPreviewImage}
              src={props.item.image}
              alt="preview"
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
              style={{ display: isImageLoading ? 'none' : 'block' }}
            />
          ) : (
            <div className={styles.emptyImage}>
              <Email className={styles.logo} />
            </div>
          )}
          {isImageLoading && <CircleLoader color="#d0d0d0" />}
        </div>
        <div className={styles.info}>
          <div className={styles.line}>
            <div className={styles.name}>
              <EditableLabel
                size="large"
                value={itemName}
                onChange={setItemName}
                onOutsideClick={handleUpdateName}
                isInputActive={isRename}
              />
            </div>
          </div>
          <div className={styles.line}>
            <PersonIcon fill="#a0a0a0" width={16} height={16} />
            <div className={styles.sender}>
              {props.item.sender?.name ?? 'Not Selected'}
            </div>
          </div>
          <div className={styles.line}>
            <SmartListIcon fill="#a0a0a0" width={16} height={16} />
            <div className={styles.list}>
              {props.item.smartList?.name ?? 'Not Selected'}
            </div>
          </div>
        </div>
        {props.item.status === 'COMPLETED' && (
          <div className={styles.stats}>
            <div className={styles.stat}>
              <SentIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.delivered === undefined,
                })}
              >
                {props.stats.sent || '-'}
              </div>
            </div>
            <div className={styles.stat}>
              <MailIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.delivered === undefined,
                })}
              >
                {props.stats.delivered || '-'}
              </div>
            </div>
            <div className={styles.stat}>
              <ViewsIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.opened === undefined,
                })}
              >
                {props.stats?.opened || '-'}
              </div>
            </div>
            <div className={styles.stat}>
              <FingerIcon />
              <div
                className={clsx(styles.statAmount, {
                  [styles.emptyStat]: props.stats?.clicked === undefined,
                })}
              >
                {props.stats?.clicked || '-'}
              </div>
            </div>
          </div>
        )}
        {props.item.status === 'WAITING' && (
          <div className={styles.scheduleContent}>
            {Number(props.item.sendAt) * 1000 > Date.now() ? (
              <>
                <div className={styles.scheduleText}>
                  <ClockSvg className={styles.clockIcon} />{' '}
                  <span className={styles.text}>Scheduled for</span>
                </div>
                <div className={styles.scheduledDate}>
                  {moment(Number(props.item.sendAt) * 1000).format(
                    'DD MMMM hh:mm A'
                  )}
                </div>
              </>
            ) : (
              <div className={styles.scheduleText}>
                <ClockSvg className={styles.clockIcon} />{' '}
                <span className={styles.text}>In Sending Queue</span>
              </div>
            )}
          </div>
        )}
        {props.item.status === 'IDLE' && (
          <div className={styles.scheduleContent}>
            <div className={styles.scheduleText}>
              <span className={styles.text}>
                {props.item.mailDraft.generationTask &&
                props.item.mailDraft.generationTask.status === 'GENERATING'
                  ? 'Generating...'
                  : 'Not Completed'}
              </span>
            </div>
          </div>
        )}
        <div className={styles.options}>
          <OptionMenu menuProps={menuProps} />
        </div>
      </div>
    </>
  );
};

export default BroadcastItem;
