import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'RadixUI/dialog';
import { Label } from 'RadixUI/label';
import { Input } from 'RadixUI/input';
import { Button } from 'RadixUI/button';

interface SettingsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  settings: {
    emailBg?: string;
    bodyColor?: string;
    bodyWidth?: number;
  };
  onSettingsChange: (settings: {
    emailBg?: string;
    bodyColor?: string;
    bodyWidth?: number;
  }) => void;
}

const widthPresets = [
  { label: 'Small', value: 640 },
  { label: 'Medium', value: 960 },
  { label: 'Large', value: 1200 },
];

export function SettingsModal({
  open,
  onOpenChange,
  settings,
  onSettingsChange,
}: SettingsModalProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Global Settings</DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          {/* Background Color */}
          <div className="space-y-2">
            <Label className="text-sm font-medium">Background Color</Label>
            <div className="flex gap-2">
              <div className="relative">
                <Input
                  type="color"
                  value={settings.emailBg}
                  onChange={(e) =>
                    onSettingsChange({
                      ...settings,
                      emailBg: e.target.value,
                    })
                  }
                  className="w-12 h-12 p-1 rounded-lg cursor-pointer"
                />
                <div
                  className="absolute inset-0 rounded-lg pointer-events-none"
                  style={{
                    background: settings.emailBg,
                    border: '2px solid hsl(var(--border))',
                  }}
                />
              </div>
              <Input
                type="text"
                value={settings.emailBg}
                onChange={(e) =>
                  onSettingsChange({
                    ...settings,
                    emailBg: e.target.value,
                  })
                }
                placeholder="#ffffff or rgb(255,255,255)"
                className="flex-1"
              />
            </div>
          </div>

          {/* Body Color */}
          <div className="space-y-2">
            <Label className="text-sm font-medium">Body Color</Label>
            <div className="flex gap-2">
              <div className="relative">
                <Input
                  type="color"
                  value={settings.bodyColor}
                  onChange={(e) =>
                    onSettingsChange({ ...settings, bodyColor: e.target.value })
                  }
                  className="w-12 h-12 p-1 rounded-lg cursor-pointer"
                />
                <div
                  className="absolute inset-0 rounded-lg pointer-events-none"
                  style={{
                    background: settings.bodyColor,
                    border: '2px solid hsl(var(--border))',
                  }}
                />
              </div>
              <Input
                type="text"
                value={settings.bodyColor}
                onChange={(e) =>
                  onSettingsChange({ ...settings, bodyColor: e.target.value })
                }
                placeholder="#ffffff or rgb(255,255,255)"
                className="flex-1"
              />
            </div>
          </div>

          {/* Body Width */}
          <div className="space-y-2">
            <Label className="text-sm font-medium">Body Width</Label>
            <div className="flex flex-col gap-2">
              <div className="flex gap-2">
                {widthPresets.map((preset) => (
                  <Button
                    key={preset.value}
                    variant={
                      settings.bodyWidth === preset.value
                        ? 'default'
                        : 'outline'
                    }
                    size="default"
                    className="flex-1"
                    onClick={() =>
                      onSettingsChange({ ...settings, bodyWidth: preset.value })
                    }
                  >
                    {preset.label}
                  </Button>
                ))}
              </div>
              <div className="flex items-center gap-2">
                <Input
                  type="number"
                  value={settings.bodyWidth}
                  onChange={(e) =>
                    onSettingsChange({
                      ...settings,
                      bodyWidth: Number(e.target.value),
                    })
                  }
                  placeholder="Custom width (e.g. 800px)"
                  className="flex-1"
                />
                px
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
