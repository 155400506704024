import { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useDrop } from 'react-dnd';
import { RootState } from 'store/rootReducer';
import {
  updateActiveTemplate,
  updateBookleTemplateBlocks,
} from 'store/books/booksActions';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { BookleTemplateBlock } from 'types';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { ReactComponent as EmptyTemplate } from 'Assets/icons/emptyTemplate.svg';
import { addItemToContainer } from '../utils';
import DraggableContent from '../DraggableContent/DraggableContent';
import DropPlace from '../DropPlace/DropPlace';
import { motion, AnimatePresence } from 'framer-motion';
import styles from './Content.module.scss';
import MoveButtons from '../../GenerationPreview/MoveButtons';
import { cn } from 'utils/Utils';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  hoveredItem: BookleTemplateBlock | undefined;
  activeBlock: BookleTemplateBlock | null;
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateActiveBlock: (payload: BookleTemplateBlock | null) => void;
}

const Content = (props: IProps) => {
  const [activeDropZone, setActiveDropZone] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const templateLayoutRef = useRef<HTMLDivElement | null>(null);
  const dropZonesRef = useRef<Map<number, DOMRect>>(new Map());
  const contentRef = useRef<HTMLDivElement>(null);
  const [showDropZones, setShowDropZones] = useState(false);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'MENU_ITEM',
      drop: (item: BookleTemplateBlock, monitor) => {
        setShowDropZones(false);

        if (monitor.isOver({ shallow: true })) {
          const data = addItemToContainer(
            item,
            props.templateBlocks,
            activeDropZone
          );
          props.updateBlocks(data);
        }
      },
      hover: () => {
        setShowDropZones(true);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [props.templateBlocks, activeDropZone]
  );

  useEffect(() => {
    const handleDragOver = (e: DragEvent) => {
      if (!showDropZones) return;

      // Check if mouse is within the content area
      const contentRect = contentRef.current?.getBoundingClientRect();
      if (
        !contentRect ||
        e.clientX < contentRect.left ||
        e.clientX > contentRect.right ||
        e.clientY < contentRect.top ||
        e.clientY > contentRect.bottom
      ) {
        setActiveDropZone(null);
        return;
      }

      let closestDistance = Infinity;
      let closestIndex: number | null = null;

      dropZonesRef.current.forEach((rect, index) => {
        const centerY = rect.top + rect.height / 2;
        const distance = Math.abs(e.clientY - centerY);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestIndex = index;
        }
      });

      // Only update if within reasonable distance (100px)
      setActiveDropZone(closestDistance < 100 ? closestIndex : null);
    };

    document.addEventListener('dragover', handleDragOver);
    return () => document.removeEventListener('dragover', handleDragOver);
  }, [showDropZones]);

  const combinedRef = (node: any) => {
    containerRef.current = node;
    drop(node);
  };

  UseOnClickOutside(templateLayoutRef, (e) => {
    const clickedInsideContainer = containerRef.current?.contains(
      e.target as any
    );

    if (clickedInsideContainer) {
      props.updateActiveBlock(null);
    }
  });

  const moveElement = (fromIndex: number, toIndex: number) => {
    const newBlocks = [...props.templateBlocks];
    const element = newBlocks[fromIndex];
    newBlocks.splice(fromIndex, 1);
    newBlocks.splice(toIndex, 0, element);
    props.updateBlocks(newBlocks);
  };

  const handleMoveElement = (index: number, direction: 'up' | 'down') => {
    const newIndex = direction === 'up' ? index - 1 : index + 1;
    if (newIndex >= 0 && newIndex < props.templateBlocks?.length) {
      moveElement(index, newIndex);
    }
  };
  const renderDropZone = (index: number) => {
    const shouldShow = showDropZones && activeDropZone === index;
    return (
      <div
        ref={(el) => {
          if (el) {
            dropZonesRef.current.set(index, el.getBoundingClientRect());
          } else {
            dropZonesRef.current.delete(index);
          }
        }}
        className={cn(
          'absolute left-0 right-0 h-8 -translate-y-2.5 transition-all duration-100',
          shouldShow ? 'opacity-100' : 'opacity-0'
        )}
      >
        <AnimatePresence>
          {shouldShow && (
            <motion.div
              initial={{ scaleY: 0, opacity: 0 }}
              animate={{
                scaleY: 1.2,
                opacity: 1,
                backgroundColor: 'hsl(var(--primary))',
              }}
              exit={{ scaleY: 0, opacity: 0 }}
              className={cn(
                'mx-8 h-1.5 rounded-full transition-colors duration-200',
                'origin-center transform'
              )}
              transition={{
                type: 'spring',
                stiffness: 800,
                damping: 20,
                opacity: { duration: 0.2 },
              }}
            />
          )}
        </AnimatePresence>
      </div>
    );
  };

  const renderItem = (item: BookleTemplateBlock, index: number) => {
    const isSelected = item.id === props.activeBlock?.id;

    return (
      <div className="relative group" key={item.id}>
        {renderDropZone(index)}
        <MoveButtons
          isSelected={isSelected}
          index={index}
          totalElements={props.templateBlocks.length}
          onMove={(direction) => handleMoveElement(index, direction)}
        />
        <motion.div
          layout="position"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            type: 'spring',
            stiffness: 500,
            damping: 30,
            mass: 1,
          }}
          className="my-[10px]"
          style={{
            opacity: 1.0 /*isDragging ? 0.5 : 1,*/,
            // animation: element.style.animation || undefined
          }}
        >
          <DraggableContent key={item?.id} item={item} />
        </motion.div>
        {renderDropZone(index + 1)}
      </div>
    );
  };

  return (
    <div
      className={styles.container}
      ref={combinedRef}
      style={{ backgroundColor: props.templateBlockStyles.emailBg }}
    >
      <div
        ref={templateLayoutRef}
        id={'GenerationTemplateLayout'}
        className={styles.contentBody}
        style={{
          width: props.templateBlockStyles.bodyWidth,
          backgroundColor: props.templateBlockStyles.bodyColor,
        }}
      >
        {!isOver && !props.templateBlocks?.length && (
          <div className={styles.emptyTemplateContainer}>
            <EmptyTemplate />
            <div className={styles.emptyText}>
              Just drag & drop blocks from the left sidebar and create your
              email in minutes!
            </div>
          </div>
        )}
        {isOver && !props.templateBlocks?.length && (
          <div className={styles.dropItem}>
            <div className={styles.text}>Drop Your Block Here</div>
          </div>
        )}
        <div ref={contentRef}>
          {props.templateBlocks?.map(
            (item: BookleTemplateBlock, index: number) =>
              renderItem(item, index)
          )}
        </div>
        {/* {isOver && !!props.templateBlocks?.length && !props.hoveredItem && (
          <DropPlace />
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  hoveredItem: state.books.bookleTemplateHoveredBlock,
  activeBlock: state.books.activeBlock,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateActiveBlock: (payload: BookleTemplateBlock | null) =>
    updateActiveTemplate(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
