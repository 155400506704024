import { Ref, useEffect, useMemo, useRef } from 'react';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from 'Pages/PageGenerationEditor/Draggable/Sidebar/Sidebar';
import { Accordion } from 'RadixUI/accordion';
import { ImageControls } from './ImageControls';
import { BlockControls } from './BlockControls';
import { ButtonControls } from './ButtonControls';
import { SpacerControls } from './SpacerControls';
import { DividerControls } from './DividerControls';
import TextShadowControls from './TextShadowControls';
import TextControls from './TextControls';

interface ControlsProps {
  element: BookleTemplateBlock | null;
  onUpdate: (key: string, value: string | boolean | any) => void;
  onUpdateStyle: (key: string, value: string | number) => void;
}

export function Controls({
  element,
  onUpdate,
  onUpdateStyle,
}: /*element, onUpdate*/ ControlsProps) {
  const accordionRef: Ref<HTMLDivElement> = useRef(null);

  const elementControls = useMemo(() => {
    if (!element) {
      return null;
    }

    switch (element.type) {
      case MenuItems.TEXT_BLOCK:
      case MenuItems.HEADING_BLOCK:
        return <TextControls />;
      case MenuItems.IMAGE_BLOCK:
        return (
          <ImageControls
            element={element}
            onUpdate={onUpdate}
            onUpdateStyle={onUpdateStyle}
          />
        );
      case MenuItems.BUTTON_BLOCK:
        return (
          <>
            <ButtonControls
              element={element}
              onUpdate={onUpdate}
              onUpdateStyle={onUpdateStyle}
            />
            <TextControls />
          </>
        );
      case MenuItems.DIVIDER_BLOCK:
        return <DividerControls element={element} onUpdate={onUpdate} />;
      case MenuItems.SPACER_BLOCK:
        return (
          <SpacerControls element={element} onUpdateStyle={onUpdateStyle} />
        );
      default:
        return null;
    }
  }, [element, onUpdate, onUpdateStyle]);

  useEffect(() => {
    if (!accordionRef.current) return;

    const children = accordionRef.current.children;
    const firstChild = children.item(0);
    if (firstChild) {
      const buttonChild = firstChild.querySelector('button');
      buttonChild?.click();
    }
  }, [element?.type]);

  return (
    <div className="space-y-6">
      <Accordion
        collapsible
        type="single"
        className="w-full"
        ref={accordionRef}
      >
        {/* Only show typography for text elements */}
        {elementControls}
        <BlockControls
          element={element}
          onUpdate={onUpdate}
          onUpdateStyle={onUpdateStyle}
        />

        {/* <AccordionItem value="advanced" className="border-none">
          <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
            Advanced
          </AccordionTrigger>
          <AccordionContent className="pt-4 pb-2 px-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label className="text-xs font-medium">Transform</Label>
                <Input
                  type="text"
                  value={element.style.transform || ""}
                  onChange={(e) => onUpdate({
                    style: { ...element.style, transform: e.target.value }
                  })}
                  placeholder="e.g. rotate(45deg) scale(1.2)"
                  className="bg-background/50 focus:bg-background transition-colors"
                />
              </div>

              <div className="space-y-2">
                <Label className="text-xs font-medium">Transition</Label>
                <Input
                  type="text"
                  value={element.style.transition || ""}
                  onChange={(e) => onUpdate({
                    style: { ...element.style, transition: e.target.value }
                  })}
                  placeholder="e.g. all 0.3s ease"
                  className="bg-background/50 focus:bg-background transition-colors"
                />
              </div>

              <div className="space-y-2">
                <Label className="text-xs font-medium">Z-Index</Label>
                <Input
                  type="number"
                  min="-99"
                  max="99"
                  value={element.style.zIndex || 0}
                  onChange={(e) => onUpdate({
                    style: { ...element.style, zIndex: parseInt(e.target.value) }
                  })}
                  className="bg-background/50 focus:bg-background transition-colors"
                />
              </div>
            </div>
          </AccordionContent>
        </AccordionItem> */}
      </Accordion>
    </div>
  );
}
