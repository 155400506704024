import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as ClockSvg } from 'Assets/icons/waiting.svg';
import { ReactComponent as CopyIcon } from 'Assets/icons/copyCraft/duplicate.svg';
import { ReactComponent as ReloadIcon } from 'Assets/icons/reload.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMarkInnerCircle.svg';
import { DNSRecord, IDomain, ISender } from 'types';
import {
  refreshMailDomainRecords,
  dnsPopupAction,
  selectSender,
} from 'store/senders/sendersActions';
import { RootState } from 'store/rootStore';
import Link from 'UILib/Link/Link';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';

import styles from './DnsRecordPopup.module.scss';

interface IProps {
  openDnsPopup: boolean;
  refreshMailDomainRecords: (id: string) => void;
  toggleDnsPopup: (payload: boolean) => void;
  selectSender: (value?: string) => void;
  domains: IDomain[];
  senders: ISender[];
  loading: boolean;
  selectedSender?: string;
}

const DnsRecordPopup = ({
  selectedSender,
  openDnsPopup,
  selectSender,
  toggleDnsPopup,
  refreshMailDomainRecords,
  loading,
  domains,
  senders,
}: IProps) => {
  const [dnsData, setDnsData] = useState<DNSRecord[]>([]);
  const [domainId, setDomainId] = useState<string>();

  const handleCopyToClipboard = (value: string) => {
    navigator.clipboard.writeText(value);
  };

  const columns: ColumnsType<DNSRecord> = useMemo(
    () => [
      {
        title: 'Host',
        width: 280,
        render: (value) => (
          <div className={styles.hostCell}>
            {value}
            <CopyIcon onClick={() => handleCopyToClipboard(value)} />
          </div>
        ),
        dataIndex: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'record_type',
      },
      {
        title: 'Priority',
        render: (value) =>
          value ?? <div style={{ color: '#a0a0a0', fontWeight: 300 }}>N/A</div>,
        dataIndex: 'priority',
      },
      {
        title: 'Value',
        width: 460,
        render: (value) => (
          <div className={styles.valueCell}>
            {value}
            <CopyIcon onClick={() => handleCopyToClipboard(value)} />
          </div>
        ),
        dataIndex: 'value',
      },
      {
        title: (
          <Button
            appearance="stroke"
            width={50}
            height={30}
            prefixIcon={
              <ReloadIcon
                className={clsx({ [styles.loadingIcon]: loading })}
                onClick={() => {
                  if (loading || !domainId) return;
                  refreshMailDomainRecords(domainId);
                }}
              />
            }
          />
        ),
        align: 'center',
        render: (value) =>
          value === 'unknown' ? (
            <ClockSvg fill="#faa500" />
          ) : (
            <CheckMarkIcon fill="#25bb73" width={16} height={16} />
          ),
        dataIndex: 'valid',
      },
    ],
    [refreshMailDomainRecords, domainId, loading]
  );

  useEffect(() => {
    if (!selectedSender) return;

    const sender = senders.find((el) => el._id === selectedSender);
    if (!sender) return;

    const domain = domains.find((el) => el._id === sender.mailDomain?._id);
    if (domain) {
      setDomainId(domain._id);
      setDnsData(domain.dnsRecords);
    }
  }, [selectedSender, domains, senders]);

  if (!openDnsPopup) return null;

  const handleClosePopup = () => {
    selectSender();
    toggleDnsPopup(false);
  };

  const handleOpenArticle = () => {
    // window.open(
    //   'https://autofunnel.freshdesk.com/support/solutions/articles/202000060373',
    //   '_blank'
    // );
  };

  return (
    <Popup
      onClose={() => toggleDnsPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.title}>Setting DNS Records</div>
        <div className={styles.subTitle}>
          In order for your sending domain to work you need to log in to where
          you bought your domain and add records. If you are not sure how to do
          this, check{' '}
          <Link to color="primary">
            detailed instruction here
          </Link>{' '}
          for most popular domain registrars.
        </div>
        <Table
          className={styles.table}
          columns={columns}
          dataSource={dnsData}
          pagination={false}
        />
      </div>
      <div className={styles.buttonsWrapper}>
        <Button appearance="stroke" height={40} onClick={handleOpenArticle}>
          How to do it?
        </Button>
        <Button appearance="highlighted" height={40} onClick={handleClosePopup}>
          Back to Settings
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  openDnsPopup: state.senders.openDnsPopup,
  domains: state.senders.domains,
  selectedSender: state.senders.selectedSender,
  senders: state.senders.senders,
  loading: state.senders.domainLoading,
});

const mapDispatchToProps = {
  toggleDnsPopup: (payload: boolean) => dnsPopupAction(payload),
  selectSender: (value?: string) => selectSender(value),
  refreshMailDomainRecords: (id: string) => refreshMailDomainRecords(id),
};

export default connect(mapStateToProps, mapDispatchToProps)(DnsRecordPopup);
