import { useState } from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import { BookleTemplateBlock } from 'types';
import { Button } from 'RadixUI/button';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import { cn } from 'utils/Utils';
import ColorSelector from 'Components/ColorSelector/ColorSelector';

interface IProps {
  element: BookleTemplateBlock;
  onUpdate: (key: string, value: string | boolean) => void;
  onUpdateStyle: (key: string, value: string | number) => void;
}

const borderRadiusTypes = [
  { type: 'Square', value: 0 },
  { type: 'Rounded', value: 6 },
  { type: 'Pill', value: 50 },
];

export const ButtonControls = ({
  element,
  onUpdate,
  onUpdateStyle,
}: IProps) => {
  const [borderRadiusType, setBorderRadiusType] = useState('square');

  return (
    <AccordionItem value="button" className="border-none">
      <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
        Button Styling
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label className="text-xs font-medium">Link</Label>
            <Input
              value={element.url}
              onChange={(e) => onUpdate('url', e.target.value)}
              placeholder="https://"
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Border Radius</Label>
            <div className="flex gap-2">
              {borderRadiusTypes.map(({ type, value }) => (
                <Button
                  variant="outline"
                  size="sm"
                  className={cn(
                    'flex-1 bg-background/50',
                    borderRadiusType === type && 'bg-muted'
                  )}
                  onClick={(event: React.MouseEvent) => {
                    event.preventDefault();
                    onUpdateStyle('borderRadius', value);
                    setBorderRadiusType(type);
                  }}
                >
                  {type}
                </Button>
              ))}
            </div>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Button Color</Label>
            <div className="flex gap-2 items-center">
              <ColorSelector
                onChange={(color) => {
                  onUpdateStyle('backgroundColor', color);
                }}
                color={element.styles?.backgroundColor || '#4957D8'}
              />
              <Input
                type="text"
                value={element.styles?.backgroundColor}
                onChange={(e) => {
                  onUpdateStyle('backgroundColor', e.target.value);
                }}
                color={element.styles?.backgroundColor}
                placeholder="#4957D8 or rgb(73 87 216)"
                className="flex-1 bg-background/50 focus:bg-background transition-colors"
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Size</Label>
            <div className="flex gap-2">
              <Input
                type="number"
                value={element.styles?.width || ''}
                onChange={(e) => onUpdateStyle('width', Number(e.target.value))}
                className="flex-1 bg-background/50 focus:bg-background transition-colors"
                min={1}
              />
              <Input
                type="number"
                value={element.styles?.height || ''}
                onChange={(e) =>
                  onUpdateStyle('height', Number(e.target.value))
                }
                className="flex-1 bg-background/50 focus:bg-background transition-colors"
                min={1}
              />
            </div>
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
