import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Controls } from './Controls/Controls';
import { ScrollArea } from 'RadixUI/scroll-area';
import { cn } from 'utils/Utils';
import { Button } from 'RadixUI/button';
import { Copy, Trash2 } from 'lucide-react';
import { BookleTemplateBlock } from 'types';
import { updateItem, updateItemStyle } from '../Draggable/utils';
import { DispatchType, RootState } from 'store/rootReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'RadixUI/alert-dialog';
import { blockTypeToName, MenuItems } from '../Draggable/Sidebar/Sidebar';

interface SidemenuProps {
  activeElement: BookleTemplateBlock | null;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (
    payload: BookleTemplateBlock[],
    updateHistory?: boolean
  ) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onClick?: (e: React.MouseEvent) => void;
}

const EditorSideMenu = ({
  activeElement,
  templateBlocks,
  updateBlocks,
  onDuplicate,
  onDelete,
  onClick,
}: SidemenuProps) => {
  const handleUpdateItem = useCallback(
    (key: string, value: string | boolean) => {
      if (!activeElement) return;

      updateItem(key, value, templateBlocks, updateBlocks, activeElement);
    },
    [activeElement, templateBlocks, updateBlocks]
  );

  const handleUpdateItemStyle = useCallback(
    (key: string, value: string | number | any) => {
      if (!activeElement) return;

      updateItemStyle(key, value, templateBlocks, updateBlocks, activeElement);
    },
    [activeElement, templateBlocks, updateBlocks]
  );

  return (
    <div
      className={cn(
        'fixed top-14 right-0 z-50 w-64 flex flex-col h-[calc(100vh-3.5rem)]',
        'w-[280px] sm:w-[320px] min-w-[280px] sm:min-w-[320px] border-l border-border',
        'bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60',
        'shadow-2xl shadow-primary/5',
        'transition-all duration-500 ease-in-out',
        !activeElement && 'translate-x-full'
      )}
      id="editor_side_menu"
      onClick={onClick}
    >
      <div className="flex h-14 items-center justify-between border-b border-border/40 px-4 sm:px-6 bg-muted/50 backdrop-blur-sm">
        <div className="space-y-1">
          <h2 className="text-sm font-semibold tracking-tight">
            {activeElement
              ? blockTypeToName(activeElement.type as MenuItems) + ' Properties'
              : 'No Element Selected'}
          </h2>
        </div>
      </div>
      <ScrollArea className="flex-1 bg-gradient-to-b from-background to-muted/20">
        <div className="p-6">
          <div className="relative">
            <div className="absolute -inset-4 bg-gradient-to-r from-primary/5 via-primary/10 to-primary/5 rounded-lg blur-lg opacity-50" />
            <div className="relative">
              <Controls
                element={activeElement}
                onUpdate={handleUpdateItem}
                onUpdateStyle={handleUpdateItemStyle}
              />
            </div>
          </div>
        </div>
      </ScrollArea>
      {activeElement && (
        <div className="border-t border-border/40 p-4 space-y-2 bg-muted/50">
          <Button
            variant="outline"
            size="sm"
            onClick={() => onDuplicate()}
            className="w-full justify-start"
          >
            <Copy className="h-4 w-4 mr-2" />
            Duplicate Element
          </Button>

          <Button
            variant="outline"
            size="sm"
            className="w-full justify-start text-destructive hover:text-destructive hover:bg-destructive/10"
            onClick={onDelete}
          >
            <Trash2 className="h-4 w-4 mr-2" />
            Delete Element
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
  activeElement: state.books.activeBlock,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateBlocks: (payload: BookleTemplateBlock[], updateHistory?: boolean) =>
    dispatch(updateBookleTemplateBlocks(payload, updateHistory)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorSideMenu);
