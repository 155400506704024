import { IUserAccess } from 'types';
import { ReactComponent as AIcon } from 'Assets/icons/appIcons/a.svg';
import { ReactComponent as CartSvg } from 'Assets/icons/cart.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as StarIcon } from 'Assets/icons/appIcons/star.svg';
import { ReactComponent as ChatIcon } from 'Assets/icons/appIcons/chat.svg';
import { ReactComponent as HelpIcon } from 'Assets/icons/appIcons/help.svg';
import { ReactComponent as ZapierSvg } from 'Assets/icons/zapier.svg';
import { ReactComponent as LabelIcon } from 'Assets/icons/appIcons/label.svg';
import { ReactComponent as BrainIcon } from 'Assets/icons/appIcons/brain.svg';
import { ReactComponent as ImageIcon } from 'Assets/icons/appIcons/image.svg';
import { ReactComponent as DomainsSvg } from 'Assets/icons/domains.svg';
import { ReactComponent as BillingSvg } from 'Assets/icons/billing.svg';
import { ReactComponent as Label2Icon } from 'Assets/icons/appIcons/label2.svg';
import { ReactComponent as RocketIcon } from 'Assets/icons/appIcons/rocket.svg';
import { ReactComponent as CoursesIcon } from 'Assets/icons/appIcons/courses.svg';
import { ReactComponent as ProfileIcon } from 'Assets/icons/appIcons/profile.svg';
import { ReactComponent as ProductsSvg } from 'Assets/icons/sideMenu/products.svg';
import { ReactComponent as ProductsIcon } from 'Assets/icons/appIcons/products.svg';
import { ReactComponent as TemplatesIcon } from 'Assets/icons/appIcons/templates.svg';
import { ReactComponent as OutreachPageSvg } from 'Assets/icons/outreach.svg';
import { ReactComponent as MailSequenceIcon } from 'Assets/icons/mailSequence.svg';
import { ReactComponent as ChangePageBookSvg } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as ChangePageThemeSvg } from 'Assets/icons/changePageTheme.svg';
import { ReactComponent as ChangePageWorldSvg } from 'Assets/icons/changePageWorld.svg';
import { ReactComponent as ChangePagePodcastSvg } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as Wallet } from 'Assets/icons/wallet.svg';
import { ReactComponent as ChangePageContactsSvg } from 'Assets/icons/changePageContacts.svg';
import { APPS } from 'Components/AutoSaas/constants';
import { isAdmin } from 'utils/Utils';

export const pageTypes = [
  'domains',
  'domains-senders',
  'wallet',
  'funnel-templates',
  'mailchimp',
  'billing',
  'billing-settings',
  'zapier',
  'auto-responders',
  'broadcasts',
  'templates',
  'projects',
  'books',
  'products',
  'settings',
  'copy-craft',
  'contacts',
  'podcaster',
  'autosaas',
  'agency',
  'outreach',
];

export interface PageType {
  key: string;
  name: string;
  icon: string;
  access?: boolean;
  redirectUrl?: string;
  autosaas?: {
    redirectUrl?: string;
    key?: string;
    openSettingModal?: boolean;
    appId?: string;
  };
}

export const menuItems = (
  userAccess: IUserAccess,
  isAdmin: boolean
): PageType[] => [
  {
    key: 'projects',
    name: 'Funnels',
    icon: 'icons/changePageWorld.svg',
    access: userAccess.funnel,
    redirectUrl: '/console/projects',
    autosaas: {
      redirectUrl: '/console/funnel-templates',
      key: APPS.PAGES,
    },
  },

  {
    key: 'books',
    name: 'Bookle',
    icon: 'icons/changePageBook.svg',
    access: userAccess.bookle,
    redirectUrl: '/console/books',
    autosaas: {
      key: APPS.BOOKLE,
    },
  },
  {
    key: 'copy-craft',
    name: 'CopyCraft',
    icon: 'icons/changePageTheme.svg',
    access: userAccess.copyCraft,
    redirectUrl: '/console/copy-craft',
    autosaas: {
      key: APPS.COPYCRAFT,
    },
  },
  {
    key: 'podcaster',
    name: 'Podcaster',
    icon: 'icons/changePodcast.svg',
    access: userAccess.podcast,
    redirectUrl: '/console/podcaster',
    autosaas: {
      key: APPS.PODCASTER,
    },
  },
  {
    key: 'outreach',
    name: 'Messaging',
    icon: 'icons/outreach.svg',
    access: userAccess.outreach,
    redirectUrl: '/console/outreach/broadcasts',
    autosaas: {
      key: APPS.OUTREACH,
    },
  },
  // {
  //   key: 'outreach',
  //   name: 'Messaging',
  //   icon: 'icons/outreach.svg',
  //   access: hasAutoSaasAccess || isAdmin,
  //   redirectUrl: '/console/outreach',
  //   autosaas: {
  //     key: APPS.OUTREACH,
  //   },
  // },
  {
    key: 'contacts',
    name: 'Contacts',
    icon: 'icons/changePageContacts.svg',
    access: userAccess.contact,
    redirectUrl: '/console/contacts',
    autosaas: {
      key: APPS.CONTACTS,
    },
  },
  {
    key: 'products',
    name: 'Products',
    icon: 'icons/sideMenu/products.svg',
    access: userAccess.funnel,
    redirectUrl: '/console/settings/products',
    autosaas: {
      key: APPS.PRODUCTS,
    },
  },
];

export const submenuItems = (isAdmin: boolean) => ({
  outreach: [
    {
      key: 'broadcasts',
      name: 'Broadcasts',
      icon: <MailIcon />,
      access: true,
      redirectUrl: '/console/outreach/broadcasts',
    },
    // {
    //   key: 'workflows',
    //   name: 'Workflows',
    //   icon: <CoursesSvg />,
    //   access: isAdmin(),
    //   redirectUrl: '/console/outreach/workflows',
    // },
    {
      key: 'auto-responders',
      name: 'Autoresponders',
      icon: <MailSequenceIcon />,
      access: isAdmin,
      redirectUrl: '/console/outreach/auto-responders',
    },
    {
      key: 'templates',
      name: 'Email Templates',
      icon: <TemplatesIcon width={26} height={26} />,
      access: true,
      redirectUrl: '/console/outreach/templates',
    },
  ],
  settings: [
    {
      key: 'domains-senders',
      name: 'Domains & Senders',
      icon: <DomainsSvg />,
      access: true,
      redirectUrl: '/console/settings/domains-senders',
    },
    {
      key: 'domains',
      name: 'Domains',
      icon: <DomainsSvg />,
      access: false,
      redirectUrl: '/console/settings/domains',
    },
    {
      key: 'billing-settings',
      name: 'Stripe',
      icon: <CartSvg />,
      access: true,
      redirectUrl: '/console/settings/billing-settings',
    },
    {
      key: 'billing',
      name: 'Billing',
      icon: <BillingSvg />,
      access: true,
      redirectUrl: '/console/settings/billing',
    },
    {
      key: 'zapier',
      name: 'Zapier',
      icon: <ZapierSvg />,
      access: true,
      redirectUrl: '/console/settings/zapier',
    },
    {
      key: 'wallet',
      name: 'Wallet',
      icon: <Wallet />,
      access: true,
      redirectUrl: '/console/settings/wallet',
    },
  ],
});

export const AutoSassIconsOptions = [
  {
    icon: <ChangePageWorldSvg />,
    path: 'icons/changePageWorld.svg',
  },
  {
    icon: <ChangePageBookSvg />,
    path: 'icons/changePageBook.svg',
  },
  {
    icon: <ChangePageThemeSvg />,
    path: 'icons/changePageTheme.svg',
  },
  {
    icon: <ChangePagePodcastSvg />,
    path: 'icons/changePodcast.svg',
  },
  {
    icon: <OutreachPageSvg />,
    path: 'icons/outreach.svg',
  },
  {
    icon: <ChangePageContactsSvg />,
    path: 'icons/changePageContacts.svg',
  },
  {
    icon: <ProductsSvg />,
    path: 'icons/sideMenu/products.svg',
  },
  {
    icon: <AIcon />,
    path: 'icons/appIcons/a.svg',
  },
  {
    icon: <BrainIcon />,
    path: 'icons/appIcons/brain.svg',
  },
  {
    icon: <ChatIcon />,
    path: 'icons/appIcons/chat.svg',
  },
  {
    icon: <CoursesIcon />,
    path: 'icons/appIcons/courses.svg',
  },
  {
    icon: <HelpIcon />,
    path: 'icons/appIcons/help.svg',
  },
  {
    icon: <ImageIcon />,
    path: 'icons/appIcons/image.svg',
  },
  {
    icon: <LabelIcon />,
    path: 'icons/appIcons/label.svg',
  },
  {
    icon: <ProductsIcon />,
    path: 'icons/appIcons/products.svg',
  },
  {
    icon: <ProfileIcon />,
    path: 'icons/appIcons/profile.svg',
  },
  {
    icon: <StarIcon />,
    path: 'icons/appIcons/star.svg',
  },
  {
    icon: <TemplatesIcon />,
    path: 'icons/appIcons/templates.svg',
  },
  {
    icon: <Label2Icon />,
    path: 'icons/appIcons/label2.svg',
  },
  {
    icon: <RocketIcon />,
    path: 'icons/appIcons/rocket.svg',
  },
];
