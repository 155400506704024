import React from 'react';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  removeAll?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ removeAll, ...props }) => {
  return (
    <label className={styles['custom-checkbox']}>
      <input className={styles.checkbox} type="checkbox" {...props} />
      <span
        className={`${styles.checkmark} ${removeAll ? styles.minus : ''}`}
      />
    </label>
  );
};

export default Checkbox;
