import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DispatchType, RootState } from 'store/rootReducer';
import { updateCurrentAudioAction } from 'store/podcasts/podcastActions';
import {
  PodcastMusicType,
  podcastsMusics,
} from 'Pages/PodcastGenerator/constants';
import { ReactComponent as MusicIcon } from 'Assets/icons/music.svg';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './SelectMusicForm.module.scss';

interface IProps {
  selectedGenres: string[];
  selectedMusicId: string;
  handleChange: (item: PodcastMusicType) => void;
  currentPlayingAudio: string | null;
  updateCurrentAudio: (audioKey: string | null) => void;
}

const SelectMusic = ({
  selectedGenres,
  selectedMusicId,
  handleChange,
  currentPlayingAudio,
  updateCurrentAudio,
}: IProps) => {
  const [filteredMusics, setFilteredMusics] = useState<PodcastMusicType[]>([]);

  useEffect(() => {
    if (selectedGenres?.length) {
      const newData: PodcastMusicType[] = podcastsMusics.filter((item) =>
        selectedGenres?.every((genre: string) => item.tags.includes(genre))
      );

      setFilteredMusics(newData);
    } else {
      setFilteredMusics([]);
    }
  }, [selectedGenres]);

  return (
    <div className={styles.container}>
      {!selectedGenres?.length && (
        <div className={styles.musicTitle}>
          <MusicIcon />
          Select a genre to view music tracks here.
        </div>
      )}
      <div className={styles.musicContainer}>
        {selectedGenres?.length > 0 && (
          <>
            {filteredMusics?.length
              ? 'Choose a music sample for your podcast episode:'
              : 'No Items to choose'}
            {filteredMusics?.map((item, index) => (
              <div className={styles.audioContainer} key={item.id}>
                <AudioBlock
                  selectable
                  theme="dark"
                  label={item.name}
                  selected={selectedMusicId === item?.id}
                  onClick={() => handleChange(item)}
                  audioUrl={item.introUrl}
                  imageSrc={item.image}
                  audioId={item.id + index.toString()}
                  updateCurrentAudio={updateCurrentAudio}
                  currentPlayingAudio={currentPlayingAudio}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentPlayingAudio: state.podcasts.currentPlayingAudio,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateCurrentAudio: (audio: string | null) =>
    dispatch(updateCurrentAudioAction(audio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectMusic);
