import { useState } from 'react';
import { connect } from 'react-redux';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as Templates } from 'Assets/icons/templates.svg';
import { graphQlCall } from 'graphql/utils';
import { BookleTemplateBlock } from 'types';
import queries from 'graphql/queries';
import GenerationPreview from 'Pages/PageGenerationEditor/GenerationPreview/GenerationPreview';
import EmptyTemplates from 'Components/EmptyTemplates/EmptyTemplates';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import Tabs from 'Components/Templates/Tabs/Tabs';
import Button from 'UILib/Button/Button';

import styles from './ChooseCampaign.module.scss';

interface IProps {
  handleBack: () => void;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  handleSubmit: (templateId: string) => void;
  loading: boolean;
}

const ChooseTemplatesScreen = ({
  handleBack,
  updateBlocks,
  handleSubmit,
  loading,
}: IProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  const handleSelectTemplate = async (id: string, isEmpty?: boolean) => {
    try {
      setSelectedTemplate(id);
      if (!isEmpty) {
        const response = await graphQlCall({
          queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
          values: { id },
          headerType: 'USER-AUTH',
        });
        if (response.layout[0]?.blocks) {
          updateBlocks(response.layout[0]?.blocks);
        } else {
          updateBlocks(response.layout);
        }
      } else {
        updateBlocks([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.templatesContainer}>
      <div className={styles.templatesMenu}>
        <Tabs
          className={styles.tabsContainer}
          contentClassName={styles.contentClassName}
          titleClassName={styles.tabTitleClassName}
          tabs={[
            {
              title: 'Empty',
              content: (
                <EmptyTemplates
                  handleSelect={handleSelectTemplate}
                  selectedTemplate={selectedTemplate}
                />
              ),
            },
            {
              title: 'Library',
              content: <></>,
            },
            {
              title: 'Your Own',
              content: <></>,
            },
          ]}
        />
        <div className={styles.buttonsContainer}>
          <Button
            appearance="stroke"
            prefixIcon={<Back />}
            onClick={handleBack}
          >
            Previous
          </Button>
          <Button
            appearance="solid"
            onClick={() => {
              if (!loading) {
                handleSubmit(selectedTemplate);
              }
            }}
            disabled={loading}
          >
            {loading ? <CircleLoader color="#ffffff" size={18} /> : 'Select'}
          </Button>
        </div>
      </div>
      <div className={styles.preview}>
        {selectedTemplate ? (
          <div className={styles.previewContent}>
            <GenerationPreview disabled />
          </div>
        ) : (
          <div className={styles.emptyPreview}>
            <Templates className={styles.emptyIcon} />
            Select Template to Preview
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseTemplatesScreen);
