import { CustomText } from 'Components/TextEditorToolbar/types';
import { Editor, Element, Text, Transforms } from 'slate';

export const getCurrentAttribute = (
  attribute: keyof CustomText,
  defaultValue: string,
  editor: Editor
) => {
  if (!editor) {
    return defaultValue;
  }
  const [match] = Editor.nodes<CustomText>(editor, {
    at: editor.selection as any,
    match: (n) => Text.isText(n) && !!(n as CustomText)[attribute],
  });
  return match ? (match[0][attribute] as string) || defaultValue : defaultValue;
};

export const applyTextStyle = (
  type: string,
  value: string | number,
  editor: Editor
) => {
  if (!editor?.selection) return;

  const { selection } = editor;
  const isCollapsed =
    selection.anchor.offset === selection.focus.offset &&
    selection.anchor.path.toString() === selection.focus.path.toString();

  if (isCollapsed) {
    for (const [node, path] of Editor.nodes(editor, {
      match: Text.isText,
      at: [],
    })) {
      Transforms.setNodes(editor, { [type]: value }, { at: path });
    }
  } else {
    Transforms.setNodes(
      editor,
      { [type]: value },
      { match: (n) => Text.isText(n), split: true }
    );
  }
};

export const applyTextLineHeight = (
  type: string,
  value: string | number,
  editor: Editor
) => {
  if (!editor?.selection) return;

  const { selection } = editor;
  const isCollapsed =
    selection.anchor.offset === selection.focus.offset &&
    selection.anchor.path.toString() === selection.focus.path.toString();

  if (isCollapsed) {
    for (const [_, path] of Editor.nodes(editor, {
      match: Text.isText,
      at: [],
    })) {
      Transforms.setNodes(editor, { [type]: value }, { at: path });
    }

    for (const [_, path] of Editor.nodes(editor, {
      match: (n) => Element.isElement(n),
      at: [],
    })) {
      Transforms.setNodes(editor, { [type]: value }, { at: path });
    }
  } else {
    const [match] = Editor.nodes(editor, {
      match: (n) => Element.isElement(n),
      mode: 'lowest',
    });

    if (match) {
      Transforms.setNodes(
        editor,
        { [type]: value },
        {
          match: () => Editor.isBlock(editor, match[0] as Element),
          split: false,
        }
      );
    }
  }
};

export const getColorFromShadow = (shadow: string) => {
  return shadow?.match(
    /#(?:[0-9a-fA-F]{3}){1,2}|\b(?:rgb|hsl)a?\(\s*\d+\s*,\s*\d+\s*,\s*\d+(?:\s*,\s*[0-9.]+)?\s*\)|\b[a-zA-Z]+\b/g
  )?.[0];
};

export const getBorderProperties = (border: string) => {
  if (!border || typeof border !== 'string') {
    return { width: 0, style: '', color: '' };
  }

  const borderStyles = [
    'none',
    'solid',
    'dashed',
    'dotted',
    'double',
    'groove',
    'ridge',
    'inset',
    'outset',
    'hidden',
  ];

  const parts = border.split(/\s+/);
  let width = 0,
    style = '',
    color = '';

  for (let part of parts) {
    if (borderStyles.includes(part)) {
      style = part;
    } else if (
      part.match(/^\d+(px|em|rem|%|vw|vh|vmin|vmax|pt|cm|mm|ex|ch|in)?$/)
    ) {
      const widthPart = part.replace(
        /(px|em|rem|%|vw|vh|vmin|vmax|pt|cm|mm|ex|ch|in)$/,
        ''
      );
      if (!isNaN(Number(widthPart))) {
        width = Number(widthPart);
      }
    } else {
      color = part;
    }
  }

  return { width, style, color };
};
