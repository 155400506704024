import { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Tag } from '../Helper/types';
import { graphQlCall } from 'graphql/utils';
import PhoneInput from 'react-phone-number-input';
import clsx from 'clsx';
import uuid from 'react-uuid';
import QUERIES from 'graphql/queries';
import Popup from 'UILib/Popup/Popup';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';

import 'react-phone-number-input/style.css';
import styles from 'Pages/PageContacts/AddContact/AddContact.module.scss';

interface ITag {
  value: string;
  label: string;
}

interface ICreateContacts {
  _id: string;
  email: string;
  phone?: string;
  tagsId: string[];
  data?: string;
}

interface IAddContact {
  isPopupOpen: boolean;
  setPopupOpen: (isOpen: boolean) => void;
  funnelId: string;
  pageId: string;
  uniqueTags: Tag[];
  fetchUserContacts: () => void;
  handleAddNewTag: (tagName: string) => Promise<Tag>;
}

const AddContactsPopup = (props: IAddContact) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [country, setCountry] = useState('');
  const [addr, setAddr] = useState('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tags, setTags] = useState<ITag[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setTags(props.uniqueTags.map((e) => ({ value: e._id, label: e.name })));
  }, [props.uniqueTags]);

  const createContact = async (data: ICreateContacts) => {
    return await graphQlCall({
      headerType: 'USER-AUTH',
      queryTemplateObject: QUERIES.CREATE_CONTACT_CONSOLE,
      values: data,
    });
  };

  const handleClosePopup = () => {
    clearForm();
    props.setPopupOpen(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    const contactData = { addr, country, name };
    const serializedData = JSON.stringify(contactData);

    const tempId = uuid();
    const data: ICreateContacts = {
      _id: tempId,
      email,
      tagsId: selectedTags,
      phone,
      // phone: phone.replace(/\D/g, ''),
      data: serializedData,
    };

    try {
      const newContact = await createContact(data);
      props.fetchUserContacts();
      handleClosePopup();
    } finally {
      setLoading(false);
    }
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setPhone('');
    setCountry('');
    setAddr('');
    setSelectedTags([]);
    setLoading(false);
  };

  const handleAddTag = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const response = await props.handleAddNewTag(inputValue);

    setTags([...tags, { value: response._id, label: response.name }]);
    setSelectedTags([...selectedTags, response._id]);
  };

  if (!props.isPopupOpen) {
    return null;
  }

  return (
    <Popup onClose={handleClosePopup} wrapperClassName={styles.popup}>
      <h2 className={styles.title}>Add Contacts</h2>
      <div className={styles.subtitle}>
        Fill in the details of your new contact below.
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          value={name}
          className={styles.formInput}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
          border="stroke"
        />
        <Input
          type="email"
          value={email}
          className={styles.formInput}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email (Optional)"
          border="stroke"
        />
        <PhoneInput
          placeholder="Phone Number (Optional)"
          defaultCountry="US"
          value={phone}
          className={clsx(styles.phoneInput, styles.formInput)}
          onChange={(value) => {
            if (value) setPhone(value);
            else setPhone('');
          }}
        />
        <Input
          value={country}
          className={styles.formInput}
          onChange={(e) => setCountry(e.target.value)}
          placeholder="Country (Optional)"
          border="stroke"
        />
        <Input
          value={addr}
          className={styles.formInput}
          onChange={(e) => setAddr(e.target.value)}
          placeholder="Address (Optional)"
          border="stroke"
        />
        <Select
          mode="multiple"
          placeholder="Add Tag"
          className={styles.select}
          options={tags}
          onChange={(e) => setSelectedTags(e)}
          optionFilterProp="label"
          value={selectedTags}
          onSearch={(value) => setInputValue(value)}
          onDropdownVisibleChange={(open) => {
            if (!open) setInputValue('');
          }}
          dropdownRender={(menu) => (
            <>
              {(tags.find((tag) => tag.label.includes(inputValue)) ||
                !inputValue) &&
                menu}
              {inputValue && !tags.find((tag) => tag.label === inputValue) && (
                <div className={styles.addTagButton} onClick={handleAddTag}>
                  <span>+ Add "{inputValue}"</span>
                </div>
              )}
            </>
          )}
        />
        <Button
          type="submit"
          disabled={loading}
          appearance="highlighted"
          className={styles.submitButton}
          height={40}
        >
          {loading ? <CircleLoader color="#ffffff" size={18} /> : 'Add Contact'}
        </Button>
      </form>
    </Popup>
  );
};

export default AddContactsPopup;
