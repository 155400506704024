export const GET_SASS_DESIGN = {
  operationName: 'getSaasDesign',
  query: `query getSaasDesign ($domain: String!) {
            getSaasDesign (domain: $domain){
              colorScheme
              domain
              logoUrl
              faviconUrl
              name
              helpCenterUrl
              alerts {
                success
                successHover
                error
                waiting
              }
              sideBar {
                background
                appHover
                appSelected
                divider
                text
                selectedText
                hoverText
              }
              accent {
                accent
                accentHover
                darkAccent
                darkAccentHover
              }
              apps {
                name
                key
                enabled
              }
            }
          }`,
};
