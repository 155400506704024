import { QueryObject, GraphQlHeadersType } from 'types';
import { getToken, validateToken, getAgencyToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';

export const prepareQuery = (query: string, values: any) => {
  let queryKeys = '';
  let queryValues = '';
  for (const key in values) {
    if (values[key] !== undefined) {
      const value = values[key];
      let queryType = 'String!';
      switch (typeof value) {
        case 'string':
          queryType = 'String!';
          break;
        case 'boolean':
          queryType = 'Boolean!';
          break;
        case 'number':
          queryType = 'Float!';
          break;
      }
      queryKeys += `$${key}: ${queryType}\n`;
      queryValues += `${key}: $${key}\n`;
    }
  }
  return query.replace('<KEYS>', queryKeys).replace('<VALUES>', queryValues);
};

export const prepareQueryObject = (
  queryTemplateObject: QueryObject,
  values: any
): QueryObject => {
  const queryObject = { ...queryTemplateObject };
  queryObject.query = prepareQuery(queryObject.query, values);
  queryObject.variables = values;
  return queryObject;
};

interface GraphQlPayload {
  queryTemplateObject: QueryObject;
  values?: any;
  headerType?: GraphQlHeadersType;
}

export const graphQlCall = async (payload: GraphQlPayload) => {
  const commonHeaders = {
    'Content-Type': 'application/json',
  };

  const headers: any = {};

  if (payload.headerType && payload.headerType === 'USER-AUTH') {
    headers.authorization = getToken();
    headers.authorization = await validateToken(headers.authorization);
  }

  if (payload.headerType && payload.headerType === 'AGENCY_AUTH') {
    headers.authorization = getAgencyToken();
    headers.authorization = await validateToken(headers.authorization, true);
  }

  const query = payload.values
    ? prepareQueryObject(payload.queryTemplateObject, payload.values)
    : payload.queryTemplateObject;

  const response = await fetch(`${PAGECRAFT_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      ...commonHeaders,
      ...headers,
    },
    body: JSON.stringify(query),
  }).then((response) => response.json());

  if (response.errors?.length) {
    throw new Error('GRAPH QL ERROR: ' + response.errors[0].message);
  }

  if (!response.data?.[query.operationName]) {
    throw new Error('GRAPH QL ERROR');
  }
  return response.data[query.operationName];
};
