import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IAutoresponder } from 'types';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Pagination from 'UILib/Pagination/Pagination';
import AutoResponderItem from 'Components/AutoResponder/AutoResponderItem/AutoResponderItem';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import BroadcastSkeleton from 'Components/Skeletons/BroadcastSkeleton/BroadcastSkeleton';
import clsx from 'clsx';

import styles from './AutoResponders.module.scss';

const sortCriteria = [
  {
    label: 'Last Update',
    value: 'updatedAt',
    sortAsc: false,
  },
  {
    label: 'Name',
    value: 'name',
    sortAsc: true,
  },
];

const LIMIT = 20;

const AutoResponders = () => {
  const history = useHistory();

  const [searchTerm, setSearchTerm] = useState<string>();
  const [autoResponders, setAutoResponders] = useState<IAutoresponder[]>([]);
  const [autoRespondersStats, setAutoRespondersStats] = useState<
    { delivered: number; opened: number; clicked: number }[]
  >([]);
  const [sortBy, setSortBy] = useState<string>('updatedAt');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);

  const getAutoResponders = () => {
    graphQlCall({
      queryTemplateObject: queries.GET_AUTO_RESPONDERS_WITH_PAGINATION_QUERY,
      headerType: 'USER-AUTH',
      values: {
        skip: (currentPage - 1) * LIMIT,
        limit: LIMIT,
        search: searchTerm,
        sortBy,
        sortAsc: sortCriteria.find((item) => item.value === sortBy)?.sortAsc,
      },
    })
      .then((data) => {
        setTotalCount(data.total);
        setAutoResponders(data.autoresponders || []);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getAutoResponders();
  }, [searchTerm, sortBy, currentPage]);

  useEffect(() => {
    if (autoResponders.length === 0) return;

    graphQlCall({
      queryTemplateObject: queries.GET_AUTO_RESPONDERS_STATISTICS,
      values: { ids: autoResponders.map((item) => item._id) },
      headerType: 'USER-AUTH',
    }).then(setAutoRespondersStats);
  }, [autoResponders]);

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.contentContainer, {
          [styles.loaderContainer]: loading,
        })}
      >
        {loading ? (
          <BroadcastSkeleton />
        ) : (
          <>
            <DashboardHeader
              total={totalCount + ' Projects'}
              title="Autoresponders Projects"
              buttonText="New Project"
              handleAddNewItem={() =>
                history.push('/console/auto-responders/create')
              }
              handleSearch={setSearchTerm}
              handleSort={setSortBy}
              sortOptions={sortCriteria}
              sortBy={sortBy}
            />
            <div className={styles.autoResponderElements}>
              {autoResponders.map((item, index) => {
                return (
                  <AutoResponderItem
                    key={item?._id || index}
                    item={item as any}
                    stats={autoRespondersStats[index] ?? {}}
                    fetchProjects={getAutoResponders}
                  />
                );
              })}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(totalCount / LIMIT)}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AutoResponders;
