export const UPDATE_MAIL_DRAFT_MUTATION = {
  operationName: 'updateMailDraft',
  query: `mutation updateMailDraft(
                    <KEYS>
                ) {
                updateMailDraft(
                  <VALUES>
                ) {
                  _id
                  html
                  text
                  name
                  data
                  senderName
                  createdAt
                  updatedAt 
                }
              }`,
};
