export const GET_AUTO_RESPONDER = {
  operationName: 'getAutoresponder',
  query: `query getAutoresponder ($id: String!) {
            getAutoresponder(id: $id) {
                autoresponder {
                    _id
                    name
                    description
                    createdAt
                    updatedAt
                    thumbnailUrl
                    isActive
                    isExclusive
                    entryTag{
                        _id
                        name
                    }
                    exitTag{
                        _id
                        name
                    }
                    sender {
                        _id
                    }
                }
                sequence {
                    mailDraft {
                    _id
                    subject
                    preheader
                    createdAt
                    updatedAt
                    thumbnailUrl
                }
                wait {
                    periodType
                    periodAmount
                    seconds
                }
            }
        }
    }`,
};
