import { api, getToken, getUserId } from '../../utils/Utils';
import { pushAlert } from '../alerts/alertsActions';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import {
  IFunnel,
  IGeneralProject,
  ITemplateItem,
  IGeneralProjectName,
  IDuplicateProjectNameAction,
  IDuplicatePageOtherAccountPayload,
  IDuplicateFunnelOtherAccountPayload,
} from 'types';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';
import { IAddPageFromAiPayload } from '../../types';

export const PROJECTS_FETCHED = 'PROJECTS_FETCHED';
export const PROJECTS_WITH_PAGINATION_FETCHED =
  'PROJECTS_WITH_PAGINATION_FETCHED';
export const PROJECTS_NAME_FETCHED = 'PROJECTS_NAME_FETCHED';
export const PROJECT_DUPLICATE = 'PROJECT_DUPLICATE';
export const PROJECT_NAME_CHANGE = 'PROJECT_NAME_CHANGE';
export const ACTIVE_PROJECT = 'ACTIVE_PROJECT';
export const ACTIVE_PROJECT_DATA = 'ACTIVE_PROJECT_DATA';
export const ARCHIVE_PROJECT = 'ARCHIVE_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const LOADING = 'PROJECTS_LOADING';
export const PROJECTS_NAMES_LOADING = 'PROJECTS_NAMES_LOADING';
export const PROJECT_DUPLICATE_LOADING = 'PROJECT_DUPLICATE_LOADING';
export const PROJECT_DATA_LOADING = 'PROJECTS_DATA_LOADING';
export const PROJECT_STATS_LOADING = 'PROJECT_STATS_LOADING';
export const PROJECT_ADD = 'PROJECT_ADD';
export const DRAW_FETCHED = 'DRAW_FETCHED';
export const STATISTIC_BY_PERIOD_FETCHED = 'STATISTIC_BY_PERIOD_FETCHED';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const activeProject = (payload: string | null) => ({
  type: ACTIVE_PROJECT,
  payload,
});

export const handleRedirectToEditor = (
  funnelId: string,
  useAIGeneration = false,
  subpageName = 'optin'
) => {
  const userName = getUserId();
  const page = useAIGeneration ? 'generate' : 'edit';
  const url = `/edit/${page}/${userName}/${funnelId}/${subpageName}`;
  window.open(url, '_self');
};

export const addPageFromAiAction = (args: IAddPageFromAiPayload) => async (
  dispatch: DispatchType
) => {
  const page: IFunnel = await graphQlCall({
    queryTemplateObject: QUERIES.CREATE_PAGE_FROM_AI_MUTATION,
    headerType: 'USER-AUTH',
    values: {
      funnelId: args.funnelId,
      pagesInfo: args.pagesInfo,
    },
  });

  // console.log('page is creaed:', page);
};

export const addPageAction = (
  template: ITemplateItem,
  useAIGeneration = false
) => async (dispatch: DispatchType, getState: GetStateType) => {
  let pageId = '';
  try {
    dispatch(loading(true));
    const state = getState();
    const activeProject = state.projects.active;
    if (!activeProject) {
      throw new Error('no active project');
    }

    const page: IFunnel = await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_PAGE_FROM_TEMPLATE_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        templateId: template._id,
        funnelId: activeProject._id,
        name: 'Page ' + (activeProject.pages.length + 1),
      },
    });

    if (activeProject && activeProject.pages?.length && activeProject.order) {
      const activePages = activeProject.pages;
      activeProject.pages = [...activePages, page];
      if (activeProject.order && activeProject.order.length == 0) {
        let page = activeProject.pages[0];
        activeProject.order = [{ id: page._id, enabled: page.enabled }];
      }
      activeProject.order.push({ id: page.id, enabled: page.enabled });
    }

    dispatch({
      type: UPDATE_PROJECT,
      payload: activeProject,
    });
    pageId = page._id;
  } catch (error: any) {
    dispatch(pushAlert('error', 'API error', error.message));
  }
  dispatch(loading(false));
  handleRedirectToEditor(pageId, useAIGeneration);
};

export const addFunnelAction = (funnelName?: string) => async (
  dispatch: DispatchType
) => {
  const name = funnelName || 'Untitled';
  const newProject: IGeneralProject = await graphQlCall({
    queryTemplateObject: QUERIES.ADD_BLANK_FUNNEL_MUTATION,
    headerType: 'USER-AUTH',
    values: {
      name: name,
    },
  });

  const addProject = {
    _id: newProject._id,
    name: newProject.name,
    position: newProject.position,
  };
  dispatch({
    type: PROJECT_ADD,
    payload: addProject,
  });
  dispatch(setActiveProjectId(newProject._id));
};

export const setActiveProjectId = (id: string | null) => async (
  dispatch: DispatchType
) => {
  dispatch(activeProject(id));
};

export const setProjectName = (id: string, newName: string) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: PROJECT_NAME_CHANGE,
    payload: { id: id, newName: newName },
  });
};

export const getActiveProjectData = (id: string, silent?: boolean) => async (
  dispatch: DispatchType
) => {
  if (!silent) {
    dispatch(loadingProjectData(true));
  }
  try {
    const activeProjectGQLData: any = await graphQlCall({
      queryTemplateObject: QUERIES.GET_FUNNELS_WITH_PAGES_QUERY,
      headerType: 'USER-AUTH',
      values: {
        id: id,
      },
    });
    const activeProjectData = {
      ...activeProjectGQLData.funnel,
      pages: activeProjectGQLData.pages,
    };

    dispatch({
      type: ACTIVE_PROJECT_DATA,
      payload: activeProjectData,
    });
  } catch (error: any) {
    console.error(error);
  }
  if (!silent) {
    dispatch(loadingProjectData(false));
  }
};

export const loading = (payload: boolean) => ({
  type: LOADING,
  payload,
});

export const loadingProjectsNames = (payload: boolean) => ({
  type: PROJECTS_NAMES_LOADING,
  payload,
});
export const loadingProjectDuplicate = (payload: boolean) => ({
  type: PROJECT_DUPLICATE_LOADING,
  payload,
});

export const loadingProjectData = (payload: boolean) => ({
  type: PROJECT_DATA_LOADING,
  payload,
});

export const loadingAction = (isLoading: boolean) => (
  dispatch: DispatchType
) => {
  dispatch(loading(isLoading));
};

export const duplicatePage = (pageId: string, funnelId?: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    const newPage = await graphQlCall({
      queryTemplateObject: QUERIES.DUPLICATE_PAGE_MUTATION,
      values: {
        id: pageId,
        funnelId: funnelId,
      },
      headerType: 'USER-AUTH',
    });
    if (!funnelId) {
      const state = getState();

      let activeProject = { ...state.projects.active };
      if (activeProject) {
        activeProject.pages = [...(activeProject.pages ?? []), newPage];

        const initialPageIndex = activeProject.order?.findIndex(
          (page) => page.id === pageId
        );
        activeProject.order?.splice(
          (initialPageIndex ?? activeProject.order.length - 1) + 1,
          0,
          {
            id: newPage._id,
            enabled: newPage.enabled,
          }
        );
      }

      dispatch({
        type: UPDATE_PROJECT,
        payload: activeProject,
      });
    } else {
      setActiveProjectId(funnelId);
      const url = `/console/projects/${funnelId}`;
      window.open(url, '_self');
    }
  } catch (error: any) {
    dispatch(pushAlert('error', 'API error', error.message));
  }
  dispatch(loading(false));
};

export const duplicatePageOtherAccountAction = (
  payload: IDuplicatePageOtherAccountPayload
) => async (dispatch: DispatchType, getState: GetStateType) => {
  await graphQlCall({
    queryTemplateObject: QUERIES.DUPLICATE_PAGE_TO_OTHER_ACCOUNT,
    values: {
      targetAccountOwner: payload.targetAccountOwner,
      sourceAccountOwner: payload.sourceAccountOwner,
      targetFunnelId: payload.funnelId,
      pageId: payload.pageId,
    },
    headerType: 'AGENCY_AUTH',
  });
  if (payload.sourceAccountOwner === payload.targetAccountOwner) {
    const state = getState();
    if (state.projects.active?._id) {
      dispatch(getActiveProjectData(state.projects.active?._id));
    }
    dispatch(setActiveProjectId(payload.funnelId));
    const url = `/console/projects/${payload.funnelId}`;
    window.open(url, '_self');
  }
};

export const duplicateFunnelOtherAccountAction = (
  payload: IDuplicateFunnelOtherAccountPayload
) => async (dispatch: DispatchType, getState: GetStateType) => {
  const result = await graphQlCall({
    queryTemplateObject: QUERIES.DUPLICATE_FUNNEL_TO_OTHER_ACCOUNT,
    values: {
      targetAccountOwner: payload.targetAccountOwner,
      sourceAccountOwner: payload.sourceAccountOwner,
      funnelId: payload.funnelId,
    },
    headerType: 'AGENCY_AUTH',
  });
  if (payload.sourceAccountOwner === payload.targetAccountOwner) {
    dispatch(fetchFunnelsNameAction());
    const newFunnelId = result.funnel._id;
    dispatch(setActiveProjectId(newFunnelId));
    const url = `/console/projects/${newFunnelId}`;
    window.open(url, '_self');
  }
};

export const fetchFunnelsAction = (projectId?: string) => async (
  dispatch: DispatchType
) => {
  dispatch(loading(true));
  try {
    let projects: IGeneralProject[] = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_FUNNELS_QUERY,
      headerType: 'USER-AUTH',
    });

    let fetchAction = {
      type: PROJECTS_FETCHED,
      payload: projects,
    };
    dispatch(fetchAction);
    if (projectId) {
      dispatch(activeProject(projectId));
    } else {
      dispatch(activeProject(null));
    }
  } catch (error) {
    console.error(error);
  }
  dispatch(loading(false));
};

export const fetchFunnelsWithPaginationAction = (
  skip?: number,
  limit?: number,
  search?: string,
  sortBy?: string,
  sortAsc?: boolean
) => async (dispatch: DispatchType) => {
  dispatch(loading(true));
  try {
    let projects: IGeneralProject[] = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_FUNNELS_WITH_PAGINATION_QUERY,
      headerType: 'USER-AUTH',
      values: {
        skip,
        limit,
        search,
        sortBy,
        sortAsc,
      },
    });

    let fetchAction = {
      type: PROJECTS_WITH_PAGINATION_FETCHED,
      payload: projects,
    };
    dispatch(fetchAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(loading(false));
};

export const fetchFunnelsNameAction = () => async (dispatch: DispatchType) => {
  dispatch(loadingProjectsNames(true));

  try {
    let projectsName: IGeneralProjectName[] = await graphQlCall({
      queryTemplateObject: QUERIES.FETCH_FUNNELS_NAME_QUERY,
      headerType: 'USER-AUTH',
    });

    let fetchAction = {
      type: PROJECTS_NAME_FETCHED,
      payload: projectsName,
    };

    dispatch(fetchAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(loadingProjectsNames(false));
};

export const clearProjects = () => (dispatch: DispatchType) => {
  let fetchAction = {
    type: PROJECTS_NAME_FETCHED,
    payload: [],
  };

  dispatch(fetchAction);
};

export const funnelDuplicateAction = (id: string) => async (
  dispatch: DispatchType
) => {
  dispatch(loadingProjectDuplicate(true));
  try {
    let duplicateProjectName: IDuplicateProjectNameAction = await graphQlCall({
      queryTemplateObject: QUERIES.DUPLICATE_FUNNEL_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id: id,
      },
    });
    let fetchAction = {
      type: PROJECT_DUPLICATE,
      payload: duplicateProjectName.funnel,
    };

    dispatch(fetchAction);
  } catch (error) {
    console.error(error);
  }
  dispatch(loadingProjectDuplicate(false));
};
export const changeFunnelPositionAction = (positions: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  const newPositions: IGeneralProject[] = await graphQlCall({
    queryTemplateObject: QUERIES.CHANGE_FUNNEL_POSITIONS_MUTATION,
    headerType: 'USER-AUTH',
    values: {
      positions: positions,
    },
  });
  const state = getState();
  let projects = [...state.projects.projectsName];
  projects = projects.map((project) => {
    newPositions.forEach((positionItem) => {
      if (project._id === positionItem._id) {
        project.position = positionItem.position;
      }
    });

    return project;
  });
  let fetchAction = {
    type: PROJECTS_FETCHED,
    payload: projects,
  };
  dispatch(fetchAction);
  dispatch({
    type: PROJECTS_FETCHED,
    payload: projects,
  });
  dispatch(loading(false));
};
