import React from 'react';
import { ReactComponent as PointerIcon } from 'Assets/icons/pointer.svg';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Pagination from 'UILib/Pagination/Pagination';
import { SearchBar } from '../Search/Search';
import { Person, Tag } from '../Helper/types';

import s from 'Pages/PageContacts/Header/Header.module.scss';

interface HeaderProps {
  smartLists: any;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  filteredPeople: Person[];
  handleClearText: () => void;
  pageSize: number;
  dropdownOptions: { label: string; value: string }[];
  handleDropdownChange: (value: string) => void;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  smartSwitch: boolean;
  totalCount: number;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  filteredTags: Tag[];
}

export function Header(props: HeaderProps) {
  return (
    <div className={s.head}>
      <div className={s.headLeft}>
        <SearchBar
          filteredTags={props.filteredTags}
          searchTerm={props.searchTerm}
          setSearchTerm={props.setSearchTerm}
          totalCount={props.totalCount}
          smartSwitch={props.smartSwitch}
          smartLists={props.smartLists}
          searchText={props.searchText}
          setSearchText={props.setSearchText}
          filteredPeople={props.filteredPeople}
          handleClearText={props.handleClearText}
        />
      </div>
      <div className={s.headRight}>
        {/* <div className={s.total}>
          Columns
          <PointerIcon />
        </div> */}
        <div className={s.hr}></div>
        <div className={s.total}>
          <Dropdown
            className={s.listPage}
            label={`Page Size: ${props.pageSize}`}
            options={props.dropdownOptions}
            onChange={props.handleDropdownChange}
            hasErrors={false}
            size="small"
          />
        </div>
        <div className={s.pagination}>
          <Pagination
            currentPage={props.currentPage}
            totalPages={props.totalPages}
            onPageChange={props.handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
