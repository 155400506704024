import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { api, getAgencyToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { ReactComponent as StatsDecor } from 'Assets/icons/statsDecor.svg';
import { ReactComponent as Episodes } from 'Assets/icons/episodes.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { IAutoResponder, IAutoResponderStats, ISequence } from 'types';
import queries from 'graphql/queries';
import AutoResponderProjectSettingsModal from 'Components/AutoResponder/AutoResponderProjectSettingsModal/AutoResponderProjectSettingsModal';
import AutoResponderSendsEmailIndicator from 'Components/AutoResponder/AutoResponderSendsEmailIndicator/AutoResponderSendsEmailIndicator';
import SequenceItem from 'Components/AutoResponder/SequenceItem/SequenceItem';
import SideMenu from 'Components/SideMenu/SideMenu';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import AutoResponderDetailHeader from './AutoResponderDetailHeader';
import AutoResponderStats from './AutoResponderStats';

import styles from './AutoResponderDetails.module.scss';

const AutoResponderDetails = () => {
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const [autoResponder, setAutoResponder] = useState<IAutoResponder>();
  const [sequences, setSequences] = useState<ISequence[]>([]);
  const [addingMail, setAddingMail] = useState<boolean>(false);
  const [statistics, setStatistics] = useState<IAutoResponderStats>();
  const [sequenceStats, setSequenceStats] = useState<IAutoResponderStats[]>([]);

  const { autoResponderId } = useParams<{ autoResponderId: string }>();

  useEffect(() => {
    getAutoResponder();
  }, [autoResponderId]);

  const getAutoResponder = () => {
    graphQlCall({
      queryTemplateObject: queries.GET_AUTO_RESPONDER,
      headerType: 'USER-AUTH',
      values: {
        id: autoResponderId,
      },
    })
      .then((data) => {
        setAutoResponder(data.autoresponder);
        setSequences(data.sequence);

        graphQlCall({
          queryTemplateObject:
            queries.GET_AUTO_RESPONDERS_SEQUENCE_ITEMS_STATISTICS,
          values: {
            autoresponderId: data.autoresponder._id,
            itemIds: data.sequence.map((item: ISequence) => item.mailDraft._id),
          },
          headerType: 'USER-AUTH',
        }).then(setSequenceStats);

        graphQlCall({
          queryTemplateObject: queries.GET_AUTO_RESPONDER_STATISTICS,
          headerType: 'USER-AUTH',
          values: {
            id: autoResponderId,
          },
        })
          .then((data) => {
            setStatistics(data);
          })
          .finally(() => setLoading(false));
      })
      .catch(console.error);
  };

  useEffect(() => {
    getAutoResponder();
  }, [autoResponderId]);

  const deleteSequence = (id: string) => {
    setSequences([...sequences.filter((item) => item.mailDraft._id !== id)]);
  };

  const handleUpdateResponderLogo = async (image: File) => {
    const request = new FormData();
    request.append('file', image);
    const headers = {
      authorization: getAgencyToken(),
    };

    await api(`${PAGECRAFT_API_URL}/`, 'POST', request, headers);
  };

  const handleAddMail = async () => {
    if (!addingMail) {
      setAddingMail(true);
      graphQlCall({
        queryTemplateObject: queries.ADD_MAIL_IN_SEQUENCE_MUTATION,
        headerType: 'USER-AUTH',
        values: {
          autoresponderId: autoResponderId,
          subject: autoResponder?.name,
          name: autoResponder?.name,
        },
      }).then(() => {
        getAutoResponder();
        setAddingMail(false);
      });
    }
  };

  const handleUpdateSequenceItem = (
    sequenceId: string,
    values: Partial<{
      subject: string;
      preheader: string;
      waitPeriodType: string;
      waitPeriodAmount: number;
    }>
  ) => {
    const sequence = sequences.find(
      (item) => item.mailDraft._id === sequenceId
    );
    if (!sequence) return;

    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'subject':
          sequence.mailDraft.subject = values.subject;
          break;
        case 'preheader':
          sequence.mailDraft.preheader = values.preheader;
          break;
        case 'waitPeriodType':
          sequence.wait.periodType = values.waitPeriodType;
          break;
        case 'waitPeriodAmount':
          sequence.wait.periodAmount = values.waitPeriodAmount;
          break;
        default:
          break;
      }
    });

    setSequences(Array.from(sequences));
  };

  return (
    <div className={styles.container}>
      <SideMenu />
      <section className={styles.pageContainer}>
        {loading ? (
          <Loader size={26} className={styles.loader} />
        ) : (
          <>
            {' '}
            <AutoResponderDetailHeader
              handleUpdateResponderLogo={handleUpdateResponderLogo}
              onOpenSettings={() => setOpenSettingsModal(true)}
              autoResponder={autoResponder}
            />
            <div className={styles.pageContent}>
              <div className={styles.stats}>
                <div className={styles.statsTitle}>
                  <StatsDecor className={styles.statsIcon} /> Stats
                </div>
                <AutoResponderStats stats={statistics} />
              </div>
              <div className={styles.sequenceSection}>
                <div className={styles.left}>
                  <div className={styles.sequenceHeader}>
                    <div className={styles.sequenceTitle}>
                      <Episodes /> Sequence{' '}
                      <span className={styles.daysCount}>8 days</span>
                    </div>
                    <Button
                      height={40}
                      postfixIcon={!addingMail && <PlusIcon />}
                      onClick={handleAddMail}
                      disabled={addingMail}
                    >
                      {addingMail ? (
                        <CircleLoader size={16} color="#ffffff" />
                      ) : (
                        'Add Email'
                      )}
                    </Button>
                  </div>
                  <div className={styles.sequenceContent}>
                    <div className={styles.sequenceItems}>
                      {sequences?.map((item: ISequence, index: number) => (
                        <SequenceItem
                          key={item.mailDraft._id}
                          mailDraft={item.mailDraft}
                          sequenceWait={item.wait}
                          autoResponderId={autoResponder?._id || ''}
                          deleteSequence={deleteSequence}
                          stats={sequenceStats[index]}
                          onUpdateSequenceItem={(values) =>
                            handleUpdateSequenceItem(item.mailDraft._id, values)
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <AutoResponderSendsEmailIndicator sequences={sequences} />
              </div>
            </div>
          </>
        )}
      </section>
      <AutoResponderProjectSettingsModal
        open={openSettingsModal}
        onClose={() => setOpenSettingsModal(false)}
        autoResponder={autoResponder}
        setAutoResponder={setAutoResponder}
      />
    </div>
  );
};

export default AutoResponderDetails;
