import { api } from '../../utils/Utils';

import { loading } from '../projects/projectsActions';
import {
  STRIPE_KEY,
  STRIPE_CANCEL_URL,
  STRIPE_SUCCESS_URL,
} from '../../GlobalConstants';
import { pushAlert } from '../alerts/alertsActions';
import { loadStripe } from '@stripe/stripe-js';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../rootReducer';
import { Action } from 'redux';
import { IStripeSubscription } from 'types';
import { graphQlCall } from '../../graphql/utils';
import QUERIES from '../../graphql/queries';

export const SET_SUBSCRIPTIONS = 'SET_SUBSCRIPTIONS';
export const SET_EMAIL = 'SET_EMAIL';
export const GET_WALLET_BALLANCE = 'GET_WALLET_BALLANCE';
export const UPDATE_WALLET_BALLANCE = 'UPDATE_WALLET_BALLANCE';

export const fetchSubscriptions = () => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    dispatch(loading(true));
    const subscriptions = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_STRIPE_SUBSCRIPTIONS_QUERY,
      headerType: 'USER-AUTH',
    });
    dispatch({
      type: SET_SUBSCRIPTIONS,
      payload: subscriptions as IStripeSubscription[],
    });
  } catch (error: any) {
    dispatch(pushAlert('error', 'API fetch stripe error', error.message));
  }

  dispatch(loading(false));
};

export const subscribe = (priceId: string) => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    const stripe = await loadStripe(STRIPE_KEY);
    if (!stripe) {
      console.error('stripe did not loaded');
      return;
    }
    const sessionId = await graphQlCall({
      queryTemplateObject: QUERIES.STRIPE_SUBSCRIBE_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        priceId,
        successUrl: STRIPE_SUCCESS_URL,
        cancelUrl: STRIPE_CANCEL_URL,
      },
    });
    stripe.redirectToCheckout({
      sessionId: sessionId,
    });
  } catch (error: any) {
    console.log('ERROR', error);
    dispatch(pushAlert('error', 'API subscribe stripe error', error.message));
  }
};

export const getSessionInfo = (sessionId: string) => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    const user = await graphQlCall({
      queryTemplateObject: QUERIES.STRIPE_SESSION_INFO_QUERY,
      headerType: 'USER-AUTH',
      values: {
        sessionId,
      },
    });
    if (user) {
      dispatch({
        type: SET_EMAIL,
        payload: user,
      });
    }
  } catch (error: any) {
    console.log('ERROR', error);
  }
};

export const getWalletBalance = () => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    const walletBalance = await graphQlCall({
      queryTemplateObject: QUERIES.GET_WALLET_BALANCE,
      headerType: 'USER-AUTH',
    });
    dispatch({
      type: GET_WALLET_BALLANCE,
      payload: {
        ...walletBalance,
        amount: walletBalance?.amount ? walletBalance?.amount / 100 : 0,
        balanceThreshold: walletBalance?.balanceThreshold
          ? walletBalance?.balanceThreshold / 100
          : 0,
        minPaymentAmount: walletBalance?.minPaymentAmount
          ? walletBalance?.minPaymentAmount / 100
          : 0,
      },
    });
  } catch (error: any) {
    console.log('ERROR', error);
  }
};

export const updateWalletBalance = ({ ...value }) => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    dispatch({
      type: UPDATE_WALLET_BALLANCE,
      payload: { ...value },
    });
  } catch (error: any) {
    console.log('ERROR', error);
  }
};
