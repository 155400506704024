export const UPDATE_AUTO_RESPONDER = {
  operationName: 'updateAutoresponder',
  query: `mutation updateAutoresponder (
      <KEYS>
      ) {
          updateAutoresponder(<VALUES>) {
                _id
                name
                description
                createdAt
                updatedAt
                isActive
                thumbnailUrl
                isExclusive
                entryTag{
                    _id
                    name
                }
                exitTag{
                    _id
                    name
                }
                sender {
                     _id
                     email
                }
            }
        }`,
};
