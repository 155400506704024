import { campaigns, ICampaign } from './constants';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import Link from 'UILib/Link/Link';
import CampaignLogo from 'Components/AutoResponder/CampaignLogo/CampaignLogo';

import styles from './ChooseCampaign.module.scss';

interface IProps {
  onCampaignClick: (campaign: ICampaign) => void;
}

const tagStyles: Record<string, string> = {
  'For Customers': '#4957d8',
  'For Leads': '#25bb73',
  'misc.': '#7d7c7c',
};

const ChooseCampaignScreen = ({ onCampaignClick }: IProps) => {
  return (
    <div className={styles.screenContainer}>
      <Link
        to="/console/outreach/auto-responders"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      <div className={styles.title}>Choose Your Campaign Type</div>
      <div className={styles.subtitle}>
        Create and manage campaign to automate business processes.
      </div>
      <div className={styles.campaignsContainer}>
        {campaigns.map((campaign) => (
          <div
            key={campaign.id}
            className={styles.campaignElement}
            onClick={() => {
              onCampaignClick(campaign);
            }}
          >
            <CampaignLogo
              color={campaign.iconColor}
              icon={campaign.icon}
              transparent={!campaign.tag}
            />
            <div className={styles.campaignTitle}>{campaign.title}</div>
            <div className={styles.campaignDescription}>
              {campaign.description}
            </div>
            {campaign.tag && (
              <div
                className={styles.tag}
                style={{ color: tagStyles[campaign.tag] }}
              >
                {campaign.tag}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseCampaignScreen;
