import { useMemo } from 'react';
import { Descendant } from 'slate';
import { ReactComponent as ImageIcon } from 'Assets/icons/rounded-image.svg';
import NodeTextEditor from 'Editors/NodeTextEditor/NodeTextEditor';
import TemplateNode, { INode, INodeEvent } from './Node';
import { convertBlocksToString, convertTextToBlocks } from '../helpers';
import { Select } from 'antd';

import styles from './Node.module.scss';

export interface INodeGenerateImage extends INode {
  prompt: string;
  model?: string;
}

interface IProps {
  node: INodeGenerateImage;
  variables?: string[];
  onChange: (node: INode) => void;
  onEvent: (event: INodeEvent) => void;
}

const GenerateImage = (props: IProps) => {
  const initialPromptValue: Descendant[] = useMemo(() => {
    return convertTextToBlocks(props.node.prompt);
  }, [props.node.prompt]);

  const handleTextChange = (blocks: Descendant[]) => {
    const finalText = convertBlocksToString(blocks);

    const newNode = { ...props.node, prompt: finalText };
    props.onChange(newNode);
  };

  const handleImageModelChange = (value: string) => {
    props.node.model = value;
    props.onChange(props.node);
  };

  const imageModelSelector = () => {
    const modelOptions = [
      { label: 'DALL·E 3', value: 'dall-e-3' },
      { label: 'Flux 1.1 Pro', value: 'black-forest-labs/FLUX.1.1-pro' },
    ];

    return (
      <div className={styles.optionSelection}>
        Model:
        <Select
          onChange={handleImageModelChange}
          defaultValue={props.node.model || modelOptions[0].value}
          style={{ width: 120 }}
          size="small"
        >
          {modelOptions.map((option) => (
            <Select.Option value={option.value}>{option.label}</Select.Option>
          ))}
        </Select>
      </div>
    );
  };

  return (
    <TemplateNode
      node={props.node}
      onChange={props.onChange}
      onEvent={props.onEvent}
      headerColor="#25BB73"
      icon={<ImageIcon />}
      footer={imageModelSelector()}
    >
      <NodeTextEditor
        editorClassName={styles.textEditor}
        tagsList={props.variables ?? []}
        initialValue={initialPromptValue}
        onChange={handleTextChange}
      />
    </TemplateNode>
  );
};

export default GenerateImage;
