import { useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { base64ToFile } from 'utils/helpers';
import { api, getToken, validateUser } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { getLayoutThumbnail } from 'utils/Utils';
import { BookleTemplateBlock, IBroadcast, IUserDetails } from 'types';
import { BroadcastMenuType } from 'Pages/Broadcasts/types';
import { Preview2HTML } from 'Pages/PageGenerationEditor/Draggable/utils';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import GenerationPreview from 'Pages/PageGenerationEditor/GenerationPreview/GenerationPreview';
import {
  BlocksHistory,
  defaultTemplateStyles,
  IBookleTemplateEditor,
  IBookleTemplateBlockStyles,
} from 'store/books/booksReducer';
import {
  redoBlocks,
  undoBlocks,
  clearBlocksHistory,
  updateBookleTemplateBlocks,
  updateBookleTemplateTextEditor,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { SettingsModal } from './SettingsModal/SettingsModal';
import BroadcastEditorHeader from './BroadcastEditorHeader';
import CompleteBroadcastCampaign from './CompleteBroadcastCampaign/CompleteBroadcastCampaign';

import styles from './BroadcastEditor.module.scss';

interface IProps {
  templateTextEditor: IBookleTemplateEditor;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  blocksHistory: BlocksHistory;
  redoBlocks: () => void;
  undoBlocks: () => void;
  clearBlocksHistory: () => void;
  user: IUserDetails;
}

const BroadcastEditor = ({
  templateTextEditor,
  updateTextEditor,
  templateBlockStyles,
  templateBlocks,
  updateBlockStyles,
  updateBlocks,
  blocksHistory,
  redoBlocks,
  undoBlocks,
  clearBlocksHistory,
  user,
}: IProps) => {
  const [showSideMenu, setShowSideMenu] = useState<BroadcastMenuType | boolean>(
    false
  );
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);
  const [showLaunchPage, setShowLaunchPage] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('Email Subject');
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [launching, setLaunching] = useState<boolean>(false);
  const [mailDraftId, setMailDraftId] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(true);
  const [broadcastData, setBroadcastData] = useState<IBroadcast>();
  const [generationTaskId, setGenerationTaskId] = useState<string>('');
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [saving, setSaving] = useState<boolean>(false);

  const { broadcastId } = useParams<{ broadcastId: string }>();

  const userAuthed = validateUser();

  const ref = useRef<HTMLDivElement>(null);

  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );
    const sideMenuElements = document.querySelectorAll(
      '[id^="editor_side_menu"]'
    );
    const textControllerElements = document.querySelectorAll(
      '.text_controller'
    );
    const portalElements = document.querySelectorAll(
      '#portal-root, [data-portal]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsideSideMenu = Array.from(sideMenuElements).some((element) =>
      element.contains(e.target as Node)
    );

    const clickedInsideTextController = Array.from(
      textControllerElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsidePortal = Array.from(portalElements).some((element) =>
      element.contains(e.target as Node)
    );

    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !clickedInsideTextEditor &&
      !clickedInsideSideMenu &&
      !clickedInsideTextController &&
      !clickedInsidePortal
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  useEffect(() => {
    return () => {
      clearBlocksHistory();
      updateBlockStyles(defaultTemplateStyles);
    };
  }, []);

  useEffect(() => {
    if (!broadcastId) return;

    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_BROADCAST,
      values: { id: broadcastId },
      headerType: 'USER-AUTH',
    }).then((data) => {
      if (data.mailDraft) {
        setMailDraftId(data.mailDraft._id);
        setBroadcastData(data);

        graphQlCall({
          queryTemplateObject: queries.GET_ONE_MAILDRAFT,
          values: { id: data.mailDraft._id },
          headerType: 'USER-AUTH',
        })
          .then((mailDraftData) => {
            if (mailDraftData.data.blocks[0]?.blocks) {
              clearBlocksHistory();
              updateBlocks(mailDraftData.data.blocks[0]?.blocks);
            } else {
              clearBlocksHistory();

              if (
                !!mailDraftData.generationTask?._id &&
                mailDraftData.generationTask?.status !== 'MERGED'
              ) {
                const blockData = mailDraftData.data.blocks.map(
                  (item: BookleTemplateBlock) => {
                    if (item.variable === 'image') {
                      const { image, ...rest } = item;
                      return rest;
                    }
                    return item;
                  }
                );
                updateBlocks(blockData);
              } else {
                updateBlocks(mailDraftData.data.blocks);
              }
            }
            updateBlockStyles(mailDraftData.data.styles);
            setSubject(mailDraftData.subject);

            if (mailDraftData.generationTask) {
              setGenerationTaskId(mailDraftData.generationTask._id);
            } else {
              setIsGenerating(false);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  }, [broadcastId]);

  const handleSaveScreenshot = async (broadcast: string) => {
    if (broadcast) {
      const dataUrl = await getLayoutThumbnail();
      const data = new FormData();
      data.append('id', broadcast);
      data.append('file', base64ToFile(dataUrl, subject));
      await api(`${PAGECRAFT_API_URL}/broadcast/upload-image`, 'POST', data, {
        Authorization: getToken(),
      });
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const convertor = new Preview2HTML();
      const blocksHtmlContent = convertor.setCorrespondingBlocks(
        templateBlocks,
        templateBlockStyles,
        false
      );
      setHtmlContent(blocksHtmlContent);

      const mailDraftResponse = await graphQlCall({
        queryTemplateObject: queries.UPDATE_MAIL_DRAFT_MUTATION,
        values: {
          id: mailDraftId,
          text: '',
          subject,
          data: JSON.stringify({
            styles: templateBlockStyles,
            blocks: templateBlocks,
          }),
          html: blocksHtmlContent,
        },
        headerType: 'USER-AUTH',
      });

      await handleSaveScreenshot(broadcastId);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const handleEmailSubjectChange = async (subject: string) => {
    setSubject(subject);
  };
  const handleLaunch = async () => {
    try {
      setLaunching(true);
      await handleSave();
      setShowLaunchPage(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLaunching(false);
    }
  };

  const handleTestEmail = () => {
    setShowTestEmailModal(true);
  };

  const handleDraggableSidebarEvent = (isSidebarOpen: boolean) => {
    if (isSidebarOpen) {
      setShowSideMenu(BroadcastMenuType.draggableComponentMenu);
      return;
    }
    setShowSideMenu(false);
  };

  if (showLaunchPage) {
    return (
      <CompleteBroadcastCampaign
        subject={subject}
        onGoBack={() => setShowLaunchPage(false)}
        onEmailSubjectChanged={handleEmailSubjectChange}
        maildraftId={mailDraftId}
        htmlContent={htmlContent}
        broadcastId={broadcastId}
        broadcastData={broadcastData}
        broadcastStyles={templateBlockStyles}
        user={user}
      />
    );
  }

  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <>
      <EmailTestModal
        open={showTestEmailModal}
        emailSubject={subject}
        onClose={() => setShowTestEmailModal(false)}
      />
      {(loading || isGenerating || saving) && (
        <div className={styles.loaderContainer}>
          <Loader color="var(--accent-accent)" className={styles.loader} />
        </div>
      )}
      <div className={styles.BroadCastEditor}>
        <BroadcastEditorHeader
          title={broadcastData?.name || ''}
          onTestEmail={handleTestEmail}
          onSave={handleSave}
          onContinue={handleLaunch}
          onUndo={undoBlocks}
          onRedo={redoBlocks}
          onOpenSettings={() => setIsSettingsModalOpen(true)}
        />
        <div className={styles.EditorContainer}>
          <div className={styles.Editor}>
            <GenerationPreview
              isSidebarHidden={showSideMenu === BroadcastMenuType.templateMenu}
              onSidebarToggle={handleDraggableSidebarEvent}
              onGenerationComplete={() => setIsGenerating(false)}
              generationTaskId={generationTaskId}
              generationComplete={!(loading || isGenerating)}
            />
          </div>
        </div>
        <SettingsModal
          settings={templateBlockStyles}
          onSettingsChange={updateBlockStyles}
          open={isSettingsModalOpen}
          onOpenChange={setIsSettingsModalOpen}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
  blocksHistory: state.books.blocksHistory,
  user: state.user.details,
});

const mapDispatchToProps = {
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
  undoBlocks: () => undoBlocks(),
  redoBlocks: () => redoBlocks(),
  clearBlocksHistory: () => clearBlocksHistory(),
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastEditor);
