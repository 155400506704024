import { Editor, Transforms } from 'slate';
import { ENVIRONMENT, PAGECRAFT_API_URL } from 'GlobalConstants';
import { IGeneralProject } from 'types';
import { getUserId } from './Utils';
import { Section } from 'Components/AutoSaas/constants';

export const checkSpecialCharacters = (text: string) => {
  const regex = /[!@#$%^~` &*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  return regex.test(text);
};

export const toLocalISOString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const localDate = new Date(date.getTime() - offset * 60000);
  return localDate.toISOString().slice(0, -1) + formatOffset(offset);
};

const formatOffset = (minutes: number) => {
  const sign = minutes > 0 ? '-' : '+';
  const absOffset = Math.abs(minutes);
  const hours = Math.floor(absOffset / 60);
  const mins = absOffset % 60;
  return `${sign}${padZero(hours)}:${padZero(mins)}`;
};

const padZero = (number: number) => {
  return number < 10 ? '0' + number : number.toString();
};

export const truncateString = (string: string | undefined, maxLength = 70) => {
  if (!string) return '';
  return string.length > maxLength
    ? string.substring(0, maxLength) + '...'
    : string;
};

export const convertCamelToKebab = (str: string) =>
  str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const capitalizeFirstLetter = (word: string) => {
  if (word.length > 0) {
    return word[0].toUpperCase() + word.slice(1);
  }
  return word;
};

export const generateProjectUrl = (
  project: IGeneralProject,
  connectedDomain?: { name: string }
) => {
  let rootDomain = 'autofunnel.ai';
  if (ENVIRONMENT === 'DEV') {
    rootDomain = 'dev.autofunnel.ai';
  }
  if (connectedDomain) {
    return `https://${connectedDomain.name}`;
  }

  if (project.customSubDomain) {
    return `https://${project.customSubDomain}.${rootDomain}`;
  }

  return `https://live.${rootDomain}/${project._id}`;
};

export const isHotkey = (
  event: React.KeyboardEvent<HTMLDivElement>,
  hotkey: string
) => {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const isModKey = isMac ? event.metaKey : event.ctrlKey;

  return isModKey && event.key.toLowerCase() === hotkey;
};

const isMarkActive = (format: string, editor: Editor) => {
  const marks = Editor.marks(editor);

  return marks ? (marks as any)[format] === true : false;
};

const HOTKEYS: { [key: string]: string } = {
  b: 'bold',
  i: 'italic',
  u: 'underline',
};

const toggleMark = (format: string, editor: Editor) => {
  const isActive = isMarkActive(format, editor);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const onTextKeyDown = (
  event: React.KeyboardEvent<HTMLDivElement>,
  editor: Editor
) => {
  for (const hotkey in HOTKEYS) {
    if (isHotkey(event, hotkey)) {
      event.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(mark, editor);
    }
  }
  if (isHotkey(event, 'k')) {
    if (window.openTextControlsAndFocusURL) {
      window.openTextControlsAndFocusURL();
    }
  }
};

export const uploadFile = (
  file: Blob,
  callback: (value: string | ArrayBuffer | null) => void
) => {
  const formData = new FormData();
  const userId = getUserId()!;
  formData.append('id', userId);
  formData.append('file', file);

  fetch(`${PAGECRAFT_API_URL}/images`, {
    method: 'POST',
    body: formData,
  })
    .then((r) => r.json())
    .then((data) => {
      callback(data.url);
    });
};

export const base64ToFile = (
  base64String: string,
  filename: string,
  mimeType = 'image/png'
) => {
  const byteString = atob(base64String.split(',')[1]);

  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], { type: mimeType });

  return new File([blob], filename, { type: mimeType });
};

export const transformAutoSaasData = (
  data: Section[],
  option: string
): Record<string, Record<string, string>> => {
  const result: Record<string, Record<string, string>> = {};

  data.forEach((section) => {
    const subItems: Record<string, string> = {};

    section.items.forEach((subItem) => {
      subItems[subItem.key] = subItem.defaultColors?.[option];
    });

    result[section.key] = subItems;
  });

  return result;
};

export const getDomain = () => {
  const url = window.location.href;
  const hostname = new URL(url).hostname;
  const isAutoFunnel =
    hostname === 'localhost' ||
    hostname === 'app.dev.autofunnel.ai' ||
    hostname === 'app.autofunnel.ai';

  return {
    domain: hostname === 'localhost' ? 'localhost:3000' : hostname,
    isAutoFunnel,
  };
};

export const formatDate = (value: number | string | Date): string => {
  const date = new Date(value);

  const options: any = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
  };

  return date.toLocaleString('en-US', options);
};

export const formatAmount = (amount: number, digits = 2) => {
  return amount
    ? amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
      })
    : '$0.00';
};

export const formatUrl = (url: string) => {
  if (url && !/^https?:\/\/|^www\./.test(url)) {
    return `https://${url}`;
  } else {
    return url;
  }
};
