export const GET_AUTO_RESPONDERS_WITH_PAGINATION_QUERY = {
  operationName: 'getAutorespondersWithPagination',
  query: `query getAutorespondersWithPagination ($skip: Float!, $limit: Float!, $search: String, $sortBy: String, $sortAsc: Boolean) {
        getAutorespondersWithPagination(skip: $skip, limit: $limit, search: $search, sortBy: $sortBy, sortAsc: $sortAsc) {
            autoresponders {
                _id
                name
                description
                isActive
                entryTag{
                    _id
                    name
                }
                exitTag{
                    _id
                    name
                }
                createdAt
                updatedAt
                thumbnailUrl
                workflow { _id }
            }
            total
        }
      }`,
};
