export const CREATE_GENERATION_TASK_MUTATION = {
  operationName: 'createGenerationTask',
  query: `mutation createGenerationTask(
    $templateId: String!,
    $name: String,
    $layout: String,
    $type: String,
  ) {
    createGenerationTask(
      templateId: $templateId,
      name: $name,
      layout: $layout,
      type: $type,
    ) {
      ... on CreateGenerationTaskResponse {
        task {
          _id
        }
        forms {
          type
          variables {
            id
            type
          }
        }
        path
        message
      }
      ... on GenerationTaskStripeResp {
        error_type
        walletBalance
      }
    }
  }`,
};
