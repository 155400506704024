import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { graphQlCall } from 'graphql/utils';
import { IDomain, ISender } from 'types';
import { RootState } from '../rootReducer';
import QUERIES from 'graphql/queries';
import { SET_DOMAIN_ERROR } from 'store/domains/domainsActions';

export const SENDERS_FETCHED = 'SENDERS_FETCHED';
export const SENDER_UPDATE = 'SENDER_UPDATE';
export const MAIL_DOMAINS_FETCHED = 'MAIL_DOMAINS_FETCHED';
export const SENDERS_LOADING = 'SENDERS_LOADING';
export const DOMAIN_LOADING = 'DOMAIN_LOADING';
export const ADD_SENDER_POPUP = 'ADD_SENDER_POPUP';
export const DNS_RECORDS_POPUP = 'SETTING_POPUP';
export const REMOVE_SENDER_POPUP = 'REMOVE_SENDER_POPUP';
export const SELECT_SENDER = 'SELECT_SENDER';

type DispatchType = ThunkDispatch<RootState, void, Action>;
type GetStateType = () => RootState;

export const sendersFetched = (payload: ISender[]) => ({
  type: SENDERS_FETCHED,
  payload,
});

export const fetchSenders = () => async (dispatch: DispatchType) => {
  dispatch(loading(true));
  const senders = await graphQlCall({
    queryTemplateObject: QUERIES.GET_MAIL_SENDERS_QUERY,
    headerType: 'USER-AUTH',
  });

  dispatch(sendersFetched(senders as ISender[]));
};

export const updateSender = (payload: ISender) => async (
  dispatch: DispatchType
) => {
  dispatch({
    type: SENDER_UPDATE,
    payload,
  });
};

export const mailDomainsFetched = (payload: IDomain[]) => ({
  type: MAIL_DOMAINS_FETCHED,
  payload,
});

export const fetchMailDomains = () => async (dispatch: DispatchType) => {
  const domains = await graphQlCall({
    queryTemplateObject: QUERIES.GET_MAIL_DOMAINS_QUERY,
    headerType: 'USER-AUTH',
  });

  dispatch(mailDomainsFetched(domains as IDomain[]));
};

export const addSender = (
  senderName: string,
  domainId: string,
  email: string
) => async (dispatch: DispatchType, getState: GetStateType) => {
  try {
    dispatch(loading(true));
    const sender = await graphQlCall({
      queryTemplateObject: QUERIES.ADD_MAILGUN_SENDER,
      values: {
        domainId,
        senderName,
        email,
      },
      headerType: 'USER-AUTH',
    });
    const state = getState();
    const existingSenders = [...state.senders.senders];

    existingSenders.push(sender);
    dispatch(sendersFetched(existingSenders));
    dispatch(selectSender(sender._id));
    dispatch(mailDomainsFetched([...state.senders.domains, sender.mailDomain]));
    dispatch(refreshMailDomainRecords(sender.mailDomain._id));
    dispatch(dnsPopupAction(true));
  } catch (error: any) {
    console.log(error.message);
    dispatch({
      type: SET_DOMAIN_ERROR,
      payload: error.message,
    });
  } finally {
    dispatch(loading(false));
  }
};

export const deleteSender = (id: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch(loading(true));
    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_MAIL_SENDER_MUTATION,
      headerType: 'USER-AUTH',
      values: {
        id,
      },
    });
    const state = getState();
    const existingSenders = [...state.senders.senders];
    const filteredDomain = existingSenders.filter(
      (sender) => sender._id !== id
    );
    dispatch(sendersFetched(filteredDomain));
    dispatch(selectSender());
  } catch (error: any) {
    console.error(error.message);
  }
};

export const refreshMailDomainRecords = (domainId: string) => async (
  dispatch: DispatchType,
  getState: GetStateType
) => {
  try {
    dispatch(domainLoading(true));
    const domain = await graphQlCall({
      queryTemplateObject: QUERIES.REFRESH_MAIL_DOMAIN_RECORDS,
      values: {
        id: domainId,
      },
      headerType: 'USER-AUTH',
    });
    const state = getState();
    const existingDomains = [...state.senders.domains];
    const index = existingDomains.findIndex((el) => el._id === domainId);
    if (index >= 0) existingDomains.splice(index, 1, domain);

    dispatch(mailDomainsFetched(existingDomains));
  } catch (error: any) {
    console.log(error.message);
  } finally {
    dispatch(domainLoading(false));
  }
};

export const selectSender = (payload?: string) => (dispatch: DispatchType) => {
  const stateAction = {
    type: SELECT_SENDER,
    payload,
  };
  dispatch(stateAction);
};

export const sendersPopupAction = (payload: boolean) => async (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: ADD_SENDER_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const dnsPopupAction = (payload: boolean) => async (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: DNS_RECORDS_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const removeSenderPopup = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: REMOVE_SENDER_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const loading = (payload: boolean) => ({
  type: SENDERS_LOADING,
  payload,
});

export const domainLoading = (payload: boolean) => ({
  type: DOMAIN_LOADING,
  payload,
});
