export const CREATE_AUTO_RESPONDER_MUTATION = {
  operationName: 'createAutoresponder',
  query: `mutation createAutoresponder (
      <KEYS>
      ) {
          createAutoresponder(<VALUES>) {
              _id
              name
              description
              entryTag {
              _id
              }
              exitTag {
              _id
              }
             createdAt
             updatedAt
             thumbnailUrl
             workflow {
             _id
             }
          }
        }`,
};
