import clsx from 'clsx';

import styles from './ToggleSwitch.module.scss';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isBinary?: boolean;
  height?: 30 | 40;
  options?: string[];
  selectedValue?: string;
  onSelectOption?: (value: string) => void;
  hint?: { message: string; optionIndex: number };
  checkValue?: boolean;
  onToggle?: (value: boolean) => void;
}

const ToggleSwitch: React.FC<IProps> = ({
  style,
  className,
  height = 40,
  options,
  selectedValue,
  onSelectOption,
  hint,
  isBinary,
  checkValue,
  onToggle,
}) => {
  const handleBinaryChange = () => {
    if (onToggle) onToggle(!checkValue);
  };

  const handleOptionSelect = (value: string) => {
    if (onSelectOption) onSelectOption(value);
  };

  return (
    <>
      {isBinary ? (
        <label
          className={clsx(styles.binarySwitchContainer, className)}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            onChange={handleBinaryChange}
            checked={checkValue}
          />
          <span className={styles.slider}></span>
        </label>
      ) : (
        <div
          className={clsx(styles.nonBinarySwitchContainer, className)}
          style={{ minHeight: height, ...style }}
        >
          {options?.map((option, index) => (
            <div
              key={index}
              className={clsx(styles.option, {
                [styles.active]: selectedValue === option,
              })}
              onClick={() => handleOptionSelect(option)}
            >
              {hint?.optionIndex === index && (
                <div className={styles.hintText}>{hint.message}</div>
              )}
              {option}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ToggleSwitch;
