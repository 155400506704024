export const START_BROADCAST_MUTATION = {
  operationName: 'startBroadcast',
  query: `mutation startBroadcast($id: String!) {
    startBroadcast(id: $id) {
      __typename
      ... on Broadcast {
        _id
        status
      }
      ... on BroadcastStripeResp {
        error_type
        walletBalance
      }
    }
  }`,
};
