import { ReactComponent as Delivered } from 'Assets/icons/conversionsIconLight.svg';
import { ReactComponent as Eye } from 'Assets/icons/eye2.svg';
import { ReactComponent as Finger } from 'Assets/icons/finger.svg';
import { IAutoResponderStats } from 'types';

import styles from './AutoResponderDetails.module.scss';

interface IProps {
  stats: IAutoResponderStats | undefined;
}

const AutoResponderStats = ({ stats }: IProps) => {
  return (
    <div className={styles.statsContainer}>
      <div className={styles.statsItem}>
        <div className={styles.statsTitle}>
          <Delivered className={styles.statIcon} /> {stats?.delivered}
        </div>
        <div className={styles.statDescription}>Total Delivered</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.statsItem}>
        <div className={styles.statsTitle}>
          <Eye className={styles.statIcon} /> {stats?.opened}
        </div>
        <div className={styles.statDescription}> Total Opened</div>
      </div>
      <div className={styles.divider} />
      <div className={styles.statsItem}>
        <div className={styles.statsTitle}>
          <Finger className={styles.statIcon} /> {stats?.clicked}
        </div>
        <div className={styles.statDescription}>Total Clicks</div>
      </div>
    </div>
  );
};

export default AutoResponderStats;
