import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { graphQlCall } from 'graphql/utils';
import { RootState } from 'store/rootReducer';
import {
  BlocksHistory,
  defaultTemplateStyles,
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from 'store/books/booksReducer';
import {
  clearBlocksHistory,
  redoBlocks,
  undoBlocks,
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { getToken, api } from 'utils/Utils';
import { capitalizeFirstLetter, base64ToFile } from 'utils/helpers';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import clsx from 'clsx';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import GenerationPreview from './GenerationPreview/GenerationPreview';
import TemplateView from './TemplateView/TemplateView';
import { INode } from './Nodes/Node';
import { INodeUserInput } from './Nodes/UserInput';
import { INodeGenerateImage } from './Nodes/GenerateImage';
import { INodeGenerateList } from './Nodes/GenerateList';
import { INodeGenerateText } from './Nodes/GenerateText';
import { PAGECRAFT_API_URL } from '../../GlobalConstants';
import { MenuItems } from './Draggable/Sidebar/Sidebar';
import { ReactComponent as PlayIcon } from 'Assets/icons/play-button.svg';
import { ReactComponent as ArrowLeftIcon } from 'Assets/icons/leftArrow.svg';
import { getLayoutThumbnail } from '../../utils/Utils';
import UndoRedoButtons from 'Components/UndoRedoButtons/UndoRedoButtons';
import AutomaticWithdrawalFailedPopup from 'Components/AutomaticWithdrawalFailedPopup/AutomaticWithdrawalFailedPopup';
import DepletedBalanceModal from 'Components/DepletedBalanceModal/DepletedBalanceModal';

import styles from './PageGenerationEditor.module.scss';

interface IProps {
  bookleTemplateBlocks: BookleTemplateBlock[];
  templateTextEditor: IBookleTemplateEditor;
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
  blocksHistory: BlocksHistory;
  redoBlocks: () => void;
  undoBlocks: () => void;
  clearBlocksHistory: () => void;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  templateBlockStyles: IBookleTemplateBlockStyles;
}

const PageGenerationTemplateEditor = ({
  bookleTemplateBlocks,
  templateTextEditor,
  updateBlocks,
  updateTextEditor,
  blocksHistory,
  redoBlocks,
  undoBlocks,
  clearBlocksHistory,
  updateBlockStyles,
  templateBlockStyles,
}: IProps) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appId = queryParams.get('app');
  const [app, setApp] = useState<IAutoApp | undefined>(undefined);
  const [openLayout, setOpenLayout] = useState<boolean | undefined>(undefined);

  const { templateId } = useParams<{ templateId: string }>();

  const [generationTemplateData, setGenerationTemplateData] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);

  const [nodeViewMode, setNodeViewMode] = useState(false);
  const [layoutViewMode, setLayoutViewMode] = useState(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  ///////////////////////refactoring area
  const [currentGenTaskId, setCurrentGenTaskId] = useState<string>();
  const [_tmp_generationData, _tmp_setGenerationData] = useState<any>({});
  const [_tmp_generationTaskId, _tmp_setGenerationTaskId] = useState();
  const [_tmp_nodes, _tmp_setNodes] = useState<any>({});
  const [openBalanceModal, setOpenBalanceModal] = useState<boolean>(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState<boolean>(
    false
  );
  const [balance, setBalance] = useState<number>(0);
  /////////////////////////////////////////

  const undoRedoButtons = useMemo(() => {
    return (
      <UndoRedoButtons
        undo={undoBlocks}
        redo={redoBlocks}
        disabledUndo={blocksHistory.activeIndex === 0}
        disabledRedo={
          !blocksHistory.blocks.length ||
          blocksHistory.blocks.length - 1 === blocksHistory.activeIndex
        }
      />
    );
  }, [blocksHistory, redoBlocks, undoBlocks]);

  useEffect(() => {
    updateBlockStyles(defaultTemplateStyles);
    loadGenerationTemplateData();
    return () => {
      updateBlocks([]);
      clearBlocksHistory();
    };
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );
    const sideMenuElements = document.querySelectorAll(
      '[id^="editor_side_menu"]'
    );
    const textControllerElements = document.querySelectorAll(
      '.text_controller'
    );
    const portalElements = document.querySelectorAll(
      '#portal-root, [data-portal]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsideSideMenu = Array.from(sideMenuElements).some((element) =>
      element.contains(e.target as Node)
    );

    const clickedInsideTextController = Array.from(
      textControllerElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsidePortal = Array.from(portalElements).some((element) =>
      element.contains(e.target as Node)
    );

    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !clickedInsideTextEditor &&
      !clickedInsideSideMenu &&
      !clickedInsideTextController &&
      !clickedInsidePortal
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('editor')) {
      const editor = queryParams.get('editor');
      if (editor === 'node') {
        setNodeViewMode(true);
        setLayoutViewMode(false);
      } else if (editor === 'layout') {
        setNodeViewMode(true);
        setLayoutViewMode(true);
      }
      if (openLayout !== undefined) {
        setOpenLayout(editor === 'node');
      }
    } else {
      setNodeViewMode(false);
      setLayoutViewMode(false);
    }

    if (queryParams.has('generationTask')) {
      getGenerationTask(queryParams.get('generationTask'));
    }
  }, [location, openLayout]);

  useEffect(() => {
    if (appId) {
      graphQlCall({
        queryTemplateObject: queries.GET_ONE_AUTO_APP,
        headerType: 'USER-AUTH',
        values: {
          id: appId,
        },
      }).then((data) => {
        setApp(data);
      });
    }
  }, [appId]);

  const getGenerationTask = async (taskId: string | null) => {
    if (taskId) {
      const taskData = await graphQlCall({
        queryTemplateObject: queries.GET_ONE_GENERATION_TASK,
        headerType: 'USER-AUTH',
        values: {
          id: taskId,
        },
      });

      console.log('task Data:', taskData);
      if (taskData.status === 'GENERATING') {
        setCurrentGenTaskId(taskId);
      }
    }
  };

  const handleChangeLayout = (type: 'layout' | 'node') => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('editor', type);

    history.push({
      pathname: location.pathname,
      search: queryParams.toString(),
    });

    //TODO: NEED TO REMOVE THIS DIRT WORKAROUND
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  };

  const handleRedirectToTemplates = () => {
    history.push(
      app?._id
        ? `/console/app/${app.name.toLowerCase().replace(/\s+/g, '')}/${
            app?._id
          }`
        : `/console/outreach/templates`
    );
  };

  const handleTemplateSave = async () => {
    try {
      setIsSaving(true);
      const actions = generateTemplateFromNodes();
      console.log('SAVING....');

      let valuesToSave: any = {
        id: templateId,
        name: generationTemplateData.name,
      };

      if (Object.keys(actions).length !== 0) {
        valuesToSave['actions'] = JSON.stringify(actions);
      }

      if (bookleTemplateBlocks.length > 0) {
        valuesToSave['layout'] = JSON.stringify({
          blocks: bookleTemplateBlocks,
          templateBlockStyles: templateBlockStyles,
        });
      }

      await graphQlCall({
        queryTemplateObject: queries.UPDATE_GENERATION_TEMPLATE_MUTATION,
        values: valuesToSave,
        headerType: 'USER-AUTH',
      });

      //saving image thumbnail
      if (bookleTemplateBlocks) {
        const dataUrl = await getLayoutThumbnail();
        if (dataUrl) {
          const data = new FormData();
          data.append('id', templateId);
          data.append('type', 'template');
          data.append(
            'file',
            base64ToFile(dataUrl, generationTemplateData.name)
          );
          await api(
            `${PAGECRAFT_API_URL}/generation/upload-thumbnail`,
            'POST',
            data,
            {
              Authorization: getToken(),
            }
          );
        }
      }

      notification.success({
        message: 'Success!',
        description: 'Template was saved',
        placement: 'topRight',
        duration: 2,
      });
      console.log('SAVE is complete');
    } catch (error) {
      notification.error({
        message: 'Error!',
        description: 'Something went wrong',
        placement: 'topRight',
        duration: 2,
      });
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const loadGenerationTemplateData = () => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
      values: { id: templateId },
      headerType: 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTemplateData(data);
        console.log('LAYOUT:', data.layout);

        if (data.layout[0]?.blocks) {
          updateBlocks(data.layout[0]?.blocks);
        } else {
          updateBlocks(data.layout);
        }

        updateBlockStyles(
          data.layout[0]?.templateBlockStyles || defaultTemplateStyles
        );
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const handleNodeExposure = (node: INode) => {
    const newBlocks = [
      ...bookleTemplateBlocks,
      {
        id: node.id,
        type: node.type?.includes('Image')
          ? MenuItems.IMAGE_BLOCK
          : MenuItems.TEXT_BLOCK,
        variable: node.variable,
        nodeId: node.id,
        ...(node.type?.includes('Text') && {
          readOnly: true,
          text: undefined,
        }),
      },
    ];
    updateBlocks(newBlocks);
  };

  const handleTemplateNameChange = (name: string) => {
    generationTemplateData.name = name;
    setGenerationTemplateData({ ...generationTemplateData });
  };

  const handleNodeVariableChange = (
    node: INode,
    oldVariable: string | null,
    newVariable: string | null
  ) => {
    bookleTemplateBlocks.forEach((block) => {
      if (block.variable === oldVariable) {
        block.variable = newVariable;
      }
    });
    updateBlocks([...bookleTemplateBlocks]);
  };

  const handleDataChange = (nodes: any) => {
    //TODO: figure out another way of doing this dictionary processing
    let data: any = {};
    for (const key in nodes) {
      const node = nodes[key];
      if (node.type === 'UserInput') {
        const nodeUi = node as INodeUserInput;
        //capture variables
        for (const form of nodeUi.forms) {
          for (const input of form.fields) {
            data[input.id] = input.example;
          }
        }
      }
    }

    _tmp_setNodes(nodes);
    _tmp_setGenerationData(data);
  };

  const generateTemplateFromNodes = () => {
    let actions: any = {};
    const visitedNodes = new Set<string>();

    const serializeNode = (node: INode) => {
      const serializedNode: any = {
        id: node.variable ?? node.id, //TODO: need to align variable names here:  id: node.id
        type: node.type,
      };

      if (node.type === 'UserInput') {
        serializedNode.forms = (node as INodeUserInput).forms.map((form) => ({
          name: form.name,
          type: form.name
            .split(' ')
            .map((word) => capitalizeFirstLetter(word))
            .join(''),
          variables: form.fields.map((field) => ({
            id: field.id,
            type: field.type,
            label: field.label,
            options: field.options,
          })),
        }));
      } else if (node.type === 'GenerateText') {
        serializedNode.variable = (node as INodeGenerateText).variable;
        serializedNode.prompt = (node as INodeGenerateText).prompt;
      } else if (node.type === 'GenerateList') {
        serializedNode.variable = (node as INodeGenerateList).variable;
        serializedNode.prompt = (node as INodeGenerateList).prompt;
      } else if (node.type === 'GenerateImage') {
        serializedNode.variable = (node as INodeGenerateImage).variable;
        serializedNode.prompt = (node as INodeGenerateImage).prompt;
        serializedNode.model = (node as INodeGenerateImage).model;
      }

      serializedNode.metadata = {
        width: node.width,
        height: node.height,
        x: node.x,
        y: node.y,
      };

      if (node.connections.length > 0) {
        serializedNode.postFunctions = node.connections.map((connectionId) => {
          const connectedNode = _tmp_nodes[connectionId];
          return {
            [connectedNode.operation || 'each']: serializeNode(connectedNode),
          };
        });
      } else {
        serializedNode.postFunctions = [];
      }

      return serializedNode;
    };

    const traverseAndSerialize = (node: INode) => {
      if (visitedNodes.has(node.id)) return null;
      visitedNodes.add(node.id);
      return serializeNode(node);
    };

    for (const key in _tmp_nodes) {
      const node = _tmp_nodes[key];
      //TODO: using UserInput as a root NODE where all connections are processed. need to finalize and clear
      if (node.type !== 'UserInput') {
        continue;
      }
      const serializedNode = traverseAndSerialize(node);
      if (serializedNode) {
        actions = serializedNode;
      }
    }

    return actions;
  };
  const handlePreviewStart = () => {
    const url = `/console/file/${app?._id}/${app?.template?._id}/create`;
    window.open(url, '_blank');
  };

  const handleGenerationStart = async () => {
    const anyEmptyFields = Object.values(_tmp_generationData).some(
      (el) => el === '' || el === undefined || el === null
    );
    if (anyEmptyFields) {
      notification.error({
        message: 'Please fill in all fields of User Input to see the preview.',
        placement: 'topRight',
        duration: 5,
      });
      return;
    }

    await handleTemplateSave();

    console.log('GENERATION STARTED...');

    const task = await graphQlCall({
      queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
      values: { templateId: templateId },
      headerType: 'USER-AUTH',
    });

    if (task.error_type === 'payment_error') {
      return setOpenWithdrawalModal(true);
    } else if (task.error_type === 'wallet_is_low') {
      setBalance(task.walletBalance);
      return setOpenBalanceModal(true);
    }

    //set current gen so it will be subscribed on updates from generation task
    setCurrentGenTaskId(task.task._id);

    const response = await graphQlCall({
      queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
      values: {
        taskId: task.task._id,
        data: JSON.stringify(_tmp_generationData),
        path: 'root',
      },
      headerType: 'USER-AUTH',
    });
    console.log('push data response: ', response);
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DepletedBalanceModal
        open={openBalanceModal}
        onClose={() => setOpenBalanceModal(false)}
        balance={balance}
      />
      <AutomaticWithdrawalFailedPopup
        open={openWithdrawalModal}
        onClose={() => setOpenWithdrawalModal(false)}
      />
      <div ref={ref} id="header">
        <EditHeader
          showConfirmButton={true}
          pageName={generationTemplateData?.name || ''}
          editableTitle
          onTitleChange={handleTemplateNameChange}
          title={`${!!appId ? 'Project' : 'Template'} name`}
          handleConfirm={handleTemplateSave}
          handleGoBack={handleRedirectToTemplates}
          buttonPlaceholder={
            isSaving ? <Loader color="#ffffff" size={16} /> : 'Save'
          }
          additionalButtons={
            <div className={styles.customHeader}>
              {undoRedoButtons}
              {nodeViewMode ? (
                <Button
                  appearance="stroke"
                  width={160}
                  height={40}
                  onClick={handlePreviewStart}
                >
                  Preview
                </Button>
              ) : undefined}
            </div>
          }
          className={styles.header}
        />
      </div>
      <div className={styles.workbench}>
        {nodeViewMode && (
          <>
            <div className={clsx(styles.nodeCanvas)}>
              <TemplateView
                templateActions={generationTemplateData.actions}
                onVariableChange={handleNodeVariableChange}
                onExpose={handleNodeExposure}
                onChange={handleDataChange}
              />
            </div>
            {/* TODO: replace this with a proper design  */}
            {!layoutViewMode && (
              <div
                className={clsx(styles.trapezoidButton, {
                  [styles.hiddenButton]:
                    !openLayout && typeof openLayout === 'boolean',
                })}
                onClick={() => {
                  setOpenLayout(false);
                  handleChangeLayout('layout');
                }}
              >
                <ArrowLeftIcon
                  className={clsx(styles.arrowIcon, styles.leftArrow)}
                />
              </div>
            )}
          </>
        )}

        <>
          {layoutViewMode && (
            <Button
              className={styles.generationButton}
              prefixIcon={<PlayIcon className={styles.settingsIcon} />}
              appearance="solid"
              width={40}
              height={40}
              onClick={handleGenerationStart}
            />
          )}
          <div
            className={clsx(
              styles.generationPreview,
              {
                [styles.animatedLayout]: !!openLayout,
              },
              {
                [styles.animatedOpenLayout]:
                  !openLayout && typeof openLayout === 'boolean',
              }
            )}
          >
            <div
              className={clsx(styles.trapezoidButton, styles.hideLayoutButton, {
                [styles.hiddenButton]: !!openLayout,
              })}
              onClick={() => {
                setOpenLayout(true);
                handleChangeLayout('node');
              }}
            >
              <ArrowLeftIcon
                className={clsx(styles.arrowIcon, styles.rightArrow)}
              />
            </div>
            <GenerationPreview generationTaskId={currentGenTaskId} />
          </div>
        </>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  bookleTemplateBlocks: state.books.bookleTemplateBlocks,
  blocksHistory: state.books.blocksHistory,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
  undoBlocks: () => undoBlocks(),
  redoBlocks: () => redoBlocks(),
  clearBlocksHistory: () => clearBlocksHistory(),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageGenerationTemplateEditor);
