import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import {
  BlocksHistory,
  defaultTemplateStyles,
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from 'store/books/booksReducer';
import {
  clearBlocksHistory,
  redoBlocks,
  undoBlocks,
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
  updateBookleTemplateTextEditor,
} from 'store/books/booksActions';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { getLayoutThumbnail } from 'utils/Utils';
import { base64ToFile } from 'utils/helpers';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { Preview2HTML } from 'Pages/PageGenerationEditor/Draggable/utils';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import GenerationPreview from 'Pages/PageGenerationEditor/GenerationPreview/GenerationPreview';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import UndoRedoButtons from 'Components/UndoRedoButtons/UndoRedoButtons';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import Button from 'UILib/Button/Button';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';

import styles from './SequenceEditor.module.scss';

interface IProps {
  templateTextEditor: IBookleTemplateEditor;
  updateTextEditor: (payload: IBookleTemplateEditor) => void;
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (
    payload: BookleTemplateBlock[],
    updateHistory?: boolean
  ) => void;
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  blocksHistory: BlocksHistory;
  redoBlocks: () => void;
  undoBlocks: () => void;
  clearBlocksHistory: () => void;
}

const SequenceEditor = ({
  templateTextEditor,
  updateTextEditor,
  templateBlockStyles,
  templateBlocks,
  updateBlockStyles,
  blocksHistory,
  redoBlocks,
  undoBlocks,
  updateBlocks,
  clearBlocksHistory,
}: IProps) => {
  const { autoresponderId, sequenceItemId } = useParams<{
    autoresponderId: string;
    sequenceItemId: string;
  }>();
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('Email Subject');
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);

  UseOnClickOutside(ref, (e) => {
    const textEditorElements = document.querySelectorAll(
      '[id^="text_editor_"]'
    );
    const sideMenuElements = document.querySelectorAll(
      '[id^="editor_side_menu"]'
    );
    const textControllerElements = document.querySelectorAll(
      '.text_controller'
    );
    const portalElements = document.querySelectorAll(
      '#portal-root, [data-portal]'
    );

    const clickedInsideTextEditor = Array.from(
      textEditorElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsideSideMenu = Array.from(sideMenuElements).some((element) =>
      element.contains(e.target as Node)
    );

    const clickedInsideTextController = Array.from(
      textControllerElements
    ).some((element) => element.contains(e.target as Node));

    const clickedInsidePortal = Array.from(portalElements).some((element) =>
      element.contains(e.target as Node)
    );

    if (
      ref.current &&
      !ref.current.contains(e.target as Node) &&
      !clickedInsideTextEditor &&
      !clickedInsideSideMenu &&
      !clickedInsideTextController &&
      !clickedInsidePortal
    ) {
      updateTextEditor({ editor: undefined, selection: undefined });
    }
  });

  useEffect(() => {
    if (sequenceItemId && autoresponderId) {
      setLoading(true);
      graphQlCall({
        queryTemplateObject: queries.GET_AUTO_RESPONDER_SEQUENCE_ITEM_QUERY,
        values: {
          autoresponderId,
          sequenceItemId,
        },
        headerType: 'USER-AUTH',
      }).then((sequenceItem) => {
        console.log('mailDraftData: ', sequenceItem.mailDraft);
        const mailDraftData = sequenceItem.mailDraft.data;

        if (mailDraftData.blocks) {
          if (mailDraftData.blocks[0]?.blocks) {
            updateBlocks(mailDraftData.blocks[0]?.blocks);
          } else {
            updateBlocks(mailDraftData.blocks);
          }
        }
        if (mailDraftData.styles) {
          updateBlockStyles(mailDraftData.styles);
        }
        setSubject(sequenceItem.mailDraft.subject);

        setLoading(false);
      });
    }
  }, [sequenceItemId, autoresponderId]);

  useEffect(() => {
    updateBlockStyles(defaultTemplateStyles);
    updateBlocks([], false);

    return () => {
      clearBlocksHistory();
    };
  }, []);

  const handleSaveScreenshot = async (sequenceItemId: string) => {
    if (sequenceItemId) {
      const dataUrl = await getLayoutThumbnail();
      const data = new FormData();
      data.append('file', base64ToFile(dataUrl, subject));
      await api(
        `${PAGECRAFT_API_URL}autoresponder/upload-mailDraft-thumbnail/${sequenceItemId}`,
        'POST',
        data,
        {
          Authorization: getToken(),
        }
      );
    }
  };

  const handleSave = async () => {
    if (!saving) {
      try {
        setSaving(true);

        const convertor = new Preview2HTML();
        const blocksHtmlContent = convertor.setCorrespondingBlocks(
          templateBlocks,
          templateBlockStyles,
          false
        );

        const response = await graphQlCall({
          queryTemplateObject:
            queries.UPDATE_AUTO_RESPONDER_SEQUENCE_ITEM_MUTATION,
          values: {
            autoresponderId: autoresponderId,
            sequenceItemId: sequenceItemId,
            text: '',
            subject,
            data: JSON.stringify({
              styles: templateBlockStyles,
              blocks: templateBlocks,
            }),
            html: blocksHtmlContent,
          },
          headerType: 'USER-AUTH',
        });

        handleSaveScreenshot(response.mailDraft._id);
      } catch (error) {
        console.error(error);
      } finally {
        setSaving(false);
      }
    }
  };

  const handleTestEmail = () => {
    setShowTestEmailModal(true);
  };

  const checkInputValidation = (
    number: string | number,
    callback: () => void
  ) => {
    if (Number(number) >= 0) {
      callback();
    }
  };

  return (
    <>
      <EmailTestModal
        open={showTestEmailModal}
        emailSubject={subject}
        onClose={() => setShowTestEmailModal(false)}
      />
      <div className={styles.sequenceEditor}>
        <div ref={ref} id="header">
          <EditHeader
            showConfirmButton={true}
            pageName={subject}
            onTitleChange={setSubject}
            editableTitle
            title="Subject"
            handleConfirm={handleSave}
            handleGoBack={() =>
              history.push(
                `/console/auto-responders/${autoresponderId}/details`
              )
            }
            buttonPlaceholder={
              saving ? (
                <CircleLoader color="#ffffff" size={20} />
              ) : (
                <div className={styles.confirmButtonPlaceholder}>Save</div>
              )
            }
            additionalButtons={
              <div className={styles.additionalButtons}>
                <UndoRedoButtons
                  undo={undoBlocks}
                  redo={redoBlocks}
                  disabledUndo={blocksHistory.activeIndex === 0}
                  disabledRedo={
                    !blocksHistory.blocks.length ||
                    blocksHistory.blocks.length - 1 ===
                      blocksHistory.activeIndex
                  }
                />
                <Button
                  appearance="stroke"
                  height={40}
                  prefixIcon={
                    <div className={styles.emailPrefixContainer}>
                      Test Email
                      <Email
                        className={styles.ghostIcon}
                        width={22}
                        height={18}
                      />
                    </div>
                  }
                  onClick={handleTestEmail}
                />
              </div>
            }
          />
        </div>
        <div className={styles.editorContainer}>
          <div className={styles.editor}>
            <GenerationPreview />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
  blocksHistory: state.books.blocksHistory,
});

const mapDispatchToProps = {
  updateTextEditor: (payload: IBookleTemplateEditor) =>
    updateBookleTemplateTextEditor(payload),
  updateBlocks: (payload: BookleTemplateBlock[], updateHistory?: boolean) =>
    updateBookleTemplateBlocks(payload, updateHistory),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    updateBookleTemplateBlockStyles(payload),
  undoBlocks: () => undoBlocks(),
  redoBlocks: () => redoBlocks(),
  clearBlocksHistory: () => clearBlocksHistory(),
};

export default connect(mapStateToProps, mapDispatchToProps)(SequenceEditor);
