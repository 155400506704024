import IconWithBackground from 'UILib/IconWithBackground/IconWithBackground';

import styles from './CampaignLogo.module.scss';

interface IProps {
  transparent?: boolean;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: string;
}

const CampaignLogo = (props: IProps) => {
  console.log(props);
  if (props.transparent) {
    return (
      <div className={styles.transparentLogo}>
        {<props.icon fill={props.color} />}
      </div>
    );
  }

  return (
    <IconWithBackground Icon={props.icon} color={props.color} showBackground />
  );
};

export default CampaignLogo;
