import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';
import { BookleTemplateBlock } from 'types';
import { uploadFile } from 'utils/helpers';

interface IProps {
  element: BookleTemplateBlock;
  onUpdate: (key: string, value: string | boolean) => void;
  onUpdateStyle: (key: string, value: string | number) => void;
}

export const ImageControls = ({ element, onUpdate, onUpdateStyle }: IProps) => {
  // const [border, setBorder] = useState({
  //   width: '',
  //   style: '',
  //   color: '',
  // });

  // useEffect(() => {
  //   if (element?.styles?.border) {
  //     setBorder(getBorderProperties(element?.styles?.border));
  //   } else {
  //     setBorder({
  //       width: '',
  //       style: '',
  //       color: '',
  //     });
  //   }
  // }, [element?.styles?.border]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onUpdate('image', reader.result as string);

        uploadFile(file, (value) => {
          onUpdate('image', value as string);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <AccordionItem value="image" className="border-none">
      <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
        Image Settings
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          <div className="space-y-4 mt-4">
            {/* <div className="grid grid-cols-[1fr,auto,1fr] gap-2 items-center">
                  <div>
                    <Label className="text-[10px] uppercase text-muted-foreground">
                      Width
                    </Label>
                    <Input
                      type="number"
                      min={10}
                      max={100}
                      value={parseInt(
                        element.styles?.width?.toString() || '100'
                      )}
                      onChange={(e) => {
                        onUpdateStyle('width', e.target.value);
                      }}
                      className="bg-background/50 focus:bg-background transition-colors"
                    />
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() =>
                      onUpdate('dimensionsLocked', !element.dimensionsLocked)
                    }
                    className="mt-4"
                  >
                    {element.dimensionsLocked ? (
                      <Lock className="h-4 w-4" />
                    ) : (
                      <Unlock className="h-4 w-4" />
                    )}
                  </Button>
                  <div>
                    <Label className="text-[10px] uppercase text-muted-foreground">
                      Height
                    </Label>
                    <Input
                      type="number"
                      min={10}
                      disabled={element.dimensionsLocked}
                      value={parseInt(
                        element.styles?.height?.toString() || '100'
                      )}
                      onChange={(e) => {
                        onUpdateStyle('height', e.target.value);
                      }}
                      className="bg-background/50 focus:bg-background transition-colors"
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <Label className="text-xs font-medium">Fit Mode</Label>
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      size="sm"
                      className={cn(
                        'flex-1 bg-background/50',
                        element.styles?.objectFit === 'cover' && 'bg-muted'
                      )}
                      onClick={() => onUpdateStyle('objectFit', 'cover')}
                    >
                      Fill
                    </Button>
                    <Button
                      variant="outline"
                      size="sm"
                      className={cn(
                        'flex-1 bg-background/50',
                        element.styles?.objectFit === 'contain' && 'bg-muted'
                      )}
                      onClick={() => onUpdateStyle('objectFit', 'contain')}
                    >
                      Fit
                    </Button>
                  </div>
                  <p className="text-xs text-muted-foreground mt-1">
                    Fill mode covers the entire area, while Fit mode maintains
                    aspect ratio.
                  </p>
                </div> */}
            <div className="space-y-2">
              <Label className="text-xs font-medium">Object Fit</Label>
              <Select
                value={element.styles?.objectFit || 'cover'}
                onValueChange={(value) => onUpdateStyle('objectFit', value)}
              >
                <SelectTrigger className="bg-background/50 focus:bg-background transition-colors">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="cover">Cover</SelectItem>
                  <SelectItem value="contain">Contain</SelectItem>
                  <SelectItem value="fill">Fill</SelectItem>
                  <SelectItem value="none">Original Size</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          {/* Alignment Controls */}
          {/* <div className="space-y-2">
            <Label className="text-xs font-medium">Alignment</Label>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                className={cn(
                  'flex-1 bg-background/50',
                  element.alignment === AlignmentEnum.CENTER && 'bg-muted'
                )}
                onClick={() => onUpdate('alignment', AlignmentEnum.CENTER)}
              >
                <AlignCenter className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className={cn(
                  'flex-1 bg-background/50',
                  element.alignment === AlignmentEnum.LEFT && 'bg-muted'
                )}
                onClick={() => onUpdate('alignment', AlignmentEnum.LEFT)}
              >
                <AlignLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                size="sm"
                className={cn(
                  'flex-1 bg-background/50',
                  element.alignment === AlignmentEnum.RIGHT && 'bg-muted'
                )}
                onClick={() => onUpdate('alignment', AlignmentEnum.RIGHT)}
              >
                <AlignRight className="h-4 w-4" />
              </Button>
            </div>
          </div> */}
          {/* Border Controls */}
          {/* <div className="space-y-2">
            <Label className="text-xs font-medium">Border</Label>
            <div className="grid grid-cols-3 gap-2">
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Width
                </Label>
                <Input
                  type="number"
                  min="0"
                  max="99"
                  value={border.width}
                  onChange={(e) =>
                    onUpdateStyle(
                      'border',
                      `${e.target.value}px ${border.style} ${border.color}`
                    )
                  }
                  className="bg-background/50 focus:bg-background transition-colors"
                />
              </div>
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Style
                </Label>
                <Select
                  value={border.style}
                  onValueChange={(value) =>
                    onUpdateStyle(
                      'border',
                      `${border.width}px ${value} ${border.color}`
                    )
                  }
                >
                  <SelectTrigger className="bg-background/50 focus:bg-background transition-colors">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="solid">Solid</SelectItem>
                    <SelectItem value="dashed">Dashed</SelectItem>
                    <SelectItem value="dotted">Dotted</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Color
                </Label>
                <div className="flex gap-1">
                  <Input
                    type="color"
                    value={border.color}
                    onChange={(e) =>
                      onUpdateStyle(
                        'border',
                        `${border.width}px ${border.style} ${e.target.value}`
                      )
                    }
                    className="w-full p-1 rounded-lg bg-background/50 focus:bg-background transition-colors"
                  />
                </div>
              </div>
            </div>
          </div> */}
          {/* Alt Text */}
          <div className="space-y-2">
            <Label className="text-xs font-medium">Alt Text</Label>
            <Input
              type="text"
              value={element.imageText || ''}
              onChange={(e) => onUpdate('imageText', e.target.value)}
              placeholder="Describe the image"
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Destination Link</Label>
            <Input
              value={element.url}
              onChange={(e) => onUpdate('url', e.target.value)}
              placeholder="https://"
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Upload Image</Label>
            <Input type="file" accept={'image/*'} onChange={handleFileChange} />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
