import { useCallback, useMemo, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import {
  addFunnelAction,
  addPageFromAiAction,
} from 'store/projects/projectsActions';
import { DispatchType, RootState } from 'store/rootReducer';
import { fetchFunnelBuilderBooks } from 'store/funnelBuilder/funnelBuilderActions';
import { fetchUserProductsAction } from 'store/products/productActions';
import { IAddPageFromAiPayload } from 'types';
import { validateUser } from 'utils/Utils';
import FunnelTypeCard from 'Components/FunnelBuilder/FunnelTypeCard/FunnelTypeCard';
import IconWithBackground from 'UILib/IconWithBackground/IconWithBackground';
import FunnelPageFlow from 'Components/FunnelBuilder/FunnelPageFlow/FunnelPageFlow';
import Button from 'UILib/Button/Button';
import Link from 'UILib/Link/Link';
import { FunnelType } from './types';
import { funnelTypes, funnelPages, funnelPagesFlows } from './constants';

import styles from './FunnelBuilder.module.scss';

export interface IAiSourceInfo {
  id: string;
  text: string;
}

export interface IPageInfo {
  aiPageId: string;
  name?: string;
  aiSourceInfo: IAiSourceInfo[];
}

interface IProps {
  activeFunnelId: string | null;
  addFunnel: (funnelName: string) => void;
  addPage: (args: IAddPageFromAiPayload) => Promise<void>;
  fetchFunnelBuilderBooks: () => void;
  fetchProducts: () => void;
}

const FunnelBuilder = (props: IProps) => {
  const [selectedFunnelType, setSelectFunnelType] = useState<FunnelType>();
  const [funnelFormIndex, setFunnelFormIndex] = useState<number>(0);
  const [funnelData, setFunnelData] = useState<
    Record<string, string | boolean | number>
  >({});
  const [isNotValidForm, setIsNotValidForm] = useState(false);

  useEffect(() => {
    props.fetchFunnelBuilderBooks();
    props.fetchProducts();
  }, []);

  const funnelForm = useMemo(() => {
    if (selectedFunnelType && funnelPages[selectedFunnelType]) {
      if (funnelFormIndex === 0) {
        return funnelPages[selectedFunnelType][funnelFormIndex];
      }

      return funnelPages[selectedFunnelType][funnelFormIndex - 1];
    }
  }, [selectedFunnelType, funnelFormIndex]);

  const handleStartGeneration = () => {
    const data = funnelData;
    props.addFunnel(typeof data.name === 'string' ? data.name : 'Blank');
  };

  useEffect(() => {
    const data = funnelData as any;
    console.log('funnel data:', data, props.activeFunnelId, selectedFunnelType);
    if (props.activeFunnelId && selectedFunnelType) {
      const sendPagesForGeneration = async (funnelId: string) => {
        const funnelFlow = funnelPagesFlows[selectedFunnelType];
        const pagesInfo: IPageInfo[] = [];
        for (const page of funnelFlow as any) {
          const aiSourceInfo: IAiSourceInfo[] = [];
          let mapIsNotComplete = false;
          for (let key in page.aiSourceMap) {
            const n = (page.aiSourceMap as any)[key];
            const value = data[n];
            if (value === undefined && n !== 'bookId') {
              mapIsNotComplete = true;
              console.log(
                'Info: Data is not complete for this page, skipping page generation'
              );
              break;
            }
            aiSourceInfo.push({
              id: key,
              text: value,
            });
          }

          if (mapIsNotComplete) {
            continue;
          }

          let payload = {
            aiPageId: page.aiPageId,
            aiSourceInfo,
            name: page.title,
          } as any;

          if (page.aiProduct) {
            const prodInfo = page.aiProduct;
            const productId = data[prodInfo.idField];
            const productName = data[prodInfo.nameField];
            if (productId || productName) {
              payload.products = [
                {
                  type: prodInfo.type,
                  id: productId,
                  name: productName,
                  currency: data[prodInfo.currencyField],
                  price: data[prodInfo.priceField],
                  aiField: prodInfo.aiField,
                },
              ];
            }
          }

          pagesInfo.push(payload);
        }

        // console.log('start page generation:', pagesInfo);

        await props.addPage({
          funnelId: funnelId,
          pagesInfo: JSON.stringify(pagesInfo),
        });

        window.location.replace(`/console/projects/${funnelId}/refresh`);
      };

      sendPagesForGeneration(props.activeFunnelId);
    }
  }, [props.activeFunnelId]);

  const handleNextClick = () => {
    if (selectedFunnelType !== undefined) {
      if (funnelFormIndex !== 0) {
        const isNotValid = Object.values(funnelForm!.fields).some(
          (field) => field.required && !funnelData[field.name]
        );
        setIsNotValidForm(isNotValid);
        if (isNotValid) return;
      }

      const funnelFormsLength = funnelPages[selectedFunnelType].length;
      if (funnelFormsLength <= funnelFormIndex) {
        handleStartGeneration();
        //TODO: might need to add 'processing...' screen
      }
    }

    setFunnelFormIndex((prev) => prev + 1);
  };

  const handlePreviousClick = () => {
    if (funnelFormIndex === 0) {
      setSelectFunnelType(undefined);
      return;
    }

    setFunnelFormIndex((prev) => prev - 1);
  };

  const handleValueChange = useCallback(
    (id: string, value: string | number | boolean) => {
      setFunnelData((prev) => ({ ...prev, [id]: value }));
    },
    []
  );

  const userAuthed = validateUser();
  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <div className={styles.container}>
      <Link to="/console" color="secondary" className={styles.backButton}>
        <ArrowLeft fill="black" />
        Dashboard
      </Link>
      {!selectedFunnelType && (
        <div className={styles.funnelTypeSelector}>
          <div className={styles.mainHeader}>
            <div className={styles.title}>Choose Your Goal</div>
            <div className={styles.subtitle}>
              Quickly and easy create a funnel that
              <br /> will meet your needs!
            </div>
          </div>
          <div className={styles.funnelTypeCards}>
            {funnelTypes.map((funnel) => (
              <FunnelTypeCard
                key={funnel.id}
                {...funnel}
                Icon={funnel.icon}
                onClick={() => {
                  setSelectFunnelType(funnel.id);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {selectedFunnelType && funnelForm && (
        <div className={styles.funnelFormWrapper}>
          <div className={styles.header}>
            <IconWithBackground
              showBackground
              Icon={funnelForm.icon}
              color={funnelForm.color}
            />
            <div className={styles.formTitle}>
              {funnelFormIndex === 0
                ? `Your ${selectedFunnelType} Funnel`
                : funnelForm.title}
            </div>
            <div className={styles.formSubtitle}>
              {funnelFormIndex === 0
                ? "Below are the pages you'll have in your funnel"
                : funnelForm.subtitle}
            </div>
          </div>
          {funnelFormIndex === 0 ? (
            <FunnelPageFlow pages={funnelPagesFlows[selectedFunnelType]} />
          ) : (
            <div className={styles.formWrapper}>
              <funnelForm.Form
                data={funnelData}
                validationFailed={isNotValidForm}
                fields={funnelForm.fields}
                onValueChange={handleValueChange}
              />
            </div>
          )}
          <div className={styles.navButtons}>
            <Button
              prefixIcon={<ArrowLeft />}
              appearance="stroke"
              onClick={handlePreviousClick}
            >
              Previous
            </Button>
            <Button
              postfixIcon={<ArrowLeft className={styles.rightIcon} />}
              appearance="solid"
              onClick={handleNextClick}
            >
              Next
            </Button>
            {funnelForm.optional && (
              <Button appearance="stroke" onClick={handleNextClick}>
                Skip this Page
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeFunnelId: state.projects.activeProjectId,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchFunnelBuilderBooks: () => dispatch(fetchFunnelBuilderBooks()),
  fetchProducts: () => dispatch(fetchUserProductsAction()),
  addFunnel: (funnelName: string) => dispatch(addFunnelAction(funnelName)),
  addPage: async (args: IAddPageFromAiPayload) =>
    dispatch(addPageFromAiAction(args)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelBuilder);
