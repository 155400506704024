import { useEffect, useState } from 'react';
import { Person, SmartList, Tag } from '../Helper/types';
import SearchInput from 'UILib/SearchInput/SearchInput';

import s from 'Pages/PageContacts/Search/Search.module.scss';

type Search = {
  searchText: string;
  setSearchText: (text: string) => void;
  filteredPeople: Person[];
  handleClearText: () => void;
  smartLists: SmartList[];
  smartSwitch: boolean;
  totalCount: number;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  filteredTags: Tag[];
};

export const SearchBar = (props: Search) => {
  const [searchedText, setSearchedText] = useState<string>('');
  const [searchedTags, setSearchedTags] = useState<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      if (props.smartSwitch) {
        props.setSearchTerm(searchedTags);
      } else {
        props.setSearchText(searchedText);
      }
    }, 800);

    return () => {
      clearTimeout(handler);
    };
  }, [searchedText, searchedTags, props.smartSwitch]);

  useEffect(() => {
    setSearchedText(props.searchText);
  }, [props.searchText]);

  return (
    <div className={s.head}>
      <div className={s.headLeft}>
        <div
          className={
            props.smartLists.length < 1
              ? s.searchBlockExpanded
              : s.searchBlockExpandedSmart
          }
        >
          {!props.smartSwitch ? (
            <div className={s.searchContent}>
              <SearchInput
                value={searchedText}
                onChange={setSearchedText}
                className={s.search}
                border="stroke"
              />
            </div>
          ) : (
            <div className={s.searchContentTag}>
              <SearchInput
                value={searchedTags}
                onChange={setSearchedTags}
                className={s.search}
                border="stroke"
              />
            </div>
          )}
        </div>

        <div
          className={
            props.smartLists.length < 1 ? s.searchResults : s.searchResultsSmart
          }
        >
          <div className={s.total}>Total: {props.totalCount}</div>
        </div>
      </div>
    </div>
  );
};
