import { useParams } from 'react-router-dom';
import GenerationPreview from './GenerationPreview/GenerationPreview';
import styles from './PageGenerationEditor.module.scss';

interface IProps {}

const PageGenerationTaskViewer = ({}: IProps) => {
  const { taskId } = useParams<{ taskId: string; project?: string }>();

  return (
    <div className={styles.container}>
      <div className={styles.workbench}>
        <div className={styles.generationPreview}>
          <GenerationPreview generationTaskId={taskId} readonly={true} />
        </div>
      </div>
    </div>
  );
};

export default PageGenerationTaskViewer;
