import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICampaign } from './constants';
import { graphQlCall } from 'graphql/utils';
import clsx from 'clsx';
import ChooseCampaignScreen from './ChooseCampaignScreen';
import ProjectInfoScreen from './ProjectInfoScreen';
import ChooseTemplatesScreen from './ChooseTemplatsScreen';
import queries from 'graphql/queries';

import styles from './ChooseCampaign.module.scss';

const ChooseCampaign = () => {
  const [selectedCampaign, setSelectedCampaign] = useState<ICampaign | null>(
    null
  );
  const [step, setStep] = useState<number>(1);
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const history = useHistory();

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  const handleCreate = (type: 'ai' | 'scratch') => {
    if (type === 'ai') {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      handleNext();
    }
  };

  const handleChangeInput = (value: string, type: 'name' | 'description') => {
    if (type === 'name') {
      setProjectName(value);
    } else {
      setProjectDescription(value);
    }
  };

  const handleSubmit = async (templateId?: string) => {
    try {
      setSaving(true);
      const response = await graphQlCall({
        queryTemplateObject:
          queries.CREATE_AUTO_RESPONDER_WITH_SEQUENCE_MUTATION,
        values: {
          name: projectName,
          description: projectDescription,
          generationTemplateId: selectedCampaign?.templateId || templateId,
        },
        headerType: 'USER-AUTH',
      });

      if (response?._id) {
        history.push(`/console/auto-responders/${response._id}/details`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const currentScreen = () => {
    switch (step) {
      case 1:
        return (
          <ChooseCampaignScreen
            onCampaignClick={(campaign) => {
              setSelectedCampaign(campaign);
              handleNext();
            }}
          />
        );
      case 2:
        return (
          <ProjectInfoScreen
            isLoading={isLoading}
            projectName={projectName}
            projectDescription={projectDescription}
            onChangeInput={handleChangeInput}
            handleCreate={handleCreate}
            selectedCampaign={selectedCampaign}
            handleBack={handleBack}
          />
        );
      case 3:
        return (
          <ChooseTemplatesScreen
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            loading={saving}
          />
        );
    }
  };

  return (
    <div
      className={clsx(styles.pageContainer, {
        [styles.selectedCampaign]: selectedCampaign !== null,
      })}
    >
      {currentScreen()}
    </div>
  );
};

export default ChooseCampaign;
