import { api, getToken } from '../../utils/Utils';
import { PAGECRAFT_API_URL } from '../../GlobalConstants';
import { pushAlert } from '../alerts/alertsActions';
import { Dispatch, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ITemplateItem, ICategory } from 'types';
import { RootState } from '../rootReducer';
import QUERIES from '../../graphql/queries';
import { graphQlCall } from '../../graphql/utils';
import queries from '../../graphql/queries';

export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_FUNNEL_IDS = 'SET_FUNNEL_IDS';

export const fetchTemplatesAction = () => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  const templatesList = await graphQlCall({
    queryTemplateObject: QUERIES.GET_TEMPLATES_LIST,
  });

  dispatch({
    type: SET_TEMPLATES,
    payload: templatesList as ITemplateItem,
  });
};

export const fetchCategoriesAction = () => (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  const headers = {
    authorization: getToken(),
  };
  api(`${PAGECRAFT_API_URL}/template-category`, 'GET', null, headers)
    .then((data: any) => {
      dispatch({
        type: SET_CATEGORIES,
        payload: data as ICategory[],
      });
    })
    .catch((error) => {
      pushAlert('error', 'API error', error.message);
    });
};

export const addCategoryAction = (fields: any) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  api(`${PAGECRAFT_API_URL}/template-category`, 'POST', fields, headers)
    .then((data: any) => {
      const state = getState();
      let categories = [...state.templates.categories];
      categories.push(data);
      dispatch({
        type: SET_CATEGORIES,
        payload: categories,
      });
    })
    .catch((error) => {
      pushAlert('error', 'API error', error.message);
    });
};

export const updateCategoryAction = (fields: any, id: string) => (
  dispatch: Dispatch,
  getState: () => RootState
) => {
  const state = getState();
  const categories = state.templates.categories.map((category) => {
    if (category._id === id) {
      category = { ...category, ...fields };
    }
    return category;
  });
  dispatch({
    type: SET_CATEGORIES,
    payload: categories,
  });

  const headers = {
    authorization: getToken(),
  };
  api(
    `${PAGECRAFT_API_URL}/template-category/${id}`,
    'PATCH',
    fields,
    headers
  ).catch((error) => {
    pushAlert('error', 'API error', error.message);
  });
};

export const deleteCategoryAction = (id: string) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  const state = getState();
  const templates = state.templates.items.filter(
    (template) => template._id === id
  );
  if (templates.length) {
    pushAlert('error', 'PageTemplatesModal is not empty');
    return;
  }
  const categories = state.templates.categories.filter(
    (category) => category._id !== id
  );
  dispatch({
    type: SET_CATEGORIES,
    payload: categories,
  });
  api(`${PAGECRAFT_API_URL}/template-category/${id}`, 'DELETE', null, headers)
    .then((data: any) => {
      if (data.error) {
        return;
      }
    })
    .catch((error) => {
      pushAlert('error', 'API error', error);
    });
};

interface IChangeCategoryActionPayload {
  categoryId: string;
  template: ITemplateItem;
}

export const changeCategoryAction = ({
  categoryId,
  template,
}: IChangeCategoryActionPayload) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const templateId = template._id;
  const headers = {
    authorization: getToken(),
  };
  const state = getState();
  const templates = state.templates.items.map((template) => {
    if (template._id === templateId) {
      template.category = categoryId;
    }
    return template;
  });
  dispatch({
    type: SET_TEMPLATES,
    payload: templates,
  });

  if (template.type === 'template') {
    api(
      `${PAGECRAFT_API_URL}/template/${templateId}`,
      'PATCH',
      { category: categoryId },
      headers
    ).catch((error) => {
      pushAlert('error', 'API error', error);
    });
  } else {
    graphQlCall({
      queryTemplateObject: queries.UPDATE_AI_PAGE,
      values: {
        pageId: template.page,
        data: JSON.stringify({ category: categoryId }),
      },
      headerType: 'AGENCY_AUTH',
    });
  }
};

interface IUpdateTemplateActionPayload {
  fields: any;
  template: ITemplateItem;
}

export const updateTemplateAction = ({
  fields,
  template,
}: IUpdateTemplateActionPayload) => async (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  const state = getState();
  const id = template._id;
  const templates = state.templates.items.map((template) => {
    if (template._id === id) {
      template = { ...template, ...fields };
    }
    return template;
  });
  dispatch({
    type: SET_TEMPLATES,
    payload: templates,
  });

  if (template.type === 'template') {
    api(`${PAGECRAFT_API_URL}/template/${id}`, 'PATCH', fields, headers).catch(
      (error) => {
        pushAlert('error', 'API error', error);
      }
    );
  } else {
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_AI_PAGE,
      values: {
        pageId: template.page,
        data: JSON.stringify({ ...fields }),
      },
      headerType: 'AGENCY_AUTH',
    });
  }
};

export const deleteTemplateAction = (template: ITemplateItem) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const id = template._id;
  const headers = {
    authorization: getToken(),
  };
  const state = getState();
  const templates = state.templates.items.filter(
    (template) => template._id !== id
  );
  dispatch({
    type: SET_TEMPLATES,
    payload: templates,
  });

  if (template.type === 'template') {
    api(`${PAGECRAFT_API_URL}/template/${id}`, 'DELETE', null, headers).catch(
      (error) => {
        pushAlert('error', 'API error', error);
      }
    );
  } else {
    graphQlCall({
      queryTemplateObject: queries.DELETE_AI_PAGE,
      values: {
        id,
      },
      headerType: 'AGENCY_AUTH',
    });
  }
};

export const fetchFunnelsIdsAction = () => async (
  dispatch: ThunkDispatch<RootState, void, Action>
) => {
  try {
    const result = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ALL_FUNNEL_IDS_QUERY,
      headerType: 'USER-AUTH',
    });

    const ids = result.ids as number[];

    dispatch({
      type: SET_FUNNEL_IDS,
      payload: ids.map((id) => ({ _id: id })),
    });
  } catch (err: any) {
    pushAlert('error', 'API error', err);
  }
};

export const createTemplateAction = (id: string, type: string) => async (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  const state = getState();
  let templates = [...state.templates.items];
  dispatch({
    type: SET_TEMPLATES,
    payload: [],
  });
  if (type === 'template') {
    const data = await api(
      `${PAGECRAFT_API_URL}/template/${id}`,
      'POST',
      null,
      headers
    );
    templates.push({ ...data, type: 'template', category: null });
    dispatch({
      type: SET_TEMPLATES,
      payload: templates,
    });
  } else {
    const data = await graphQlCall({
      queryTemplateObject: QUERIES.GET_ONE_AI_PAGE,
      values: {
        pageId: id,
      },
      headerType: 'AGENCY_AUTH',
    });
    console.log('NEW AI PAGE DATA', data);
    templates.push({ ...data, type: 'aipage', page: id, category: null });
    console.log('NEW TEMPLATES', templates);
    dispatch({
      type: SET_TEMPLATES,
      payload: templates,
    });
  }
};

export const syncTemplateAction = (id: string) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const headers = {
    authorization: getToken(),
  };
  api(`${PAGECRAFT_API_URL}/template/${id}`, 'PUT', null, headers)
    .then((data: any) => {
      const state = getState();
      const templates = state.templates.items.map((template) => {
        if (template._id === id) {
          template = data;
        }
        return template;
      });
      dispatch({
        type: SET_TEMPLATES,
        payload: templates,
      });
    })
    .catch((error) => {
      console.log('ERROR', error);
      pushAlert('error', 'API error', error);
    });
};

interface IUpdateImageActionPayload {
  template: ITemplateItem;
  file: File;
}

export const updateTemplateImageAction = ({
  template,
  file,
}: IUpdateImageActionPayload) => (
  dispatch: ThunkDispatch<RootState, void, Action>,
  getState: () => RootState
) => {
  const id = template._id;
  const request = new FormData();
  request.append('file', file);
  request.append('id', id);
  const headers = {
    authorization: getToken(),
  };

  const url =
    template.type === 'template'
      ? `${PAGECRAFT_API_URL}/template/${id}`
      : `${PAGECRAFT_API_URL}/ai-page/${id}`;

  api(url, 'PATCH', request, headers)
    .then((data: any) => {
      const state = getState();
      const templates = state.templates.items.map((template) => {
        if (template._id === id) {
          template = { ...template, ...data };
        }
        return template;
      });
      dispatch({
        type: SET_TEMPLATES,
        payload: templates,
      });
    })
    .catch((error) => {
      console.log('ERROR', error);
      pushAlert('error', 'API error', error);
    });
};
