import { ReactComponent as Email } from 'Assets/icons/email.svg';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';

import styles from './LaunchConfirmationPopup.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  subject: string;
  emailCount: number;
  onSubmit: () => Promise<void>;
  isLoading: boolean;
}

const LaunchConfirmationPopup = ({
  open,
  onClose,
  subject,
  emailCount,
  onSubmit,
  isLoading,
}: IProps) => {
  if (!open) return null;

  const handleSubmit = async () => {
    if (!isLoading) {
      await onSubmit();
      onClose();
    }
  };

  return (
    <Popup onClose={onClose} wrapperClassName={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.form}>
          <Email className={styles.emailIcon} />
          <div className={styles.title}>Confirm Sending</div>
          <div className={styles.subtitle}>
            Confirm the email campaign you are about to launch.
          </div>
          <div className={styles.subjectContainer}>
            <div className={styles.subtitle}>Subject </div>
            {subject}
          </div>
          <div className={styles.subtitle}>
            You are going to send{' '}
            <b className={styles.emailsCount}>{emailCount}</b> emails.
          </div>
          <div className={styles.buttonsContainer}>
            <Button
              onClick={onClose}
              className={styles.backButton}
              height={40}
              appearance="stroke"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={styles.backButton}
              height={40}
              disabled={isLoading}
            >
              {isLoading ? (
                <CircleLoader size={20} color="#ffffff" />
              ) : (
                'Launch'
              )}
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default LaunchConfirmationPopup;
