export const GET_AUTO_RESPONDERS_STATISTICS = {
  operationName: 'getAutorespondersStatistic',
  query: `query getAutorespondersStatistic($ids: [String!]!) {
              getAutorespondersStatistic(ids: $ids) {
                  delivered
                  opened
                  clicked
              }
            }`,
};
