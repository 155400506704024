import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ChevronDown, ChevronUp, Plus } from 'lucide-react';
import { DispatchType, RootState } from 'store/rootReducer';
import { fetchUserDetails } from 'store/user/userActions';
import { IWalletBalance } from 'store/stripe/stripeReducer';
import {
  getWalletBalance,
  updateWalletBalance,
} from 'store/stripe/stripeActions';
import { Card } from 'RadixUI/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';
import { Button } from 'RadixUI/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'RadixUI/table';
import { ReactComponent as Refresh } from 'Assets/icons/refresh.svg';
import { ReactComponent as Attention } from 'Assets/icons/attention.svg';
import { cn } from 'utils/Utils';
import { formatAmount } from 'utils/helpers';
import { graphQlCall } from 'graphql/utils';
import { IUsageDetail, services, tableItems } from './constants';
import { IUserDetails } from 'types';
import { balanceDropdownItems } from './constants';
import queries from 'graphql/queries';
import AddWalletBalancePopup from 'Components/AddWalletBalancePopup/AddWalletBalancePopup';
import WalletSkeleton from 'Components/Skeletons/WalletSkeletion/WalletSkeletion';

import styles from './Wallet.module.scss';

interface IProps {
  userDetails: IUserDetails;
  fetchUserDetails: () => void;
  walletBalance: IWalletBalance;
  updateWalletBalance: ({ ...value }) => void;
  getWalletBalance: () => void;
}

const Wallet = ({
  userDetails,
  fetchUserDetails,
  walletBalance,
  updateWalletBalance,
  getWalletBalance,
}: IProps) => {
  const [period, setPeriod] = useState<string>('current_month');
  const [sortBy, setSortBy] = useState<{ key: string; asc: boolean }>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [usageStats, setUsageStats] = useState<IUsageDetail[]>([]);
  const [addBalancePopupOpen, setAddBalancePopupOpen] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [spinRefresh, setSpinRefresh] = useState(false);

  useEffect(() => {
    if (!userDetails._id) {
      fetchUserDetails();
    } else {
      getWalletBalance();
    }
  }, [userDetails]);

  const getUsageStats = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: queries.GET_USAGE_STATISTICS,
        headerType: 'USER-AUTH',
        values: getTimeRange(period),
      });

      //TODO: rmeove this hardcoded workaround once the backend is fixed.
      //We will hide AI Credits from customer for now to prevent confusion.
      const filteredServices = response.details.filter(
        (e: any) => e.serviceName !== 'AI_CREDITS'
      );
      setUsageStats(filteredServices);
      setTotalAmount(response.totalAmount);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateAutoRechargeBalance = async ({ ...values }) => {
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_AUTO_RECHARGE_BALANCE,
      headerType: 'USER-AUTH',
      values: values,
    });
  };

  useEffect(() => {
    getUsageStats();
  }, [period]);

  const getTimeRange = (value: string) => {
    const now = new Date();

    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    switch (value) {
      case 'current_month':
        return {
          startDate: startOfMonth,
          endDate: today,
        };
      case 'last_month':
        return {
          startDate: new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            1
          ).toLocaleDateString('en-US'),
          endDate: new Date(
            now.getFullYear(),
            now.getMonth(),
            0
          ).toLocaleDateString('en-US'),
        };
      case 'two_month_ago':
        return {
          startDate: new Date(
            now.getFullYear(),
            now.getMonth() - 2,
            1
          ).toLocaleDateString('en-US'),
          endDate: new Date(
            now.getFullYear(),
            now.getMonth() - 1,
            0
          ).toLocaleDateString('en-US'),
        };
      default:
        return { startDate: startOfMonth, endDate: today };
    }
  };

  const sortedUsageStats = [...usageStats].sort((a, b) => {
    if (!sortBy) return 0;
    const { key, asc } = sortBy;

    let valueA = '';
    let valueB = '';

    if (key === 'service') {
      valueA = services.find((e) => e.key === a.serviceName)?.name || '';
      valueB = services.find((e) => e.key === b.serviceName)?.name || '';
    } else {
      valueA = a[key as keyof IUsageDetail] as string;
      valueB = b[key as keyof IUsageDetail] as string;
    }

    if (typeof valueA === 'string') valueA = valueA.toLowerCase();
    if (typeof valueB === 'string') valueB = valueB.toLowerCase();

    if (valueA < valueB) return asc ? -1 : 1;
    if (valueA > valueB) return asc ? 1 : -1;
    return 0;
  });

  const onUpdateBalance = (amount: number) => {
    updateWalletBalance({ amount: walletBalance.amount + amount });
  };

  const handleRefreshBalance = () => {
    setSpinRefresh(true);
    getWalletBalance();
    setTimeout(() => setSpinRefresh(false), 700);
  };

  return (
    <>
      <AddWalletBalancePopup
        open={addBalancePopupOpen}
        onClose={() => setAddBalancePopupOpen(false)}
        onUpdateBalance={onUpdateBalance}
        hasStripeAccount={!!userDetails.stripeClientId}
      />
      {loading ? (
        <WalletSkeleton />
      ) : (
        <div className="max-w-4xl">
          <Card className="p-6 mb-6">
            <div className="space-y-8">
              <div>
                <h3 className="text-base text-muted-foreground mb-2">
                  Your Wallet Balance
                </h3>
                <div className="flex items-center justify-between">
                  <p className="text-4xl font-bold">
                    {formatAmount(walletBalance.amount)}
                  </p>
                  <div className="flex gap-2">
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={handleRefreshBalance}
                      disabled={spinRefresh}
                    >
                      <span className="sr-only">Refresh balance</span>
                      <Refresh
                        className={cn(spinRefresh && 'animate-spin-once')}
                      />
                    </Button>
                    <Button onClick={() => setAddBalancePopupOpen(true)}>
                      <Plus className="h-4 w-4 mr-2" />
                      Add Balance
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-base text-muted-foreground mb-4">
                  Auto recharge with
                </h3>
                <div className="flex items-center space-x-4">
                  <div className="flex items-center space-x-2">
                    <Select
                      defaultValue="10"
                      value={String(walletBalance.minPaymentAmount)}
                      onValueChange={(value) => {
                        if (Number(value) > 0) {
                          updateWalletBalance({
                            minPaymentAmount: Number(value),
                          });
                          updateAutoRechargeBalance({
                            amount: Number(value) * 100,
                          });
                        }
                      }}
                    >
                      <SelectTrigger className="w-[100px]">
                        <SelectValue placeholder="Amount" />
                      </SelectTrigger>
                      <SelectContent>
                        {balanceDropdownItems.map((item) => (
                          <SelectItem value={String(item.value)}>
                            $ {item.value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>

                    <span>when balance lower than</span>
                    <Select
                      defaultValue="0"
                      value={String(walletBalance.balanceThreshold)}
                      onValueChange={(value) => {
                        if (Number(value) >= 0) {
                          updateWalletBalance({
                            balanceThreshold: Number(value),
                          });
                          updateAutoRechargeBalance({
                            balanceThreshold: Number(value) * 100,
                          });
                        }
                      }}
                    >
                      <SelectTrigger className="w-[100px]">
                        <SelectValue placeholder="Amount" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="0">$ 0</SelectItem>
                        {balanceDropdownItems.map((item) => (
                          <SelectItem value={String(item.value)}>
                            $ {item.value}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-x-[10px] bg-gray-100 w-fit py-[7px] px-[10px] rounded-[4px] text-gray-400">
                <Attention className={styles.attentionIcon} /> Recharge amount
                will be auto-updated based on your recharge history
              </div>
            </div>
          </Card>
          <Card className="p-4 sm:p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold">
                Detailed Usage Breakdown
              </h3>
              <Select
                defaultValue="current"
                value={period}
                onValueChange={setPeriod}
              >
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select period" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="current_month">Current Month</SelectItem>
                  <SelectItem value="last_month">Last Month</SelectItem>
                  <SelectItem value="two_month_ago">2 Months Ago</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  {tableItems.map((item) => (
                    <TableHead
                      onClick={(e) => {
                        e.stopPropagation();
                        setSortBy({
                          asc: !sortBy?.asc,
                          key: item.value,
                        });
                      }}
                    >
                      <span
                        className={cn(
                          item.className,
                          'relative cursor-pointer group flex'
                        )}
                      >
                        {item.label}{' '}
                        <span className="absolute right-[-20px] top-[-5px]">
                          <ChevronUp
                            className="opacity-0 group-hover:opacity-100"
                            width={16}
                            height={16}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSortBy({
                                asc: true,
                                key: item.value,
                              });
                            }}
                            color={
                              !!sortBy?.asc && sortBy?.key === item.value
                                ? '#4619ff'
                                : 'black'
                            }
                          />
                          <ChevronDown
                            className="opacity-0 group-hover:opacity-100"
                            width={16}
                            height={16}
                            color={
                              !sortBy?.asc && sortBy?.key === item.value
                                ? '#4619ff'
                                : 'black'
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              setSortBy({
                                asc: false,
                                key: item.value,
                              });
                            }}
                          />
                        </span>
                      </span>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedUsageStats.map((item) => {
                  const service = services.find(
                    (e) => e.key === item.serviceName
                  );

                  return (
                    <TableRow key={item.serviceName}>
                      <TableCell className="font-medium">
                        {service?.name}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-x-[20px] items-center">
                          {item.usage} {service?.usage}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        {formatAmount(item.amount)}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow className="font-medium">
                  <TableCell>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell className="text-right">
                    {formatAmount(totalAmount)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
  walletBalance: state.stripe.walletBalance,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  fetchUserDetails: () => dispatch(fetchUserDetails()),
  getWalletBalance: () => dispatch(getWalletBalance()),
  updateWalletBalance: ({ ...value }) =>
    dispatch(updateWalletBalance({ ...value })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
