export const GET_WALLET_BALANCE = {
  operationName: 'getWalletBalance',
  query: `mutation getWalletBalance {
            getWalletBalance {
                amount
                minPaymentAmount
                balanceThreshold
            }
        }`,
};
