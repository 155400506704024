import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MagicIcon } from 'Assets/icons/magic.svg';
import { ICampaign } from './constants';
import CampaignLogo from 'Components/AutoResponder/CampaignLogo/CampaignLogo';
import Button from 'UILib/Button/Button';
import Input from 'UILib/Input/Input';
import Loader from 'UILib/Loader/Loader';
import TextArea from 'UILib/TextArea/TextArea';
import Link from 'UILib/Link/Link';

import styles from './ChooseCampaign.module.scss';

interface IProps {
  selectedCampaign: ICampaign | null;
  projectDescription: string;
  projectName: string;
  onChangeInput: (value: string, type: 'name' | 'description') => void;
  isLoading: boolean;
  handleCreate: (type: 'scratch' | 'ai') => void;
  handleBack: () => void;
}

const ProjectInfoScreen = ({
  selectedCampaign,
  onChangeInput,
  projectName,
  projectDescription,
  isLoading,
  handleCreate,
  handleBack,
}: IProps) => {
  if (!selectedCampaign) {
    return null;
  }

  return (
    <div className={styles.screenContainer}>
      <Link
        to="/console/outreach/auto-responders"
        className={styles.backLink}
        prefixIcon={<Back />}
      >
        Dashboard
      </Link>
      <CampaignLogo
        color={selectedCampaign.iconColor}
        icon={selectedCampaign.icon}
        transparent={!selectedCampaign.tag}
      />
      <div className={styles.selectedCampaignTitle}>
        {selectedCampaign.title}
      </div>
      <div className={styles.selectedCampaignSubtitle}>
        Give us more information about your project.
      </div>
      <div className={styles.campaignForm}>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>Project Name</div>
          <Input
            border="stroke"
            placeholder="Name Your Project"
            value={projectName}
            onChange={(e) => onChangeInput(e.target.value, 'name')}
          />
        </div>
        <div className={styles.formGroup}>
          <div className={styles.formLabel}>Description</div>
          <TextArea
            height={172}
            placeholder="What is your project about?"
            value={projectDescription}
            onChange={(e) => onChangeInput(e, 'description')}
          />
        </div>
        <div className={styles.buttonsContainer}>
          {selectedCampaign.id !== 1 ? (
            <Button
              appearance="highlighted"
              width={240}
              height={50}
              prefixIcon={!isLoading && <MagicIcon />}
              disabled={!projectName || !projectDescription || isLoading}
              onClick={() => handleCreate('ai')}
            >
              Create with AI
            </Button>
          ) : (
            <Button
              appearance="stroke"
              width={180}
              height={40}
              disabled={!projectName || !projectDescription || isLoading}
              onClick={() => handleCreate('scratch')}
            >
              {isLoading ? (
                <Loader color="#000000" size={16} />
              ) : (
                'Create from Scratch'
              )}
            </Button>
          )}
          <div className={styles.back} onClick={handleBack}>
            <Back className={styles.backIcon} /> Back
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectInfoScreen;
