import { useHistory } from 'react-router-dom';
import { ReactComponent as Alert } from 'Assets/icons/alert.svg';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';

import styles from './AutomaticWithdrawalFailedPopup.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
}

const AutomaticWithdrawalFailedPopup = ({ open, onClose }: IProps) => {
  const history = useHistory();

  if (!open) {
    return null;
  }

  return (
    <Popup onClose={onClose} wrapperClassName={styles.popup}>
      <div className={styles.container}>
        <Alert className={styles.alert} />
        <div className={styles.title}>Automatic Withdrawal of Funds Failed</div>
        <div className={styles.subtitle}>
          Please go to wallet page and try to replenishment your balance
        </div>
        <div className={styles.buttonsContainer}>
          <Button onClick={onClose} height={40} width={180} appearance="stroke">
            Cancel
          </Button>
          <Button
            height={40}
            width={180}
            onClick={() => history.push('/console/settings/wallet')}
          >
            Go to Wallet
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AutomaticWithdrawalFailedPopup;
