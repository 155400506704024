import { ReactNode, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { validateUser } from 'utils/Utils';
import SideMenu from 'Components/SideMenu/SideMenu';
import Templates from 'Pages/GenerationTemplates/GenerationTemplates';
import Workflows from 'Pages/Workflows/Workflows';
import Broadcasts from 'Pages/Broadcasts/Broadcasts';
import AutoResponders from 'Pages/AutoResponders/AutoResponders';

import styles from './Outreach.module.scss';

const outreachProjects: Record<string, ReactNode> = {
  broadcasts: <Broadcasts />,
  workflows: <Workflows />,
  templates: <Templates />,
  'auto-responders': <AutoResponders />,
};

const Outreach = () => {
  const location = useLocation();
  const userAuthed = validateUser();

  useEffect(() => {
    if (location.pathname === '/console/outreach') {
      setActiveProject('broadcasts');
    } else {
      Object.keys(outreachProjects).forEach((project) => {
        if (location.pathname.includes(project)) {
          setActiveProject(project);
        }
      });
    }
  }, [location.pathname]);

  const [activeProject, setActiveProject] = useState('');

  if (!userAuthed) {
    return <Redirect to={{ pathname: '/console/login' }} />;
  }

  return (
    <div className={styles.pageWrapper}>
      <SideMenu />
      {outreachProjects[activeProject]}
    </div>
  );
};

export default Outreach;
