import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { AutoSassIconsOptions } from 'Components/SideMenu/constants';
import { ReactComponent as AppIcon } from 'Assets/icons/appIcons/templates.svg';
import { ReactComponent as ArrowRightIcon } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as MagicIcon } from 'Assets/icons/magic.svg';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { BookleTemplateBlock, IGenerationTemplate, TemplateForm } from 'types';
import { getFormElement } from './AddFileForm';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import DepletedBalanceModal from 'Components/DepletedBalanceModal/DepletedBalanceModal';
import AutomaticWithdrawalFailedPopup from 'Components/AutomaticWithdrawalFailedPopup/AutomaticWithdrawalFailedPopup';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import Link from 'UILib/Link/Link';

import styles from './AddNewAutoFile.module.scss';

interface IProps {
  autoApps?: IAutoApp[];
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (payload: BookleTemplateBlock[]) => void;
}

const AddNewAutoFile = ({ autoApps, updateBlocks, templateBlocks }: IProps) => {
  const { appId, templateId } = useParams<{
    appId: string;
    templateId: string;
  }>();
  const [appData, setAppData] = useState<IAutoApp>();
  const [templateForms, setTemplateForms] = useState<TemplateForm[]>([]);
  const [layout, setLayout] = useState<string>();
  const [fetchingForms, setFetchingForms] = useState<boolean>(false);
  const [fetchingGenerationTask, setFetchingGenerationTask] = useState<boolean>(
    false
  );
  const [currentFormIndex, setCurrentFormIndex] = useState<number>(0);
  const [formValidationError, setFormValidationError] = useState(false);
  const [generationData, setGenerationData] = useState<
    Record<string, Record<string, string | number>>
  >({});
  const [initialTemplate, setInitialTemplate] = useState<IGenerationTemplate>();
  const [openBalanceModal, setOpenBalanceModal] = useState<boolean>(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState<boolean>(
    false
  );
  const [balance, setBalance] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    if (templateForms.length > 0) {
      const form = templateForms[currentFormIndex];
      if (!generationData[form.type]) {
        const generationDataCopy = { ...generationData };
        generationDataCopy[form.type] = {};
        form.variables.forEach((variable) => {
          generationDataCopy[form.type][variable.id] = '';
        });
        setGenerationData(generationDataCopy);
      }
    }
  }, [currentFormIndex, templateForms]);

  useEffect(() => {
    if (!appData && !!appId) {
      if (autoApps?.length) {
        const data = autoApps.find((e) => e._id === appId);
        if (data) {
          setAppData(data);
        } else {
          getAutoAppData();
        }
      } else {
        getAutoAppData();
      }
    }
  }, [appId, autoApps, appData]);

  const getAutoAppData = () => {
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_AUTO_APP,
      headerType: 'USER-AUTH',
      values: {
        id: appId,
      },
    }).then((data) => {
      console.log('getting app data:', data);
      setAppData(data);
    });
  };

  useEffect(() => {
    if (templateId !== '') {
      setFetchingForms(true);

      graphQlCall({
        queryTemplateObject: queries.GET_ONE_GENERATION_TEMPLATE,
        values: { id: templateId },
        headerType: 'USER-AUTH',
      })
        .then((data) => {
          if (data.actions) {
            setTemplateForms(data.actions.forms);
          } else {
            setTemplateForms([]);
          }
          setLayout(data.layout);
        })
        .finally(() => setFetchingForms(false));
    }
  }, [templateId]);

  const handleGenerationStart = async () => {
    setFetchingGenerationTask(true);
    const keys = Object.keys(generationData);
    const firstQuestion = generationData[keys[0]];

    try {
      const task = await graphQlCall({
        queryTemplateObject: queries.CREATE_GENERATION_TASK_MUTATION,
        values: {
          name: firstQuestion[Object.keys(firstQuestion)[0]] || '',
          templateId: appData?.template?._id,
          type: appData?._id,
          layout: JSON.stringify(layout),
        },
        headerType: 'USER-AUTH',
      });

      if (task.error_type === 'payment_error') {
        return setOpenWithdrawalModal(true);
      } else if (task.error_type === 'wallet_is_low') {
        setBalance(task.walletBalance);
        return setOpenBalanceModal(true);
      }

      const response = await graphQlCall({
        queryTemplateObject: queries.PUSH_DATA_TO_GENERATION_TASK_MUTATION,
        values: {
          taskId: task.task._id,
          data: JSON.stringify(generationData[keys[0]]),
          path: 'root',
        },
        headerType: 'USER-AUTH',
      });

      setFetchingGenerationTask(false);

      history.push(appLink());
    } catch (error) {
      setFetchingGenerationTask(false);
      notification.error({
        message: 'Something went wrong',
        duration: 3,
      });
      console.error('Generation start error: ', error);
    }
  };

  const appLink = () => {
    return `/console/app/${appData?.name.toLowerCase().replace(/\s+/g, '')}/${
      appData?._id
    }`;
  };

  const handleNextClick = async () => {
    const form = templateForms[currentFormIndex];
    const formValues = generationData[form.type];
    for (const key in formValues) {
      if (!formValues[key]) {
        setFormValidationError(true);
        return;
      }
    }
    setFormValidationError(false);
    if (currentFormIndex < templateForms.length - 1) {
      setCurrentFormIndex(currentFormIndex + 1);
    } else {
      await handleGenerationStart();
    }
  };

  if (fetchingForms) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#4957d8" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <DepletedBalanceModal
        open={openBalanceModal}
        onClose={() => setOpenBalanceModal(false)}
        balance={balance}
      />
      <AutomaticWithdrawalFailedPopup
        open={openWithdrawalModal}
        onClose={() => setOpenWithdrawalModal(false)}
      />
      <Link to={appLink()} className={styles.backLink} prefixIcon={<Back />}>
        Dashboard
      </Link>
      <div className={styles.header}>
        {AutoSassIconsOptions.find((item) => item.path === appData?.iconUrl)
          ?.icon || <AppIcon />}
        <div className={styles.title}>{appData?.name}</div>
        <div className={styles.subtitle}></div>
      </div>
      <div className={styles.form}>
        {templateForms[currentFormIndex]?.variables.map((variable) => (
          <div key={variable.id} className={styles.formElement}>
            {variable.label}
            {getFormElement(
              templateForms[currentFormIndex].type,
              variable,
              generationData,
              formValidationError,
              setGenerationData
            )}
          </div>
        ))}
      </div>
      <div className={styles.buttonContainer}>
        {currentFormIndex <= templateForms.length - 1 ? (
          fetchingGenerationTask ? (
            <Button appearance="solid" onClick={handleNextClick}>
              <Loader color="#ffffff" size={20} />
            </Button>
          ) : (
            <Button
              appearance="solid"
              onClick={handleNextClick}
              prefixIcon={<MagicIcon />}
            >
              Generate
            </Button>
          )
        ) : (
          <Button
            appearance="solid"
            onClick={handleNextClick}
            postfixIcon={<ArrowRightIcon />}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  autoApps: state.autosaas.autoApps,
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[]) =>
    updateBookleTemplateBlocks(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAutoFile);
