import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown as AntdDropdown, Menu } from 'antd';
import { RootState } from 'store/rootReducer';
import {
  removeSmartListPopup,
  setSelectedSmartList,
  cancelImportingContacts,
} from 'store/contacts/contactsActions';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as ImportIcon } from 'Assets/icons/import.svg';
import { ReactComponent as BackIcon } from 'Assets/icons/arrowL.svg';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ReactComponent as ThreeDotsIcon } from 'Assets/icons/threeDots.svg';
import { ReactComponent as Close } from 'Assets/icons/close2.svg';
import { ContactItem } from 'Pages/PageContacts/ContactItem/ContactItem';
import { Person, SmartList, Tag } from 'Pages/PageContacts/Helper/types';
import { Header } from 'Pages/PageContacts/Header/Header';
import { Footer } from 'Pages/PageContacts/Footer/Footer';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { validateUser } from 'utils/Utils';
import { graphQlCall } from 'graphql/utils';
import { ISmartList, ITag } from 'types';
import { TagItem } from './TagItem/TagItem';
import { TagItemPopup } from './TagItem/TagItemPopup';
import { TableHeaderTag } from './TableHeaderTag/TableHeaderTag';
import ImportContacts from './ImportContacts/ImportPopups';
import AddContactsPopup from 'Pages/PageContacts/AddContact/AddContact';
import TableHeader from 'Pages/PageContacts/TableHeader/TableHeader';
import Button from 'UILib/Button/Button';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import SmartListPopup from 'Pages/PageContacts/SmartList/SmartListPopup';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import SideMenu from 'Components/SideMenu/SideMenu';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import ContactsSkeleton from 'Components/Skeletons/ContactsSkeleton/ContactsSkeleton';
import QUERIES from 'graphql/queries';
import SmartListItem from './SmartList/SmartListItem/SmartListItem';
import clsx from 'clsx';

import scroll from './index.module.scss';
import s from './PageContacts.module.scss';

interface IProps {
  selectedSmartListId: string | undefined;
  openDeleteSmartListPopup: boolean;
  setSmartList: (payload: string) => void;
  removeSmartListPopup: (payload: boolean) => void;
  importingContacts: boolean;
  cancelImportingContacts: () => void;
}

const PageContacts = ({
  selectedSmartListId,
  openDeleteSmartListPopup,
  setSmartList,
  removeSmartListPopup,
  importingContacts,
  cancelImportingContacts,
}: IProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tagInput, setTagInput] = useState('');
  const [people, setPeople] = useState<Person[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filteredPeople, setFilteredPeople] = useState<Person[]>([]);
  const [smart, setSmart] = useState(false);
  const [smartLists, setSmartLists] = useState<SmartList[]>([]);
  const [contactSortItem, setContactSortItem] = useState({
    sortBy: 'createdAt',
    sortAsc: false,
  });
  const [selectedSmartList, setSelectedSmartList] = useState<string | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [smartSwitch, setSmartSwitch] = useState<boolean>(false);
  const [tagsCount, setTagsCount] = useState<number>(0);
  const [tags, setTags] = useState<any>({});
  const [tagAdd, setTagAdd] = useState(false);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [uniqueTags, setUniqueTags] = useState<Tag[]>([]);
  const [funnelId, setFunnelId] = useState<string>('');
  const [pageId, setPageId] = useState<string>('');
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isImportPopupOpen, setIsImportPopupOpen] = useState<boolean>(false);
  const [isSmartListInLeft, setIsSmartListInLeft] = useState<boolean>(false);
  const [closeSmartListDropdown, setCloseSmartListDropdown] = useState<boolean>(
    false
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [contentWidth, setContentWidth] = useState<number | string>('100%');

  const modalRef = useRef<HTMLDivElement | null>(null);
  const contentContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentContainerRef.current) {
      setContentWidth(contentContainerRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (contentContainerRef.current) {
        setContentWidth(contentContainerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [contentContainerRef.current?.offsetWidth]);

  useEffect(() => {
    const userAuth = validateUser();
    if (!userAuth) {
      window.open('/console/login', '_self');
    }
  }, []);

  const handleAddNewTag = async (tagName: string): Promise<Tag> => {
    const response = await graphQlCall({
      queryTemplateObject: QUERIES.ADD_CONTACT_TAG,
      headerType: 'USER-AUTH',
      values: {
        tagName: tagName,
      },
    });

    setUniqueTags([...uniqueTags, response]);

    return response;
  };

  const fetchUserContacts = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_CONTACTS_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          limit: pageSize,
          skip: (currentPage - 1) * pageSize,
          sortAsc: contactSortItem.sortAsc,
          sortBy: contactSortItem.sortBy,
          search: searchText,
          smartListId: selectedSmartList,
        },
      });
      const usersWithDefaults = response?.contacts?.map(addUserDefaults);
      setTotalContacts(response?.total);
      setPeople(usersWithDefaults);

      usersWithDefaults.forEach((user: any) => {
        setFunnelId(user?.funnel?._id);
        setPageId(user?.page?._id);
      });
    } catch (error) {
      console.error('Error fetching user contacts:', error);
    } finally {
      setLoading(false);
    }
  };

  const removeSmartList = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: QUERIES.DELETE_SMART_LIST,
        values: { id: selectedSmartListId },
        headerType: 'USER-AUTH',
      });
      const updatedSmartLists = smartLists.filter(
        (list: ISmartList) => list._id !== selectedSmartListId
      );
      setSmartLists(updatedSmartLists);
      setSmartList('');
      if (selectedSmartList === selectedSmartListId) setSelectedSmartList(null);
      removeSmartListPopup(false);
    } catch (error) {
      console.error('Error deleting smart list:', error);
    }
  };

  const addUserDefaults = (user: any) => ({
    ...user,
    phone: user.phone || '',
    data: {
      name: user.data?.name || '',
      country: user.data?.country || '',
      addr: user.data?.addr || '',
    },
    tagsId: user.tagsId || '',
  });

  useEffect(() => {
    fetchUserContacts();
  }, [pageSize, currentPage, contactSortItem, searchText, selectedSmartList]);

  const fetchSmartLists = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_SMART_LISTS,
        headerType: 'USER-AUTH',
      });
      setSmartLists(response);
    } catch (error) {
      console.error('Error fetching smart lists:', error);
    }
  };

  useEffect(() => {
    fetchSmartLists();
  }, []);

  useEffect(() => {
    fetchUniqueTags();
  }, []);

  const fetchTagCount = async () => {
    try {
      const queryResult = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_CONTACT_TAGS_WITH_PAGINATION,
        headerType: 'USER-AUTH',
        values: {
          limit: pageSize,
          skip: (currentPage - 1) * pageSize,
          search: searchTerm,
        },
      });

      setTags(queryResult.tagList);
      setTagsCount(queryResult.total);
    } catch (error) {
      console.error('Error fetching unique tags:', error);
    }
  };

  useEffect(() => {
    fetchTagCount();
  }, [people, pageSize, currentPage, searchTerm]);

  UseOnClickOutside(modalRef, () => {
    setSmart(false);
  });

  const dropdownOptions = [
    { label: '10', value: 'option1' },
    { label: '20', value: 'option2' },
    { label: '50', value: 'option3' },
    { label: '100', value: 'option4' },
  ];

  const handleDropdownChange = (value: string) => {
    switch (value) {
      case 'option1':
        setPageSize(10);
        setCurrentPage(1);
        break;
      case 'option2':
        setPageSize(20);
        setCurrentPage(1);
        break;
      case 'option3':
        setPageSize(50);
        setCurrentPage(1);
        break;
      case 'option4':
        setPageSize(100);
        setCurrentPage(1);
        break;
      default:
        break;
    }
  };

  const fetchUniqueTags = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.GET_USER_CONTACT_TAGS,
        headerType: 'USER-AUTH',
      });
      setUniqueTags(
        response?.sort((a: ITag, b: ITag) => a.name.localeCompare(b.name))
      );
    } catch (error: any) {
      console.error(`\x1b[31mError fetching unique tags:\x1b[0m`, error);
    }
  };

  const deletePersons = async () => {
    try {
      const deletedIds = selectedCheckboxes.filter(Boolean);
      await Promise.all(
        deletedIds.map(async (_id) => {
          if (_id) {
            try {
              await graphQlCall({
                queryTemplateObject: QUERIES.DELETE_CONTACT,
                values: {
                  id: _id.toString(),
                },
                headerType: 'USER-AUTH',
              });
            } catch (error) {
              console.error(`Failed to delete contact with ID ${_id}:`, error);
            }
          }
        })
      );

      await fetchUserContacts();
      setSelectedCheckboxes([]);
    } catch (error) {
      console.error('Error deleting contacts:', error);
    }
  };

  const addTagToPerson = async (personId: string, tagName: string) => {
    if (!tagName.trim()) {
      console.error('Tag name cannot be empty');
      return;
    }

    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.ADD_CONTACT_TAG,
        headerType: 'USER-AUTH',
        values: {
          tagName: tagName,
        },
      });
      const newTagId = response._id;
      setPeople((prevPeople) =>
        prevPeople.map((person) => {
          if (person._id === personId) {
            const tagExists = person.tags.some((tag) => tag.name === tagName);
            if (!tagExists) {
              const updatedTags = [
                ...person.tags,
                { name: tagName, _id: newTagId },
              ];
              return { ...person, tags: updatedTags };
            }
          }
          return person;
        })
      );
      const updatedTags =
        people
          .find((person) => person._id === personId)
          ?.tags.map((tag) => tag._id) || [];
      updatedTags.push(newTagId);
      await updateContactTags(personId, updatedTags);
    } catch (error) {
      console.error('Error adding tag to contact:', error);
    }
  };

  const updateContactTags = async (
    id: string | undefined,
    tagsId: string[]
  ) => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: QUERIES.UPDATE_CONTACT,
        values: {
          id: id,
          tagsId: tagsId,
        },
        headerType: 'USER-AUTH',
      });
      return response;
    } catch (error) {
      console.error('Error updating contact tags:', error);
      throw error;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const removeTag = async () => {
    try {
      const updatedPeople = people.map((person) => {
        if (selectedCheckboxes.includes(person._id)) {
          const updatedTags = person.tags.filter(
            (tag) => !tag.name.toLowerCase().includes(tagInput.toLowerCase())
          );
          const updatedTagsIds = updatedTags.map((tag) => tag._id);
          updateContactTags(person._id, updatedTagsIds).catch((err) => {
            console.error('Error updating tagsId:', err);
          });

          return { ...person, tags: updatedTags };
        }
        return person;
      });

      setPeople(updatedPeople);
    } catch (error) {
      console.error('Error removing tag:', error);
    } finally {
      setTagInput('');
    }
  };

  const deleteTag = async (_id: string, tagIndex: number) => {
    try {
      const personToUpdate = people.find((person) => person._id === _id);
      if (!personToUpdate) return;

      const updatedTags = personToUpdate.tags.filter(
        (_, index) => index !== tagIndex
      );
      await graphQlCall({
        queryTemplateObject: QUERIES.UPDATE_CONTACT,
        values: {
          id: _id.toString(),
          tags: updatedTags,
        },
        headerType: 'USER-AUTH',
      });
      const updatedTagsIds = updatedTags.map((tag) => tag._id);
      await updateContactTags(_id.toString(), updatedTagsIds);

      setPeople((prevPeople) =>
        prevPeople.map((person) =>
          person._id === _id ? { ...person, tags: updatedTags } : person
        )
      );
    } catch (error) {
      console.error('Error deleting tag:', error);
    }
  };

  const handleClearText = () => {
    setSearchText('');
  };

  const handleSmartContacts = () => {
    setSmart(true);
  };
  const handleAddTag = () => {
    setTagAdd(true);
  };

  useEffect(() => {
    const applyFilters = () => {
      let filteredContacts = [...people];
      if (searchText.trim() !== '') {
        filteredContacts = filteredContacts.filter((person) =>
          Object.keys(person).some((key) => {
            if (key === 'tags') {
              return person.tags.some((tag) =>
                tag.name.toLowerCase().includes(searchText.toLowerCase())
              );
            } else if (key === 'data') {
              return Object.values(person.data).some(
                (value) =>
                  typeof value === 'string' &&
                  value.toLowerCase().includes(searchText.toLowerCase())
              );
            } else if (typeof person[key as keyof Person] === 'string') {
              return person[key as keyof Person]
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }
            return false;
          })
        );
      }

      setFilteredPeople(filteredContacts);
    };

    applyFilters();
  }, [selectedSmartList, people, smartLists, searchText]);

  const sortUsers = (key: string, sortAsc: boolean) => {
    setContactSortItem({ sortBy: key, sortAsc });
  };

  const exportSelectedUsersAsCSV = () => {
    if (selectedCheckboxes.length === 0) {
      alert('Please select at least one user to export.');
      return;
    }

    const selectedUsers = people.filter((person) =>
      selectedCheckboxes.includes(person._id)
    );
    const csvHeader = [
      'Full Name',
      'Email',
      'Phone',
      'Country',
      'Address',
      'Created',
      'Tags',
    ];
    const csvRows = selectedUsers.map((user) => [
      user.data?.name,
      user.email,
      user.phone,
      user.data?.country,
      user.data?.addr,
      user.createdAt,
      user.tags?.map((tag) => tag.name).join(', '),
    ]);

    const csvContent = [csvHeader, ...csvRows]
      .map((row) => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'selected_users.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleSwichToSmart = () => {
    setSmartSwitch(!smartSwitch);
    fetchUniqueTags();
  };

  const filteredTags = Array.isArray(tags)
    ? tags.filter((tag) => {
        const nameMatch = tag.tag.name.toLowerCase();

        return nameMatch;
      })
    : [];

  useEffect(() => {
    const pages = Math.ceil(
      (!smartSwitch ? totalContacts : tagsCount) / pageSize
    );
    setTotalPages(!!pages ? pages : 1);
  }, [smartSwitch, totalContacts, tagsCount, pageSize]);

  const updateSmartList = (id: string, newName: string) => {
    setSmartLists((prevSmartLists) => {
      const targetSmartList = prevSmartLists.find(
        (smartList) => smartList._id === id
      );

      if (!targetSmartList) return prevSmartLists;

      targetSmartList.name = newName;

      return [...prevSmartLists];
    });
  };

  const createSmartListButton = useMemo(
    () => (
      <div className={s.createButton} onClick={(e) => e.stopPropagation()}>
        <Button
          className={s.button}
          appearance="solid"
          postfixIcon={<PlusIcon />}
          height={40}
          onClick={() => {
            setIsSmartListInLeft(true);
            setCloseSmartListDropdown(true);
            handleSmartContacts();
          }}
        >
          Create Smart List
        </Button>
      </div>
    ),
    [setIsSmartListInLeft, handleSmartContacts]
  );

  const menu = (
    <Menu>
      <Menu.Item className={s.menuItem} key="1" onClick={handleOpenPopup}>
        <PlusIcon /> Add Contact
      </Menu.Item>
      <Menu.Item
        className={s.menuItem}
        key="4"
        onClick={() => setIsImportPopupOpen(true)}
      >
        <ImportIcon /> Import Contacts
      </Menu.Item>
      <Menu.Item className={s.menuItem} key="2" onClick={handleSmartContacts}>
        <PlusIcon /> Create Smart List
      </Menu.Item>
      <Menu.Item className={s.menuItem} key="2" onClick={handleSwichToSmart}>
        <TagIcon /> Setup Tags
      </Menu.Item>
      <Menu.Item
        className={s.menuItem}
        key="3"
        onClick={exportSelectedUsersAsCSV}
      >
        <BackIcon className={s.back} /> Download CSV
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className={s.cover}>
        <SideMenu />
        <div className={s.container}>
          {loading ? (
            <div className={s.loader}>
              <ContactsSkeleton />
            </div>
          ) : (
            <>
              <ConfirmArchivationPopup
                open={openDeleteSmartListPopup}
                onClose={() => removeSmartListPopup(false)}
                onDonePressed={removeSmartList}
                description="Once you delete this smart list, the saved view will be permanently removed. Your contacts and data will remain intact. Are you sure you want to continue?"
              />
              <div className={s.contentContainer} ref={contentContainerRef}>
                <div className={s.head}>
                  <div className={s.headLeft}>
                    {!smartSwitch ? (
                      <>
                        <CustomDropdown
                          footer={createSmartListButton}
                          options={[
                            {
                              label: (
                                <div className={s.option}>All Contacts</div>
                              ),
                              value: null,
                            },
                            ...smartLists.map((e) => ({
                              label: (
                                <SmartListItem
                                  smartList={e}
                                  setSmartList={setSmartList}
                                  removeSmartListPopup={(value) => {
                                    setCloseSmartListDropdown(true);
                                    removeSmartListPopup(value);
                                  }}
                                  updateSmartList={updateSmartList}
                                  key={e._id + e.name}
                                />
                              ),
                              value: e._id,
                            })),
                          ]}
                          onChange={setSelectedSmartList}
                          label={<div className={s.title}>All Contacts</div>}
                          className={s.dropdown}
                          value={selectedSmartList}
                          optionsClassName={s.options}
                          close={closeSmartListDropdown}
                          setClose={setCloseSmartListDropdown}
                        />
                      </>
                    ) : (
                      <div className={s.tagsSwich}>
                        <p onClick={handleSwichToSmart}>
                          <BackIcon fill="#a0a0a0" />
                          Back to Contacts
                        </p>
                        <div className={s.titleTags}>Tags</div>
                      </div>
                    )}
                  </div>
                  <div className={s.headRight}>
                    {importingContacts && (
                      <div className={s.loadingContainer}>
                        Contacts Uploading{' '}
                        <CircleLoader
                          size={16}
                          color="#D0D0D0"
                          className={s.circleLoader}
                        />
                        <Close
                          className={s.close}
                          onClick={cancelImportingContacts}
                        />
                      </div>
                    )}
                    {!smartSwitch ? (
                      //  <Button
                      //   width={170}
                      //   height={40}
                      //   appearance="highlighted"
                      //   onClick={() => {
                      //     setIsSmartListInLeft(false);
                      //     handleSmartContacts();
                      //   }}
                      //   postfixIcon={<PlusIcon />}
                      // >
                      //   Smart List
                      // </Button>
                      <AntdDropdown
                        overlay={menu}
                        className={s.antdDrop}
                        trigger={['click']}
                      >
                        <Button
                          appearance="stroke"
                          width={40}
                          height={40}
                          prefixIcon={<ThreeDotsIcon />}
                        />
                      </AntdDropdown>
                    ) : (
                      <Button
                        height={40}
                        appearance="highlighted"
                        onClick={handleAddTag}
                        postfixIcon={<PlusIcon />}
                      >
                        Add Tag
                      </Button>
                    )}
                    <SmartListPopup
                      smart={smart}
                      setSmart={setSmart}
                      onAddNewSmartList={(newValue) => {
                        setSmartLists([...smartLists, newValue]);
                        setSelectedSmartList(newValue._id);
                        setSearchText('');
                      }}
                      uniqueTags={uniqueTags}
                      fetchSmartLists={fetchSmartLists}
                      className={clsx({ [s.leftSmartList]: isSmartListInLeft })}
                      contentWidth={contentWidth}
                    />
                    {tagAdd && (
                      <TagItemPopup
                        setTagAdd={setTagAdd}
                        fetchTagCount={fetchTagCount}
                      />
                    )}
                  </div>
                  <AddContactsPopup
                    isPopupOpen={isPopupOpen}
                    setPopupOpen={setPopupOpen}
                    funnelId={funnelId}
                    pageId={pageId}
                    uniqueTags={uniqueTags}
                    fetchUserContacts={fetchUserContacts}
                    handleAddNewTag={handleAddNewTag}
                  />
                  <ImportContacts
                    open={isImportPopupOpen}
                    onClose={() => setIsImportPopupOpen(false)}
                    tags={uniqueTags}
                    smartLists={smartLists}
                    handleAddNewTag={handleAddNewTag}
                    fetchUserContacts={fetchUserContacts}
                  />
                </div>
                <Header
                  filteredTags={filteredTags}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  totalCount={!smartSwitch ? totalContacts : tagsCount}
                  smartSwitch={smartSwitch}
                  currentPage={currentPage}
                  dropdownOptions={dropdownOptions}
                  filteredPeople={filteredPeople}
                  handleClearText={handleClearText}
                  handleDropdownChange={handleDropdownChange}
                  handlePageChange={handlePageChange}
                  pageSize={pageSize}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  smartLists={smartLists}
                  totalPages={totalPages}
                />
                <div>
                  <div className={scroll.scrollbar}>
                    <div className={s.table}>
                      {!smartSwitch ? (
                        <>
                          <TableHeader
                            selectedCheckboxes={selectedCheckboxes}
                            sortUsers={sortUsers}
                            exportSelectedUsersAsCSV={exportSelectedUsersAsCSV}
                            deletePersons={deletePersons}
                            removeTag={removeTag}
                            setSelectedCheckboxes={setSelectedCheckboxes}
                            visiblePeople={people}
                            uniqueTags={uniqueTags}
                            tagInput={tagInput}
                            setTagInput={setTagInput}
                            fetchUniqueTags={fetchUniqueTags}
                            addTagToPerson={addTagToPerson}
                            sortKey={contactSortItem.sortBy}
                            isAscending={contactSortItem.sortAsc}
                          />
                          {filteredPeople.map((person) => (
                            <ContactItem
                              key={person._id}
                              _id={person._id}
                              updateContactTags={updateContactTags}
                              person={person}
                              selectedCheckboxes={selectedCheckboxes}
                              deleteTag={deleteTag}
                              tagInput={tagInput}
                              setTagInput={setTagInput}
                              modalRef={modalRef}
                              people={people}
                              setPeople={setPeople}
                              field={person.data.name}
                              selectAll={selectAll}
                              setSelectAll={setSelectAll}
                              setSelectedCheckboxes={setSelectedCheckboxes}
                              value={tagInput}
                              visiblePeople={people}
                              fetchUniqueTags={fetchUniqueTags}
                              uniqueTags={uniqueTags}
                              addTagToPerson={addTagToPerson}
                              smartSwitch={smartSwitch}
                              handleSwichToSmart={handleSwichToSmart}
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          <TableHeaderTag />
                          {filteredTags.map((tag) => (
                            <TagItem
                              key={tag._id}
                              fetchTagCount={fetchTagCount}
                              tagName={tag.tag.name}
                              tagCount={tag.count}
                              tagCreatedAt={tag.tag.createdAt}
                              tagList={tag}
                              setSmartSwitch={setSmartSwitch}
                            />
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <Footer
                  currentPage={currentPage}
                  dropdownOptions={dropdownOptions}
                  handleDropdownChange={handleDropdownChange}
                  handlePageChange={handlePageChange}
                  pageSize={pageSize}
                  totalPages={totalPages}
                />
                <div className={s.bottomSpace} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedSmartListId: state.contact.selectedSmartList,
  openDeleteSmartListPopup: state.contact.openDeleteSmartListPopup,
  importingContacts: state.contact.importingContacts,
});

const mapDispatchToProps = {
  setSmartList: (payload: string) => setSelectedSmartList(payload),
  removeSmartListPopup: (payload: boolean) => removeSmartListPopup(payload),
  cancelImportingContacts: () => cancelImportingContacts(),
};

export default connect(mapStateToProps, mapDispatchToProps)(PageContacts);
