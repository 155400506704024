import { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { ReactComponent as PersonIcon } from 'Assets/icons/profile.svg';
import { ReactComponent as ArrowDown } from 'Assets/icons/ArrowDown.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from 'Assets/icons/Mobile.svg';
import { ReactComponent as WorldIcon } from 'Assets/icons/world.svg';
import { ReactComponent as LocationIcon } from 'Assets/icons/Location.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendarContact.svg';
import { ReactComponent as TagIcon } from 'Assets/icons/Tag.svg';
import { ReactComponent as BackIcon } from 'Assets/icons/arrowL.svg';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash.svg';
import { ReactComponent as TagAddIcon } from 'Assets/icons/tagAdd.svg';
import { ReactComponent as TagRemoveIcon } from 'Assets/icons/tagRemove.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/PlusContacts.svg';
import { Person, Tag } from 'Pages/PageContacts/Helper/types';
import clsx from 'clsx';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import Checkbox from 'UILib/CheckBox/Checkbox';

import s from 'Pages/PageContacts/TableHeader/TableHeader.module.scss';

interface TopTableProps {
  selectedCheckboxes: number[];
  sortUsers: (key: string, sortAsc: boolean) => void;
  exportSelectedUsersAsCSV: () => void;
  removeTag: () => void;
  deletePersons: () => Promise<void>;
  setSelectedCheckboxes: (ids: number[]) => void;
  visiblePeople: Person[];
  uniqueTags: Tag[];
  tagInput: string;
  setTagInput: (input: string) => void;
  fetchUniqueTags: () => void;
  addTagToPerson: (id: any, tag: string) => void;
  sortKey: string | undefined;
  isAscending: boolean;
}

const tableItems = [
  {
    icon: <PersonIcon fill="#a0a0a0" />,
    name: 'Full Name',
    key: 'data.name',
    class: 'nameImg',
    sortable: true,
  },
  {
    icon: <MailIcon />,
    name: 'Email',
    key: 'email',
    class: 'emailTable',
    sortable: false,
  },
  {
    icon: <PhoneIcon />,
    name: 'Phone',
    key: 'phone',
    class: 'phoneTable',
    sortable: false,
  },
  {
    icon: <WorldIcon />,
    name: 'Country',
    key: 'data.country',
    class: 'phoneTable',
    sortable: false,
  },
  {
    icon: <LocationIcon />,
    name: 'Address',
    key: 'data.addr',
    class: 'addressTable',
    sortable: false,
  },
  {
    icon: <CalendarIcon />,
    name: 'Created',
    key: 'createdAt',
    class: 'phoneTable',
    sortable: true,
  },
  {
    icon: <WorldIcon />,
    name: 'Funnel',
    key: 'funnel.name',
    class: 'phoneTable',
    sortable: true,
  },
  {
    icon: <TagIcon />,
    name: 'Tags',
    key: 'tags',
    class: 'tags',
    sortable: false,
  },
];

const TableHeader = (props: TopTableProps) => {
  const [selectAll, setSelectAll] = useState(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState<boolean>(false);

  const handleAddTagToSelected = (tag: {
    _id: string;
    name: string;
    createdAt: string;
  }) => {
    props.selectedCheckboxes.forEach((id) => {
      props.addTagToPerson(id, tag.name);
    });
    props.setTagInput('');
    setDropdownVisible(false);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      props.setSelectedCheckboxes([]);
    } else {
      const visibleIds = props.visiblePeople.map((person: any) => person._id);
      props.setSelectedCheckboxes(visibleIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectAllCheckboxChange = () => {
    setSelectAll(!selectAll);
    handleSelectAllChange();
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleDropdownClick = () => {
    props.fetchUniqueTags();
    setDropdownVisible(!dropdownVisible);
  };

  const menu = (
    <>
      <Menu className={s.tagMenu}>
        {props.uniqueTags.map(
          (tag: { _id: string; name: string; createdAt: string }) => (
            <Menu.Item
              key={tag._id}
              className={s.tagDisplay}
              onClick={() => handleAddTagToSelected(tag)}
            >
              {tag.name}
            </Menu.Item>
          )
        )}
      </Menu>
      <Menu className={s.secondMenu}>
        <Menu.Divider />
        <Menu.Item key="2">
          <div className={s.popupInput}>
            <div
              style={{
                border: `1px solid ${isFocused ? '#6674F4' : '#A0A0A0'}`,
              }}
            >
              <input
                placeholder="Add New Tag"
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={props.tagInput}
                onChange={(e) => props.setTagInput(e.target.value)}
              />
              <button
                className={s.inputButton}
                onClick={() =>
                  handleAddTagToSelected({
                    _id: '',
                    name: props.tagInput,
                    createdAt: '',
                  })
                }
              >
                <PlusIcon />
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <div className={s.personTable}>
      <Checkbox
        checked={selectAll || props.selectedCheckboxes.length > 0}
        onChange={handleSelectAllCheckboxChange}
        removeAll={props.selectedCheckboxes.length > 0}
      />
      <ArrowDown className={s.smallArrow} />
      {!props.selectedCheckboxes.length ? (
        <>
          {tableItems.map((item) => {
            return (
              <div
                className={s?.[item.class]}
                onClick={() => {
                  if (item.sortable) {
                    props.sortUsers(item.key, !props.isAscending);
                  }
                }}
                key={item.key}
              >
                {item.icon}
                {item.name}
                {item.sortable && (
                  <div className={s.points}>
                    <ArrowDown
                      className={clsx(s.rotated, s.arrow, {
                        [s.activeArrow]:
                          props.isAscending && props.sortKey === item.key,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.sortUsers(item.key, true);
                      }}
                    />
                    <ArrowDown
                      className={clsx(s.arrow, {
                        [s.activeArrow]:
                          !props.isAscending && props.sortKey === item.key,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.sortUsers(item.key, false);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div className={s.select}>
            Selected: {props.selectedCheckboxes.length}
          </div>
          <Dropdown
            overlay={menu}
            trigger={['click']}
            visible={dropdownVisible}
            onVisibleChange={(visible) => setDropdownVisible(visible)}
            className={s.dropdown}
          >
            <div className={s.tagBtn} onClick={handleDropdownClick}>
              Add Tag
              <TagAddIcon />
            </div>
          </Dropdown>
          <div className={s.tagBtn} onClick={props.removeTag}>
            Remove All Tags
            <TagRemoveIcon />
          </div>
          <div className={s.tagBtn} onClick={props.exportSelectedUsersAsCSV}>
            Export CSV
            <BackIcon className={s.back} />
          </div>
          <div className={s.tagBtn} onClick={() => setIsDeletePopupOpen(true)}>
            Delete
            <TrashIcon />
          </div>
        </>
      )}
      <ConfirmArchivationPopup
        description="Are you sure you want to delete these contacts"
        onClose={() => setIsDeletePopupOpen(false)}
        onDonePressed={() => {
          props.deletePersons();
          setIsDeletePopupOpen(false);
        }}
        open={isDeletePopupOpen}
      />
    </div>
  );
};

export default TableHeader;
