import { TableRows } from '../ImportPopups';
import ContactItem from './ContactItem/ContactItem';

import styles from './ContactsColumns.module.scss';

interface IProps {
  data: TableRows[];
  previewData: { [key: string]: string };
  onChange: (index: number, key: string, value: string | boolean) => void;
}

const ContactsColumns = ({ data, previewData, onChange }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <div className={styles.tableRow}>
          <div className={styles.title}>Column Header from File</div>
          <div className={styles.title}>Preview</div>
          <div className={styles.title}>Contact Field</div>
        </div>
        {data.map((item, index) => (
          <div className={styles.tableRow} key={item.csvColumn}>
            <ContactItem
              csvColumn={item.csvColumn}
              contactField={item.contactField}
              preview={previewData?.[item.csvColumn]}
              onChange={(key, value) => onChange(index, key, value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsColumns;
