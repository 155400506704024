import { motion, AnimatePresence } from 'framer-motion';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { Button } from 'RadixUI/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'RadixUI/tooltip';

const MoveButtons = ({
  isSelected,
  index,
  totalElements,
  onMove,
}: {
  isSelected: boolean;
  index: number;
  totalElements: number;
  onMove: (direction: 'up' | 'down') => void;
}) => (
  <AnimatePresence>
    {isSelected && (
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        className="absolute -top-1 -left-12 z-50 flex flex-col gap-1"
      >
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon"
                className="h-8 w-8 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-sm hover:bg-background"
                onClick={(e) => {
                  e.stopPropagation();
                  onMove('up');
                }}
                disabled={index === 0}
              >
                <ChevronUp className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">Move up</TooltipContent>
          </Tooltip>
        </TooltipProvider>

        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="secondary"
                size="icon"
                className="h-8 w-8 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 shadow-sm hover:bg-background"
                onClick={(e) => {
                  e.stopPropagation();
                  onMove('down');
                }}
                disabled={index === totalElements - 1}
              >
                <ChevronDown className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">Move down</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </motion.div>
    )}
  </AnimatePresence>
);

export default MoveButtons;
