import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RootState } from 'store/rootStore';
import { connect } from 'react-redux';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import { STRIPE_KEY } from 'GlobalConstants';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import QUERIES from 'graphql/queries';
import { graphQlCall } from 'graphql/utils';
import { getUserName } from 'utils/Utils';
import s from './PageUpdatePaymentForm.module.scss';
import clsx from 'clsx';
import closeSvg from '../Assets/icons/close2.svg';
import InputV4 from 'Components/Common/Inputs/InputV4/InputV4';
import { ReactComponent as ProfileSvg } from '../Assets/icons/profile.svg';
import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';
import { ReactComponent as DoneSvg } from '../Assets/icons/doneIcon.svg';
import { IUserDetails } from 'types';
import { fetchUserDetails } from 'store/user/userActions';

const stripePromise = loadStripe(STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      borderRadius: '4px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface IProps {
  name: string;
  secondName: string;
  setSuccess: (success: boolean) => void;
  success: boolean;
  handlePurchase: () => void;
}

const CardForm = (props: IProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  const handlePaymentSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrorMessage('');

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const data = await graphQlCall({
      queryTemplateObject: QUERIES.AF_CREATE_SETUP_INTENT_PAYMENT_UPDATE,
      values: {
        owner: getUserName(),
      },
    });

    const result = await stripe.confirmCardSetup(data.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement)!,
        billing_details: {
          name: `${props.name} ${props.secondName}`,
        },
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message || 'An error occurred');
      setLoading(false);
    } else {
      // console.log('result:', result);
      const data = await graphQlCall({
        queryTemplateObject: QUERIES.SET_USER_PAYMENT_METHOD,
        values: {
          owner: getUserName(),
          paymentMethodId: result.setupIntent.payment_method,
        },
      });
      // console.log('data:', data);
      props.setSuccess(true);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handlePaymentSubmit}>
      <span className={s.blockTitle}>Credit Card and ZIP</span>
      <div className={s.inputContainer}>
        <div
          className={
            !errorMessage.length ? s.stripeContainer : s.stripeContainerError
          }
        >
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>

        <p className={s.error}>{errorMessage}</p>
        <div className={s.submitButton}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <button
              className={s.button}
              type="submit"
              onClick={() => props.handlePurchase()}
            >
              Add Card
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

interface IFormProps {
  userDetails: IUserDetails;
  fetchUserDetails: () => void;
}

const PageUpdatePaymentForm = (props: IFormProps) => {
  const [show, setShow] = useState(true);
  const [name, setName] = useState('');
  const [secondName, setSecondName] = useState('');
  const [success, setSuccess] = useState(false);
  const [nameError, setNameError] = useState('');
  const [secondNameError, setSecondNameError] = useState('');

  const modalRoot = document.getElementById('modal');

  useEffect(() => {
    props.fetchUserDetails();
  }, []);

  useEffect(() => {
    console.log(props.userDetails);
    if (props.userDetails.name) {
      const nameComponents = props.userDetails.name.split(' ');
      if (nameComponents.length >= 2) {
        setName(nameComponents[0]);
        setSecondName(nameComponents[1]);
      }
    }
  }, [props.userDetails]);

  const handleClose = () => {
    // setShow(false);
    window.parent.postMessage({ status: 'close' });
  };

  const handlePurchase = () => {
    setNameError('');
    setSecondNameError('');
    if (!name.trim()) {
      setNameError('Please enter your First Name');
    }
    if (!secondName.trim()) {
      setSecondNameError('Please enter your Last Name');
    } else return;
  };

  return show && modalRoot
    ? ReactDOM.createPortal(
        <div className={s.screenContainer}>
          <div className={s.modalContainer}>
            {!success ? (
              <div>
                <div className={s.close} onClick={handleClose}>
                  <img src={closeSvg} alt="Close" />
                </div>
                <div>
                  <h1 className={s.title}>Payment method</h1>
                  <div className={s.subTitle}>Billing Detail</div>
                  <div className={s.nameBlock}>
                    <div className={s.userInfo}>
                      <div className={s.fieldName}>
                        <span className={s.blockTitle}>First Name</span>
                        <InputV4
                          width={275}
                          value={name}
                          placeholder="First Name"
                          name="firstName"
                          type="text"
                          icon={<ProfileSvg fill="#a0a0a0" />}
                          border="full"
                          onChange={(e) => setName(e.target.value)}
                          error={nameError}
                        />
                      </div>
                      <div className={s.fieldSecondName}>
                        <span className={s.blockTitle}>Second Name</span>
                        <InputV4
                          width={275}
                          value={secondName}
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                          icon={<ProfileSvg fill="#a0a0a0" />}
                          border="full"
                          onChange={(e) => setSecondName(e.target.value)}
                          error={secondNameError}
                        />
                      </div>
                    </div>
                  </div>
                  <Elements stripe={stripePromise}>
                    <CardForm
                      name={name}
                      secondName={secondName}
                      setSuccess={handleClose}
                      success={success}
                      handlePurchase={handlePurchase}
                    />
                  </Elements>
                </div>
              </div>
            ) : (
              <div className={s.congratulationBlock}>
                <DoneSvg fill="#25BB73" />
                <h3>Congratulation!</h3>
                <div className={s.successDescription}>
                  {/* <span>Your order is complete!</span> */}
                  <span>We've successfully processed your request!</span>
                </div>
                <div className={s.gratitude}>Thank you</div>
                <button
                  className={clsx(s.button, s.successButton)}
                  onClick={() => handleClose()}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>,
        modalRoot
      )
    : null;
};
const mapStateToProps = (state: RootState) => ({
  userDetails: state.user.details,
});

const mapDispatchToProps = {
  fetchUserDetails: () => fetchUserDetails(),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageUpdatePaymentForm);
