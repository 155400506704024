export const CREATE_AUTO_RESPONDER_WITH_SEQUENCE_MUTATION = {
  operationName: 'createAutoresponderWithSequence',
  query: `mutation createAutoresponderWithSequence (
        <KEYS>
        ) {
            createAutoresponderWithSequence(<VALUES>) {
                _id
                name
                description
                entryTag {
                _id
                }
                exitTag {
                _id
                }
               createdAt
               updatedAt
               thumbnailUrl
               workflow {
               _id
               }
            }
          }`,
};
