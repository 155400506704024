import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { BookleTemplateBlock, IUserProduct } from 'types';
import { RootState } from '../rootReducer';
import {
  IBookleTemplateBlockStyles,
  IBookleTemplateEditor,
} from './booksReducer';
import _ from 'lodash';

export const UPDATE_SETTINGS_POPUP = 'UPDATE_POPUP';
export const UPDATE_BOOKLE_TEMPLATE_BLOCKS = 'UPDATE_BOOKLE_TEMPLATE_BLOCKS';
export const UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK =
  'UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK';
export const UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR =
  'UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR';
export const UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES =
  'UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES';
export const UPDATE_ACTIVE_BOOKLE_TEMPLATE = 'UPDATE_ACTIVE_BOOKLE_TEMPLATE';
export const UPDATE_BLOCKS_HISTORY = 'UPDATE_BLOCKS_HISTORY';

type DispatchType = ThunkDispatch<RootState, void, Action>;

export const bookSettingsPopupAction = (payload: {
  open?: boolean;
  initialState?: IUserProduct;
}) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_SETTINGS_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateBlocks = (
  payload: BookleTemplateBlock[],
  updateHistory = true
) => async (dispatch: DispatchType, getSate: () => RootState) => {
  const blocksHistory = structuredClone(getSate().books.blocksHistory);
  let blockIndex = blocksHistory.activeIndex;
  let history = [];
  const isEqual = _.isEqual(payload, blocksHistory.blocks[blockIndex]);
  if (!isEqual && updateHistory && !payload.some((e) => e.generating)) {
    if (
      blocksHistory.blocks?.length > 0 &&
      blockIndex < blocksHistory.blocks?.length - 1
    ) {
      history = [
        { ...blocksHistory }.blocks.slice(0, blockIndex + 1),
        structuredClone(payload),
      ];
    } else {
      history = [...blocksHistory.blocks, structuredClone(payload)];
    }

    if (history.length > 200) {
      history = history.slice(history.length - 200);
    }

    if (!!history.length) {
      dispatch({
        type: UPDATE_BLOCKS_HISTORY,
        payload: {
          activeIndex: history.length - 1,
          blocks: history,
        },
      });
    }
  }

  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_BLOCKS,
    payload,
  };
  dispatch(stateAction);
};

export const undoBlocks = () => async (
  dispatch: DispatchType,
  getState: () => RootState
) => {
  const blocksHistory = { ...getState().books.blocksHistory };

  if (blocksHistory.activeIndex > 0) {
    const newActiveIndex = blocksHistory.activeIndex - 1;

    const newBlocks = [...blocksHistory.blocks[newActiveIndex]];

    dispatch({
      type: UPDATE_BLOCKS_HISTORY,
      payload: {
        ...blocksHistory,
        activeIndex: newActiveIndex,
      },
    });

    dispatch({
      type: UPDATE_BOOKLE_TEMPLATE_BLOCKS,
      payload: newBlocks,
    });
  }
};

export const redoBlocks = () => async (
  dispatch: DispatchType,
  getSate: () => RootState
) => {
  const blocksHistory = { ...getSate().books.blocksHistory };

  if (blocksHistory.activeIndex < blocksHistory.blocks.length - 1) {
    const newActiveIndex = blocksHistory.activeIndex + 1;

    dispatch({
      type: UPDATE_BLOCKS_HISTORY,
      payload: {
        ...blocksHistory,
        activeIndex: newActiveIndex,
      },
    });

    const newBlocks = structuredClone(blocksHistory.blocks[newActiveIndex]);

    dispatch({
      type: UPDATE_BOOKLE_TEMPLATE_BLOCKS,
      payload: newBlocks,
    });
  }
};

export const clearBlocksHistory = () => async (dispatch: DispatchType) => {
  dispatch({
    type: UPDATE_BLOCKS_HISTORY,
    payload: {
      blocks: [],
      activeIndex: 0,
    },
  });
};

export const updateBookleTemplateHoveredBlock = (
  payload: BookleTemplateBlock | undefined
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_HOVERED_BLOCK,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateTextEditor = (
  payload: IBookleTemplateEditor
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_TEXT_EDITOR,
    payload,
  };
  dispatch(stateAction);
};

export const updateBookleTemplateBlockStyles = (
  payload: IBookleTemplateBlockStyles
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_BOOKLE_TEMPLATE_BLOCK_STYLES,
    payload,
  };
  dispatch(stateAction);
};

export const updateActiveTemplate = (
  payload: BookleTemplateBlock | null
) => async (dispatch: DispatchType) => {
  const stateAction = {
    type: UPDATE_ACTIVE_BOOKLE_TEMPLATE,
    payload,
  };
  dispatch(stateAction);
};
