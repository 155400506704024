export const GET_ONE_BROADCAST = {
  operationName: 'getOneBroadcast',
  query: `query getOneBroadcast ($id: String!){
            getOneBroadcast(id: $id) {
              _id
              name
              smartList { _id }
              sender { _id }
              mailDraft { 
                _id
                senderName
              }
              image
              sendAt
              status
              createdAt
              updatedAt
              tags {
              _id,
              name
              }
            }
          }`,
};
