import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_ANALYTICS_API_URL } from 'GlobalConstants';
import {
  AnalyticsGraphResponse,
  AnalyticsTableResponse,
  AnalyticsTableRequestBody,
  AnalyticsTotalStatsResponse,
  AnalyticsTotalStatsRequestBody,
  AnalyticsGraphStatsRequestBody,
  FunnelsAnalyticsTableRequestBody,
} from './types';

class AnalyticsService {
  private static baseUrl: string;

  constructor() {
    if (!AnalyticsService.baseUrl)
      AnalyticsService.baseUrl = PAGECRAFT_ANALYTICS_API_URL;
  }

  private static getHeaders() {
    return {
      auth: 'b ' + getToken()?.split(' ')[1],
    };
  }

  getTableStats(payload: AnalyticsTableRequestBody) {
    if (!AnalyticsService.baseUrl)
      return new Promise<never[]>((resolve) => resolve([]));

    const headers = AnalyticsService.getHeaders();

    return api<AnalyticsTableResponse>(
      AnalyticsService.baseUrl + '/get_stat_table',
      'POST',
      payload,
      headers
    );
  }

  getTotalStats(payload: AnalyticsTotalStatsRequestBody) {
    if (!AnalyticsService.baseUrl)
      return new Promise<undefined>((resolve) => resolve(undefined));

    const headers = AnalyticsService.getHeaders();

    return api<AnalyticsTotalStatsResponse>(
      AnalyticsService.baseUrl + '/get_total_stat',
      'POST',
      payload,
      headers
    );
  }

  getFunnelsTotalStats(payload: FunnelsAnalyticsTableRequestBody) {
    if (!AnalyticsService.baseUrl)
      return new Promise<undefined>((resolve) => resolve(undefined));

    const headers = AnalyticsService.getHeaders();

    return api<AnalyticsTotalStatsResponse>(
      AnalyticsService.baseUrl + '/get_funnels_statistic',
      'POST',
      payload,
      headers
    );
  }

  getGraphStats(payload: AnalyticsGraphStatsRequestBody) {
    if (!AnalyticsService.baseUrl)
      return new Promise<undefined>((resolve) => resolve(undefined));

    const headers = AnalyticsService.getHeaders();

    return api<AnalyticsGraphResponse>(
      AnalyticsService.baseUrl + '/get_graph_data',
      'POST',
      payload,
      headers
    );
  }
}

export default AnalyticsService;
