import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchedAutoSassDesign,
  setAutoSaasLogoUrl,
} from 'store/autosaas/autosaasActions';
import { IAutoSassDesign } from 'store/autosaas/autosaasReducer';
import { DispatchType, RootState } from 'store/rootReducer';
import { ReactComponent as CustomersIcon } from 'Assets/icons/episodes.svg';
import { ReactComponent as Link } from 'Assets/icons/chain.svg';
import { graphQlCall } from 'graphql/utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { transformAutoSaasData } from 'utils/helpers';
import { getToken } from 'utils/Utils';
import { api } from 'utils/Utils';
import {
  advancedSchemeDefaultData,
  ColorScheme,
} from 'Components/AutoSaas/constants';
import Tabs from 'Components/Templates/Tabs/Tabs';
import SideMenu from 'Components/SideMenu/SideMenu';
import CustomersTable from 'Components/AutoSaas/CustomersTable/CustomersTable';
import SettingsTab from 'Components/AutoSaas/Settings/Settings';
import ProductsTab from 'Components/AutoSaas/Products/Products';
import Logo from 'UILib/Logo/Logo';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import queries from 'graphql/queries';

import styles from './AutoSaas.module.scss';

interface IProps {
  autosaasLogo?: string;
  setAutoSaasLogoUrl: (url: string) => void;
  updateAutoSassDesign: (payload: IAutoSassDesign) => void;
}

const AutoSaas = (props: IProps) => {
  const [customersCount, setCustomersCount] = useState<number>(0);
  const [sassDesign, setSassDesign] = useState<ColorScheme>();
  const [colors, setColors] = useState<ColorScheme>(
    transformAutoSaasData(advancedSchemeDefaultData, 'default')
  );
  const [selectedOption, setSelectedOption] = useState<string>('default');

  const handleUpdateAutoSaasLogo = async (image: File) => {
    const request = new FormData();
    request.append('file', image);
    const headers = {
      authorization: getToken(),
    };
    const response = await api(
      `${PAGECRAFT_API_URL}/saas/upload-saas-logo`,
      'POST',
      request,
      headers
    );
    setTimeout(() => {
      props.setAutoSaasLogoUrl(response.url + '?' + new Date().getTime());
    }, 2000);
  };

  useEffect(() => {
    fetchAutoSassDesign();
  }, []);

  const fetchAutoSassDesign = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: queries.GET_MY_SASS_DESIGN,
        headerType: 'USER-AUTH',
      });
      setSelectedOption(response.colorScheme);
      const fetchedColors = {
        accent: response.accent,
        alerts: response.alerts,
        sideBar: response.sideBar,
      };
      setColors(fetchedColors);
      setSassDesign(response);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const handleUpdateAutoSassName = async () => {
    await graphQlCall({
      queryTemplateObject: queries.UPDATE_SAAS_DESIGN,
      values: {
        name: sassDesign?.name,
      },
      headerType: 'USER-AUTH',
    });
  };

  return (
    <main className={styles.mainContent}>
      <SideMenu />
      <section className={styles.pageContent}>
        <div
          className={styles.bannerContainer}
          style={{
            backgroundImage: `url(${props.autosaasLogo})`,
          }}
        >
          <div className={styles.banner} />
          <div className={styles.bannerInfo}>
            <Logo
              onLogoUpdated={handleUpdateAutoSaasLogo}
              logoUrl={props.autosaasLogo}
            />
            <div className={styles.autosaasInfo}>
              <div className={styles.title}>
                <EditableLabel
                  size="large"
                  maxWidth={400}
                  className={styles.editableLabel}
                  value={
                    sassDesign?.name || sassDesign?.name === ''
                      ? sassDesign?.name || ''
                      : 'AutoSass Name'
                  }
                  onChange={(name) => setSassDesign({ ...sassDesign, name })}
                  onOutsideClick={handleUpdateAutoSassName}
                />
              </div>
              <div className={styles.customers}>
                {customersCount} Customer{customersCount > 1 && 's'}
              </div>
              <div className={styles.domainUrl}>
                <Link className={styles.linkIcon} />
                {sassDesign?.domain?.includes('http')
                  ? sassDesign?.domain
                  : `https://${sassDesign?.domain}`}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contentContainer}>
          <Tabs
            tabs={[
              {
                title: 'Customers',
                content: (
                  <div className={styles.customersContainer}>
                    <div className={styles.customers}>
                      <CustomersIcon />
                      Customers
                    </div>
                    <CustomersTable setCustomersCount={setCustomersCount} />
                  </div>
                ),
              },
              {
                title: 'Settings',
                content: (
                  <SettingsTab
                    favicon={sassDesign?.faviconUrl}
                    colors={colors}
                    setColors={setColors}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    helpUrl={sassDesign?.helpCenterUrl}
                    updateAutoSassDesign={props.updateAutoSassDesign}
                  />
                ),
              },
              { title: 'Plans', content: <ProductsTab /> },
            ]}
            tabsHeader={styles.tabBar}
            contentClassName={styles.contentClassName}
          />
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = (state: RootState) => ({
  autosaasLogo: state.autosaas.logoUrl,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  setAutoSaasLogoUrl: (url: string) => dispatch(setAutoSaasLogoUrl(url)),
  updateAutoSassDesign: (payload: IAutoSassDesign) =>
    dispatch(fetchedAutoSassDesign(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoSaas);
