import clsx from 'clsx';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { fetchUserAccess } from 'store/user/userActions';
import { DispatchType, RootState } from 'store/rootReducer';
import {
  setActiveAccountAction,
  fetchAccountsAction,
} from 'store/accounts/accountsActions';
import { getAutoSassApps } from 'store/autosaas/autosaasActions';
import { IAutoApp } from 'store/autosaas/autosaasReducer';
import {
  isAdmin,
  getUserId,
  getUserName,
  getActiveSubId,
  switchSubAccount,
} from 'utils/Utils';
import { ReactComponent as ChangePageSettingSvg } from 'Assets/icons/changePageSetting.svg';
import { ReactComponent as ChangePageLogoutSvg } from 'Assets/icons/changePageLogout.svg';
import { ReactComponent as ChangePageHelpSvg } from 'Assets/icons/changePageHelpCenter.svg';
import { ReactComponent as PlusCircleIcon } from 'Assets/icons/plusInCircle.svg';
import { ReactComponent as BetaBadgeIcon } from 'Assets/icons/beta-badge.svg';
import { ReactComponent as SettingsIcon } from 'Assets/icons/contextMenu/settingsIcon.svg';
import { ReactComponent as AccountIcon } from 'Assets/icons/blankThumbnail.svg';
import { ReactComponent as RocketIcon } from 'Assets/icons/rocket.svg';
import { ReactComponent as DotsSvg } from 'Assets/icons/copyCraft/dots.svg';
import { ReactComponent as GoBack } from 'Assets/icons/sideMenu/go-back.svg';
import { ReactComponent as AppIcon } from 'Assets/icons/appIcons/templates.svg';
import { IAccount } from 'Components/Accounts/types';
import { graphQlCall } from 'graphql/utils';
import { truncateString } from 'utils/helpers';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import {
  AutoSassIconsOptions,
  menuItems,
  PageType,
  pageTypes,
  submenuItems,
} from './constants';
import { IUserAccess } from 'types';
import queries from 'graphql/queries';
import LogoSvg from 'Assets/icons/sideMenu/logo.svg';
import AgenciesDropDown from 'Components/Accounts/AgenciesDropDown/AgenciesDropDown';
import AutoSassAppsSettingsModal from 'Components/AutoSassAppsSettingsModal/AutoSassAppsSettingsModal';
import { IProps, ActiveSettingPageType, ActivePageType } from './types';

import s from './SideMenu.module.scss';

const SideMenu = (props: IProps) => {
  const subAccounts = props.accounts;
  const [userAccess, setUserAccesses] = useState<IUserAccess | undefined>();
  const history = useHistory();
  const location = useLocation();
  const [activePage, setActivePage] = useState<
    ActivePageType | ActiveSettingPageType | null
  >(null);
  const [accountAccessComplete, setAccountAccessComplete] = useState<boolean>(
    false
  );
  const [isAppSettingModalOpen, setIsAppSettingsModalOpen] = useState<boolean>(
    false
  );
  const [selectedAutoSassApp, setSelectedAutoSassApp] = useState<
    IAutoApp | PageType | undefined
  >(undefined);
  const [subMenu, setSubMenu] = useState<'outreach' | 'settings'>();
  const [openAgencyMenu, setOpenAgencyMenu] = useState(false);
  const [activeAccount, setActiveAccount] = useState<{
    name?: string;
    thumbnail?: string;
  }>({});
  const [menuPages, setMenuPages] = useState<PageType[]>([]);
  const [helpCenterUrl, setHelpCenterUrl] = useState<string>('');

  const modalRef = useRef<HTMLDivElement>(null);
  UseOnClickOutside(modalRef, (e) => setOpenAgencyMenu(false));

  useEffect(() => {
    let helpUrl = localStorage.getItem('helpCenterUrl');
    if (props.autoSassDesign?.helpCenterUrl) {
      helpUrl = props.autoSassDesign?.helpCenterUrl;
      localStorage.setItem('helpCenterUrl', helpUrl);
    }

    // In case the help center URL is not provided in the AutoSaas Design props,
    // we use the default one
    if (!props.autoSassDesign && !helpUrl) {
      helpUrl = 'https://help.autofunnel.ai';
    }

    if (helpUrl) {
      setHelpCenterUrl(helpUrl);
    }
  }, [props.autoSassDesign?.helpCenterUrl]);

  useEffect(() => {
    if (props.userAccess) {
      setUserAccesses(props.userAccess);
    }
  }, [props.userAccess]);

  useLayoutEffect(() => {
    const init = async () => {
      for (const e of [
        ...pageTypes,
        ...(props?.autoApps?.map(
          (item) => item.name?.toLowerCase().replace(/\s+/g, '') || ''
        ) ?? []),
      ]) {
        const path = location.pathname.split('/');

        if (path[2] === e || path[3] === e) {
          setActivePage(e as ActivePageType | ActiveSettingPageType);
          break;
        }
      }
      await props.getAccountAccess();
      setAccountAccessComplete(true);
    };

    init();
  }, [props.autoApps, location.pathname]);

  useEffect(() => {
    if (accountAccessComplete && userAccess) {
      if (
        !userAccess.autosaas ||
        (userAccess.autosaas && props.autoApps && props.autoSassAppsNames) ||
        (userAccess.agency && !!activeAccount?.name && !userAccess.autosaas)
      ) {
        let pages = menuItems(userAccess, isAdmin()).map((page) => ({
          ...page,
          name:
            props.autoSassAppsNames?.find(
              (item) =>
                item?.key?.toLowerCase() === page.autosaas?.key?.toLowerCase()
            )?.name || page.name,
        }));

        if (props.autoApps?.length) {
          pages = [
            ...pages,
            ...props.autoApps.map((page: any) => ({
              key: page.name.toLowerCase().replace(/\s+/g, ''),
              name: page.name,
              icon:
                AutoSassIconsOptions.find((item) => item.path === page.iconUrl)
                  ?.path || '',
              access: true,
              redirectUrl: `/console/app/${page.name
                .toLowerCase()
                .replace(/\s+/g, '')}/${page?._id}`,
              autosaas: {
                appId: page._id,
              },
            })),
          ];
        }
        localStorage.setItem('pages', JSON.stringify(pages));
        setMenuPages(pages);
      }
    }
  }, [
    props.autoApps,
    userAccess,
    accountAccessComplete,
    props.autoSassAppsNames,
    activeAccount,
  ]);

  useEffect(() => {
    if (props.getAutoSassApps) {
      props.getAutoSassApps();
    }
  }, []);

  useEffect(() => {
    const cashedAccount = localStorage.getItem('activeAccount');

    if (cashedAccount) {
      setActiveAccount(JSON.parse(cashedAccount));
    }

    const accesses = localStorage.getItem('userAccess');

    if (accesses) {
      setUserAccesses(JSON.parse(accesses));
    }

    const cachedPages = localStorage.getItem('pages');

    if (cachedPages) {
      setMenuPages(JSON.parse(cachedPages));
    }
  }, []);

  useEffect(() => {
    const id = getActiveSubId();

    const account = subAccounts.find((e) => e._id === id);

    const accountData = account
      ? { name: account.name, thumbnail: account.thumbnail }
      : {
          name: props.userDetails.name,
          thumbnail: props.userDetails.thumbnail,
        };
    if (accountData?.name) {
      localStorage.setItem('activeAccount', JSON.stringify(accountData));

      setActiveAccount(accountData);
    }
  }, [subAccounts, props.userDetails]);

  const fetchSubAccounts = async () => {
    props.getAccounts();
  };

  const getUserAccess = async () => {
    let access = {
      ...userAccess,
    };

    const location = window.location.pathname;

    if (
      (!access.bookle && location.includes('books')) ||
      (!access.funnel && location.includes('projects')) ||
      (!access.copyCraft && location.includes('copy-craft')) ||
      (!access.agency && location.includes('agency')) ||
      (!props.userDetails.scopes?.includes('SAAS_OWNER') &&
        location.includes('autosaas')) ||
      (!access.podcast && location.includes('podcaster'))
    ) {
      window.open('/console/settings', '_self');
    }

    if (access.agency) {
      await fetchSubAccounts();
    }
    if (
      !access.bookle &&
      !access.copyCraft &&
      !access.funnel &&
      // !access.contacts &&
      props.onShowNoSubscriptionAlert
    ) {
      props.onShowNoSubscriptionAlert();
    }
  };

  useEffect(() => {
    if (accountAccessComplete) {
      getUserAccess();
    }
  }, [userAccess, props.onShowNoSubscriptionAlert, accountAccessComplete]);

  const handleLinkClick = (
    link: string,
    newActivePage?: ActivePageType | ActiveSettingPageType
  ) => {
    history.push(link);
    if (newActivePage) {
      setActivePage(newActivePage);
    }
  };

  const handleCLickManage = () => {
    history.push('/console/agency');
  };

  const handleDepricatedAppKeysCorrection = (key: string) => {
    if (key === 'PROJECTS') {
      return 'PAGES';
    } else if (key === 'BOOKS') {
      return 'BOOKLE';
    } else if (key === 'COPY-CRAFT') {
      return 'COPYCRAFT';
    }
    return key;
  };

  const handleAppUpdate = async (updatedApp: { key: string; name: string }) => {
    updatedApp.key = handleDepricatedAppKeysCorrection(updatedApp.key);

    if (!props.autoSassAppsNames) return;

    const appIndex = props.autoSassAppsNames?.findIndex(
      (el) => el.key === updatedApp.key
    );

    if (appIndex !== -1) {
      props.autoSassAppsNames[appIndex] = updatedApp;
    } else {
      props.autoSassAppsNames.push(updatedApp);
    }

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_SAAS_DESIGN,
      values: {
        apps: JSON.stringify(
          props.autoSassAppsNames.map((app) => ({ ...app, enabled: true }))
        ),
      },
      headerType: 'USER-AUTH',
    });

    if (props.userAccess) {
      setUserAccesses({ ...props.userAccess });
    }
    setIsAppSettingsModalOpen(false);
    setSelectedAutoSassApp(undefined);
  };

  const handleSwitchAccount = async (account: IAccount) => {
    props.setActiveAccount(account);
  };

  const handleSwitchToAgencyAccount = async () => {
    const id = getUserId() || '';
    const owner = getUserName();
    await switchSubAccount(id, owner);
    const link = `/console/projects`;
    window.open(link, '_self');
  };

  useEffect(() => {
    if (!activePage) return;

    if (
      [
        'domains-senders',
        'domains',
        'billing-settings',
        'billing',
        'zapier',
        'wallet',
      ].includes(activePage)
    ) {
      setSubMenu('settings');
    } else if (
      ['outreach', 'templates', 'broadcasts', 'auto-responders'].includes(
        activePage
      )
    ) {
      setSubMenu('outreach');
    } else if (activePage.includes('funnel-templates')) {
      setActivePage('projects');
    } else if (activePage.includes('outreach')) {
      setActivePage('messaging');
    }
    // console.log('active page:', activePage);
  }, [activePage]);

  const handleOpenAgencyMenu = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setOpenAgencyMenu(true);
  };

  const handleClickMenuItem = (key: string, redirectUrl: string) => {
    if (key === 'outreach') {
      setSubMenu('outreach');
    }
    if (!location.pathname?.includes('outreach') || key !== 'outreach') {
      handleLinkClick(redirectUrl ?? '', (key as ActivePageType) || undefined);
    }
  };

  return (
    <>
      <div className={s.menuWrapper}>
        <div className={s.topItem}>
          <div className={s.logo}>
            <img
              src={props.autosaasLogo || LogoSvg}
              onClick={() => history.push('/console')}
              alt="logo"
            />
          </div>
          <div className={s.menuItems}>
            {subMenu ? (
              <>
                <div
                  className={clsx(s.menuItem, s.topItem)}
                  onClick={() => setSubMenu(undefined)}
                >
                  <div className={s.menuIcon}>
                    <GoBack />
                  </div>
                  <span>Go Back</span>
                </div>
                {submenuItems(isAdmin())[subMenu].map(
                  (e, index) =>
                    !!e.access && (
                      <div
                        className={clsx(s.menuItem, s.topItem, {
                          [s.activeItem]:
                            (activePage === subMenu && index === 0) ||
                            activePage === e.key,
                        })}
                        onClick={() =>
                          handleLinkClick(
                            e.redirectUrl,
                            (e.key as ActiveSettingPageType) || undefined
                          )
                        }
                        key={e.key}
                      >
                        <div
                          className={clsx(s.menuIcon, {
                            [s.outreach]: subMenu === 'outreach',
                          })}
                        >
                          {e.icon}
                        </div>
                        <span>{e.name}</span>
                      </div>
                    )
                )}
              </>
            ) : (
              <>
                {!!menuPages.length &&
                  (userAccess?.autosaas ? (
                    <div
                      className={clsx(s.menuItem, {
                        [s.activeItem]: activePage === 'autosaas',
                      })}
                      onClick={() =>
                        handleLinkClick('/console/autosaas', 'autosaas')
                      }
                    >
                      <RocketIcon />
                      <span>AutoSaas</span>
                    </div>
                  ) : (
                    userAccess?.agency && (
                      <div
                        className={clsx(s.menuItem, s.agencyItem, {
                          [s.activeItem]: activePage === 'agency',
                        })}
                        onClick={() =>
                          handleLinkClick('/console/agency', 'agency')
                        }
                      >
                        <div className={s.agencyImage}>
                          {activeAccount?.thumbnail ? (
                            <img
                              src={activeAccount?.thumbnail}
                              className={s.agencyLogo}
                              alt="agency logo"
                            />
                          ) : (
                            <span className={s.thumbnailBox}>
                              <AccountIcon className={s.photoPlaceholder} />
                            </span>
                          )}
                          <span>{truncateString(activeAccount?.name, 25)}</span>
                        </div>
                        <DotsSvg
                          onClick={handleOpenAgencyMenu}
                          className={s.dots}
                        />
                      </div>
                    )
                  ))}
                {(userAccess?.agency || userAccess?.autosaas) && (
                  <div ref={modalRef} className={s.agency}>
                    {openAgencyMenu && (
                      <AgenciesDropDown
                        accounts={subAccounts}
                        onSwitchAccount={handleSwitchAccount}
                        onManageClick={() => handleCLickManage()}
                        onSwitchToAgencyAccount={() =>
                          handleSwitchToAgencyAccount()
                        }
                        userDetails={props.userDetails}
                      />
                    )}
                  </div>
                )}
                {menuPages?.map(
                  (e, index) =>
                    !!e.access && (
                      <div
                        className={clsx(s.menuItem, s.topItem, {
                          [s.activeItem]:
                            activePage === e.key ||
                            location.pathname.split('/')[2] === e.key,
                        })}
                        onClick={() =>
                          handleClickMenuItem(e.key, e?.redirectUrl || '')
                        }
                        key={index}
                      >
                        {AutoSassIconsOptions.find(
                          (icon) => icon.path === e.icon
                        )?.icon || <AppIcon />}
                        <span className={s.pageName}>
                          {truncateString(e.name, 20)}
                        </span>
                        {userAccess?.autosaas && (
                          <SettingsIcon
                            className={s.settingsIcon}
                            onClick={(ev) => {
                              ev.stopPropagation();
                              ev.preventDefault();

                              const customApp = props.autoApps?.find(
                                (item) => item._id === e.autosaas?.appId
                              );
                              if (customApp) {
                                setSelectedAutoSassApp(customApp);
                              } else {
                                setSelectedAutoSassApp(e);
                              }
                              setIsAppSettingsModalOpen(true);
                            }}
                          />
                        )}
                      </div>
                    )
                )}
              </>
            )}
            {!!menuPages.length && userAccess?.autosaas && !subMenu && (
              <>
                <div className={s.addAppDivider} />
                <div
                  className={s.menuItem}
                  onClick={() => history.push('/console/new-app')}
                >
                  <PlusCircleIcon width={26} height={26} />
                  <span>New App</span>
                  <BetaBadgeIcon className={s.badge} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={s.bottomItems}>
          <div
            className={clsx(s.menuItem)}
            onClick={() => handleLinkClick('/console/logout')}
          >
            <ChangePageLogoutSvg />
            <span>Log Out</span>
          </div>
          {!!helpCenterUrl && (
            <div
              className={clsx(s.menuItem)}
              onClick={() => window.open(helpCenterUrl)}
            >
              <ChangePageHelpSvg />
              <span>Help Center</span>
            </div>
          )}
          <div
            className={clsx(s.menuItem, {
              [s.activeItem]: activePage === 'settings',
            })}
            onClick={() => setSubMenu('settings')}
          >
            <ChangePageSettingSvg />
            <span>Settings</span>
          </div>
        </div>
      </div>
      <AutoSassAppsSettingsModal
        appData={selectedAutoSassApp}
        open={isAppSettingModalOpen}
        onUpdateApp={handleAppUpdate}
        onClose={() => {
          setIsAppSettingsModalOpen(false);
          setSelectedAutoSassApp(undefined);
        }}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  accounts: state.accounts.accounts,
  loading: state.accounts.loading,
  activeAccount: state.accounts.activeAccount,
  userAccess: state.user.userAccess,
  autosaasLogo: state.autosaas.logoUrl,
  userDetails: state.user.agencyDetails,
  autoSassAppsNames: state.autosaas.appsNames,
  autoApps: state.autosaas.autoApps,
  autoSassDesign: state.autosaas.autoSassDesign,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  getAccounts: () => dispatch(fetchAccountsAction()),
  setActiveAccount: (account: IAccount | null) =>
    dispatch(setActiveAccountAction(account)),
  getAccountAccess: () => dispatch(fetchUserAccess()),
  getAutoSassApps: () => dispatch(getAutoSassApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
