import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { RootState } from 'store/rootReducer';
import clsx from 'clsx';
import Link from 'UILib/Link/Link';
import Input from 'UILib/Input/Input';
import Button from 'UILib/Button/Button';
import Dropdown from 'UILib/Dropdown/Dropdown';
import TimeInput from 'UILib/TimeInput/TimeInput';
import RadioButton from 'UILib/RadioButton/RadioButton';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import Calendar from 'Components/Common/DatePicker/Calendar/Calendar';
import EmailTestModal from 'Components/EmailTestModal/EmailTestModal';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import DepletedBalanceModal from 'Components/DepletedBalanceModal/DepletedBalanceModal';
import LaunchConfirmationPopup from 'Components/LaunchConfirmationPopup/LaunchConfirmationPopup';
import AutomaticWithdrawalFailedPopup from 'Components/AutomaticWithdrawalFailedPopup/AutomaticWithdrawalFailedPopup';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import { timezones } from 'utils/constants';
import { graphQlCall } from 'graphql/utils';
import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { Tag } from 'Pages/PageContacts/Helper/types';
import { IBroadcast, ISender, ISmartList, IUserDetails } from 'types';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as ClockIcon } from 'Assets/icons/clockNotFill.svg';
import { ReactComponent as CalendarIcon } from 'Assets/icons/calendar.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as CrossIcon } from 'Assets/icons/cross.svg';
import { ReactComponent as Attention } from 'Assets/icons/attention.svg';

import styles from './CompleteBroadcastCampaign.module.scss';

interface IProps {
  onGoBack: () => void;
  onEmailSubjectChanged: (subject: string) => void;
  subject: string;
  maildraftId: string;
  htmlContent: string;
  broadcastId: string;
  broadcastData?: IBroadcast;
  broadcastStyles?: IBookleTemplateBlockStyles;
  user: IUserDetails;
}

enum ContactsOptionsEnum {
  ALL_CONTACTS = 'all-contacts',
  SMART_LIST = 'smart-list',
  TAGS = 'tags',
}

const CompleteBroadcastCampaign = ({
  onGoBack,
  onEmailSubjectChanged,
  subject,
  maildraftId,
  htmlContent,
  broadcastId,
  broadcastData,
  broadcastStyles,
  user,
}: IProps) => {
  const [senders, setSenders] = useState<ISender[]>([]);
  const [domains, setDomains] = useState<ISender[]>([]);
  const [smartLists, setSmartLists] = useState<ISmartList[]>([]);
  const [emailSubject, setEmailSubject] = useState<string>(subject);
  const [selectedOption, setSelectedOption] = useState<'send-now' | 'send-on'>(
    'send-now'
  );
  const [contactsOptions, setContactsOptions] = useState<ContactsOptionsEnum>(
    ContactsOptionsEnum.ALL_CONTACTS
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [selectedTimezone, setSelectedTimezone] = useState<string>(
    localStorage.getItem('preferredTimezone') ?? ''
  );
  const [selectedSmartList, setSelectedSmartList] = useState<string>('');
  const [selectedSender, setSelectedSender] = useState<string>(
    localStorage.getItem('preferredSender') ?? ''
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [showTestEmailModal, setShowTestEmailModal] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    smartList: boolean;
    date: boolean;
    time: boolean;
    tags: boolean;
    contacts: boolean;
  }>({
    smartList: false,
    date: false,
    time: false,
    tags: false,
    contacts: false,
  });
  const [openBalanceModal, setOpenBalanceModal] = useState<boolean>(false);
  const [openWithdrawalModal, setOpenWithdrawalModal] = useState<boolean>(
    false
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(
    false
  );
  const [isGettingContactCount, setIsGettingContactsCount] = useState<boolean>(
    false
  );
  const [contactsCount, setContactsCount] = useState<number>(0);
  const [uniqueTags, setUniqueTags] = useState<Tag[]>([]);
  const [searchedTag, setSearchedTag] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [balance, setBalance] = useState<number>(0);
  const [senderName, setSenderName] = useState<string>('');

  const hasContactError = !selectedSender && contactsCount > 5000;

  const filteredTags = useMemo(() => {
    return uniqueTags
      .filter((e) => e.name.includes(searchedTag))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [searchedTag, uniqueTags]);

  const verifiedSenders = useMemo(() => {
    return senders
      .filter((sender) =>
        domains.some(
          (domain) =>
            domain._id === sender.mailDomain?._id &&
            domain.status === 'Verified'
        )
      )
      .map((sender) => ({
        label: sender.email,
        value: sender._id,
      }));
  }, [senders, domains]);

  useEffect(() => {
    if (broadcastData) {
      setSelectedSender(broadcastData?.sender?._id || '');
      setSenderName(broadcastData?.mailDraft.senderName || user.name || '');
      if (!!broadcastData?.smartList?._id) {
        setSelectedSmartList(broadcastData?.smartList?._id || '');
        setContactsOptions(ContactsOptionsEnum.SMART_LIST);
      } else if (!!broadcastData.tags.length) {
        setSelectedTags(broadcastData.tags?.map((e) => e.name));
        setContactsOptions(ContactsOptionsEnum.TAGS);
      }
    }
  }, [broadcastData, user]);

  const getContactsCount = async () => {
    try {
      setIsGettingContactsCount(true);
      if (
        (contactsOptions === ContactsOptionsEnum.SMART_LIST &&
          !selectedSmartList) ||
        (contactsOptions === ContactsOptionsEnum.TAGS && !selectedTags.length)
      ) {
        return setContactsCount(0);
      }

      const response = await graphQlCall({
        queryTemplateObject: queries.GET_USER_CONTACT_COUNT,
        headerType: 'USER-AUTH',
        values: {
          ...(contactsOptions === ContactsOptionsEnum.SMART_LIST
            ? {
                smartListId: selectedSmartList,
              }
            : contactsOptions === ContactsOptionsEnum.TAGS
            ? { tagsNames: selectedTags }
            : {}),
        },
      });

      setContactsCount(response.count);
    } catch (error) {
      console.error(error);
    } finally {
      setIsGettingContactsCount(false);
    }
  };

  useEffect(() => {
    setErrors({
      ...errors,
      contacts: false,
    });
    getContactsCount();
  }, [contactsOptions, selectedSmartList, selectedTags]);

  const history = useHistory();

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_SENDERS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setSenders);
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_DOMAINS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setDomains);
    graphQlCall({
      queryTemplateObject: queries.GET_USER_SMART_LISTS,
      headerType: 'USER-AUTH',
    }).then(setSmartLists);
    graphQlCall({
      queryTemplateObject: queries.GET_USER_CONTACT_TAGS,
      headerType: 'USER-AUTH',
    }).then(setUniqueTags);
  }, []);

  const checkValidation = () => {
    let errors = {
      smartList:
        !selectedSmartList &&
        contactsOptions === ContactsOptionsEnum.SMART_LIST,
      date: false,
      time: false,
      tags:
        !selectedTags.length && contactsOptions === ContactsOptionsEnum.TAGS,
      contacts: !contactsCount,
    };
    let isSendingInfoValid = true;

    if (contactsOptions === ContactsOptionsEnum.SMART_LIST) {
      isSendingInfoValid = !!selectedSmartList;
    } else if (contactsOptions === ContactsOptionsEnum.TAGS) {
      isSendingInfoValid = !!selectedTags.length;
    }

    if (selectedOption === 'send-on') {
      errors = {
        ...errors,
        date: !selectedDate,
        time: !selectedTime,
      };

      setErrors(errors);

      return (
        isSendingInfoValid &&
        !!selectedDate &&
        !!selectedTime &&
        !!contactsCount
      );
    }

    setErrors(errors);
    return isSendingInfoValid && !!contactsCount;
  };

  const updateBroadcast = async () => {
    try {
      let sendAt = Date.now();
      if (selectedOption === 'send-on') {
        const timezoneOffsetMatch = selectedTimezone.match(
          /([+-]\d{2}):(\d{2})/
        );
        const timezoneOffsetHours = parseFloat(timezoneOffsetMatch?.[1] || '0');
        const timezoneOffsetMinutes = parseFloat(
          timezoneOffsetMatch?.[2] || '0'
        );

        const formattedDate = selectedDate?.toISOString().split('T')[0];

        const sendAtDateString = `${formattedDate}T${selectedTime}:00.000Z`;

        const sendAtDate = new Date(sendAtDateString);

        sendAtDate.setUTCHours(
          sendAtDate.getUTCHours() + timezoneOffsetHours,
          sendAtDate.getUTCMinutes() + timezoneOffsetMinutes
        );

        sendAtDate.setHours(sendAtDate.getHours() + timezoneOffsetHours);
        sendAtDate.setMinutes(sendAtDate.getMinutes() + timezoneOffsetMinutes);
        sendAt = sendAtDate.getTime();
      }

      await graphQlCall({
        queryTemplateObject: queries.UPDATE_MAIL_DRAFT_MUTATION,
        values: {
          id: maildraftId,
          senderName,
        },
        headerType: 'USER-AUTH',
      });

      const broadcast = await graphQlCall({
        queryTemplateObject: queries.UPDATE_BROADCAST_MUTATION,
        values: {
          id: broadcastId,
          senderId: selectedSender,
          ...(contactsOptions === ContactsOptionsEnum.SMART_LIST
            ? {
                smartListId: selectedSmartList,
              }
            : contactsOptions === ContactsOptionsEnum.TAGS
            ? { tagsNames: selectedTags }
            : {}),
          mailSubject: emailSubject,
          mailDraftId: maildraftId,
          sendAt: sendAt / 1000, //NOTE: we need to send EPOCH in seconds
        },
        headerType: 'USER-AUTH',
      });
      return broadcast?._id;
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailSubjectChange = async (value: string) => {
    setEmailSubject(value);
    onEmailSubjectChanged(value);
  };

  const handleConfirmLaunch = async () => {
    try {
      setLoading(true);
      const result = await updateBroadcast();
      if (!result) return;

      localStorage.setItem('preferredTimezone', selectedTimezone);
      localStorage.setItem('preferredSender', selectedSender);
      const response = await graphQlCall({
        queryTemplateObject: queries.START_BROADCAST_MUTATION,
        values: {
          id: result,
        },
        headerType: 'USER-AUTH',
      });

      if (response.__typename === 'BroadcastStripeResp') {
        if (response.error_type === 'payment_error') {
          return setOpenWithdrawalModal(true);
        } else if (response.error_type === 'wallet_is_low') {
          setBalance(response.walletBalance);
          return setOpenBalanceModal(true);
        }
      } else if (response.__typename === 'Broadcast') {
        if (response.status === 'ACTIVE' || response.status === 'WAITING') {
          return history.push('/console/outreach/broadcasts');
        }
      }
    } catch (error: any) {
      console.error(error);
      notification.error({
        message: error.message?.replace('GRAPH QL ERROR: ', ''),
        duration: 3,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBroadcastLaunch = async () => {
    const isValid = checkValidation();

    if (!isValid) return;
    setOpenConfirmationModal(true);
  };

  const handleTagRemove = (tagToRemove: string) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    setSelectedTags(updatedTags);
  };

  const handleTagClick = (tag: Tag) => {
    const tagName = tag.name;

    if (!selectedTags.includes(tagName)) {
      const updatedTags = [...selectedTags, tagName];
      setSelectedTags(updatedTags);
    }
  };

  const ContactsCount = ({
    isLoading,
    count,
  }: {
    isLoading: boolean;
    count: number;
  }) => {
    return (
      <span className={styles.contactCountMessage}>
        You are going to send 
        {isLoading ? <CircleLoader color="#A0A0A0" size={12} /> : count} emails.
      </span>
    );
  };

  return (
    <>
      <DepletedBalanceModal
        open={openBalanceModal}
        onClose={() => setOpenBalanceModal(false)}
        balance={balance}
      />
      <AutomaticWithdrawalFailedPopup
        open={openWithdrawalModal}
        onClose={() => setOpenWithdrawalModal(false)}
      />
      <EmailTestModal
        htmlScript={htmlContent}
        emailSubject={emailSubject}
        senderId={selectedSender}
        open={showTestEmailModal}
        onClose={() => setShowTestEmailModal(false)}
      />
      <LaunchConfirmationPopup
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        subject={subject}
        emailCount={contactsCount}
        onSubmit={handleConfirmLaunch}
        isLoading={loading}
      />
      <div className={styles.container}>
        <Link to="" className={styles.backLink}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onGoBack();
            }}
            className={styles.backButton}
          >
            <Back />
            Back
          </div>
        </Link>
        <div className={styles.formWrapper}>
          <div className={clsx(styles.section, styles.firstSection)}>
            <MailIcon className={styles.icon} width={32} height={24} />
            <div className={styles.title}>Complete Campaign</div>
            <div className={styles.subtitle}>
              Check everything and set final settings
            </div>
            <div className={styles.formInput}>
              Sending Domain
              <CustomDropdown
                label="Select Sender"
                value={selectedSender}
                className={styles.dropdown}
                options={[
                  {
                    value: '',
                    label: (
                      <div className={styles.autoFunnelSender}>
                        <span>QuickSend.ai</span>
                        <span className={styles.description}>
                          Autofunnel’s default address Limited to 5,000
                          recipiants
                        </span>
                      </div>
                    ),
                  },
                  ...verifiedSenders,
                ]}
                onChange={(item) => {
                  setSelectedSender(item);
                }}
                footer={
                  <div
                    className={styles.addSender}
                    onClick={() =>
                      history.push('/console/settings/domains-senders')
                    }
                  >
                    <PlusIcon className={styles.plusIcon} />
                    Add sender
                  </div>
                }
              />
            </div>
            <div className={styles.radioButtonGroup}>
              <RadioButton
                name="All Contacts"
                value={ContactsOptionsEnum.ALL_CONTACTS}
                label="All Contacts"
                checked={contactsOptions === ContactsOptionsEnum.ALL_CONTACTS}
                onChange={(value) =>
                  setContactsOptions(value as ContactsOptionsEnum)
                }
                className={styles.radioButton}
              />
              <RadioButton
                name="Smart List"
                value={ContactsOptionsEnum.SMART_LIST}
                label="Smart List"
                checked={contactsOptions === ContactsOptionsEnum.SMART_LIST}
                onChange={(value) =>
                  setContactsOptions(value as ContactsOptionsEnum)
                }
                className={styles.radioButton}
              />
              <RadioButton
                name="Tags"
                value={ContactsOptionsEnum.TAGS}
                label="Tags"
                checked={contactsOptions === ContactsOptionsEnum.TAGS}
                onChange={(value) =>
                  setContactsOptions(value as ContactsOptionsEnum)
                }
                className={styles.radioButton}
              />
            </div>
            {contactsOptions === ContactsOptionsEnum.ALL_CONTACTS ? (
              !hasContactError && (
                <div className={styles.contactsCount}>
                  <ContactsCount
                    isLoading={isGettingContactCount}
                    count={contactsCount}
                  />
                </div>
              )
            ) : contactsOptions === ContactsOptionsEnum.SMART_LIST ? (
              <div className={styles.formInput}>
                <div className={styles.inputLabel}>
                  Smart List
                  <ContactsCount
                    isLoading={isGettingContactCount}
                    count={contactsCount}
                  />
                </div>
                <Dropdown
                  type="stroke"
                  label="Select List"
                  value={selectedSmartList}
                  className={styles.dropdown}
                  options={smartLists.map((list) => ({
                    label: list.name,
                    value: list._id,
                  }))}
                  onChange={(item) => {
                    setErrors({ ...errors, smartList: false });
                    setSelectedSmartList(item);
                  }}
                  hasErrors={errors.smartList}
                />
                {(errors.smartList || errors.contacts) && (
                  <div className={styles.error}>
                    {errors.smartList
                      ? 'Smart List is required'
                      : 'There are no contacts in this Smart List'}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.formInput}>
                <div className={styles.inputLabel}>
                  Tags
                  <ContactsCount
                    isLoading={isGettingContactCount}
                    count={contactsCount}
                  />
                </div>
                <CustomDropdown
                  label="Select Tags"
                  size="medium"
                  className={styles.dropdown}
                  header={
                    <div className={styles.searchContainer}>
                      <Input
                        placeholder="Search"
                        border="stroke"
                        className={styles.search}
                        onChange={(e) => setSearchedTag(e.target.value)}
                        value={searchedTag}
                      />
                    </div>
                  }
                  options={filteredTags.map((tag) => ({
                    label: tag.name,
                    value: tag._id,
                    selected: selectedTags.some((item) => item === tag.name),
                  }))}
                  onChange={(selectedTagId) => {
                    const selectedTag = uniqueTags.find(
                      (tag) => tag._id === selectedTagId
                    );
                    if (selectedTag) {
                      handleTagClick(selectedTag);
                    }
                  }}
                />
                {(errors.tags || errors.contacts) && (
                  <div className={styles.error}>
                    {errors.tags
                      ? 'Tags are required'
                      : 'There are no contacts with these tags'}
                  </div>
                )}
                <div className={styles.addedTags}>
                  {selectedTags.map((tag, index) => (
                    <div key={index} className={styles.tag}>
                      <div className={styles.tagText}>{tag}</div>
                      <CrossIcon
                        className={styles.crossBtn}
                        onClick={() => handleTagRemove(tag)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            {hasContactError && (
              <div className={styles.contactsError}>
                <Attention className={styles.attention} /> There are{' '}
                {contactsCount} contacts, will be sent to the first 5,000.
              </div>
            )}
          </div>
          <div className={styles.section}>
            <div className={styles.secondSectionWrapper}>
              <div
                className={clsx(
                  styles.formInput,
                  styles.noMargin,
                  styles.nameInput
                )}
              >
                From Name
                <Input
                  border="stroke"
                  className={styles.input}
                  value={senderName}
                  onChange={(e) => setSenderName(e.target.value)}
                />
              </div>
              <div className={clsx(styles.formInput, styles.noMargin)}>
                Email Subject
                <Input
                  border="stroke"
                  className={styles.input}
                  value={emailSubject}
                  onChange={(e) => handleEmailSubjectChange(e.target.value)}
                />
              </div>
              <div className={styles.radioButtonGroup}>
                <RadioButton
                  name="Send Now"
                  value="send-now"
                  label="Send Now"
                  checked={selectedOption === 'send-now'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                  className={styles.radioButton}
                />
                <RadioButton
                  name="Send On"
                  value="send-on"
                  label="Send On"
                  checked={selectedOption === 'send-on'}
                  onChange={(value) =>
                    setSelectedOption(value as 'send-now' | 'send-on')
                  }
                  className={styles.radioButton}
                />
              </div>
              {selectedOption === 'send-on' && (
                <div className={styles.dateContainer}>
                  <Input
                    readOnly
                    border="stroke"
                    placeholder="DD:MM:YYYY"
                    className={styles.dateInput}
                    onClick={() => setOpenCalendar(true)}
                    prefixIcon={<CalendarIcon width={16} height={16} />}
                    value={
                      selectedDate
                        ? selectedDate
                            .toLocaleDateString('en-GB')
                            .split('/')
                            .join(':')
                        : ''
                    }
                    error={errors.date ? 'Date is required' : ''}
                  />
                  <TimeInput
                    value={selectedTime}
                    className={styles.timeInput}
                    onTimeChange={(item) => {
                      setErrors({ ...errors, time: false });
                      setSelectedTime(item);
                    }}
                    prefixIcon={<ClockIcon width={16} height={16} />}
                    error={errors.time ? 'Time is required' : ''}
                  />
                  <Dropdown
                    type="stroke"
                    size="medium"
                    label={timezones[7]}
                    value={selectedTimezone}
                    className={styles.timezoneDropdown}
                    options={timezones.map((tz) => ({ label: tz, value: tz }))}
                    onChange={(timezone) => setSelectedTimezone(timezone)}
                    optionsClassName={styles.dropdownOptionsClassName}
                  />
                  <Calendar
                    selectionType="single"
                    startDate={selectedDate}
                    setDate={(newDate: Date) => {
                      setErrors({ ...errors, date: false });
                      setSelectedDate(newDate);
                    }}
                    isOpen={openCalendar}
                    onClose={() => setOpenCalendar(false)}
                  />
                </div>
              )}
            </div>
            <Button
              width={160}
              height={40}
              appearance="highlighted"
              postfixIcon={!loading && <MailIcon />}
              className={styles.launchButton}
              onClick={handleBroadcastLaunch}
              disabled={loading}
            >
              {loading ? <Loader size={20} color="#ffffff" /> : 'Launch'}
            </Button>
          </div>
        </div>
        <div className={styles.previewWrapper}>
          <div className={styles.title}>Email Preview</div>
          <div className={styles.buttonsWrapper}>
            <Button
              width={180}
              height={40}
              appearance="solid"
              prefixIcon={<MailIcon />}
              onClick={() => setShowTestEmailModal(true)}
            >
              Send Test Email
            </Button>
            <Button
              width={40}
              height={40}
              appearance="stroke"
              prefixIcon={<EditIcon />}
              onClick={onGoBack}
            />
          </div>
          <div
            className={styles.previewContainer}
            style={{ backgroundColor: broadcastStyles?.emailBg }}
            id="templatePreview"
          >
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteBroadcastCampaign;
