import clsx from 'clsx';
import { connect } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { createSocket, getToken } from 'utils/Utils';
import { DispatchType, RootState } from 'store/rootReducer';
import { Descendant } from 'slate';
import { unified } from 'unified';
import markdown from 'remark-parse';
import gfm from 'remark-gfm';
import frontmatter from 'remark-frontmatter';
import { remarkToSlate } from 'remark-slate-transformer';
import {
  defaultTemplateStyles,
  IBookleTemplateBlockStyles,
} from 'store/books/booksReducer';
import {
  clearBlocksHistory,
  updateBookleTemplateBlocks,
  updateBookleTemplateBlockStyles,
} from 'store/books/booksActions';
import { BookleTemplateBlock } from 'types';
import { UseOnClickOutside } from 'utils/UseOnClickOutside';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import { Sidebar, MenuItems } from '../Draggable/Sidebar/Sidebar';
import { findAndDuplicate, findItemById } from '../Draggable/utils';
import Content from '../Draggable/Content/Content';
import SettingsModal from './SettingsModal/SettingsModal';
import EditorSideMenu from './EditorSidemenu';
import styles from './GenerationPreview.module.scss';

interface IProps {
  templateBlocks: BookleTemplateBlock[];
  updateBlocks: (
    payload: BookleTemplateBlock[],
    updateHistory?: boolean
  ) => void;
  isSidebarHidden?: boolean; // this attribute will hide the sidebar toggle
  onSidebarToggle?: (isSidebarOpen: boolean) => void; // even't which should be handled once toggle sidebar
  templateBlockStyles: IBookleTemplateBlockStyles;
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) => void;
  generationTaskId?: string; //subscribing on receiving data for generation;
  disabled?: boolean;
  clearBlocksHistory: () => void;
  onGenerationComplete?: () => void;
  activeBlock: BookleTemplateBlock | null;
  generationComplete?: boolean;
  readonly?: boolean;
}

const GenerationPreview = (props: IProps) => {
  const socket = useRef<Socket | null>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  const [generationTask, setGenerationTask] = useState<any>(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const sidebarRef = useRef<HTMLDivElement | null>(null);

  type SocketCallback = (payload: any) => void;

  const setupSocketListeners = (
    socket: Socket | null,
    event: string,
    callback: SocketCallback
  ): (() => void) => {
    if (socket) {
      socket.on(event, callback);
      return () => socket.off(event, callback);
    }
    return () => {};
  };

  useEffect(() => {
    if (!socket.current) {
      socket.current = createSocket();

      if (socket.current) {
        socket.current.on('connect', () => {
          console.log('connected to server');
        });
      }
    }
  }, []);

  // useEffect(() => {
  //   console.log('***UPDATING BLOCKS: ', props.templateBlocks);
  // }, [props.templateBlocks]);

  useEffect(() => {
    if (isGenerating) {
      const cleanup = setupSocketListeners(
        socket.current,
        'generating-task-info-response',
        handleGenerationTaskUpdates
      );

      return cleanup;
    }
    //TODO: potential area for optimization. we no need to trigger this one on evert props.templateBlocks update
  }, [isGenerating, props.templateBlocks]);

  useEffect(() => {
    if (props.generationTaskId) {
      loadGenerationTaskData(props.generationTaskId);
    }
  }, [props.generationTaskId, props.generationComplete]);

  useEffect(() => {
    if (!generationTask) return;

    let blocks = generationTask.layout;

    if (blocks[0]?.blocks) {
      blocks = blocks[0].blocks;
    }

    if (!blocks || blocks.length === 0) {
      blocks = [...props.templateBlocks];
    }

    if (generationTask.status === 'GENERATING') {
      setIsGenerating(true);

      subscribeOnGenerationTaskUpdates(generationTask._id);
      //mark all nodes that have variable as pending for generation
      for (const block of blocks) {
        if (block.variable) {
          block.generating = true;
        }
      }
    }
    if (
      generationTask.status === 'COMPLETE' ||
      generationTask.status === 'GENERATING'
    ) {
      //handling application of already generated data to template
      traverseGenerationData(generationTask.data, blocks);
    }

    if (blocks && blocks.length > 0) {
      if (blocks[0]?.blocks) {
        props.clearBlocksHistory();
        props.updateBlocks(blocks[0]?.blocks);
      } else {
        props.clearBlocksHistory();
        props.updateBlocks(blocks);
      }
      props.updateBlockStyles(
        blocks[0]?.templateBlockStyles || defaultTemplateStyles
      );
    }

    setTimeout(() => {
      if (
        generationTask.status !== 'GENERATING' &&
        props.onGenerationComplete
      ) {
        props.onGenerationComplete();
      }
    }, 500);
  }, [generationTask]);

  const traverseGenerationData = (data: any, blocks: any) => {
    for (const key in data) {
      const value = data[key];
      if (!value) {
        continue;
      }
      blocks = updateGenerationBlockContent(key, value.value, blocks);

      if (value.children) {
        traverseGenerationData(value.children, blocks);
      }
    }
    return blocks;
  };

  const subscribeOnGenerationTaskUpdates = (taskId: string) => {
    if (socket.current) {
      socket.current.emit('generating-task-info', {
        taskId,
        token: getToken(),
      });
    }
  };

  const handleGenerationTaskUpdates = (payload: any) => {
    if (payload.action === 'content generated') {
      if (payload.data) {
        //TODO: need to fix backend and send error outside data
        const pathComponents = payload.path.split('.');
        const variable = pathComponents[pathComponents.length - 1];
        let updatedBlocks;
        if (payload.data.error) {
          updatedBlocks = updateGenerationBlockContent(
            variable,
            { error: payload.data.error },
            props.templateBlocks
          );
        } else {
          updatedBlocks = updateGenerationBlockContent(
            variable,
            payload.data.result,
            props.templateBlocks
          );
        }

        props.updateBlocks([...updatedBlocks], false);
      }
    } else if (payload.action === 'task is complete') {
      props.updateBlocks([...props.templateBlocks], true);
      setIsGenerating(false);
      if (props.onGenerationComplete) props.onGenerationComplete();
    }
  };

  const loadGenerationTaskData = (taskId: string) => {
    graphQlCall({
      queryTemplateObject: queries.GET_ONE_GENERATION_TASK,
      values: { id: taskId },
      //TODO: need to figure out how to give access to Server Side Rendered pages and keep everyone else away.
      headerType: props.readonly ? undefined : 'USER-AUTH',
    })
      .then((data) => {
        setGenerationTask(data);
      })
      .catch((err) => console.log(err));
  };

  const convertNode = (node: any) => {
    switch (node.type) {
      case 'root':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'paragraph':
        return {
          type: 'paragraph',
          children: node.children.map(convertNode),
        };
      case 'text':
        return {
          text: node.value,
        };
      case 'heading':
        return {
          type: 'title',
          // type: `heading-${node.depth}`,
          depth: node.depth,
          children: node.children.map(convertNode),
        };
      // case "emphasis":
      //   return {
      //     type: "emphasis",
      //     children: node.children.map(convertNode),
      //   };
      case 'break':
        return {
          text: '\n',
        };
      case 'thematicBreak':
        return {
          type: 'line',
          children: [{ text: '' }],
        };
      case 'list':
        return {
          type: 'bulleted-list',
          // ordered: node.ordered,
          children: node.children.map(convertNode),
        };
      case 'listItem':
        return {
          type: 'list-item',
          children: node.children.map(convertNode),
        };
      // case "link":
      //   return {
      //     type: "link",
      //     url: node.url,
      //     children: node.children.map(convertNode),
      //   };
      default:
        const finalNode = node;
        if (node.strong) {
          finalNode.bold = true;
        }
        return finalNode;
    }
  };

  const mergeChildrenTextNodes = (output: any[]) => {
    return output.map((item) => {
      if (item.children && item.children.length > 1) {
        let mergedChildren: any[] = [];

        for (let i = 0; i < item.children.length; i++) {
          let current = item.children[i];

          if (mergedChildren.length > 0) {
            let last = mergedChildren[mergedChildren.length - 1];

            if (
              typeof current.text === 'string' &&
              typeof last.text === 'string'
            ) {
              last.text += current.text;
              continue;
            }
          }

          mergedChildren.push(current);
        }

        return { ...item, children: mergedChildren };
      }

      return item;
    });
  };

  const parseMarkdown = (content: string, originalContent: Descendant[]) => {
    const toSlateProcessor = unified()
      .use(markdown)
      .use(gfm)
      .use(frontmatter)
      .use(remarkToSlate);
    const toSlate = (s: string) => toSlateProcessor.processSync(s).result;

    let ouput = toSlate(content).map(convertNode);

    ouput = mergeChildrenTextNodes(ouput);

    // //here is we applying original text styling to new text
    let originalTextStyling: any = null;
    //gather original text attributes
    if (originalContent && originalContent.length > 0) {
      const originalTextItem = originalContent[0] as any; //TODO: figure out proper type
      if (originalTextItem.children && originalTextItem.children.length > 0) {
        originalTextStyling = { ...originalTextItem.children[0] };
        if (originalTextStyling.text) {
          delete originalTextStyling.text;
        }
      }
    }

    //Apply original text attributes to new text
    if (originalTextStyling) {
      ouput = ouput.map((block) => ({
        ...block,
        children: block.children.map((item: any) => ({
          ...item,
          ...(item.text ? { text: item.text.replace(/^"(.*)"$/, '$1') } : {}),
          ...originalTextStyling,
        })),
      }));
    }

    return ouput;
  };

  const anyBlockInGeneration = (blocks: any) => {
    for (const block of blocks) {
      if (block.generating) {
        return true;
      }
    }
    return false;
  };

  const updateGenerationBlockContent = (
    variable: string,
    content: any,
    blocks: any[]
  ) => {
    for (const block of blocks) {
      if (block.variable === variable) {
        block.generating = false;

        if (content.error) {
          block.error = content.error.replace('400', ''); //TODO: remove 400 from OpenAI original message
        } else {
          block.error = undefined;
          if (
            block.type === MenuItems.TEXT_BLOCK ||
            block.type === MenuItems.BUTTON_BLOCK
          ) {
            block.text = parseMarkdown(content, block.text) as Descendant[];
          } else if (block.type === MenuItems.IMAGE_BLOCK) {
            block.image = content;
          }
        }
        return blocks;
      }
    }
    if (anyBlockInGeneration(blocks) === false) {
      setIsGenerating(false);
    }
    return blocks;
  };

  /////////////////////////////////////////////

  UseOnClickOutside(sidebarRef, () => {
    if (isSidebarOpen) toggleSidebar();
  });

  useEffect(() => {
    if (props.isSidebarHidden) {
      setIsSidebarOpen(false);
    }
  }, [props.isSidebarHidden]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (props.onSidebarToggle) props.onSidebarToggle(!isSidebarOpen);
  };

  const handleCloseSettings = () => {
    setIsSettingsModalOpen(false);
  };

  const handleRemoveBlock = () => {
    if (props?.activeBlock) {
      const newArray = [...props.templateBlocks];
      findItemById(newArray, props?.activeBlock?.id, true);
      props.updateBlocks(newArray);
    }
  };

  const handleDuplicateBlock = () => {
    if (props?.activeBlock) {
      const newArray = [...props.templateBlocks];
      findAndDuplicate(newArray, props?.activeBlock.id);
      props.updateBlocks(newArray);
    }
  };

  if (props.readonly) {
    return (
      <DndProvider backend={HTML5Backend}>
        <Content />
      </DndProvider>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {!props.disabled && (props.generationComplete ?? true) && <Sidebar />}
      <div
        className={clsx(styles.container, {
          [styles.pointerNone]: props.disabled,
        })}
      >
        {isGenerating ? (
          <div className={styles.loaderContainer}>
            <Loader color="#d0d0d0" />
            GENERATING...
          </div>
        ) : (
          <>
            <SettingsModal
              styles={props.templateBlockStyles}
              updateStyles={props.updateBlockStyles}
              open={isSettingsModalOpen}
              handleClose={handleCloseSettings}
            />
            <Content />
          </>
        )}
      </div>
      <EditorSideMenu
        onDuplicate={handleDuplicateBlock}
        onDelete={handleRemoveBlock}
        onClick={() => {}}
      />
    </DndProvider>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlockStyles: state.books.bookleTemplateBlockStyles,
  templateBlocks: state.books.bookleTemplateBlocks,
  activeBlock: state.books.activeBlock,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateBlocks: (payload: BookleTemplateBlock[], updateHistory?: boolean) =>
    dispatch(updateBookleTemplateBlocks(payload, updateHistory)),
  updateBlockStyles: (payload: IBookleTemplateBlockStyles) =>
    dispatch(updateBookleTemplateBlockStyles(payload)),
  clearBlocksHistory: () => dispatch(clearBlocksHistory()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerationPreview);
