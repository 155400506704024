import { useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Divider, notification } from 'antd';
import { balanceDropdownItems } from 'Pages/Wallet/constants';
import { ReactComponent as CoinIcon } from 'Assets/icons/statsRevenue.svg';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';

import styles from './AddWalletBalancePopup.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  onUpdateBalance: (amount: number) => void;
  hasStripeAccount?: boolean;
}

const AddWalletBalancePopup = ({
  open,
  onClose,
  onUpdateBalance,
  hasStripeAccount,
}: Props) => {
  const [amount, setAmount] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const handleClose = () => {
    setAmount(null);
    onClose();
  };

  const handleRefill = async () => {
    try {
      if (amount && !loading) {
        setLoading(true);
        const response = await graphQlCall({
          queryTemplateObject: queries.CREATE_PAYMENT_INTENT,
          headerType: 'USER-AUTH',
          values: { amount: amount * 100 },
        });

        notification.success({
          message: 'Success!',
          description: response.message,
          placement: 'topRight',
          duration: 4,
        });
        onUpdateBalance(amount);
        handleClose();
      }
    } catch (error: any) {
      let errorMessage = error.message;

      if (errorMessage.includes('GRAPH QL ERROR: ')) {
        errorMessage = errorMessage.replace('GRAPH QL ERROR: ', '');
      }

      const key = `open${Date.now()}`;
      notification.error({
        key,
        message: 'Error!',
        description: errorMessage,
        placement: 'topRight',
        duration: 10,
        btn: (
          <Button
            height={40}
            width={320}
            onClick={() => {
              history.push('/console/settings/billing');
              notification.close(key);
            }}
          >
            Manage Payment Details
          </Button>
        ),
      });

      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToStripe = () => {
    history.push('/console/settings/billing');
  };

  if (!open) return null;

  return createPortal(
    <div className={styles.container}>
      <div className={styles.overlay} onClick={handleClose} />
      <div className={styles.coverContainer} onClick={handleClose}>
        <div
          className={styles.contentCover}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles.header}>
            <CoinIcon className={styles.headerIcon} />
            <div className={styles.textContainer}>
              <div className={styles.title}>Refill the Balance</div>
              <div className={styles.subtitle}>
                How much would you like to top up your wallet balance?{' '}
              </div>
            </div>
          </div>
          <Divider className={styles.divider} />
          <div className={styles.dropdownContainer}>
            {hasStripeAccount ? (
              <>
                <div className={styles.label}>Amount</div>
                <Dropdown
                  className={styles.dropdown}
                  type="stroke"
                  label="Choose Amount"
                  options={balanceDropdownItems.filter((e) => e.value !== 0)}
                  onChange={setAmount}
                  value={amount}
                />
                <div className={styles.buttonsContainer}>
                  <Button height={40} appearance="stroke" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    disabled={!amount || loading}
                    height={40}
                    onClick={handleRefill}
                  >
                    {loading ? (
                      <CircleLoader size={18} color="#ffffff" />
                    ) : (
                      'Refill'
                    )}
                  </Button>
                </div>
              </>
            ) : (
              <div className={styles.stripeLinkContainer}>
                <div>Stripe Account</div>
                <div className={styles.subtitle}>
                  To be able to top up your balance, you need to update your
                  payment method.
                </div>
                <Button
                  height={40}
                  width={220}
                  onClick={handleNavigateToStripe}
                >
                  Manage Payment Method
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AddWalletBalancePopup;
