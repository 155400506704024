import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { DispatchType } from 'store/rootReducer';
import { getToken } from 'utils/Utils';

export const REMOVE_SMART_LIST_POPUP = 'REMOVE_SMART_LIST_POPUP';
export const SELECTED_SMART_LIST = 'SELECTED_SMART_LIST';
export const IMPORTING_CONTACTS = 'IMPORTING_CONTACTS';

export const removeSmartListPopup = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: REMOVE_SMART_LIST_POPUP,
    payload,
  };
  dispatch(stateAction);
};

export const updateImportingContacts = (payload: boolean) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: IMPORTING_CONTACTS,
    payload,
  };
  dispatch(stateAction);
};

export const setSelectedSmartList = (payload: string) => (
  dispatch: DispatchType
) => {
  const stateAction = {
    type: SELECTED_SMART_LIST,
    payload,
  };
  dispatch(stateAction);
};

export const cancelImportingContacts = () => async (dispatch: DispatchType) => {
  try {
    await fetch(`${PAGECRAFT_API_URL}/contacts/cancel-upload`, {
      method: 'POST',
      headers: {
        Authorization: getToken(),
      },
    });

    const stateAction = {
      type: IMPORTING_CONTACTS,
      payload: false,
    };
    dispatch(stateAction);
  } catch (error) {
    console.error(error);
  }
};
