export const GET_AUTO_RESPONDERS_SEQUENCE_ITEMS_STATISTICS = {
  operationName: 'getAutoresponderSequenceItemsStatistic',
  query: `query getAutoresponderSequenceItemsStatistic($autoresponderId: String!, $itemIds: [String!]!) {
              getAutoresponderSequenceItemsStatistic(autoresponderId: $autoresponderId, itemIds: $itemIds) {
                  delivered
                  opened
                  clicked
              }
            }`,
};
