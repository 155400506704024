import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import {
  deletePodcastEpisodeAction,
  fetchPodcastEpisodesAction,
} from 'store/podcasts/podcastActions';
import { graphQlCall } from 'graphql/utils';
import { api } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { IPodcast, IPodcastEpisode } from 'types';
import { ReactComponent as EpisodesSvg } from 'Assets/icons/episodes.svg';
import { ReactComponent as PodcastPlus } from 'Assets/icons/plusAdd.svg';
import { ReactComponent as Back } from 'Assets/arrow-left-back.svg';
import coverImg from 'Assets/images/hardcodeCover.png';
import queries from 'graphql/queries';
import Episode from 'Components/Episode/Episode';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import Link from 'UILib/Link/Link';

import styles from './PodcastPage.module.scss';

interface IProps {
  episodes: IPodcastEpisode[];
  fetchEpisodes: (podcastId: string) => void;
  deleteEpisode: (episodeId: string) => void;
  loading: boolean;
  podcastId: string;
}

const PodcastPage = (props: IProps) => {
  const history = useHistory();

  const [podcast, setPodcast] = useState<IPodcast>();
  const [podcastLoading, setPodcastsLoading] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [imagePreview, setImagePreview] = useState<string>('');

  useEffect(() => {
    getPodcast();
  }, [props.podcastId]);

  const getPodcast = async () => {
    setPodcastsLoading(true);
    try {
      const data: IPodcast = await graphQlCall({
        queryTemplateObject: queries.GET_PODCAST_BY_ID,
        headerType: 'USER-AUTH',
        values: { id: props.podcastId },
      });
      setPodcast(data);
      props.fetchEpisodes(data._id);
    } catch (error) {
      console.error(error);
    } finally {
      setPodcastsLoading(false);
    }
  };

  useEffect(() => {
    if (podcast?.image) {
      setImagePreview(podcast.image);
    }
  }, [podcast?.image]);

  const handleAddEpisode = () => {
    history.push(`/console/podcaster/create?podcastId=${podcast?._id}`);
  };

  const handleGoToDashboard = () => {
    history.push('/console/podcaster');
  };

  const handleChangeImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isImageUploading) {
      try {
        setIsImageUploading(true);
        const image = event.target.files ? event.target.files[0] : null;
        if (image) {
          const request = new FormData();
          request.append('file', image);
          request.append('id', podcast?._id || '');

          await api(
            `${PAGECRAFT_API_URL}/podcasts/upload-image?id=${podcast?._id}`,
            'POST',
            request
          );
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string);
          };
          reader.readAsDataURL(image);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsImageUploading(false);
      }
    }
  };

  // const updateEpisode = async (
  //   id: string,
  //   audioUrl: string
  // ): Promise<boolean> => {
  //   try {
  //     await graphQlCall({
  //       queryTemplateObject: QUERIES.UPDATE_EPISODE_MUTATION,
  //       headerType: 'USER-AUTH',
  //       values: {
  //         id,
  //         audioUrl,
  //       },
  //     });
  //     return true;
  //   } catch (error) {
  //     console.error('Error updating episode:', error);
  //     return false;
  //   }
  // };

  return (
    <div className={styles.container}>
      {podcastLoading || props.loading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <>
          <div
            className={styles.bannerContainer}
            style={{
              backgroundImage: `url(${imagePreview || coverImg})`,
            }}
          >
            <div className={styles.bannerCover} />
            <input
              type="file"
              accept="image/*"
              id="podcast-image"
              onChange={handleChangeImage}
              className={styles.uploadImageInput}
              disabled={isImageUploading}
            />
            <div className={styles.bannerInfo}>
              <label htmlFor="podcast-image">
                <img
                  src={imagePreview || coverImg}
                  crossOrigin="anonymous"
                  alt="cover"
                  className={styles.podcastCover}
                />
              </label>
              <div className={styles.podcastInfo}>
                <Link
                  to="/console/podcaster"
                  theme="dark"
                  className={styles.back}
                  prefixIcon={<Back />}
                >
                  Back to Podcasts
                </Link>
                <div>
                  <div className={styles.podcastName}>{podcast?.name}</div>
                  <div className={styles.episodesAmount}>
                    {props.episodes.length}{' '}
                    {props.episodes.length > 1 ? `Episodes` : `Episode`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.itemsContainer}>
            <div className={styles.episodesBlock}>
              <div className={styles.episodeSectionName}>
                <EpisodesSvg />
                <span>Episodes</span>
              </div>
              <Button
                appearance="highlighted"
                height={40}
                onClick={handleAddEpisode}
                postfixIcon={<PodcastPlus />}
              >
                Add Episode
              </Button>
            </div>
            <div className={styles.episodes}>
              {props.episodes.map((episode: any, index: number) => (
                <Episode
                  key={episode._id}
                  id={episode._id}
                  episodeInfo={{
                    episodeName: episode.name,
                    episodeNumber: index + 1,
                    episodeAudioUrl: episode.audioUrl,
                  }}
                  podcastId={props.podcastId}
                  podcastName={podcast ? podcast.name : ' '}
                  imageUrl={episode.image}
                  onDelete={() => props.deleteEpisode(episode._id)}
                  cover={episode.coverData}
                  status={episode.status}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  episodes: state.podcasts.podcastEpisodes,
  loading: state.podcasts.episodesLoading,
});

const mapDispatchToProps = {
  fetchEpisodes: (podcastId: string) => fetchPodcastEpisodesAction(podcastId),
  deleteEpisode: (episodeId: string) => deletePodcastEpisodeAction(episodeId),
};

export default connect(mapStateToProps, mapDispatchToProps)(PodcastPage);
