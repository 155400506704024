export const UPDATE_SENDER_MUTATION = {
  operationName: 'updateMailGunSender',
  query: `mutation updateMailGunSender(
      $id: String!
      $domainId: String
      $senderName: String
      $email: String
    ) {
      updateMailGunSender(
        id: $id
        domainId: $domainId
        senderName: $senderName
        email: $email
      ) {
       _id
        name
        status
        email
        mailDomain {
          _id
        }
    }
  }`,
};
