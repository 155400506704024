export const ADD_MAIL_IN_SEQUENCE_MUTATION = {
  operationName: 'addMailInSequence',
  query: `mutation addMailInSequence (
        <KEYS>
        ) {
            addMailInSequence(<VALUES>) {
                _id
            }
          }`,
};
