import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { GripHorizontal } from 'lucide-react';
import { updateBookleTemplateBlocks } from 'store/books/booksActions';
import { RootState } from 'store/rootReducer';
import { ReactComponent as FolderIcon } from 'Assets/icons/folder.svg';
import { BookleTemplateBlock } from 'types';
import { uploadFile } from 'utils/helpers';
import { cn } from 'utils/Utils';
import { findItemById, updateItemStyle } from '../utils';

import styles from './ImageBlock.module.scss';

interface IProps {
  item: BookleTemplateBlock;
  updateBlocks: (
    payload: BookleTemplateBlock[],
    updateHistory?: boolean
  ) => void;
  templateBlocks: BookleTemplateBlock[];
}

const ImageBlock = ({
  item,
  updateBlocks,
  templateBlocks,
}: IProps): JSX.Element => {
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(
    (item.styles?.height as number) || 0
  );

  useEffect(() => {
    setHeight(item.styles?.height as number);
  }, [item.styles?.height]);

  const handleUploadImage = (file: Blob, image: string) => {
    const newArray = [...templateBlocks];
    const imageItem: BookleTemplateBlock = findItemById(
      newArray,
      item.id as string
    );

    uploadFile(file, (value) => {
      imageItem.image = value as string;

      updateBlocks(newArray);
    });

    if (image) {
      imageItem.image = image;
      updateBlocks(newArray);
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();

    const items = e.dataTransfer.items;

    if (items && items.length > 0) {
      const item = items[0];
      if (item.kind === 'file' && item.type.startsWith('image/')) {
        setIsDragging(true);
      }
    }
  };

  const handleDragLeave = () => {
    !!isDragging && setIsDragging(false);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    if (!isDragging) return;

    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0] && files[0].type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        event.target &&
          handleUploadImage(files[0], event.target.result as string);
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const handleFileInputChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (event) => {
        event.target && handleUploadImage(file, event.target.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalHeight } = e.target as HTMLImageElement;
    const containerHeight = containerRef.current?.offsetHeight || 0;

    updateItemStyle(
      'height',
      containerHeight && naturalHeight > containerHeight
        ? containerHeight
        : naturalHeight,
      templateBlocks,
      updateBlocks,
      item,
      false
    );
  };

  const handleResizeStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const startY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    const imgElement = (e.currentTarget as HTMLElement).parentElement?.querySelector(
      'img'
    );
    if (!imgElement) return;

    const startHeight = imgElement.getBoundingClientRect().height;
    let currentHeight = startHeight;

    const handleMove = (moveEvent: MouseEvent | TouchEvent) => {
      const currentY =
        'touches' in moveEvent
          ? (moveEvent as TouchEvent).touches[0].clientY
          : (moveEvent as MouseEvent).clientY;

      const delta = currentY - startY;
      const newHeight = Math.max(50, startHeight + delta);

      setHeight(newHeight);

      if (newHeight !== currentHeight) {
        currentHeight = newHeight;
        updateItemStyle(
          'height',
          Math.round(newHeight),
          templateBlocks,
          updateBlocks,
          item,
          false
        );
      }
    };

    const handleEnd = () => {
      document.removeEventListener('mousemove', handleMove);
      document.removeEventListener('touchmove', handleMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };

    document.addEventListener('mousemove', handleMove);
    document.addEventListener('touchmove', handleMove);
    document.addEventListener('mouseup', handleEnd);
    document.addEventListener('touchend', handleEnd);
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.emptyImageContainer]: !item.image,
      })}
      style={{
        ...(item.hasColor ? { backgroundColor: item.color } : {}),
        justifyContent: item.alignment as any,
        ...(item.hasPadding
          ? {
              paddingBottom: item.styles?.blockSpacing?.paddingBottom,
              paddingTop: item.styles?.blockSpacing?.paddingTop,
              paddingLeft: item.styles?.blockSpacing?.paddingLeft,
              paddingRight: item.styles?.blockSpacing?.paddingRight,
            }
          : {}),
        borderRadius: item.styles?.containerBorderRadius,
      }}
    >
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={clsx(styles.imageContent, {
          [styles.emptyImageContent]: !item.image,
          [styles.activeImageContent]: isDragging,
        })}
      >
        {!item.image && !item.imagePreview ? (
          <div className={styles.emptyImage}>
            {item.error ? (
              <div className={styles.emptyText}>{item.error}</div>
            ) : (
              <>
                <div className={styles.emptyText}>
                  Drag and drop cover image, click to browse or generate with AI
                </div>
                <label htmlFor={`image-${item.id}`}>
                  <div className={styles.browseButton}>
                    <FolderIcon fill="#8c93c3" /> Browse
                  </div>
                </label>
              </>
            )}
          </div>
        ) : (
          <div
            className={styles.image}
            ref={containerRef}
            style={{ justifyContent: item.alignment }}
          >
            <img
              src={item.imagePreview || item.image}
              alt={item.imageText}
              crossOrigin="anonymous"
              style={{
                objectFit: item.styles?.objectFit || 'cover',
                width: `${item.styles?.width}%`,
                height: `${height}px`,
                border: item.styles?.border,
              }}
              onLoad={handleImageLoad}
            />
          </div>
        )}
        {item.generating && <div className={styles.generating}></div>}
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        id={`image-${item.id}`}
      />
      {(item.imagePreview || item.image) && (
        <div
          className={cn(
            'absolute bottom-0 left-0 right-0 h-6 cursor-ns-resize flex items-center justify-center',
            'bg-primary/40 border-t border-primary/40',
            'opacity-0 group-hover/image:opacity-100 hover:bg-primary/50 transition-all',
            'group/resize',
            styles.resizer
          )}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleResizeStart(e);
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleResizeStart(e);
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <GripHorizontal className="h-4 w-4 text-primary-foreground/90 group-hover/resize:text-primary-foreground transition-colors" />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateBlocks: state.books.bookleTemplateBlocks,
});

const mapDispatchToProps = {
  updateBlocks: (payload: BookleTemplateBlock[], updateHistory?: boolean) =>
    updateBookleTemplateBlocks(payload, updateHistory),
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageBlock);
