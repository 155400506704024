import ColorSelector from 'Components/ColorSelector/ColorSelector';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import {
  CustomElement,
  LIST_TYPES,
  TEXT_ALIGN_TYPES,
} from 'Components/TextEditorToolbar/types';
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Bold,
  Italic,
  Underline,
} from 'lucide-react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import { Button } from 'RadixUI/button';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Editor,
  Transforms,
  Element as SlateElement,
  BaseSelection,
  Text,
} from 'slate';
import { IBookleTemplateEditor } from 'store/books/booksReducer';
import { RootState } from 'store/rootReducer';
import { cn } from 'utils/Utils';
import {
  applyTextLineHeight,
  applyTextStyle,
  getCurrentAttribute,
} from './helpers';

interface IProps {
  templateTextEditor: IBookleTemplateEditor;
}

const MarkButton: React.FC<{
  format: string;
  icon: JSX.Element;
  editor?: Editor;
  selection?: BaseSelection;
}> = ({ format, icon, editor, selection }) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const active = setIsActive(isMarkActive(format));
    if (selection && typeof active === 'boolean') {
      setIsActive(active);
    }
  }, [format, selection, editor]);

  const isMarkActive = (format: string) => {
    if (!editor) {
      return false;
    }

    const marks = Editor.marks(editor);

    return marks ? (marks as any)[format] === true : false;
  };

  const toggleMark = (format: string) => {
    if (!editor) return;

    const { selection } = editor;
    const isCollapsed =
      selection?.anchor.offset === selection?.focus.offset &&
      selection?.anchor.path.toString() === selection?.focus.path.toString();

    const isActive = isMarkActive(format);

    if (isCollapsed) {
      for (const [node, path] of Editor.nodes(editor, {
        match: Text.isText,
        at: [],
      })) {
        Transforms.setNodes(
          editor,
          { [format]: isActive ? undefined : true },
          { at: path }
        );
      }
    } else {
      if (isActive) {
        Editor.removeMark(editor, format);
      } else {
        Editor.addMark(editor, format, true);
      }
    }
  };

  return (
    <Button
      variant="outline"
      size="sm"
      className={cn('flex-1 bg-background/50', isActive && 'bg-muted')}
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleMark(format);
        setIsActive(!isActive);
      }}
    >
      {icon}
    </Button>
  );
};

const BlockButton: React.FC<{
  format: string;
  icon: JSX.Element;
  activeAlign: string;
  setActiveAlign: (align: string) => void;
  toggleBlock: (format: string) => void;
}> = ({ format, icon, activeAlign, setActiveAlign, toggleBlock }) => {
  return (
    <Button
      variant="outline"
      size="sm"
      className={cn(
        'flex-1 bg-background/50',
        activeAlign === format && 'bg-muted'
      )}
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        toggleBlock(format);
        setActiveAlign(activeAlign !== format ? format : 'left');
      }}
    >
      {icon}
    </Button>
  );
};

const TextControls = ({ templateTextEditor }: IProps) => {
  const { editor, selection } = templateTextEditor;
  const [currentColor, setCurrentColor] = useState('#000000');
  const [currentFontFamily, setCurrentFontFamily] = useState('Arial');
  const [size, setSize] = useState<string>('');
  const [weight, setWeight] = useState<string>('');
  const [lineHeight, setLineHeight] = useState<string>('');
  const [letterSpacing, setLetterSpacing] = useState<string>('');
  const [currentLink, setCurrentLink] = useState<string>('');
  const [isBold, setIsBold] = useState<boolean>(false);
  const [availableWeights, setAvailableWeights] = useState<number[]>([]);
  const [activeAlign, setActiveAlign] = useState<string>('left');
  const [isAttributesReceived, setIsAttributesReceived] = useState<boolean>(
    false
  );
  const urlInputRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    window.openTextControlsAndFocusURL = () => {
      if (triggerRef?.current?.dataset.state === 'closed') {
        triggerRef.current.click();
      }

      setTimeout(() => {
        if (urlInputRef.current) {
          urlInputRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          urlInputRef.current.focus({ preventScroll: true });
        }
      }, 300);
    };
  }, [triggerRef, urlInputRef]);

  const fonts = useMemo(() => {
    return FontFamilies.filter((font) => font.webSafe)
      .map((font) => ({
        ...font,
        style: {
          fontFamily: font.label,
        },
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  const setDefaults = () => {
    setCurrentColor('#000000');
    setCurrentFontFamily('Arial');
    setSize('18');
    setLineHeight('1.4');
    setWeight('400');
    setLetterSpacing('1');
  };

  const setCurrentTextAttributes = () => {
    if (!editor || !editor.selection) {
      setDefaults();
      return;
    }

    setCurrentColor(getCurrentAttribute('color', '#000000', editor));
    setCurrentFontFamily(getCurrentAttribute('font', 'Arial', editor));
    setSize(getCurrentAttribute('fontSize', '18', editor));
    setLineHeight(getCurrentAttribute('lineHeight', '1.4', editor));
    setWeight(getCurrentAttribute('weight', '400', editor));
    setCurrentLink(getCurrentAttribute('link', '', editor));
    setLetterSpacing(getCurrentAttribute('letterSpacing', '0', editor));
    setIsAttributesReceived(true);
  };

  useEffect(() => {
    if (editor && selection) {
      setCurrentTextAttributes();
    }
  }, [selection, editor]);

  useEffect(() => {
    setActiveAlign(isBlockActive('align').value);
  }, [editor, selection]);

  useEffect(() => {
    if (isAttributesReceived) {
      const font = FontFamilies.find(
        (item) => item.value === currentFontFamily
      );
      const weights = font?.weights || [400];
      setAvailableWeights(font?.weights || [400]);

      if (!weights.includes(Number(weight)) && editor) {
        setWeight(weights[0].toString());
        applyTextStyle('weight', weights[0], editor);
      }
      setIsBold(Number(weight) > 600);
    }
  }, [currentFontFamily, weight, editor, isAttributesReceived]);

  const isBlockActive = (
    format: string,
    blockType?: keyof SlateElement
  ): { isActive: boolean; value: string } => {
    if (!selection || !editor) return { isActive: false, value: '' };

    try {
      const [match] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n) =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            (!blockType || (n as CustomElement)?.[blockType] === format),
        })
      );

      return {
        isActive: !!match,
        value: match ? (match[0] as any)?.[format] ?? null : null,
      };
    } catch (error) {
      console.error(
        'Invalid path or node encountered in isBlockActive:',
        error
      );
      return { isActive: false, value: '' };
    }
  };

  const toggleBold = () => {
    if (!editor) {
      return;
    }

    const newWeight = isBold
      ? Math.max(...availableWeights.filter((w) => w < 600))
      : Math.min(...availableWeights.filter((w) => w >= 600));

    setWeight(newWeight.toString());
    applyTextStyle('weight', newWeight, editor);
    setIsBold(!isBold);
  };

  const toggleBlock = (format: string) => {
    if (!editor) return;

    const { selection } = editor;
    const isCollapsed =
      selection?.anchor.offset === selection?.focus.offset &&
      selection?.anchor.path.toString() === selection?.focus.path.toString();

    const isActive = isBlockActive(
      format,
      (TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type') as any
    ).isActive;

    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => LIST_TYPES.includes((n as CustomElement).type),
      split: true,
    });

    if (isCollapsed) {
      for (const [node, path] of Editor.nodes(editor, {
        match: (n) => SlateElement.isElement(n),
        at: [],
      })) {
        let newProperties: Partial<CustomElement>;

        if (TEXT_ALIGN_TYPES.includes(format)) {
          newProperties = { align: isActive ? undefined : format };
        } else if (isList) {
          newProperties = { type: isActive ? 'paragraph' : 'list-item' };
        } else {
          newProperties = { type: isActive ? 'paragraph' : (format as any) };
        }

        Transforms.setNodes(editor, newProperties, { at: path });

        if (!isActive && isList) {
          const block: any = { type: format, children: [] };
          Transforms.wrapNodes(editor, block, { at: path });
        }
      }
    } else {
      let newProperties: Partial<CustomElement>;

      if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = { align: isActive ? undefined : format };
      } else if (isList) {
        newProperties = { type: isActive ? 'paragraph' : 'list-item' };
      } else {
        newProperties = { type: isActive ? 'paragraph' : (format as any) };
      }

      Transforms.setNodes(editor, newProperties);

      if (!isActive && isList) {
        const block: any = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
      }
    }
  };

  return (
    <AccordionItem value="typography" className="border-none">
      <AccordionTrigger
        ref={triggerRef}
        className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors"
      >
        Typography
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label className="text-xs font-medium">Font Family</Label>
            <Select
              value={currentFontFamily}
              onValueChange={(value) => {
                applyTextStyle('font', value, editor as Editor);
                setCurrentFontFamily(value);
              }}
            >
              <SelectTrigger className="bg-background/50 focus:bg-background transition-colors">
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="text_controller">
                {fonts.map((item) => (
                  <SelectItem value={item.value} key={item.value}>
                    <img
                      className="h-[30px]"
                      src={`${process.env.PUBLIC_URL}/assets/fontPreview/${item.value}.svg`}
                      alt={item.label}
                    />
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Font Size</Label>
            <Input
              type="number"
              min="0"
              max="99"
              value={size}
              onChange={(e) => {
                applyTextStyle(
                  'fontSize',
                  Number(e.target.value),
                  editor as Editor
                );
                setSize(e.target.value);
              }}
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Line Height</Label>
            <Input
              type="number"
              min="0"
              max="99"
              step="0.1"
              value={lineHeight}
              onChange={(e) => {
                applyTextLineHeight(
                  'lineHeight',
                  Number(e.target.value),
                  editor as Editor
                );
                setLineHeight(e.target.value);
              }}
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Letter Spacing</Label>
            <Input
              type="number"
              min="0"
              max="99"
              step="0.1"
              value={letterSpacing}
              onChange={(e) => {
                applyTextStyle(
                  'letterSpacing',
                  Number(e.target.value),
                  editor as Editor
                );
                setLetterSpacing(e.target.value);
              }}
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Font Weight</Label>
            <Input
              type="number"
              min={Math.max(...availableWeights)}
              max={Math.min(...availableWeights)}
              step="100"
              value={weight}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (availableWeights.includes(value)) {
                  setWeight(e.target.value);
                  applyTextStyle('weight', value, editor as Editor);
                  setIsBold(value > 600);
                } else if (value < Number(weight)) {
                  const currentIndex = availableWeights.indexOf(Number(weight));
                  console.log(currentIndex, availableWeights, weight);

                  if (currentIndex > 0) {
                    const newWeight = availableWeights[currentIndex - 1];
                    setWeight(newWeight.toString());
                    applyTextStyle('weight', newWeight, editor as Editor);
                    setIsBold(newWeight > 600);
                  } else if (value > Number(weight)) {
                    const currentIndex = availableWeights.indexOf(
                      Number(weight)
                    );
                    if (
                      currentIndex >= 0 &&
                      currentIndex < availableWeights.length - 1
                    ) {
                      const newWeight = availableWeights[currentIndex + 1];
                      setWeight(newWeight.toString());
                      applyTextStyle('weight', newWeight, editor as Editor);
                      setIsBold(newWeight > 600);
                    }
                  }
                }
              }}
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Text Formatting</Label>
            <div className="flex gap-2">
              <Button
                variant="outline"
                size="sm"
                className={cn('flex-1 bg-background/50', isBold && 'bg-muted')}
                onClick={(event) => {
                  event.preventDefault();
                  toggleBold();
                }}
              >
                <Bold className="h-4 w-4" />
              </Button>
              <MarkButton
                format="italic"
                icon={<Italic className="h-4 w-4" />}
                selection={selection}
                editor={editor}
              />
              <MarkButton
                format="underline"
                icon={<Underline className="h-4 w-4" />}
                selection={selection}
                editor={editor}
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Text Alignment</Label>
            <div className="flex gap-2">
              <BlockButton
                format="left"
                icon={<AlignLeft className="h-4 w-4" />}
                activeAlign={activeAlign}
                setActiveAlign={setActiveAlign}
                toggleBlock={toggleBlock}
              />
              <BlockButton
                format="center"
                icon={<AlignCenter className="h-4 w-4" />}
                activeAlign={activeAlign}
                setActiveAlign={setActiveAlign}
                toggleBlock={toggleBlock}
              />
              <BlockButton
                format="right"
                icon={<AlignRight className="h-4 w-4" />}
                activeAlign={activeAlign}
                setActiveAlign={setActiveAlign}
                toggleBlock={toggleBlock}
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">Text Color</Label>
            <div className="flex gap-2 items-center">
              <Input
                type="color"
                value={currentColor}
                onChange={(e) => {
                  applyTextStyle('color', e.target.value, editor as Editor);
                  setCurrentColor(e.target.value);
                }}
                className="w-12 h-12 p-1 rounded-lg bg-background/50 focus:bg-background transition-colors"
              />
              <Input
                type="text"
                value={currentColor || ''}
                onChange={(e) => {
                  applyTextStyle('color', e.target.value, editor as Editor);
                  setCurrentColor(e.target.value);
                }}
                color={currentColor}
                placeholder="#000000 or rgb(0,0,0)"
                className="flex-1 bg-background/50 focus:bg-background transition-colors"
              />
            </div>
          </div>
          <div className="space-y-2">
            <Label className="text-xs font-medium">URL</Label>
            <Input
              type="Url"
              value={currentLink || ''}
              ref={urlInputRef}
              onChange={(e) => {
                if (!currentLink) {
                  applyTextStyle('color', '#0000FF', editor as Editor);
                  setCurrentColor('#0000FF');
                }
                if (!e.target.value && currentColor === '#0000FF') {
                  applyTextStyle('color', '#000000', editor as Editor);
                  setCurrentColor('#000000');
                }
                applyTextStyle('link', e.target.value, editor as Editor);
                setCurrentLink(e.target.value);
              }}
              color={currentColor}
              placeholder="https://"
              className="flex-1 bg-background/50 focus:bg-background transition-colors"
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

const mapStateToProps = (state: RootState) => ({
  templateTextEditor: state.books.bookleTemplateTextEditor,
});

export default connect(mapStateToProps)(TextControls);
