import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as Eye } from 'Assets/icons/eye2.svg';
import { ReactComponent as Edit } from 'Assets/icons/edit2.svg';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as Finger } from 'Assets/icons/finger.svg';
import { ReactComponent as EditIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as Settings } from 'Assets/icons/settingsIconsWhite.svg';
import { ReactComponent as Delivered } from 'Assets/icons/conversionsIconLight.svg';
import { ReactComponent as DeleteIcon } from 'Assets/icons/close2.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/clone.svg';
import { IAutoResponderStats, IMailDraft, ISequence } from 'types';
import { truncateString } from 'utils/helpers';
import clsx from 'clsx';
import queries from 'graphql/queries';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import SequenceItemSettingsModal from 'Components/SequenceItemSettingsModal/SequenceItemSettingsModal';

import styles from './SequenceItem.module.scss';

interface IProps {
  autoResponderId: string;
  mailDraft: IMailDraft;
  sequenceWait: ISequence['wait'];
  deleteSequence: (id: string) => void;
  onUpdateSequenceItem: (
    payload: Partial<{
      subject: string;
      preheader: string;
      waitPeriodType: string;
      waitPeriodAmount: number;
    }>
  ) => void;
  stats: IAutoResponderStats;
}

enum MenuItems {
  DELETE,
  EDIT,
  DUPLICATE,
  COPY,
  SETTINGS,
}

const menuItems = [
  {
    label: 'Edit Email',
    key: MenuItems.EDIT,
    icon: <EditIcon className={styles.menuIcon} />,
  },
  {
    label: 'Duplicate',
    key: MenuItems.DUPLICATE,
    icon: (
      <DuplicateIcon className={clsx(styles.menuIcon, styles.duplicateIcon)} />
    ),
  },
  {
    label: 'Settings',
    key: MenuItems.SETTINGS,
    icon: <Settings className={clsx(styles.menuIcon, styles.duplicateIcon)} />,
  },
  {
    label: 'Delete',
    key: MenuItems.DELETE,
    icon: <DeleteIcon className={clsx(styles.menuIcon, styles.closeIcon)} />,
  },
];

const SequenceItem = ({
  mailDraft,
  sequenceWait,
  autoResponderId,
  deleteSequence,
  onUpdateSequenceItem,
  stats,
}: IProps) => {
  const [subject, setSubject] = useState<string>(mailDraft?.subject || '');
  const [archiveFunnelOpen, setArchiveFunnelOpen] = useState<boolean>(false);
  const [isEditingSubject, setIsEditingSubject] = useState<boolean>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    setSubject(mailDraft?.subject || '');
  }, [mailDraft?.subject]);

  const onEdit = () => {
    history.push(
      `/console/auto-responders/${autoResponderId}/edit/${mailDraft._id}`
    );
  };

  const handleUpdateSequence = async ({
    ...values
  }: Partial<{
    subject: string;
    preheader: string;
    waitPeriodType: string;
    waitPeriodAmount: number;
  }>) => {
    try {
      setIsEditingSubject(false);
      await graphQlCall({
        queryTemplateObject:
          queries.UPDATE_AUTO_RESPONDER_SEQUENCE_ITEM_MUTATION,
        values: {
          autoresponderId: autoResponderId,
          sequenceItemId: mailDraft._id,
          ...values,
        },
        headerType: 'USER-AUTH',
      });
      onUpdateSequenceItem(values);
    } catch (error) {
      console.error(error);
    }
  };

  const copySequence = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.COPY_AUTO_RESPONDER_SEQUENCE_ITEM,
        values: {
          autoresponderId: autoResponderId,
          sequenceItemId: mailDraft._id,
        },
        headerType: 'USER-AUTH',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        setArchiveFunnelOpen(true);
        break;
      }
      case MenuItems.EDIT: {
        onEdit();
        break;
      }
      case MenuItems.SETTINGS: {
        setOpenSettingsModal(true);
        break;
      }
      case MenuItems.DUPLICATE: {
        copySequence();
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const deleteProject = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_SEQUENCE_ITEM,
        values: {
          autoresponderId: autoResponderId,
          sequenceItemId: mailDraft._id,
        },
        headerType: 'USER-AUTH',
      });
      deleteSequence(mailDraft._id);
      setArchiveFunnelOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SequenceItemSettingsModal
        open={openSettingsModal}
        mailDraft={mailDraft}
        sequenceWait={sequenceWait}
        onUpdateSequence={handleUpdateSequence}
        onClose={() => setOpenSettingsModal(false)}
      />
      <ConfirmArchivationPopup
        open={archiveFunnelOpen}
        description="Are you sure you want to delete this sequence?"
        onDonePressed={deleteProject}
        onClose={() => setArchiveFunnelOpen(false)}
      />
      <div className={styles.container} onClick={onEdit}>
        <div className={styles.left}>
          <div className={styles.banner} onClick={onEdit}>
            <div className={styles.editSequence}>
              <Edit className={styles.editIcon} />
            </div>
            {mailDraft?.thumbnailUrl ? (
              <img
                className={styles.image}
                src={mailDraft.thumbnailUrl}
                alt={subject}
              />
            ) : (
              <div className={styles.emptyImageContainer}>
                <Email className={styles.emailIcon} />
              </div>
            )}
          </div>
          <div className={styles.titleContainer}>
            <EditableLabel
              value={isEditingSubject ? subject : truncateString(subject, 40)}
              onChange={(value) => setSubject(value)}
              className={styles.title}
              onOutsideClick={() => handleUpdateSequence({ subject })}
              onClick={() => setIsEditingSubject(true)}
            />
            {/* <EditableLabel
              value={preheader}
              onChange={(value) => setPreheader(value)}
              className={styles.subtitle}
              onOutsideClick={() => updateSequence({ preheader })}
            /> */}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <Delivered className={styles.statIcon} />
              {stats?.delivered || 0}
            </div>
            <div className={styles.stat}>
              <Eye className={styles.statIcon} />
              {stats?.opened || 0}
            </div>
            <div className={styles.stat}>
              <Finger className={styles.statIcon} />
              {stats?.clicked || 0}
            </div>
          </div>
          <div className={styles.options}>
            <OptionMenu menuProps={menuProps} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SequenceItem;
