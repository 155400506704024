import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FunnelTotalStats } from 'services/types';
import { DispatchType, RootState } from 'store/rootReducer';
import {
  duplicateFunnelOtherAccountAction,
  funnelDuplicateAction,
  setActiveProjectId,
} from 'store/projects/projectsActions';
import { queueArchiveProject } from 'store/projects/projectsQueueActions';
import { IAccount } from 'Components/Accounts/types';
import { generateProjectUrl } from 'utils/helpers';
import { getActiveSubId, getUserName } from 'utils/Utils';
import { ReactComponent as StatsViews } from 'Assets/icons/statsViews.svg';
import { ReactComponent as StatsLeads } from 'Assets/icons/statsLeads.svg';
import { ReactComponent as StatsSales } from 'Assets/icons/statsSales.svg';
import { ReactComponent as StatsRevenue } from 'Assets/icons/statsRevenue.svg';
import { ReactComponent as Logo } from 'Assets/icons/changePageLogo.svg';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../Accounts/icons/edit.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/duplicate.svg';
import {
  IDomain,
  IDuplicateFunnelOtherAccountPayload,
  IGeneralProject,
} from 'types';
import { DATA_URL } from 'GlobalConstants';
import clsx from 'clsx';
import pageLink from 'Assets/icons/contextMenu/pageLink.svg';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import SettingsModal from 'Components/projects/ProjectSettings/ProjectSettings';
import DuplicateFunnelOtherAccountPopup from 'Components/projects/popups/DuplicateFunnelOtherAccountPopup/DuplicateFunnelOtherAccountPopup';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';

import styles from './FunnelItem.module.scss';

enum MenuItems {
  DELETE,
  EDIT,
  DUPLICATE,
}

const menuItems = [
  {
    label: 'Edit',
    key: MenuItems.EDIT,
    icon: <EditIcon />,
  },
  {
    label: 'Duplicate',
    key: MenuItems.DUPLICATE,
    icon: <DuplicateIcon />,
  },
  {
    label: 'Delete',
    key: MenuItems.DELETE,
    icon: <DeleteIcon />,
  },
];

interface IFunnelProps {
  item: IGeneralProject;
  domains: IDomain[];
  setActiveProjectId: (id: string) => void;
  funnelDuplicate: (id: string) => void;
  subAccounts: IAccount[];
  duplicateFunnelOtherAccountAction: (
    payload: IDuplicateFunnelOtherAccountPayload
  ) => void;
  setArchive: (id: string) => void;
  stats: any;
}

const FunnelItem = (props: IFunnelProps) => {
  const [url, setUrl] = useState<string>('');
  const [connectedDomain, setConnectedDomain] = useState<IDomain | undefined>();
  const [totalStats, setTotalStats] = useState<FunnelTotalStats>();

  const [owner, setOwner] = useState<string>('');
  const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
  const [accountsToCopy, setAccountsToCopy] = useState<IAccount[]>([]);
  const [showProjectSettings, setShowProjectSettings] = useState<boolean>(
    false
  );
  const [archiveFunnelOpen, setArchiveFunnelOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    let sub = getUserName();
    const subUserId = getActiveSubId();
    if (subUserId) {
      const account = props.subAccounts.find((item) => item._id === subUserId);
      if (account) {
        sub = account.owner;
      }
    }
    setOwner(sub);

    const mainAccount = {
      _id: '',
      email: '',
      name: 'Main Account',
      owner: getUserName(),
    };
    const newArray = [mainAccount];

    if (props.subAccounts.length) {
      newArray.push(...props.subAccounts);
    }

    setAccountsToCopy([...newArray]);
  }, [props.subAccounts]);

  useEffect(() => {
    if (props.stats) {
      setTotalStats(props.stats);
    }
  }, [props.stats]);

  const searchAndSetConnectedDomain = () => {
    setConnectedDomain(undefined);
    props.domains.forEach((domain) => {
      if (domain.projectId === props.item._id) {
        setConnectedDomain(domain);
      }
    });
  };

  useEffect(() => {
    searchAndSetConnectedDomain();
  }, [props.domains, props.item._id]);

  const handleMenuClick = (e: any) => {
    e.domEvent.stopPropagation();
    switch (+e.key) {
      case MenuItems.DELETE: {
        setArchiveFunnelOpen(true);
        break;
      }
      case MenuItems.EDIT: {
        setShowProjectSettings(true);
        break;
      }
      case MenuItems.DUPLICATE: {
        setOpenDuplicate(true);
        break;
      }
    }
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    setUrl(generateProjectUrl(props.item, connectedDomain));
  }, [connectedDomain, props.item]);

  const handleCopyPageUrl = () => {
    navigator.clipboard.writeText(url);
  };

  const handleNavigate = () => {
    props.setActiveProjectId(props.item._id);
    history.push(`/console/projects/${props.item._id}`);
  };

  const handleCloseProjectModal = () => {
    setShowProjectSettings(false);
  };

  const handleDelete = async () => {
    props.setArchive(props.item._id);

    setArchiveFunnelOpen(false);
  };

  return (
    <>
      <div className={styles.container} onClick={handleNavigate}>
        <div className={styles.image}>
          {props.item.thumbnail ? (
            <img
              className={styles.funnelPreviewImage}
              src={`${DATA_URL}/${props.item.thumbnail}`}
              alt="preview"
            />
          ) : (
            <Logo className={styles.logo} />
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.line}>
            <div className={styles.name}>{props.item.name}</div>
          </div>
          <div
            className={styles.line}
            onClick={(e) => {
              e.stopPropagation();
              window.open(url, '_blank');
            }}
          >
            <img
              src={pageLink}
              onClick={(e) => {
                e.stopPropagation();
                handleCopyPageUrl();
              }}
              alt="link"
            />
            <span className={styles.url}>{url}</span>
          </div>
        </div>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <StatsViews />
            <div
              className={clsx(styles.statAmount, {
                [styles.emptyStat]: !totalStats?.total_visitors,
              })}
            >
              {totalStats?.total_visitors ?? '-'}
            </div>
          </div>
          <div className={styles.stat}>
            <StatsLeads />
            <div
              className={clsx(styles.statAmount, {
                [styles.emptyStat]: !totalStats?.total_leads,
              })}
            >
              {totalStats?.total_leads ?? '-'}
            </div>
          </div>
          <div className={styles.stat}>
            <StatsSales />
            <div
              className={clsx(styles.statAmount, {
                [styles.emptyStat]: !totalStats?.total_sales,
              })}
            >
              {totalStats?.total_sales ?? '-'}
            </div>
          </div>
          <div className={styles.stat}>
            <StatsRevenue />
            <div
              className={clsx(styles.statAmount, {
                [styles.emptyStat]: true,
              })}
            >
              {'-'}
            </div>
          </div>
        </div>
        <div className={styles.options}>
          <OptionMenu menuProps={menuProps} />
        </div>
      </div>
      <DuplicateFunnelOtherAccountPopup
        funnelId={props.item._id}
        owner={owner}
        open={openDuplicate}
        accounts={accountsToCopy}
        onClose={() => setOpenDuplicate(false)}
        onDuplicate={props.duplicateFunnelOtherAccountAction}
      />
      <SettingsModal
        show={showProjectSettings}
        onClose={handleCloseProjectModal}
        project={props.item}
      />
      <ConfirmArchivationPopup
        open={archiveFunnelOpen}
        description="Once you delete your funnel, all the pages and data from it will be gone. Are you sure you want to continue?"
        onDonePressed={handleDelete}
        onClose={() => setArchiveFunnelOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  domains: state.domains.items,
  subAccounts: state.accounts.accounts,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  setActiveProjectId: (id: string) => dispatch(setActiveProjectId(id)),
  funnelDuplicate: (id: string) => dispatch(funnelDuplicateAction(id)),
  duplicateFunnelOtherAccountAction: (
    payload: IDuplicateFunnelOtherAccountPayload
  ) => dispatch(duplicateFunnelOtherAccountAction(payload)),
  setArchive: (id: string) => dispatch(queueArchiveProject(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunnelItem);
