import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { HfInferenceEndpoint } from '@huggingface/inference';
import { Brain, Send, StopCircle } from 'lucide-react';
import { Textarea } from 'RadixUI/textarea';
import { ScrollArea } from 'RadixUI/scroll-area';
import { Button } from 'RadixUI/button';
import {
  AI_COPY_CRAFT_TEST_EMAIL_URL,
  AI_COPY_CRAFT_TEST_SALES_URL,
  AI_COPY_CRAFT_TEST_BRAIN_URL,
} from 'GlobalConstants';
import { createSSEConnection, createSSEStream } from 'utils/sse';
import { IAiResponse, ITopic } from 'types';
import { ReactComponent as BackArrowSvg } from 'Assets/icons/backArrow.svg';
import { ReactComponent as CopyCraftIcon } from 'Assets/icons/changePageTheme.svg';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';
import Link from 'UILib/Link/Link';
import CopyCraftChatCard from './CopyCraftChatCard';
import clsx from 'clsx';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import { Card } from 'RadixUI/card';
import { Label } from 'RadixUI/label';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';

import s from './CopyCraft.module.scss';

const types = [
  {
    value: 'Email Copy',
    label: 'Email Copy',
  },
  {
    value: 'Sales Copy',
    label: 'Sales Copy',
  },
];

interface IProps {
  topicId: string;
}

interface IPrompt {
  role: 'user' | 'assistant';
  content: string;
}

interface IOptions {
  max_new_tokens: number;
  temperature: number;
  repetition_penalty: number;
  return_full_text: boolean;
}

const CopyCraftChat = (props: IProps) => {
  const location = useLocation();
  const history = useHistory();
  const [topic, setTopic] = useState<ITopic | null>(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [inputText, setInputText] = useState('');
  const [error, setError] = useState(false);
  const [type, setType] = useState('Email Copy');
  const [messages, setMessages] = useState<IAiResponse[]>([]);
  const [scrollingDown, setScrollingDown] = useState(true);
  const [loading, setLoading] = useState<boolean>(!!props.topicId);
  const [maxTokens, setMaxTokens] = useState(1800);
  const [temperature, setTemperature] = useState(0.7);
  const [penalty, setPenalty] = useState(1.2);
  const [upperTokensRange, setUpperTokenRange] = useState(2000);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const [contentHistory, setContentHistory] = useState<IAiResponse[]>([]);

  const streamRef = useRef<AsyncGenerator<any, void, unknown> | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const sseConnection = useRef<HfInferenceEndpoint | null>(null);
  const bottomRef = React.useRef<HTMLDivElement | null>(null);
  const [
    userScrolledUpDuringGeneration,
    setUserScrolledUpDuringGeneration,
  ] = useState(false);

  useEffect(() => {
    sseConnection.current = createSSEConnection(
      AI_COPY_CRAFT_TEST_EMAIL_URL
    ) as HfInferenceEndpoint;
  }, []);

  useEffect(() => {
    if (!!props.topicId) {
      fetchTopic();
      fetchRequests();
    }
  }, [props.topicId]);

  useEffect(() => {
    if (!userScrolledUpDuringGeneration && bottomRef.current && !loading) {
      bottomRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages, requestInProgress, userScrolledUpDuringGeneration, loading]);

  useEffect(() => {
    const chatSection = document.getElementById('chatSection');
    if (chatSection) {
      let lastScrollTop = chatSection.scrollTop;

      const handleScroll = () => {
        const currentScrollTop = chatSection.scrollTop;
        const scrollHeight = chatSection.scrollHeight;
        const clientHeight = chatSection.clientHeight;

        if (currentScrollTop > lastScrollTop) {
          setScrollingDown(true);
        } else if (currentScrollTop < lastScrollTop) {
          setScrollingDown(false);
        }

        if (
          scrollingDown &&
          scrollHeight - currentScrollTop <= clientHeight + 100
        ) {
          setUserScrolledUpDuringGeneration(false);
        } else {
          setUserScrolledUpDuringGeneration(true);
        }

        lastScrollTop = currentScrollTop;
      };

      chatSection.addEventListener('scroll', handleScroll);

      return () => {
        chatSection.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollingDown]);

  const submitInput = async (text?: string, responseType?: string) => {
    if (!sseConnection.current || (!inputText && !text)) {
      return;
    }

    const newText = text ?? inputText;
    const newType = responseType ?? type;
    let topicId = props.topicId;

    if (!topicId) {
      const newTopic = await graphQlCall({
        queryTemplateObject: QUERIES.CREATE_AI_TEXT_TOPIC_MUTATION,
        values: {
          name: newText,
          type,
        },
        headerType: 'USER-AUTH',
      });

      const queryParams = new URLSearchParams(location.search);

      topicId = newTopic._id;
      queryParams.set('topicId', newTopic._id);
      history.push({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    }

    setRequestInProgress(true);

    const prompts: IPrompt[] = [];

    messages.forEach((response) => {
      prompts.push({
        role: 'user',
        content: response.request,
      });
      prompts.push({
        role: 'assistant',
        content: response.response,
      });
    });

    prompts.push({
      role: 'user',
      content: newText,
    });

    const sseOptions: IOptions = {
      max_new_tokens: maxTokens,
      temperature,
      repetition_penalty: penalty,
      return_full_text: false,
    };

    const stream = createSSEStream(sseConnection.current, prompts, sseOptions);
    streamRef.current = stream;

    // Create new response object immediately
    const newResponse = {
      request: newText,
      requestType: newType,
      response: '',
      hasLiked: 0,
    };

    // Add to messages and history right away
    const newResponses = [...messages, newResponse];
    setMessages(newResponses);
    const newHistory = [...contentHistory, newResponse];
    setContentHistory(newHistory);
    setCurrentHistoryIndex(newHistory.length - 1);

    // Stream the response
    let result = '';
    try {
      for await (const r of stream) {
        result += r.token.text;
        // Update the response in both arrays
        newResponse.response = result;
        setMessages([...newResponses]);
        setContentHistory([...newHistory]);
      }
    } catch (error) {
      console.error('Streaming error:', error);
    }

    setInputText('');
    handleCompleteGeneration(result, newText, newType, topicId);
    setRequestInProgress(false);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [inputText]);

  const fetchRequests = async () => {
    try {
      const newRequests = await graphQlCall({
        queryTemplateObject: QUERIES.GET_AI_REQUEST_BY_TOPIC,
        values: {
          topicId: props.topicId,
        },
        headerType: 'USER-AUTH',
      });
      setMessages(newRequests);
      setContentHistory(newRequests);
      setCurrentHistoryIndex(newRequests.length - 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length > 5000) {
      setError(true);
    } else {
      setError(false);
      setInputText(e.target.value);
    }
  };

  const handleStopGeneration = () => {
    if (streamRef.current && streamRef.current.return) {
      streamRef.current.return();
    }
    setRequestInProgress(false);
  };

  const switchServer = (newType: string, changeType = true) => {
    if (newType === type) {
      return;
    }
    if (newType === 'Email Copy') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_EMAIL_URL);
      setUpperTokenRange(2000);
    } else if (newType === 'Sales Copy') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_SALES_URL);
      setUpperTokenRange(4000);
    } else if (newType === 'Brain') {
      sseConnection.current = createSSEConnection(AI_COPY_CRAFT_TEST_BRAIN_URL);
      setUpperTokenRange(4000);
    }

    if (changeType) {
      setType(newType);
    }
  };

  const handleRegenerate = async (text: string, newType: string) => {
    switchServer(newType, false);
    await submitInput(text, newType);
    switchServer(type, false);
  };

  const handleDelete = async (requestId: string) => {
    if (!requestId) {
      return;
    }

    await graphQlCall({
      queryTemplateObject: QUERIES.DELETE_AI_REQUEST,
      values: {
        id: requestId,
      },
      headerType: 'USER-AUTH',
    });

    const newResponses = messages.filter(
      (response, i) => response._id !== requestId
    );
    setMessages(newResponses);
  };

  const handleLike = async (like: number, requestId: string) => {
    if (!requestId) {
      return;
    }

    const newResponses = messages.map((response) => {
      if (requestId === response._id) {
        if (like === response.hasLiked) {
          response.hasLiked = 0;
        } else {
          response.hasLiked = like;
        }
      }
      return response;
    });

    setMessages(newResponses);

    await graphQlCall({
      queryTemplateObject: QUERIES.UPDATE_AI_TEXT_REQUEST,
      values: {
        id: requestId,
        hasLiked: like,
      },
      headerType: 'USER-AUTH',
    });
  };

  const fetchTopic = async () => {
    const newTopic = await graphQlCall({
      queryTemplateObject: QUERIES.GET_AI_TOPIC_QUERY,
      values: {
        id: props.topicId,
      },
      headerType: 'USER-AUTH',
    });

    setTopic(newTopic);
  };

  const handleCompleteGeneration = async (
    response: string,
    text: string,
    type: string,
    topicId: string
  ) => {
    const payload = {
      topicId: topicId,
      request: text,
      response,
      requestType: type,
    };
    await graphQlCall({
      queryTemplateObject: QUERIES.CREATE_AI_TEXT_REQUEST,
      values: payload,
      headerType: 'USER-AUTH',
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitInput();
    }
  };

  const goToPreviousGeneration = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(currentHistoryIndex - 1);
    }
  };

  const goToNextGeneration = () => {
    if (currentHistoryIndex < contentHistory.length - 1) {
      setCurrentHistoryIndex(currentHistoryIndex + 1);
    }
  };

  return (
    <div className="h-screen overflow-hidden">
      <div className="h-full overflow-auto">
        <div className="container max-w-[1200px] mx-auto p-6">
          <div className="mb-6">
            <Link
              to="/console/copy-craft"
              className="flex-1"
              prefixIcon={<BackArrowSvg />}
            >
              Back to Crafted Copies
            </Link>
          </div>

          {loading ? (
            <div className="flex-1 flex items-center justify-center">
              <CircleLoader color="#4719ff" />
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left panel - Input form */}
              <div className="relative">
                <div className="sticky top-4">
                  <Card className="p-6 shadow-sm">
                    <h2 className="text-xl font-bold mb-4">CopyCraft</h2>
                    <p className="text-muted-foreground mb-6 text-sm">
                      Select type and provide a detailed prompt to craft your
                      perfect copy
                    </p>

                    <div className="space-y-6">
                      <div className="space-y-2">
                        <Label>Copy Type</Label>
                        <Select value={type} onValueChange={switchServer}>
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select type" />
                          </SelectTrigger>
                          <SelectContent>
                            {types.map((type) => (
                              <SelectItem key={type.value} value={type.value}>
                                {type.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>

                      <div className="space-y-2">
                        <Label>Detailed Prompt</Label>
                        <Textarea
                          value={inputText}
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          placeholder="Describe your needs"
                          className="min-h-[180px] resize-y"
                          disabled={requestInProgress}
                        />
                      </div>

                      <Button
                        className="w-full"
                        onClick={() => submitInput()}
                        disabled={!inputText.trim() || requestInProgress}
                      >
                        {requestInProgress ? 'Stop Generating' : 'Craft Copy'}
                      </Button>
                    </div>
                  </Card>
                </div>
              </div>

              {/* Right panel - Chat content */}
              <div className="lg:col-span-2">
                <Card className="p-6 shadow-sm">
                  {messages.length === 0 && !requestInProgress ? (
                    <div className="flex items-center justify-center h-[350px] text-muted-foreground">
                      <div className="text-center">
                        <CopyCraftIcon
                          className={clsx(
                            'h-16 w-16 mx-auto mb-4 opacity-50',
                            s.copyCraftIcon
                          )}
                        />
                        <span className="text-base">
                          Your crafted copy will appear here
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      <div className="flex justify-between items-center mb-6">
                        <div className="flex items-center gap-2">
                          <h2 className="text-xl font-bold">Crafted Copy</h2>
                          {contentHistory[currentHistoryIndex] && (
                            <span className="px-2 py-0.5 bg-primary/10 text-primary rounded-full text-xs font-medium">
                              {contentHistory[currentHistoryIndex].requestType}
                            </span>
                          )}
                        </div>
                        {contentHistory.length > 1 && (
                          <div className="flex items-center gap-1">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={goToPreviousGeneration}
                              disabled={currentHistoryIndex <= 0}
                              className="h-8 w-8 rounded-full hover:bg-muted"
                              title="Previous version"
                            >
                              <ChevronLeft className="h-4 w-4" />
                              <span className="sr-only">Previous version</span>
                            </Button>

                            <span className="text-sm text-muted-foreground px-1">
                              {currentHistoryIndex + 1}/{contentHistory.length}
                            </span>

                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={goToNextGeneration}
                              disabled={
                                currentHistoryIndex >= contentHistory.length - 1
                              }
                              className="h-8 w-8 rounded-full hover:bg-muted"
                              title="Next version"
                            >
                              <ChevronRight className="h-4 w-4" />
                              <span className="sr-only">Next version</span>
                            </Button>
                          </div>
                        )}
                      </div>

                      {/* Show only the current message from history */}
                      {contentHistory[currentHistoryIndex] && (
                        <CopyCraftChatCard
                          request={contentHistory[currentHistoryIndex]}
                          onLike={(like) =>
                            handleLike(
                              like,
                              contentHistory[currentHistoryIndex]._id || ''
                            )
                          }
                          onDelete={() =>
                            handleDelete(
                              contentHistory[currentHistoryIndex]._id || ''
                            )
                          }
                          userScrolledUpDuringGeneration={
                            userScrolledUpDuringGeneration
                          }
                          onRegenerate={() =>
                            handleRegenerate(
                              contentHistory[currentHistoryIndex].request,
                              contentHistory[currentHistoryIndex].requestType
                            )
                          }
                          bottomRef={bottomRef}
                          inProgress={requestInProgress}
                        />
                      )}

                      {requestInProgress && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="flex gap-4 items-center"
                        >
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: [0.4, 1, 0.4] }}
                            transition={{ duration: 1.5, repeat: Infinity }}
                            className="text-sm text-muted-foreground"
                          >
                            AI is crafting your copy...
                          </motion.div>
                        </motion.div>
                      )}
                    </div>
                  )}
                </Card>
                <div ref={bottomRef} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CopyCraftChat;
