import {
  SET_SUBSCRIPTIONS,
  SET_EMAIL,
  GET_WALLET_BALLANCE,
  UPDATE_WALLET_BALLANCE,
} from './stripeActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { IStripeSubscription } from 'types';

export interface IWalletBalance {
  amount: number;
  balanceThreshold: number;
  minPaymentAmount: number;
}

export interface IStripeState {
  subscriptions: IStripeSubscription[];
  email: string | null;
  walletBalance: IWalletBalance;
}

const initialState: IStripeState = {
  subscriptions: [],
  email: null,
  walletBalance: {
    amount: 0,
    balanceThreshold: 0,
    minPaymentAmount: 0,
  },
};

const stripeReducer = (
  state = initialState,
  action: PayloadAction<string | IStripeSubscription[] | IWalletBalance>
) => {
  switch (action.type) {
    case SET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload as IStripeSubscription[],
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.payload as string,
      };
    case GET_WALLET_BALLANCE:
      return {
        ...state,
        walletBalance: action.payload as IWalletBalance,
      };
    case UPDATE_WALLET_BALLANCE:
      return {
        ...state,
        walletBalance: {
          ...state.walletBalance,
          ...(action.payload as IWalletBalance),
        },
      };
    default:
      return state;
  }
};

export { initialState, stripeReducer };
