import * as React from 'react';
import { Plus } from 'lucide-react';
import { cn } from 'utils/Utils';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from 'RadixUI/button';
import { Card, CardContent } from 'RadixUI/card';
import { SidebarItem, SidebarItemDragLayer } from './SidebarItem';

import section_1_icon from 'Assets/icons/draggableMenuIcons/section_1_icon.svg';
import section_2_icon from 'Assets/icons/draggableMenuIcons/section_2_icon.svg';
import section_3_icon from 'Assets/icons/draggableMenuIcons/section_3_icon.svg';
import section_4_icon from 'Assets/icons/draggableMenuIcons/section_4_icon.svg';
import text_icon from 'Assets/icons/draggableMenuIcons/text_icon.svg';
import image_icon from 'Assets/icons/draggableMenuIcons/image_icon.svg';
import divider_icon from 'Assets/icons/draggableMenuIcons/divider_icon.svg';
import button_icon from 'Assets/icons/draggableMenuIcons/button_icon.svg';
import spacer from 'Assets/icons/draggableMenuIcons/spacer.svg';
import heading from 'Assets/icons/draggableMenuIcons/heading.svg';

export enum MenuItems {
  ONE_SECTION = 'section_1_block',
  TWO_SECTION = 'section_2_block',
  THREE_SECTION = 'section_3_block',
  FOUR_SECTION = 'section_4_block',
  TEXT_BLOCK = 'text_block',
  IMAGE_BLOCK = 'image_block',
  DIVIDER_BLOCK = 'divider_block',
  BUTTON_BLOCK = 'button_block',
  HEADING_BLOCK = 'heading_block',
  SPACER_BLOCK = 'spacer_block',
}
export const blockTypeToName = (blockType: MenuItems): string => {
  const blockTypeMap: { [key in MenuItems]: string } = {
    [MenuItems.ONE_SECTION]: '1 Section',
    [MenuItems.TWO_SECTION]: '2 Section',
    [MenuItems.THREE_SECTION]: '3 Section',
    [MenuItems.FOUR_SECTION]: '4 Section',
    [MenuItems.TEXT_BLOCK]: 'Text',
    [MenuItems.IMAGE_BLOCK]: 'Image',
    [MenuItems.DIVIDER_BLOCK]: 'Divider',
    [MenuItems.BUTTON_BLOCK]: 'Button',
    [MenuItems.HEADING_BLOCK]: 'Heading',
    [MenuItems.SPACER_BLOCK]: 'Spacer',
  };

  return blockTypeMap[blockType] || 'Unknown';
};

export const sidebarItems = [
  // {
  //   type: MenuItems.ONE_SECTION,
  //   text: '1 Section',
  //   icon: section_1_icon,
  // },
  // {
  //   type: MenuItems.TWO_SECTION,
  //   text: '2 Section',
  //   icon: section_2_icon,
  // },
  {
    type: MenuItems.HEADING_BLOCK,
    text: 'Heading',
    icon: heading,
  },
  //NOTE: disabled for now.
  // {
  //   type: MenuItems.THREE_SECTION,
  //   text: '3 Section',
  //   icon: section_3_icon,
  // },
  // {
  //   type: MenuItems.FOUR_SECTION,
  //   text: '4 Section',
  //   icon: section_4_icon,
  // },
  {
    type: MenuItems.TEXT_BLOCK,
    text: 'Text',
    icon: text_icon,
  },
  {
    type: MenuItems.IMAGE_BLOCK,
    text: 'Image',
    icon: image_icon,
  },
  {
    type: MenuItems.DIVIDER_BLOCK,
    text: 'Divider',
    icon: divider_icon,
    divider: '1px solid #d0d0d0',
  },
  {
    type: MenuItems.SPACER_BLOCK,
    text: 'Spacer',
    icon: spacer,
    isSpacer: true,
    styles: {
      containerHeight: 20,
    },
  },
  {
    type: MenuItems.BUTTON_BLOCK,
    text: 'Button',
    icon: button_icon,
    styles: {
      width: 220,
      height: 50,
    },
  },
];

export const Sidebar = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div className="fixed top-20 left-1 z-50 flex flex-col items-center w-[90px]">
        <div>
          <Button
            variant="outline"
            size="icon"
            className={cn(
              'h-[50px] w-[50px] rounded-lg border-2 bg-background shadow-lg',
              isOpen
                ? 'border-primary bg-primary text-primary-foreground'
                : 'border-border'
            )}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Plus
              className={cn(
                'h-5 w-5',
                isOpen && 'rotate-45 transition-transform duration-200'
              )}
            />
          </Button>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 10 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.2 }}
              className="absolute left-[3px] top-[60px] z-50"
            >
              <Card className="w-[90px]">
                <CardContent className="p-2">
                  <div className="flex flex-col gap-1">
                    {sidebarItems.map((item, index) => (
                      <SidebarItem key={index} item={item} />
                    ))}
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <SidebarItemDragLayer />
    </>
  );
};
