export const GET_AUTO_RESPONDER_STATISTICS = {
  operationName: 'getAutoresponderStatistic',
  query: `query getAutoresponderStatistic($id: String!) {
              getAutoresponderStatistic(id: $id) {
                  delivered
                  opened
                  clicked
              }
            }`,
};
