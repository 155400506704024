import React, { PropsWithChildren, ForwardedRef } from 'react';

import styles from './TextEditorToolbar.module.scss';

interface BaseProps {
  className?: string;
  [key: string]: unknown;
}

export const Button = React.forwardRef(
  (
    {
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active?: boolean;
        reversed?: boolean;
      } & BaseProps
    >,
    ref: ForwardedRef<HTMLSpanElement>
  ) => {
    return (
      <span
        {...props}
        ref={ref}
        className={`${styles.button} ${active ? styles.activeButton : ''}`}
      />
    );
  }
);

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: ForwardedRef<HTMLDivElement>
  ) => <div {...props} ref={ref} data-test-id="menu" className={className} />
);

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: ForwardedRef<HTMLDivElement>
  ) => <Menu {...props} ref={ref} className={styles.toolbar} />
);
