import React, { useEffect, useState } from 'react';
import { useDrag, useDragLayer } from 'react-dnd';
import { motion } from 'framer-motion';
import { Button } from 'RadixUI/button';
import { cn } from 'utils/Utils';

const layerStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(initialOffset: any, currentOffset: any) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const SidebarItemDragLayer: React.FC = () => {
  const {
    itemType,
    isDragging,
    item,
    initialOffset,
    currentOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <motion.div
          className="h-[72px] w-[72px] flex flex-col items-center justify-center gap-2 p-2 rounded-lg bg-background shadow-lg"
          initial={{ opacity: 0.5 }}
          animate={{ opacity: 1 }}
        >
          <img
            src={item.icon}
            alt={item?.text}
            className="h-5 w-5"
            style={{ fill: 'black' }}
          />
          <div className="text-xs font-medium text-muted-foreground">
            {item?.text}
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const SidebarItem = ({ item }: any) => {
  const [isPressed, setIsPressed] = useState(false);
  const [{ isDragging: dragState }, drag, preview] = useDrag(() => ({
    type: 'MENU_ITEM',
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  useEffect(() => {
    if (dragState) {
      setIsPressed(false);
    }
  }, [dragState]);

  useEffect(() => {
    const transparentImage = new Image();
    transparentImage.src =
      'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA='; // Transparent 1x1 GIF
    preview(transparentImage, { captureDraggingState: true });
  }, [preview]);

  return (
    <motion.div>
      <Button
        ref={drag}
        variant="ghost"
        onMouseDown={() => setIsPressed(true)}
        onMouseUp={() => setIsPressed(false)}
        className={cn(
          'h-[72px] w-[72px] flex flex-col items-center justify-center gap-2 p-2 transition-all',
          isPressed && 'shadow-xl transform translate-y-[-8px] duration-200'
        )}
      >
        <img
          src={item.icon}
          alt={item?.text}
          className="h-5 w-5"
          style={{ fill: 'black' }}
        />
        <div className="text-xs font-medium text-muted-foreground">
          {item?.text}
        </div>
      </Button>
    </motion.div>
  );
};

export { SidebarItem, SidebarItemDragLayer };
