import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import { motion } from 'framer-motion';
import {
  Copy,
  RefreshCw,
  ThumbsDown,
  ThumbsUp,
  X,
  ChevronDown,
} from 'lucide-react';
import { Button } from 'RadixUI/button';

interface IAiRequestPrototype {
  request: string;
  requestType: string;
  response: string;
  hasLiked?: number;
  _id?: string;
}

interface IProps {
  request: IAiRequestPrototype;
  userScrolledUpDuringGeneration: boolean;
  inProgress?: boolean;
  onLike?: (like: number) => void;
  onDelete?: () => void;
  onRegenerate?: () => void;
  bottomRef?: React.RefObject<HTMLDivElement>;
}

const CopyCraftChatCard = (props: IProps) => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (
      props.inProgress &&
      !props.userScrolledUpDuringGeneration &&
      props.bottomRef?.current
    ) {
      props.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    props.request.response,
    props.userScrolledUpDuringGeneration,
    props.inProgress,
  ]);

  const hasLiked = props.request.hasLiked || 0;

  const handleLike = (like: number) => {
    if (props.onLike) {
      props.onLike(like);
    }
  };

  const handleDelete = () => {
    if (props.onDelete) {
      props.onDelete();
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(props.request.response);
    notification.success({
      message: 'Success!',
      description: 'Copied to clipboard',
      placement: 'topRight',
      duration: 2,
    });
  };

  const processText = (text: string) => {
    if (!text) return '';
    return text
      .replace(/^[\n===]+/, '')
      .replace(/\n{2,}/g, '\n')
      .replace(/===/, '<br><hr>')
      .replace(/\n/g, '<br>')
      .replace(/<LINK>/g, '{link}');
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2 flex-wrap">
          <button
            onClick={() => setShowPrompt(!showPrompt)}
            className="flex items-center gap-2 bg-transparent border-none cursor-pointer p-0 hover:opacity-80"
          >
            <ChevronDown
              className={`text-muted-foreground h-4 w-4 transition-transform ${
                showPrompt ? 'rotate-180' : ''
              }`}
            />
            <span className="text-sm font-medium text-muted-foreground">
              Prompt
            </span>
          </button>
        </div>
      </div>

      {showPrompt && (
        <div className="text-sm text-muted-foreground bg-muted/30 p-2 rounded mb-4">
          {props.request.request}
        </div>
      )}

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        className="flex gap-4"
      >
        <div className="flex flex-col w-full group">
          <div className="px-4 py-3 space-y-4 relative">
            <p className="whitespace-pre-line prose max-w-none">
              <span
                dangerouslySetInnerHTML={{
                  __html: processText(props.request.response),
                }}
              />
            </p>
          </div>
          <div className="opacity-0 group-hover:opacity-100 transition-opacity flex gap-1 mt-2">
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={handleCopyToClipboard}
            >
              <Copy className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={() => handleLike(hasLiked === 1 ? 0 : 1)}
            >
              <ThumbsUp
                className="h-4 w-4"
                fill={hasLiked === 1 ? 'black' : 'transparent'}
              />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={() => handleLike(hasLiked === -1 ? 0 : -1)}
            >
              <ThumbsDown
                className="h-4 w-4"
                fill={hasLiked === -1 ? 'black' : 'transparent'}
              />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={props.onRegenerate}
            >
              <RefreshCw className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-8 w-8"
              onClick={handleDelete}
            >
              <X className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default CopyCraftChatCard;
