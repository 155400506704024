import { ReactComponent as ProductsIcon } from 'Assets/icons/sideMenu/products.svg';
import { ReactComponent as SpentIcon } from 'Assets/icons/statsRevenue.svg';
import { ReactComponent as Email } from 'Assets/icons/email.svg';
import { ReactComponent as Book } from 'Assets/icons/changePageBook.svg';
import { ReactComponent as AIICon } from 'Assets/icons/ai.svg';
import { ReactComponent as PersonIcon } from 'Assets/icons/person.svg';
import { ReactComponent as PhoneIcon } from 'Assets/icons/phone.svg';
import { ReactComponent as PodcastIcon } from 'Assets/icons/changePodcast.svg';
import { ReactComponent as MailSequenceIcon } from 'Assets/icons/mailSequence.svg';
import { ReactComponent as TemplatesIcon } from 'Assets/icons/appIcons/templates.svg';
import { ReactComponent as UsageIcon } from 'Assets/icons/usage.svg';

import styles from './Wallet.module.scss';

export const balanceDropdownItems = [
  {
    value: 10,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>10
      </span>
    ),
  },
  {
    value: 25,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>25
      </span>
    ),
  },
  {
    value: 100,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>100
      </span>
    ),
  },
  {
    value: 500,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>500
      </span>
    ),
  },
  {
    value: 1000,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>1000
      </span>
    ),
  },
  {
    value: 5000,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>5000
      </span>
    ),
  },
  {
    value: 10000,
    label: (
      <span className={styles.dropdownOptionLabel}>
        <span className={styles.dollar}>$ </span>10000
      </span>
    ),
  },
];

export const timeSortItems = [
  {
    value: 'current_month',
    label: 'Current Month',
  },
  {
    value: 'last_month',
    label: 'Last Month',
  },
  {
    value: 'two_month_ago',
    label: '2 Month Ago',
  },
];

export const tableItems = [
  {
    label: 'Service',
    value: 'service',
    icon: <ProductsIcon className={styles.itemIcon} />,
    className: 'w-fit',
  },
  {
    label: 'Usage',
    value: 'usage',
    icon: <UsageIcon className={styles.itemIcon} />,
    className: 'w-fit',
  },
  {
    label: 'Cost',
    value: 'amount',
    icon: <SpentIcon className={styles.itemIcon} />,
    className: 'justify-end mr-[10px]',
  },
];

export const services = [
  {
    key: 'I_EML_MESSAGES',
    name: 'Email Sends',
    usage: 'Sent',
    icon: <Email className={styles.serviceIcon} />,
  },
  {
    key: 'BOOKLE_LARGE_BOOK',
    name: 'Books',
    usage: 'Created',
    icon: <Book className={styles.serviceIcon} />,
  },
  {
    key: 'AI_CREDITS',
    name: 'AI Generation',
    usage: 'Used',
    icon: <AIICon className={styles.serviceIcon} />,
  },
  {
    key: 'CONTACTS',
    name: 'Contacts',
    usage: 'Total',
    icon: <PersonIcon className={styles.serviceIcon} />,
  },
  {
    key: 'SMS_MESSAGES',
    name: 'SMS',
    usage: 'Used',
    icon: <PhoneIcon className={styles.serviceIcon} />,
  },
  {
    key: 'PRO_PODCASTS',
    name: 'Pro Podcasts',
    usage: 'Used',
    icon: <PodcastIcon className={styles.serviceIcon} />,
  },
  {
    key: 'I_AUTORESPONDERS',
    name: 'Autoresponders',
    usage: 'Used',
    icon: <MailSequenceIcon className={styles.serviceIcon} />,
  },
  {
    key: 'I_WORKFLOWS',
    name: 'Workflows',
    usage: 'Used',
    icon: <TemplatesIcon className={styles.serviceIcon} />,
  },
];

export interface IUsageDetail {
  amount: number;
  count: number;
  limit: number;
  serviceName: string;
  usage: string;
}
