import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as CartIcon } from 'Assets/icons/cart.svg';
import { ReactComponent as WaveHandIcon } from 'Assets/icons/waveHand.svg';

export interface ICampaign {
  id: number;
  title: string;
  description: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconColor: string;
  tag: string | null;
  templateId?: string;
}

export const campaigns: ICampaign[] = [
  {
    id: 1,
    title: 'Start from Scratch',
    description: 'Build your own workflow from scratch.',
    icon: PlusIcon,
    iconColor: '#d0d0d0',
    tag: null,
  },
  {
    id: 2,
    title: 'AI Message Series',
    description: 'User added to your contacts or to a specific smart list.',
    icon: WaveHandIcon,
    iconColor: '#4719ff',
    tag: 'For Customers',
    templateId: '672a055e980031f7267c142d',
  },
  {
    id: 3,
    title: 'AI Promo Series',
    description:
      'Submit the details of the purchased item and ask for feedback after some time.',
    icon: CartIcon,
    iconColor: '#25bb73',
    tag: 'For Customers',
    templateId: '672a0016980031f7267c071a',
  },
];
