import { BaseText } from 'slate';

export interface CustomText extends BaseText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  color?: string;
  font?: string;
  weight?: string;
  fontSize?: string;
  lineHeight?: string;
  link?: string;
  textShadow?: string;
  letterSpacing?: string;
}

export type CustomElement = {
  type:
    | 'paragraph'
    | 'block-quote'
    | 'bulleted-list'
    | 'list-item'
    | 'number-list'
    | 'list-item';
  align?: string;
  lineHeight?: string;
  children: CustomText[];
};

export const LIST_TYPES = ['number-list', 'bulleted-list'];
export const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
