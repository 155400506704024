import { useEffect, useState } from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import { Switch } from 'RadixUI/switch';
import { BookleTemplateBlock } from 'types';
import { getBorderProperties } from './helpers';
import { cn } from 'utils/Utils';
import ColorSelector from 'Components/ColorSelector/ColorSelector';

const shadowPresets = {
  none: 'none',
  sm: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
  base: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
  md: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
  lg: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  xl: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
  '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
};

interface IProps {
  element: BookleTemplateBlock | null;
  onUpdate: (key: string, value: string | boolean) => void;
  onUpdateStyle: (key: string, value: string | number | any) => void;
}

const paddings = [
  { name: 'top', value: 'paddingTop' },
  { name: 'left', value: 'paddingLeft' },
  { name: 'right', value: 'paddingRight' },
  { name: 'bottom', value: 'paddingBottom' },
];

const margins = [
  { name: 'top', value: 'marginTop' },
  { name: 'left', value: 'marginLeft' },
  { name: 'right', value: 'marginRight' },
  { name: 'bottom', value: 'marginBottom' },
];

export const BlockControls = ({ element, onUpdate, onUpdateStyle }: IProps) => {
  const [border, setBorder] = useState({
    width: 0,
    style: '',
    color: '',
  });

  useEffect(() => {
    if (element?.styles?.containerBorder) {
      setBorder(getBorderProperties(element?.styles?.containerBorder));
    } else {
      setBorder({
        width: 0,
        style: '',
        color: '',
      });
    }
  }, [element?.styles?.containerBorder]);

  if (!element) {
    return null;
  }

  const getCurrentShadowPreset = () => {
    const currentShadow = '';
    const preset = Object.entries(shadowPresets).find(
      ([_, value]) => value === currentShadow
    );
    return preset ? preset[0] : 'custom';
  };

  const handleChangeBlockSizes = (key: string, value: string) => {
    if (Number(value) < 1000 && Number(value) >= 0) {
      const blockSpacing = {
        ...element.styles?.blockSpacing,
        [key]: parseInt(value, 10),
      };
      onUpdateStyle('blockSpacing', blockSpacing);
    }
  };

  return (
    <AccordionItem value="box-model" className="border-none">
      <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
        Layout & Spacing
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          {/* Spacing Controls */}
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <Label className="text-xs font-medium">Padding</Label>
              <Switch
                checked={element.hasPadding}
                onCheckedChange={(checked) => onUpdate('hasPadding', checked)}
              />
            </div>
            {element.hasPadding && (
              <div className="space-y-2">
                <div className="grid grid-cols-2 gap-2">
                  {paddings.map((side, index) => {
                    let gridPosition = '';

                    switch (index) {
                      case 0:
                        gridPosition =
                          'col-span-2 row-start-1 flex justify-center';
                        break;
                      case 1:
                        gridPosition = 'col-start-1 row-start-2';
                        break;
                      case 2:
                        gridPosition = 'col-start-2 row-start-2';
                        break;
                      case 3:
                        gridPosition =
                          'col-span-2 row-start-3 flex justify-center';
                        break;
                    }

                    return (
                      <div
                        key={side.name}
                        className={cn(
                          'space-y-1 max-w-[112px] relative w-full mx-auto',
                          gridPosition
                        )}
                      >
                        <Label className="text-[10px] capitalize text-muted-foreground absolute left-[10px] top-[50%] translate-y-[-40%]">
                          {side.name}
                        </Label>
                        <Input
                          type="number"
                          min="0"
                          max="999"
                          value={
                            element.styles?.blockSpacing?.[
                              side.value as keyof typeof element.styles.blockSpacing
                            ] || 0
                          }
                          onChange={(e) =>
                            handleChangeBlockSizes(side.value, e.target.value)
                          }
                          className="bg-background/50 focus:bg-background transition-colors px-[5px] text-end"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <Label className="text-xs font-medium">Margin</Label>
              <Switch
                checked={element.hasMargin}
                onCheckedChange={(checked) => onUpdate('hasMargin', checked)}
              />
            </div>
            {element.hasMargin && (
              <div className="space-y-2">
                <div className="grid grid-cols-2 gap-2">
                  {margins.map((side, index) => {
                    let gridPosition = '';

                    switch (index) {
                      case 0:
                        gridPosition =
                          'col-span-2 row-start-1 flex justify-center';
                        break;
                      case 1:
                        gridPosition = 'col-start-1 row-start-2';
                        break;
                      case 2:
                        gridPosition = 'col-start-2 row-start-2';
                        break;
                      case 3:
                        gridPosition =
                          'col-span-2 row-start-3 flex justify-center';
                        break;
                    }

                    return (
                      <div
                        key={side.name}
                        className={cn(
                          'space-y-1 max-w-[112px] relative w-full mx-auto',
                          gridPosition
                        )}
                      >
                        <Label className="text-[10px] capitalize text-muted-foreground absolute left-[10px] top-[50%] translate-y-[-40%]">
                          {side.name}
                        </Label>
                        <Input
                          type="number"
                          min="0"
                          max="999"
                          value={
                            element.styles?.blockSpacing?.[
                              side.value as keyof typeof element.styles.blockSpacing
                            ] || 0
                          }
                          onChange={(e) =>
                            handleChangeBlockSizes(side.value, e.target.value)
                          }
                          className="bg-background/50 focus:bg-background transition-colors px-[5px] text-end"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          {/* Background */}
          <div className="space-y-2">
            <Label className="text-xs font-medium">Background</Label>
            <div className="flex items-center justify-between">
              <Label className="text-xs">Enable Background</Label>
              <Switch
                checked={element.hasColor}
                onCheckedChange={(checked) => onUpdate('hasColor', checked)}
              />
            </div>
            {element?.hasColor && (
              <div className="flex gap-2 mt-2 items-center">
                <ColorSelector
                  color={element.color || '#ffffff'}
                  onChange={(color) => onUpdate('color', color)}
                />
                <Input
                  type="text"
                  value={element.color || '#ffffff'}
                  onChange={(e) => onUpdate('color', e.target.value)}
                  placeholder="#ffffff or rgb(255,255,255)"
                  className="flex-1 bg-background/50 focus:bg-background transition-colors"
                />
              </div>
            )}
          </div>

          {/* Box Shadow */}
          {/* <div className="space-y-4">
            <Label className="text-xs font-medium">Box Shadow</Label>
            <div className="grid grid-cols-3 gap-2">
              {Object.entries(shadowPresets).map(([key, value]) => (
                <div
                  key={key}
                  className={cn(
                    'h-16 rounded-lg border bg-background cursor-pointer transition-all duration-200',
                    'hover:border-primary/50'
                    //   element.style.boxShadow === value && "border-primary ring-1 ring-primary",
                  )}
                  style={{ boxShadow: value }}
                  // onClick={() => onUpdate({
                  //   style: { ...element.style, boxShadow: value }
                  // })}
                >
                  <div className="h-full flex items-center justify-center">
                    <span className="text-xs text-muted-foreground capitalize">
                      {key === '2xl' ? '2XL' : key}
                    </span>
                  </div>
                </div>
              ))}
            </div>

            {getCurrentShadowPreset() === 'custom' && (
              <div className="space-y-4 pt-4">
                <div className="space-y-2">
                  <Label className="text-xs font-medium">Shadow Color</Label>
                  <div className="flex gap-2">
                    <Input
                      type="color"
                      // value={element.style.boxShadow?.match(/rgb\([^)]+\)/)?.[0] || "rgba(0,0,0,0.1)"}
                      // onChange={(e) => {
                      //   const color = e.target.value;
                      //   onUpdate({
                      //     style: {
                      //       ...element.style,
                      //       boxShadow: `0 4px 6px -1px ${color}, 0 2px 4px -2px ${color}`
                      //     }
                      //   });
                      // }}
                      className="w-12 h-12 p-1 rounded-lg bg-background/50 focus:bg-background transition-colors"
                    />
                    <Input
                      type="text"
                      // value={element.style.boxShadow}
                      // onChange={(e) => onUpdate({
                      //   style: { ...element.style, boxShadow: e.target.value }
                      // })}
                      placeholder="e.g. 0 4px 6px -1px rgba(0,0,0,0.1)"
                      className="flex-1 bg-background/50 focus:bg-background transition-colors"
                    />
                  </div>
                </div>
              </div>
            )}
          </div> */}

          {/* Border */}
          <div className="space-y-2">
            <Label className="text-xs font-medium">Border</Label>
            <div className="grid grid-cols-3 gap-2">
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Width
                </Label>
                <Input
                  type="number"
                  min="0"
                  max="99"
                  value={border.width}
                  onChange={(e) => {
                    const newValue = Number(e.target.value);
                    const newStyle =
                      newValue > 0 && !border.style ? 'solid' : border.style;
                    onUpdateStyle(
                      'containerBorder',
                      `${newValue}px ${newStyle} ${border.color}`
                    );
                  }}
                  className="bg-background/50 focus:bg-background transition-colors"
                />
              </div>
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Style
                </Label>
                <Select
                  value={Number(border.width) === 0 ? 'none' : border.style}
                  onValueChange={(value) => {
                    let newWidth = border.width;
                    if (value === 'none') {
                      newWidth = 0;
                    } else if (border.width === 0) {
                      newWidth = 1;
                    }
                    onUpdateStyle(
                      'containerBorder',
                      `${newWidth}px ${value} ${border.color}`
                    );
                  }}
                >
                  <SelectTrigger className="bg-background/50 focus:bg-background transition-colors">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">None</SelectItem>
                    <SelectItem value="solid">Solid</SelectItem>
                    <SelectItem value="dashed">Dashed</SelectItem>
                    <SelectItem value="dotted">Dotted</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-1.5">
                <Label className="text-[10px] uppercase text-muted-foreground">
                  Color
                </Label>
                <div className="flex gap-1">
                  <Input
                    type="color"
                    value={border.color}
                    onChange={(e) =>
                      onUpdateStyle(
                        'containerBorder',
                        `${border.width}px ${border.style} ${e.target.value}`
                      )
                    }
                    className="w-full p-1 rounded-lg bg-background/50 focus:bg-background transition-colors"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Border Radius */}
          <div className="space-y-2">
            <Label className="text-xs font-medium">Border Radius</Label>
            <Input
              type="number"
              min="0"
              max="99"
              value={element.styles?.containerBorderRadius}
              onChange={(e) =>
                onUpdateStyle('containerBorderRadius', Number(e.target.value))
              }
              placeholder="0-99px"
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>

          {/* Opacity */}
          <div className="space-y-2">
            <Label className="text-xs font-medium">Opacity</Label>
            <Input
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={element.styles?.opacity}
              onChange={(e) => onUpdateStyle('opacity', Number(e.target.value))}
              placeholder="0 to 1"
              className="bg-background/50 focus:bg-background transition-colors"
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
