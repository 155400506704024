import clsx from 'clsx';
import Button from 'UILib/Button/Button';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';

import styles from './EditHeader.module.scss';

interface IProps {
  showConfirmButton: boolean;
  handleConfirm: () => void;
  title?: string;
  pageName: string;
  customHeader?: JSX.Element;
  showCustomHeader?: boolean;
  handleGoBack: () => void;
  buttonPlaceholder: string | JSX.Element;
  additionalButtons?: JSX.Element;
  className?: string;
  customHeaderPlace?: 'left' | 'right';
  editableTitle?: boolean;
  onTitleChange?: (value: string) => void;
}

const EditHeader = ({
  showConfirmButton,
  handleConfirm,
  title,
  pageName,
  customHeader,
  showCustomHeader,
  handleGoBack,
  buttonPlaceholder,
  additionalButtons,
  className,
  customHeaderPlace = 'left',
  editableTitle,
  onTitleChange,
}: IProps) => {
  return (
    <div className={clsx(styles.header, className)}>
      <div className={styles.metadata}>
        <Button
          appearance="stroke"
          width={40}
          height={40}
          prefixIcon={<ArrowLeft width={14} height={14} />}
          onClick={handleGoBack}
        />
        {showCustomHeader && customHeader && customHeaderPlace === 'left'
          ? customHeader
          : title && (
              <div className={styles.titleContainer}>
                <span className={styles.label}>{title}: </span>
                {editableTitle ? (
                  <EditableLabel
                    value={pageName}
                    onChange={(e) => onTitleChange && onTitleChange(e)}
                  />
                ) : (
                  `${pageName} `
                )}
              </div>
            )}
      </div>
      {showCustomHeader && customHeader && customHeaderPlace === 'right' ? (
        customHeader
      ) : (
        <div className={styles.buttonsContainer}>
          {additionalButtons && additionalButtons}
          {showConfirmButton && (
            <Button width={160} height={40} onClick={handleConfirm}>
              {buttonPlaceholder}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default EditHeader;
