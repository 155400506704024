import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ISender } from 'types';
import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import { ReactComponent as ProfileIcon } from 'Assets/icons/profile.svg';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/checkMarkInnerCircle.svg';
import { RootState } from 'store/rootStore';
import {
  fetchSenders,
  fetchMailDomains,
  sendersPopupAction,
} from 'store/senders/sendersActions';
import Button from 'UILib/Button/Button';
import Loader from 'UILib/Loader/Loader';
import SenderItem from './SenderItem/SenderItem';

import s from './Senders.module.scss';

interface IProps {
  senders: ISender[];
  isLoading: boolean;
  fetchSenders: () => void;
  fetchMailDomains: () => void;
  toggleAddSenderPopup: (payload: boolean) => void;
}

const Senders = ({
  toggleAddSenderPopup,
  fetchMailDomains,
  fetchSenders,
  isLoading,
  senders,
}: IProps) => {
  useEffect(() => {
    fetchSenders();
    fetchMailDomains();
  }, [fetchSenders, fetchMailDomains]);

  return (
    <div className={s.blockWrapper}>
      <div className={s.header}>
        <div>
          <h2 className={s.title}>Verified Sending Domains</h2>
          <div className={s.subtitle}>
            Authenticated domains and email addresses for sending email
            campaigns
          </div>
        </div>
        {!isLoading && (
          <Button
            width={220}
            height={40}
            className={s.addFirstDomainBtn}
            postfixIcon={<PlusIcon />}
            onClick={() => toggleAddSenderPopup(true)}
          >
            {senders.length > 0
              ? 'Add Sending Domain'
              : 'Add First Sending Domain'}
          </Button>
        )}
      </div>

      {isLoading ? (
        <div className={s.loaderContainer}>
          <Loader />
        </div>
      ) : senders.length === 0 ? (
        <div className={s.addFirstSenderWrapper}>
          <p className={s.tipText}>
            Your account has no connected sending domains. You can connect the
            first one by clicking the button above
          </p>
        </div>
      ) : (
        <div className={s.sendersBlockWrapper}>
          <div className={s.sendersHeaderBlock}>
            <div>
              <ProfileIcon />
              Sending Domain Name
            </div>
            <div>
              <MailIcon /> Email
            </div>
            <div>
              <CheckMarkIcon /> Status
            </div>
            <div></div>
          </div>
          <div className={s.sendersWrapper}>
            {senders.map((sender: ISender) => (
              <SenderItem
                key={sender._id}
                name={sender.name}
                id={sender._id}
                email={sender.email}
                domain={sender.mailDomain}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  senders: state.senders.senders,
  isLoading: state.senders.loading,
});

const mapDispatchToProps = {
  fetchSenders: () => fetchSenders(),
  fetchMailDomains: () => fetchMailDomains(),
  toggleAddSenderPopup: (payload: boolean) => sendersPopupAction(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(Senders);
