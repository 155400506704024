import React from 'react';
import { Button } from 'RadixUI/button';
import {
  ChevronLeft,
  RotateCcw,
  RotateCw,
  Mail,
  Save,
  ArrowRight,
  Settings,
} from 'lucide-react';

interface BroadcastEditorHeaderProps {
  // Define any props that this component might need
  title: string;
  onSave: () => void;
  onTestEmail: () => void;
  onOpenSettings: () => void;
  onContinue: () => void;
  onUndo: () => void;
  onRedo: () => void;
}

const BroadcastEditorHeader: React.FC<BroadcastEditorHeaderProps> = ({
  title,
  onSave,
  onTestEmail,
  onOpenSettings,
  onContinue,
  onRedo,
  onUndo,
}) => {
  return (
    <header className="sticky top-0 z-50 border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-14 items-center gap-4 px-6">
        <Button
          variant="ghost"
          size="icon"
          className="h-8 w-8 rounded-lg hover:bg-primary/10 hover:text-primary transition-colors"
          title="Back to Templates"
          asChild
        >
          <a href="/console/outreach/broadcasts">
            <ChevronLeft className="h-5 w-5" />
          </a>
        </Button>

        <div className="h-5 w-px bg-border/40" />
        <h1 className="text-sm font-medium">{title}</h1>

        <div className="flex-1" />

        <div className="flex items-center gap-3">
          <div className="h-5 w-px bg-border/40" />

          <div className="flex items-center gap-1.5 bg-muted/50 rounded-lg p-1">
            <Button
              variant="ghost"
              size="icon"
              className="h-7 w-7 rounded-md"
              title="Undo"
              onClick={onUndo}
            >
              <RotateCcw className="h-4 w-4" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-7 w-7 rounded-md"
              title="Redo"
              onClick={onRedo}
            >
              <RotateCw className="h-4 w-4" />
            </Button>
          </div>

          <Button
            variant="outline"
            size="icon"
            className="h-8 w-8"
            title="Global Settings"
            onClick={onOpenSettings}
          >
            <Settings className="h-4 w-4" />
          </Button>

          <Button
            variant="outline"
            size="sm"
            className="h-8 text-xs"
            onClick={onTestEmail}
          >
            <Mail className="h-3.5 w-3.5 mr-2" />
            Test Email
          </Button>
          <Button
            variant="outline"
            size="sm"
            className="h-8 text-xs"
            onClick={onSave}
          >
            <Save className="h-3.5 w-3.5 mr-2" />
            Save
          </Button>
          <Button
            variant="default"
            size="sm"
            className="h-8 text-xs font-medium"
            onClick={onContinue}
          >
            Continue
            <ArrowRight className="h-3.5 w-3.5 ml-2" />
          </Button>
        </div>
      </div>
    </header>
  );
};

export default BroadcastEditorHeader;
