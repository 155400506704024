export const UPDATE_BROADCAST_MUTATION = {
  operationName: 'updateBroadcast',
  query: `mutation updateBroadcast($id: String!, $name: String, $smartListId: String, $senderId: String, $mailSubject: String, $mailDraftId: String, $sendAt: Float, $tagsNames: [String!]) {
            updateBroadcast(id: $id, name: $name, smartListId: $smartListId, senderId: $senderId, mailSubject: $mailSubject, mailDraftId: $mailDraftId, sendAt: $sendAt, tagsNames: $tagsNames) {
                  _id
                  name
                  workflow { _id }
                  sender { _id }
                  mailDraft { _id }
                  sendAt
                  nextSendAt
                  status
                  createdAt
                  updatedAt
            }
      }`,
};
