import { useEffect, useState } from 'react';
import { ReactComponent as BackArrowSvg } from 'Assets/icons/backArrow.svg';
import { ReactComponent as SenderIcon } from 'Assets/icons/blankThumbnail.svg';
import { ReactComponent as ExitTag } from 'Assets/icons/tagRemove.svg';
import { ReactComponent as EntryTag } from 'Assets/icons/tagAdd.svg';
import { ReactComponent as Settings } from 'Assets/icons/settingsIconsWhite.svg';
import { ReactComponent as Attention } from 'Assets/icons/attention2.svg';
import { ReactComponent as MailSequenceIcon } from 'Assets/icons/mailSequence.svg';
import { IAutoResponder } from 'types';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Link from 'UILib/Link/Link';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';

import styles from './AutoResponderDetails.module.scss';

interface IProps {
  handleUpdateResponderLogo: (image: File) => void;
  onOpenSettings: () => void;
  autoResponder?: IAutoResponder;
}

const AutoResponderDetailHeader = ({
  handleUpdateResponderLogo,
  onOpenSettings,
  autoResponder,
}: IProps) => {
  const [autoResponderName, setAutoResponderName] = useState<string>('');
  const [isLive, setIsLive] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (autoResponder) {
      setAutoResponderName(autoResponder.name);
      setIsLive(autoResponder.isActive);
    }
  }, [autoResponder]);

  const handleUpdateAutoResponder = async ({ ...values }) => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.UPDATE_AUTO_RESPONDER,
        values: {
          id: autoResponder?._id,
          ...values,
        },
        headerType: 'USER-AUTH',
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={styles.bannerContainer}
      style={{
        backgroundImage: autoResponder?.thumbnailUrl,
      }}
    >
      <div className={styles.banner} />
      <div className={styles.bannerInfo}>
        {autoResponder?.thumbnailUrl ? (
          <div className={styles.image}>
            <img
              className={styles.previewImage}
              src={autoResponder?.thumbnailUrl}
              alt="preview"
              onLoad={() => setIsImageLoading(false)}
              onError={() => setIsImageLoading(false)}
              style={{ display: isImageLoading ? 'none' : 'block' }}
            />
            {isImageLoading && <CircleLoader color="#d0d0d0" />}
          </div>
        ) : (
          <div className={styles.cover}>
            <MailSequenceIcon className={styles.mailSequenceIcon} />
          </div>
        )}
        <div className={styles.projectInfo}>
          <Link
            to="/console/outreach/auto-responders"
            className={styles.backLink}
            prefixIcon={<BackArrowSvg />}
          >
            Back to projects
          </Link>
          <EditableLabel
            value={autoResponderName}
            onChange={setAutoResponderName}
            className={styles.editable}
            onOutsideClick={() => {
              if (autoResponderName === autoResponder?.name) return;
              handleUpdateAutoResponder({ name: autoResponderName });
            }}
          />
          <div className={styles.info}>
            <SenderIcon className={styles.icon} />
            Sender:
            <span className={styles.infoName}>
              {autoResponder?.sender?.email || 'Not Selected'}
            </span>
          </div>
          <div className={styles.tagsInfo}>
            <div className={styles.info}>
              <EntryTag className={styles.icon} />
              Entry:
              <span className={styles.infoName}>
                {autoResponder?.entryTag?.name || 'Not Selected'}
              </span>
            </div>
            <div className={styles.info}>
              <ExitTag className={styles.icon} />
              Exist:
              <span className={styles.infoName}>
                {autoResponder?.exitTag?.name || 'Not Selected'}
              </span>
            </div>
          </div>
          <div className={styles.toggleContainer}>
            Live{' '}
            <ToggleSwitch
              isBinary
              checkValue={isLive}
              onToggle={(value) => {
                setIsLive(value);
                handleUpdateAutoResponder({ isLive: value });
              }}
            />
          </div>
        </div>
        <div className={styles.settings} onClick={onOpenSettings}>
          Project Settings
          <div className={styles.settingsIcons}>
            <Settings className={styles.settingsIcon} />
            {(!autoResponder?.entryTag?._id ||
              !autoResponder.exitTag?._id ||
              !autoResponder.sender?.email) && (
              <Attention className={styles.attention} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoResponderDetailHeader;
