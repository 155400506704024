import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import { BookleTemplateBlock } from 'types';

interface IProps {
  element: BookleTemplateBlock;
  onUpdateStyle: (key: string, value: string | number) => void;
}

export const SpacerControls = ({ element, onUpdateStyle }: IProps) => {
  return (
    <AccordionItem value="spacer" className="border-none">
      <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
        Spacer Settings
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          <Label className="text-xs font-medium">Height</Label>
          <div className="flex items-center space-x-2">
            <Input
              type="number"
              min="0"
              value={element.styles?.containerHeight}
              onChange={(e) =>
                onUpdateStyle('containerHeight', Number(e.target.value))
              }
              className="bg-background/50 focus:bg-background transition-colors"
            />
            <span className="text-sm text-muted-foreground self-center">
              px
            </span>
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
