import React from 'react';
import { ReactComponent as UserIcon } from 'Assets/icons/blankThumbnail.svg';
import { ISequence } from 'types';

import styles from './AutoResponderSendsEmailIndicator.module.scss';

interface IProps {
  sequences: ISequence[];
}

const AutoResponderSendsEmailIndicator = ({ sequences }: IProps) => {
  return (
    <div className={styles.container}>
      {sequences?.map(({ wait }, index) => (
        <React.Fragment key={index}>
          <div className={styles.circleContent}>
            <div className={styles.circle} />
            <div className={styles.stepLabel}>
              {wait.periodAmount} {wait.periodType}
            </div>
            {index !== 0 && (
              <div className={styles.users}>
                <UserIcon className={styles.userIcon} />
                {0}
              </div>
            )}
          </div>
          {index !== sequences.length - 1 && <div className={styles.line} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AutoResponderSendsEmailIndicator;
