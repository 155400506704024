import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { validateUser } from 'utils/Utils';
import burgerSvg from 'Assets/icons/burger.svg';
import SideMenu from 'Components/SideMenu/SideMenu';
import CopyCraftChat from 'Components/CopyCraft/CopyCraftChat3';
import CopyCraftDashboard from 'Components/CopyCraft/CopyCraftDashboard/CopyCraftDashboard';

import s from './PageCopyCraft.module.scss';

const PageCraftPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true);
  const isEditPage = location.pathname.includes('/edit');

  const topicId = queryParams.get('topicId');

  useEffect(() => {
    const userAuth = validateUser();
    if (!userAuth) {
      window.open('/console/login', '_self');
    }

    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <main className={s.mainContent}>
      <div
        className={clsx(s.sidebarToggleBtnBlock, {
          [s.sidebarOpen]: isSidebarOpen,
        })}
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        <img src={burgerSvg} alt="burger svg" />
      </div>
      <div
        className={clsx(s.content, {
          [s.sidebarClosed]: !isSidebarOpen,
        })}
      >
        <div
          className={clsx(s.menu, {
            [s.closeMenu]: !isSidebarOpen,
          })}
        >
          <SideMenu />
        </div>
        {isEditPage ? (
          <CopyCraftChat topicId={topicId || ''} />
        ) : (
          <CopyCraftDashboard />
        )}
      </div>
    </main>
  );
};

export default PageCraftPage;
