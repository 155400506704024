export const GET_USAGE_STATISTICS = {
  operationName: 'getUsageStatistics',
  query: `mutation getUsageStatistics ($startDate: DateTime!, $endDate: DateTime!) {
              getUsageStatistics(startDate: $startDate, endDate: $endDate) {
                  totalAmount
                  details {
                  serviceName
                  usage
                  amount
                  count
                  limit
                  }
            }
        }`,
};
