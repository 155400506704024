export const GET_AUTO_RESPONDER_SEQUENCE_ITEM_QUERY = {
  operationName: 'getAutoresponderSequenceItem',
  query: `query getAutoresponderSequenceItem ($autoresponderId: String!, $sequenceItemId: String!) {
          getAutoresponderSequenceItem(autoresponderId: $autoresponderId, sequenceItemId: $sequenceItemId) {
            mailDraft {
              _id
              data
              subject
              preheader
            }
            wait {
              seconds
              periodType
              periodAmount
            }
          }
        }`,
};
