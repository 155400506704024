import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { BaseSelection, Descendant, Editor } from 'slate';
import { ReactComponent as InfoIcon } from 'Assets/icons/info.svg';
import { ReactComponent as TitleIcon } from 'Assets/icons/Podcaster64.svg';
import { ReactComponent as Settings } from 'Assets/icons/settingsIconsWhite.svg';
import { IBook, IChapter, PageContainerState } from 'types';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import CoverActions from './AdditionalActions/CoverActions/CoverActions';
import ChangePaddingModal from 'Components/ChangePaddingModal/ChangePaddingModal';
import TextEditorToolbar from 'Components/TextEditorToolbar/TextEditorToolbar';
import EditorSidebar from 'Components/Common/EditorSidebar/EditorSidebar';
import EditHeader from 'Components/Common/EditHeader/EditHeader';
import TextEditor from 'Editors/TextEditor/TextEditor';
import FontManager from 'CoverEditor/FontManager';
import CoverEditor from 'CoverEditor/CoverEditor';
import Loader from 'UILib/Loader/Loader';
import queries from 'graphql/queries';

import styles from './EditBook.module.scss';

const EditBook = () => {
  const { bookId } = useParams<{ bookId: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [chapters, setChapters] = useState<
    (IChapter & { hasChanges?: boolean })[]
  >([]);
  const [book, setBook] = useState<IBook>();
  const [bookChapters, setBookChapters] = useState<Descendant[][]>([]);
  const [bookSection, setBookSections] = useState<any[]>();
  const [editor, setEditor] = useState<Editor | undefined>();
  const [selection, setSelection] = useState<BaseSelection | undefined>(
    undefined
  );
  const [coverData, setCoverData] = useState({});
  const [isPaddingModalOpen, setIsPaddingModalOpen] = useState<boolean>(false);
  const [pageSizes, setPageSizes] = useState<PageContainerState>({
    pl: '20',
    pt: '30',
    pr: '40',
    mt: '30',
    ml: '30',
    mb: '30',
    mr: '30',
  });

  const pagesRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    graphQlCall({
      queryTemplateObject: queries.GET_BOOK_FOR_EDIT_BY_ID,
      values: { id: bookId },
      headerType: 'USER-AUTH',
    })
      .then((data: IBook) => {
        if (!data) return;
        setChapters(data.chapters || []);
        setBookSections(data.data.blocks);
        setBook(data);
        setCoverData(data.coverData);
        if (data?.data?.pageSizes) {
          setPageSizes(data?.data?.pageSizes);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [bookId]);

  const applyInlineStyles = (
    text: string,
    inlineStyleRanges: any[],
    entityRanges: any[],
    entityMap: any,
    defaultFontSize: number
  ) => {
    const styledSections = [];
    const textLength = text.length;

    const charStyles: any = Array(textLength)
      .fill(null)
      .map(() => ({
        underline: false,
        italic: false,
        bold: false,
        color: undefined,
        font: undefined,
        fontSize: defaultFontSize,
        weight: 400,
        link: undefined,
      }));

    inlineStyleRanges.forEach((range: any) => {
      const start = range.offset;
      const end = start + range.length;

      for (let i = start; i < end; i++) {
        if (range.style.includes('UNDERLINE')) charStyles[i].underline = true;
        if (range.style.includes('ITALIC')) charStyles[i].italic = true;
        if (range.style.includes('BOLD')) charStyles[i].bold = true;
        if (range.style.includes('color-'))
          charStyles[i].color = `#${
            range.style.match(/color-#([0-9a-fA-F]{6})/)[1]
          }`;
        if (range.style.includes('weight-'))
          charStyles[i].weight = parseInt(range.style.match(/weight-(\d+)/)[1]);
        if (range.style.includes('font-')) {
          const font = range.style.match(/font-([A-Za-z0-9]+)/)[1];
          FontManager.getInstance().loadFont(
            FontFamilies.find((e) => e.value === font)?.label || '',
            charStyles[i].weight
          );
          charStyles[i].font = font;
        }
        if (range.style.includes('size-'))
          charStyles[i].fontSize = parseInt(
            range.style.match(/size-(\d+)px/)[1]
          );
      }
    });

    entityRanges?.forEach((range: any) => {
      const entity = entityMap[range.key];
      if (entity && entity.type === 'LINK') {
        const start = range.offset;
        const end = start + range.length;
        const linkUrl = entity.data.url;

        for (let i = start; i < end; i++) {
          charStyles[i].link = linkUrl;
        }
      }
    });

    let currentSection = { text: '', ...charStyles[0] };

    for (let i = 0; i < textLength; i++) {
      const currentChar = text[i];
      const currentCharStyle = charStyles[i];

      if (
        currentCharStyle.underline === currentSection.underline &&
        currentCharStyle.italic === currentSection.italic &&
        currentCharStyle.bold === currentSection.bold &&
        currentCharStyle.color === currentSection.color &&
        currentCharStyle.font === currentSection.font &&
        currentCharStyle.fontSize === currentSection.fontSize &&
        currentCharStyle.weight === currentSection.weight &&
        currentCharStyle.link === currentSection.link
      ) {
        currentSection.text += currentChar;
      } else {
        styledSections.push(currentSection);
        currentSection = { text: currentChar, ...currentCharStyle };
      }
    }

    styledSections.push(currentSection);

    return styledSections;
  };

  useEffect(() => {
    if (!bookSection || bookSection.length === 0) return;

    const mappedSections = bookSection.map((section, sectionIndex) => {
      if (section?.children) {
        const chapters = section?.children?.[0].children.map(
          (chapter: any, chapterIndex: number) => {
            if (chapter.type === 'Text') {
              const sectionPartsArray = chapter.text.value.blocks.map(
                (block: any, blockIndex: number) => {
                  const blockSections =
                    block.inlineStyleRanges.length || block.entityRanges.length
                      ? applyInlineStyles(
                          block.text,
                          block.inlineStyleRanges,
                          block.entityRanges,
                          chapter.text.value.entityMap,
                          chapter.textClass.includes('title') ? 40 : 25
                        )
                      : [{ text: block.text }];

                  return {
                    type:
                      block.type === 'unordered-list-item'
                        ? 'number-list'
                        : chapter.textClass.includes('title')
                        ? 'title'
                        : 'paragraph',
                    children: blockSections, // Use the styled sections
                    id: chapter.id, // Unique ID
                    align: block.type.includes('center')
                      ? 'center'
                      : block.type.includes('right')
                      ? 'right'
                      : block.type.includes('left')
                      ? 'left'
                      : 'center',
                  };
                }
              );

              return sectionPartsArray;
            } else if (chapter.type === 'Image') {
              return {
                type: 'image',
                src: chapter.imageUrl || chapter.src,
                children: [{ text: '' }],
              };
            } else {
              return null;
            }
          }
        );

        return chapters?.flat()?.filter(Boolean);
      } else {
        return section;
      }
    });

    setBookChapters(mappedSections);
  }, [bookSection]);

  const onSectionElementClick = (index: number) => {
    const chapter = document.getElementById('chapter ' + (index + 1));
    if (!chapter) return;

    chapter.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  };

  const handleGoBack = () => {
    history.push('/console/books');
  };

  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader color="#d0d0d0" />
        Loading...
      </div>
    );
  }

  const handleChange = (value: any, index: number) => {
    value.map((e: any) => {
      e.children.map((item: any) => {
        if (item.font) {
          FontManager.getInstance().loadFont(
            FontFamilies.find((e) => e.value === item.font)?.label || '',
            item.weight
          );
        }
      });
    });

    const updatedChapters = [...bookChapters];
    updatedChapters[index] = value;

    setBookChapters(updatedChapters);
  };

  const handleSetEditor = (item?: Editor, selection?: BaseSelection) => {
    setEditor(item);
    setSelection(selection);
  };

  const handleChangePageSize = (type: string, value: string) => {
    if (Number(value) < 100 && Number(value) > 0) {
      setPageSizes({ ...pageSizes, [type]: value });
    }
  };

  const handleSave = async () => {
    let values: any = {
      id: book?._id,
      data: JSON.stringify({ blocks: bookChapters, pageSizes }),
      title: book?.title,
      chapters: JSON.stringify(chapters),
      coverData: JSON.stringify(coverData),
    };

    await graphQlCall({
      queryTemplateObject: queries.UPDATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values,
    });
  };

  const onCoverChange = (field: string, value: string) => {
    setCoverData({ ...coverData, [field]: value });
  };

  return (
    <div className={styles.container}>
      <EditHeader
        showConfirmButton={true}
        pageName={book?.title || ''}
        title="Book name"
        handleConfirm={handleSave}
        handleGoBack={handleGoBack}
        buttonPlaceholder="Save"
        customHeader={
          <TextEditorToolbar editor={editor} selection={selection} />
        }
        showCustomHeader={!!editor}
      />
      <div className={styles.wrapper}>
        <EditorSidebar
          header={
            <div className={styles.titleContainer}>
              <div className={styles.sidebarTitle}>
                <TitleIcon />
                Episode Chapters
              </div>
              <InfoIcon />
            </div>
          }
          elements={chapters}
          elementName="chapter"
          isElementGenerating={false}
          onElementClick={onSectionElementClick}
          onAddButtonClick={() => {}}
          onElementTitleChange={() => {}}
          onElementDragEnd={(orderedSections) => {
            setChapters(orderedSections);
          }}
          borderType="round"
        />
        <div className={styles.contentWrapper}>
          <div
            className={styles.pageSettingsIcon}
            onClick={() => setIsPaddingModalOpen(true)}
          >
            <Settings />
          </div>
          <div className={styles.modalContainer}>
            <ChangePaddingModal
              open={isPaddingModalOpen}
              onChange={handleChangePageSize}
              onClose={() => setIsPaddingModalOpen(false)}
              pageSizes={pageSizes}
            />
          </div>
          <div className={styles.contentContainer}>
            <div className={styles.coverCoverContainer}>
              <CoverEditor
                width={820}
                height={1150}
                data={coverData || []}
                titles={(coverData as any)?.titles || []}
                onChangeData={(data) => setCoverData(data)}
              />
              <CoverActions cover={coverData} onChange={onCoverChange} />
            </div>
            <div ref={pagesRef}>
              {bookChapters.map((section, index) => {
                return (
                  <div key={index} className={styles.bookSection}>
                    <div
                      className={styles.textWrapper}
                      id={'chapter ' + (index + 1)}
                      style={{
                        paddingTop: pageSizes.pt + 'px',
                        paddingLeft: pageSizes.pl + 'px',
                        paddingRight: pageSizes.pr + 'px',
                        marginTop: pageSizes.mt + 'px',
                        marginLeft: pageSizes.ml + 'px',
                        marginRight: pageSizes.mr + 'px',
                        marginBottom: pageSizes.mb + 'px',
                      }}
                    >
                      <TextEditor
                        initialValue={section}
                        onChange={(value) => handleChange(value, index)}
                        setEditor={handleSetEditor}
                        canUpdateOnlyFirstParagraph
                      />
                    </div>
                    {index !== bookChapters.length - 1 && (
                      <hr className={styles.separator} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditBook;
