import { useEffect, useState } from 'react';
import { IMailDraft, ISequence } from 'types';
import clsx from 'clsx';
import Input from 'UILib/Input/Input';
import Dropdown from 'UILib/Dropdown/Dropdown';
import Button from 'UILib/Button/Button';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import Popup from 'UILib/Popup/Popup';

import styles from './SequenceItemSettingsModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  mailDraft: IMailDraft;
  sequenceWait: ISequence['wait'];
  onUpdateSequence: (values: {
    subject: string;
    preheader: string;
    waitPeriodType: string;
    waitPeriodAmount: number;
  }) => Promise<void>;
}

const timeDropDownOptions = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

const SequenceItemSettingsModal = ({
  open,
  onClose,
  mailDraft,
  sequenceWait,
  onUpdateSequence,
}: IProps) => {
  const [timeType, setTimeType] = useState<string>('days');
  const [subject, setSubject] = useState<string>('');
  const [preheader, setPreheader] = useState<string>('');
  const [delayTime, setDelayTime] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    if (!mailDraft || !sequenceWait || !open) return;

    setSubject(mailDraft.subject || '');
    setPreheader(mailDraft.preheader || '');
    setTimeType(sequenceWait.periodType || '');
    setDelayTime(sequenceWait.periodAmount?.toString() || '');
  }, [mailDraft, sequenceWait, open]);

  const handleSave = async () => {
    try {
      setSaving(true);
      await onUpdateSequence({
        subject,
        preheader,
        waitPeriodType: timeType,
        waitPeriodAmount: parseInt(delayTime),
      });
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <Popup onClose={onClose} wrapperClassName={styles.popup}>
      <div className={styles.contentContainer}>
        <div className={styles.title}>Settings</div>
        <div className={styles.inputsContainer}>
          <div className={styles.inputCOntainer}>
            <div className={styles.inputLabel}>Subject</div>
            <Input
              placeholder="Some Subject"
              border="stroke"
              className={styles.input}
              height={40}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </div>
          {/* <div className={styles.inputCOntainer}>
            <div className={styles.inputLabel}>Preheader</div>
            <Input
              placeholder="Some Preheader"
              border="stroke"
              className={styles.input}
              height={40}
              value={preheader}
              onChange={(e) => setPreheader(e.target.value)}
            />
          </div> */}
          <div className={styles.inputCOntainer}>
            <div className={styles.inputLabel}>Time Daley</div>
            <div className={styles.timeContainer}>
              <Input
                placeholder="Time"
                border="stroke"
                className={clsx(styles.input, styles.timeInput)}
                height={40}
                value={delayTime}
                onChange={(e) => setDelayTime(e.target.value)}
              />
              <Dropdown
                label="Days"
                options={timeDropDownOptions}
                onChange={setTimeType}
                className={styles.dropdown}
                value={timeType}
                type="stroke"
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button height={40} width={180} appearance="stroke" onClick={onClose}>
            Cancel Changes
          </Button>
          <Button
            height={40}
            width={180}
            disabled={saving}
            onClick={handleSave}
          >
            {saving ? <CircleLoader color="#ffffff" size={16} /> : 'Save'}
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default SequenceItemSettingsModal;
