import { ReactComponent as MailIcon } from 'Assets/icons/mail.svg';
import EmptyTemplates from 'Components/EmptyTemplates/EmptyTemplates';

import styles from './EmailTemplatesSelector.module.scss';

interface IProps {
  onClick: (templateId: string, isEmpty?: boolean) => void;
}

const EmailTemplatesSelector = (props: IProps) => {
  return (
    <>
      <div className={styles.pageHeader}>
        <MailIcon className={styles.mailIcon} />
        <div className={styles.title}>Choose Your Broadcast Type</div>
        <div className={styles.subtitle}>
          Quickly and easily create an email to suit your needs.
        </div>
      </div>
      <EmptyTemplates
        handleSelect={(item, empty) => props.onClick(item, empty)}
        className={styles.templates}
      />
    </>
  );
};

export default EmailTemplatesSelector;
