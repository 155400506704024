export const UPDATE_AUTO_RESPONDER_SEQUENCE_ITEM_MUTATION = {
  operationName: 'updateAutoresponderSequenceItem',
  query: `mutation updateAutoresponderSequenceItem (
    <KEYS>
    ) {
        updateAutoresponderSequenceItem(<VALUES>) {
          mailDraft {
            _id
            data
            subject
            preheader
          }
          wait {
            seconds
            periodType
            periodAmount
          }
        }
      }`,
};
