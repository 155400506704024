import { useState } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as Arrow } from 'Assets/icons/ArrowDown.svg';
import { ReactComponent as CheckMark } from 'Assets/icons/checkMark.svg';
import ContactsDetails, {
  addToOptionsEnum,
  whatDoYouWantEnum,
} from './ContactsDetails/ContactsDetails';
import { graphQlCall } from 'graphql/utils';
import { api, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { SmartList, Tag } from '../Helper/types';
import { DispatchType, RootState } from 'store/rootReducer';
import {
  cancelImportingContacts,
  updateImportingContacts,
} from 'store/contacts/contactsActions';
import clsx from 'clsx';
import queries from 'graphql/queries';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';
import ContactsColumns from './ContactsColumns/ContactsColumns';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import ProgressBar from 'UILib/ProgressBar/ProgressBar';
import UploadCsv from './UploadCsv/UploadCsv';

import styles from './ImportContacts.module.scss';

interface IProps {
  onClose: () => void;
  open: boolean;
  tags: Tag[];
  smartLists: SmartList[];
  handleAddNewTag: (tagName: string) => Promise<Tag>;
  fetchUserContacts: () => void;
  importingContacts: boolean;
  updateImportingContacts: (payload: boolean) => void;
  cancelUpload: () => void;
}

export interface TableRows {
  csvColumn: string;
  contactField: string;
}

const steps = [
  { key: 1, name: 'Upload' },
  { key: 2, name: 'Columns' },
  { key: 3, name: 'Details' },
];

const supportedContactFields = [
  'Full Name',
  'First Name',
  'Last Name',
  'Email',
  'Phone',
  'Country',
  'Address',
  'Tags',
];

function createMergedObject(data: string[][]): { [key: string]: string } {
  const keys = data[0];
  const finalObject: { [key: string]: string } = {};
  keys.forEach((key, index) => {
    for (let i = 1; i < data.length; i++) {
      const value = data[i][index];
      if (value) {
        finalObject[key] = value;
        break;
      }
    }
    if (!finalObject[key]) {
      finalObject[key] = '';
    }
  });
  return finalObject;
}

const ImportContacts = ({
  onClose,
  open,
  tags,
  smartLists,
  handleAddNewTag,
  fetchUserContacts,
  importingContacts,
  cancelUpload,
  updateImportingContacts,
}: IProps) => {
  const [step, setStep] = useState<number>(1);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadEta, setUploadEta] = useState(0);
  const [tableRows, setTableRows] = useState<TableRows[]>([]);
  const [previewData, setPreviewData] = useState({});
  const [addContactToOption, setAddContactToOption] = useState<string>(
    addToOptionsEnum.ALL
  );
  const [whatYouWantOption, setWhatYouWantOption] = useState<string>(
    whatDoYouWantEnum.ADD_NEW_AND_UPDATE
  );
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [newSmartListName, setNewSmartListName] = useState<string>('');
  const [smartListId, setSmartListId] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  function normalizeFieldName(str: string) {
    return str
      .toLowerCase()
      .replace(/[^\w\s]/g, '')
      .trim();
  }

  function getSimilarityScore(a: string, b: string) {
    const wordsA = a.split(/\s+/);
    const wordsB = b.split(/\s+/);
    let score = 0;
    for (const wA of wordsA) {
      for (const wB of wordsB) {
        if (wA.includes(wB) || wB.includes(wA)) {
          score += Math.min(wA.length, wB.length);
        }
      }
    }
    return score;
  }

  function findBestMatchField(inputField: string) {
    const normalizedInput = normalizeFieldName(inputField);
    let bestMatch: string | null = null;
    let highestScore = 0;
    for (const supportedField of supportedContactFields) {
      const normalizedSupported = normalizeFieldName(supportedField);
      const score = getSimilarityScore(normalizedInput, normalizedSupported);
      if (score > highestScore) {
        highestScore = score;
        bestMatch = supportedField;
      }
    }
    const THRESHOLD = 2;
    return highestScore >= THRESHOLD ? bestMatch : 'Do Not Import';
  }

  const handleNext = async () => {
    try {
      if (step === 1) {
        if (file) {
          setIsLoading(true);
          const formData = new FormData();
          formData.append('file', file);
          const data = await api(
            `${PAGECRAFT_API_URL}/contacts/preview-upload`,
            'POST',
            formData
          );
          const rows: any[] = [];
          for (const item of data.contacts[0]) {
            let field = findBestMatchField(item);
            const index = rows.findIndex(
              (row: any) => row.contactField === field
            );
            if (index >= 0) {
              field = 'Do Not Import';
            }
            if (field === 'Full Name') {
              const index = rows.findIndex(
                (row: any) =>
                  row.contactField === 'Last Name' ||
                  row.contactField === 'First Name'
              );
              if (index >= 0) {
                field = 'Do Not Import';
              }
            }
            rows.push({ csvColumn: item, contactField: field });
          }
          setTableRows(rows);
          setPreviewData(createMergedObject(data.contacts));
          setStep(step + 1);
        }
      } else {
        setStep(step + 1);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeContactsData = (
    index: number,
    key: string,
    value: string | boolean
  ) => {
    setTableRows((prevRows) =>
      prevRows.map((row, idx) =>
        idx === index ? { ...row, [key]: value } : row
      )
    );
  };

  const addNewTag = async (tagName: string) => {
    const response = await handleAddNewTag(tagName);
    setSelectedTags([...selectedTags, response]);
  };

  const handleAddTag = (tag: Tag) => {
    setSelectedTags((prevTags) => {
      const tagExists = prevTags.some(
        (existingTag) => existingTag._id === tag._id
      );
      return tagExists ? prevTags : [...prevTags, tag];
    });
  };

  const handleRemoveTag = (tagId: string) => {
    setSelectedTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
  };

  const handleAddContacts = async () => {
    try {
      setErrors({});
      updateImportingContacts(true);
      let smartList = '';
      if (addContactToOption === addToOptionsEnum.NEW_SMART_LIST) {
        if (!newSmartListName) {
          return setErrors({ smartListName: 'Smart List name is required' });
        }
        if (!selectedTags.length) {
          return setErrors({ tags: 'Tags are required' });
        }
        const newSmartList = await graphQlCall({
          queryTemplateObject: queries.CREATE_SMART_LIST,
          values: {
            name: newSmartListName,
            includeAll: selectedTags.map((tag) => tag._id),
          },
          headerType: 'USER-AUTH',
        });
        smartList = newSmartList._id;
      } else if (addContactToOption === addToOptionsEnum.EXISTING_SMART_LIST) {
        if (!smartListId) {
          return setErrors({ smartList: 'Smart List is required' });
        }
        smartList = smartListId;
      }

      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('tags', JSON.stringify(selectedTags));
        formData.append('smartListId', smartList);
        formData.append('smartListType', addContactToOption);
        formData.append('contactsColumns', JSON.stringify(tableRows));
        formData.append(
          'isUpdate',
          (
            whatYouWantOption === whatDoYouWantEnum.ADD_NEW_AND_UPDATE
          ).toString()
        );

        const response = await fetch(`${PAGECRAFT_API_URL}/contacts/upload`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: getToken(),
          },
        });

        if (response.body) {
          const reader = response.body.getReader();
          const decoder = new TextDecoder('utf-8');
          setStep(4);
          let finalStatus: string | null = null;
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value, { stream: true });
            const lines = chunk
              .split('\n')
              .filter((line) => line.trim() !== '');
            for (const line of lines) {
              if (line.includes('|')) {
                const [progress, eta] = line.split('|');
                setUploadProgress(parseInt(progress));
                setUploadEta(parseInt(eta));
              } else if (line === 'Cancelled' || line === 'Completed') {
                finalStatus = line;
              }
            }
          }
          if (finalStatus === 'Cancelled') {
            console.log('Upload was cancelled by the user.');
          } else if (finalStatus === 'Completed') {
            console.log('Upload completed successfully.');
          }
        }
        fetchUserContacts();
      }
      handleCloseModal();
    } catch (error) {
      console.error(error);
    } finally {
      updateImportingContacts(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setStep(1);
    setTableRows([]);
    setPreviewData({});
    setAddContactToOption(addToOptionsEnum.ALL);
    setWhatYouWantOption(whatDoYouWantEnum.ADD_NEW_AND_UPDATE);
    setSelectedTags([]);
    setNewSmartListName('');
    setSmartListId('');
    setFile(undefined);
  };

  const convertEtaToHuman = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes > 0
      ? `${minutes}m ${remainingSeconds}s`
      : `${remainingSeconds}s`;
  };

  if (!open) return null;

  return (
    <Popup onClose={handleCloseModal} wrapperClassName={styles.popup}>
      <div className={styles.container}>
        <div className={styles.title}>Import Contacts</div>
        <div className={styles.subtitle}>Complete three quick steps</div>
        <div className={styles.steps}>
          {steps.map((item) => (
            <div className={styles.stepItem} key={item.key}>
              <div
                className={clsx(styles.stepNumber, {
                  [styles.passedStep]: step > item.key,
                })}
              >
                {step > item.key ? <CheckMark /> : <span>{item.key}</span>}
              </div>
              <div className={styles.stepName}>{item.name}</div>
              {item.key !== 3 && <Arrow className={styles.arrow} />}
            </div>
          ))}
        </div>
        <div className={step === 2 ? styles.shadow : styles.divider} />
        {step === 1 && <UploadCsv file={file} setFile={setFile} />}
        {step === 2 && (
          <ContactsColumns
            data={tableRows}
            previewData={previewData}
            onChange={handleChangeContactsData}
          />
        )}
        {step === 3 && (
          <ContactsDetails
            contactOption={addContactToOption}
            onChangeContactOption={setAddContactToOption}
            whatYouWantOption={whatYouWantOption}
            onChangeWantOption={setWhatYouWantOption}
            tags={tags}
            smartLists={smartLists}
            selectedTags={selectedTags}
            handleAddTag={handleAddTag}
            handleRemoveTag={handleRemoveTag}
            onChangeSmartListName={setNewSmartListName}
            smartListName={newSmartListName}
            setSmartListId={setSmartListId}
            smartListId={smartListId}
            errors={errors}
            handleAddNewTag={addNewTag}
          />
        )}
        {step === 4 && (
          <div className={styles.progressBarContainer}>
            <div className={styles.bar}>
              <ProgressBar progress={uploadProgress} />
              <div className={styles.label}>
                Processing file. About {convertEtaToHuman(uploadEta)} left
              </div>
            </div>
          </div>
        )}
        <div
          className={
            step === 2
              ? clsx(styles.shadow, styles.bottom)
              : clsx(styles.divider, styles.secondDivider)
          }
        />
        <div className={styles.buttonsContainer}>
          {step > 1 && (
            <Button
              height={40}
              appearance="stroke"
              onClick={() => setStep(step - 1)}
              disabled={isLoading}
            >
              Previous
            </Button>
          )}
          {step < 3 ? (
            <Button
              height={40}
              appearance="highlighted"
              onClick={handleNext}
              disabled={isLoading}
            >
              {isLoading ? <CircleLoader color="#ffffff" size={16} /> : 'Next'}
            </Button>
          ) : (
            <>
              <Button
                height={40}
                onClick={handleAddContacts}
                disabled={importingContacts}
              >
                {importingContacts ? (
                  <CircleLoader color="#ffffff" size={16} />
                ) : (
                  'Add Contacts'
                )}
              </Button>
              <Button
                height={40}
                appearance="stroke"
                onClick={cancelUpload}
                disabled={!importingContacts}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  importingContacts: state.contact.importingContacts,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateImportingContacts: (payload: boolean) =>
    dispatch(updateImportingContacts(payload)),
  cancelUpload: () => dispatch(cancelImportingContacts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportContacts);
