import { IBookleTemplateBlockStyles } from 'store/books/booksReducer';
import { BookleTemplateBlock } from 'types';
import { MenuItems } from './Sidebar/Sidebar';
import { formatUrl } from 'utils/helpers';

export const addItemToContainer = (
  item: BookleTemplateBlock,
  blocks: Array<BookleTemplateBlock>,
  index?: number | null
): BookleTemplateBlock[] => {
  const newArray = [...blocks];

  let newItem = item.id
    ? findItemById(newArray, item.id, true) || item
    : createNewItem(item);

  if (typeof index === 'number') {
    newArray.splice(index, 0, newItem);
  } else {
    newArray.push(newItem);
  }

  return newArray;
};

export const getBlockSpacing = (type: string) => {
  const isTextOrButton =
    type === MenuItems.TEXT_BLOCK ||
    type === MenuItems.HEADING_BLOCK ||
    type === MenuItems.BUTTON_BLOCK;
  const isDivider = type === MenuItems.DIVIDER_BLOCK;

  return {
    paddingLeft: isTextOrButton || isDivider ? 30 : 0,
    paddingRight: isTextOrButton || isDivider ? 30 : 0,
    paddingTop: isTextOrButton ? 20 : isDivider ? 15 : 0,
    paddingBottom: isTextOrButton ? 20 : isDivider ? 15 : 0,
  };
};

const createNewItem = (item: any) => {
  const { type, isSpacer, styles, divider } = item;
  const newItem = {
    id: generateNewIndex(),
    type,
    hasPadding: [
      MenuItems.BUTTON_BLOCK,
      MenuItems.DIVIDER_BLOCK,
      MenuItems.TEXT_BLOCK,
      MenuItems.HEADING_BLOCK,
    ].includes(type),
    isSpacer,
    divider,
    styles: {
      ...styles,
      blockSpacing: getBlockSpacing(type),
    },
  };

  if (type?.includes('section')) {
    return {
      ...newItem,
      items: Array.from({ length: Number(type.split('_')[1]) }).map(() => ({
        id: generateNewIndex(),
        items: [],
      })),
    };
  }

  return newItem;
};

export function findItemById(
  items: any[],
  id: string,
  isRemove?: boolean
): any {
  if (!Array.isArray(items)) {
    return null;
  }

  for (let i = 0; i < items.length; i++) {
    let item = items[i];

    if (item?.id === id) {
      if (isRemove) {
        return items.splice(i, 1)[0];
      }
      return item;
    }

    if (item?.items && item?.items?.length > 0) {
      let found = findItemById(item.items, id, isRemove);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export function findAndDuplicate(
  array: BookleTemplateBlock[],
  targetId: string
) {
  function duplicateItem(item: BookleTemplateBlock): BookleTemplateBlock {
    const newItem = JSON.parse(JSON.stringify(item));
    const queue: BookleTemplateBlock[] = [newItem];

    while (queue.length > 0) {
      const current = queue.shift();
      if (current) {
        current.id = generateNewIndex();
        if (current.items) {
          queue.push(...current.items);
        }
      }
    }

    return newItem;
  }

  function recursiveFindAndInsert(arr: any[], id: string): boolean {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i]?.id === id) {
        const newItem = duplicateItem(arr[i]);
        arr?.splice(i + 1, 0, newItem);
        return true;
      } else if (arr[i]?.items && arr[i]?.items.length > 0) {
        if (recursiveFindAndInsert(arr[i]?.items, id)) {
          return true;
        }
      }
    }
    return false;
  }

  recursiveFindAndInsert(array, targetId);
}

export const moveItem = (
  hoveredItem: BookleTemplateBlock,
  draggedItem: BookleTemplateBlock,
  isFirstHalf: boolean,
  items: Array<BookleTemplateBlock>,
  setItems: (items: Array<BookleTemplateBlock>) => void,
  parentId?: string
) => {
  const newArray = [...items];
  let parent: any = parentId ? findItemById(newArray, parentId) : null;
  const hoverItem = findItemById(newArray, hoveredItem.id as string);

  const container = parent?.id ? parent.items : newArray;

  const newIndex = container.indexOf(hoverItem) + (isFirstHalf ? 0 : 1);

  const itemToMove = draggedItem.id
    ? findItemById(newArray, draggedItem.id as string, true) || draggedItem
    : createNewItem(draggedItem);

  if (parent?.id) {
    parent.items.splice(newIndex, 0, itemToMove);
  } else {
    newArray.splice(newIndex, 0, itemToMove);
  }

  setItems(newArray);
};

export default addItemToContainer;

export function generateNewIndex(): string {
  return Math.random().toString(36).substr(2, 9);
}

export function isChildOf(
  items: BookleTemplateBlock[],
  parentId: string,
  childId: string
): boolean {
  const parent = findItemById(items, parentId);

  if (!parent || !parent.items || parent.items.length === 0) {
    return false;
  }

  return recursiveChildSearch(parent.items, childId);
}

function recursiveChildSearch(
  items: BookleTemplateBlock[] | undefined,
  childId: string
): boolean {
  if (!items) return false;
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === childId) {
      return true;
    }

    if (items[i].items && (items?.[i]?.items as any).length > 0) {
      if (recursiveChildSearch(items[i].items, childId)) {
        return true;
      }
    }
  }

  return false;
}

export const updateItem = (
  key: keyof BookleTemplateBlock,
  value: number | string | boolean | never[],
  templateBlocks: BookleTemplateBlock[],
  updateBlocks: (payload: BookleTemplateBlock[]) => void,
  item: BookleTemplateBlock
) => {
  const newArray = [...templateBlocks];
  const buttonItem: BookleTemplateBlock | undefined = findItemById(
    newArray,
    item.id as string
  );

  if (!buttonItem) return;

  buttonItem[key] = value;

  updateBlocks(newArray);
};

export const updateItemStyle = (
  key: string,
  value: number | string,
  templateBlocks: BookleTemplateBlock[],
  updateBlocks: (
    payload: BookleTemplateBlock[],
    updateHistory: boolean
  ) => void,
  item: BookleTemplateBlock,
  updateHistory = true
) => {
  const newArray = [...templateBlocks];
  let buttonItem: BookleTemplateBlock | undefined = findItemById(
    newArray,
    item.id as string
  );

  if (!buttonItem) return;

  (buttonItem as any).styles = {
    ...buttonItem.styles,
    [key]: value,
  };

  updateBlocks(newArray, updateHistory);
};

export class Preview2HTML {
  generateStyles(
    styles?: BookleTemplateBlock['styles'],
    hasColor?: boolean,
    color?: string,
    hasMargin?: boolean,
    hasPadding?: boolean
  ): { containerStyles: string; rootContainerStyles: string } {
    if (!styles) return { containerStyles: '', rootContainerStyles: '' };

    if (!styles.blockSpacing) {
      return { containerStyles: '', rootContainerStyles: '' };
    }

    const {
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingBottom,
      marginTop,
      marginBottom,
      marginRight,
      marginLeft,
    } = styles.blockSpacing;

    const containerStylesString = `
    ${
      hasPadding
        ? `
    ${
      paddingLeft
        ? `padding-left: ${
            typeof paddingLeft === 'number' ? `${paddingLeft}px` : paddingLeft
          };`
        : ''
    }
    ${
      paddingRight
        ? `padding-right: ${
            typeof paddingRight === 'number'
              ? `${paddingRight}px`
              : paddingRight
          };`
        : ''
    }
    ${
      paddingTop
        ? `padding-top: ${
            typeof paddingTop === 'number' ? `${paddingTop}px` : paddingTop
          };`
        : ''
    }
    ${
      paddingBottom
        ? `padding-bottom: ${
            typeof paddingBottom === 'number'
              ? `${paddingBottom}px`
              : paddingBottom
          };`
        : ''
    }`
        : ''
    }
    ${
      styles.containerBorderRadius
        ? `border-radius: ${styles.containerBorderRadius}px;`
        : ''
    }`.trim();

    const rootContainerStylesString = `
      ${
        hasMargin
          ? `
        ${
          marginLeft
            ? `padding-left: ${
                typeof marginLeft === 'number' ? `${marginLeft}px` : marginLeft
              };`
            : ''
        }
        ${
          marginRight
            ? `padding-right: ${
                typeof marginRight === 'number'
                  ? `${marginRight}px`
                  : marginRight
              };`
            : ''
        }
        ${
          marginTop
            ? `padding-top: ${
                typeof marginTop === 'number' ? `${marginTop}px` : marginTop
              };`
            : ''
        }
        ${
          marginBottom
            ? `padding-bottom: ${
                typeof marginBottom === 'number'
                  ? `${marginBottom}px`
                  : marginBottom
              };`
            : ''
        }
      `
          : ''
      }
      ${hasColor ? `background-color: ${color ?? '#ffffff'};` : ''}
      ${styles.opacity ? `opacity: ${styles.opacity};` : ''}
      ${styles.containerBorder ? `border: ${styles.containerBorder};` : ''}
      ${styles.containerWidth ? `width: ${styles.containerWidth};` : ''}
      ${styles.containerHeight ? `height: ${styles.containerHeight}px;` : ''}
      ${
        styles.containerBorderRadius
          ? `border-radius: ${styles.containerBorderRadius}px;`
          : ''
      }
    `.trim();

    return {
      containerStyles: containerStylesString.replace(/\s+/g, ' '),
      rootContainerStyles: rootContainerStylesString.replace(/\s+/g, ' '),
    };
  }

  constructTextBlock(blockId: string, removeMargin?: boolean): string {
    const textEditorElement = document.getElementById(`text_editor_${blockId}`);

    if (!textEditorElement) {
      return '';
    }

    const contentEditableElements = textEditorElement.querySelectorAll(
      '[contentEditable="true"]'
    );
    contentEditableElements.forEach((element) => {
      element.removeAttribute('contentEditable');
    });

    const paragraphs = textEditorElement.querySelectorAll('p');
    paragraphs.forEach((p, index) => {
      if (removeMargin) {
        p.style.marginBottom = '0';
        p.style.marginTop = '0';
      }
    });

    const htmlContent = textEditorElement.innerHTML;

    contentEditableElements.forEach((element) => {
      element.setAttribute('contentEditable', 'true');
    });

    return htmlContent;
  }

  constructBlock(previewBlock: BookleTemplateBlock): string {
    let block = '';
    const { containerStyles, rootContainerStyles } = this.generateStyles(
      previewBlock.styles,
      previewBlock.hasColor,
      previewBlock.color,
      previewBlock.hasMargin,
      previewBlock.hasPadding
    );

    const containerColor =
      previewBlock.color && previewBlock.hasColor
        ? `background-color: ${previewBlock.color};`
        : '';

    switch (previewBlock.type) {
      case MenuItems.IMAGE_BLOCK: {
        if (!previewBlock.image) {
          break;
        }

        const height = `height: ${
          previewBlock.styles?.height
            ? `${previewBlock.styles?.height}px`
            : 'unset'
        }`;
        const width = `width: ${
          previewBlock.styles?.width ? `${previewBlock.styles?.width}%` : '100%'
        }`;
        const border = `border: ${previewBlock.styles?.border ?? 'none'}`;
        const objectFit = `object-fit: ${
          previewBlock.styles?.objectFit ?? 'cover'
        }`;
        const childTag = `
        <img 
          style="max-width: 100%; ${height}; ${width}; ${border}; ${objectFit}" 
          alt="${previewBlock.imageText ?? 'img'}"
          title="${previewBlock.imageText ?? 'img'}"
          src="${previewBlock.image}"
         />`;
        block = `<div style="${containerColor} ${containerStyles} overflow: hidden">
                  <table role="presentation" style="border-collapse: collapse; width: 100%;">
                      <tr>
                        <td align=${
                          previewBlock?.alignment || 'center'
                        } valign="middle" style="overflow: hidden; padding: 0;">
                          ${
                            previewBlock.url
                              ? `<a style="text-decoration: none;" href="${formatUrl(
                                  previewBlock.url
                                )}" rel="noopener noreferrer">${childTag}</a>`
                              : childTag
                          }
                        </td>
                      </tr>
                  </table>
                </div>`;
        break;
      }
      case MenuItems.TEXT_BLOCK:
      case MenuItems.HEADING_BLOCK: {
        let htmlContent = this.constructTextBlock(previewBlock.id);
        const str = htmlContent.replace(
          /\[([^\]]+)\]\(([^)]+)\)/g,
          "<a href='$2' style='color: inherit; text-decoration: underline' rel='noopener noreferrer'>$1</a>"
        );

        block = `<div style="${containerColor} ${containerStyles}">${str}</div>`;
        break;
      }
      case MenuItems.BUTTON_BLOCK: {
        const backgroundColor = `background-color: ${
          previewBlock.styles?.backgroundColor ?? '#4957d8'
        };`;
        const height = `height: ${previewBlock.styles?.height ?? 50}px;`;
        const width = `width: ${previewBlock.styles?.width ?? 220}px;`;
        const borderRadius = `border-radius: ${
          previewBlock.styles?.borderRadius ?? 0
        }px;`;

        const htmlContent = this.constructTextBlock(previewBlock.id, true);
        const str = htmlContent.replace(
          /\[([^\]]+)\]\(([^)]+)\)/g,
          "<a href='$2' style='color: inherit' rel='noopener noreferrer'>$1</a>"
        );

        const childTag = `<table role="presentation" style="border-collapse: collapse; ${backgroundColor} ${height} ${width} ${borderRadius}">
                            <tr>
                              <td align="center" valign="middle" style="overflow: hidden; padding: 0;">
                                ${str}
                              </td>
                            </tr>
                          </table>`;

        block = `<div style="${containerColor} ${containerStyles}">
                  <table role="presentation" style="border-collapse: collapse; width: 100%">
                    <tr>
                      <td align="${
                        previewBlock?.alignment || 'center'
                      }" style="padding: 0;">
                        ${
                          previewBlock.url
                            ? `<a style="text-decoration: none;" href="${formatUrl(
                                previewBlock.url
                              )}" rel="noopener noreferrer">${childTag}</a>`
                            : childTag
                        }
                      </td>
                    </tr>
                  </table>
                </div>`;
        break;
      }
      case MenuItems.DIVIDER_BLOCK:
      case MenuItems.SPACER_BLOCK: {
        const border = !previewBlock.isSpacer
          ? `border-top: ${previewBlock.divider ?? '1px solid #d0d0d0'};`
          : '';
        const height = `height: ${!previewBlock.isSpacer ? '1px' : '100%'};`;

        block = `<div role="presentation" style="${containerStyles} ${containerColor}">
                    <div style="${border} ${height} "></div>
                </div>`;
        break;
      }
      case MenuItems.ONE_SECTION:
      case MenuItems.TWO_SECTION:
      case MenuItems.THREE_SECTION:
      case MenuItems.FOUR_SECTION: {
        block = `<table role="presentation" style="border-collapse: collapse; width: 100%; ${containerColor}">
                  <tr>${previewBlock.items
                    ?.map(
                      (item: BookleTemplateBlock) =>
                        `<td style="width: ${
                          (previewBlock?.items as any).length > 0
                            ? 100 / (previewBlock?.items as any).length
                            : 1
                        }%; padding: 0;">
                        <div style="${containerStyles}">
                          ${this.setCorrespondingBlocks(
                            item.items || [],
                            undefined,
                            true
                          )}
                        </div>
                        </td>`
                    )
                    .join('')}
                  </tr>
                </table>`;
        break;
      }
    }

    return `<div style="${rootContainerStyles}">${block}</div>`;
  }

  setCorrespondingBlocks(
    previewBlocks: BookleTemplateBlock[],
    templateBlockStyles?: IBookleTemplateBlockStyles,
    isSection?: boolean
  ): string {
    let blockContainer = '';
    for (const previewBlock of previewBlocks) {
      blockContainer += this.constructBlock(previewBlock);
    }

    if (isSection) {
      return blockContainer;
    }

    const styleLinks = Array.from(
      document.querySelectorAll('link[rel="stylesheet"]')
    )
      .map((link) => link.outerHTML)
      .join('\n');

    const styles = templateBlockStyles
      ? `background-color: ${templateBlockStyles.bodyColor}; width: ${templateBlockStyles.bodyWidth}px;`
      : '';

    return `
          <!DOCTYPE html>
          <html lang='en'>
          <head>
              <meta charset='UTF-8'>
              <meta name='viewport' content='width=device-width, initial-scale=1.0'>
              <title>Generated HTML</title>
              ${styleLinks}
          </head>
          <body style='background-color: ${templateBlockStyles?.emailBg}'>
              <div style='${styles} margin: 0 auto;'>${blockContainer}</div>
          </body>
          </html>
  `;
  }
}
