import { useEffect, useState } from 'react';
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'RadixUI/accordion';
import { Input } from 'RadixUI/input';
import { Label } from 'RadixUI/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'RadixUI/select';
import { BookleTemplateBlock } from 'types';
import { getBorderProperties } from './helpers';

interface IProps {
  element: BookleTemplateBlock;
  onUpdate: (key: string, value: string | boolean) => void;
}

export const DividerControls = ({ element, onUpdate }: IProps) => {
  const [border, setBorder] = useState({
    width: 1,
    style: '',
    color: '',
  });

  useEffect(() => {
    if (element?.divider) {
      setBorder(getBorderProperties(element?.divider));
    } else {
      setBorder({
        width: 0,
        style: '',
        color: '',
      });
    }
  }, [element?.divider]);

  return (
    <AccordionItem value="divider" className="border-none">
      <AccordionTrigger className="hover:no-underline py-3 px-4 rounded-lg data-[state=open]:bg-muted/50 transition-colors">
        Divider Settings
      </AccordionTrigger>
      <AccordionContent className="pt-4 pb-2 px-4">
        <div className="space-y-4">
          <div className="space-y-2">
            <Label className="text-xs font-medium">Divider Style</Label>
            <Select
              value={border.style || 'solid'}
              onValueChange={(value: 'solid' | 'dashed' | 'dotted') =>
                onUpdate(
                  'divider',
                  `${border.width}px ${value} ${border.color}`
                )
              }
            >
              <SelectTrigger className="bg-background/50 focus:bg-background transition-colors">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="solid">Solid</SelectItem>
                <SelectItem value="dashed">Dashed</SelectItem>
                <SelectItem value="dotted">Dotted</SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="space-y-2">
            <Label className="text-xs font-medium">Divider Width</Label>
            <div className="flex gap-2">
              <Input
                type="number"
                min="1"
                max="10"
                value={border.width || 1}
                onChange={(e) =>
                  onUpdate(
                    'divider',
                    `${e.target.value}px ${border.style} ${border.color}`
                  )
                }
                className="bg-background/50 focus:bg-background transition-colors"
              />
              <span className="text-sm text-muted-foreground self-center">
                px
              </span>
            </div>
          </div>

          <div className="space-y-2">
            <Label className="text-xs font-medium">Divider Color</Label>
            <div className="flex gap-2">
              <Input
                type="color"
                value={border?.color || '#000000'}
                onChange={(e) =>
                  onUpdate(
                    'divider',
                    `${border.width}px ${border.style} ${e.target.value}`
                  )
                }
                className="w-12 h-12 p-1 rounded-lg bg-background/50 focus:bg-background transition-colors"
              />
              <Input
                type="text"
                value={border?.color || '#000000'}
                onChange={(e) =>
                  onUpdate(
                    'divider',
                    `${border.width}px ${border.style} ${e.target.value}`
                  )
                }
                placeholder="e.g. #000000 or hsl(var(--border))"
                className="flex-1 bg-background/50 focus:bg-background transition-colors"
              />
            </div>
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};
