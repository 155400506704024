import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import WaveSurfer from 'wavesurfer.js';
import clsx from 'clsx';
import coverImg from 'Assets/images/hardcodeCover.png';
import { ReactComponent as DotsIcon } from 'Assets/icons/editDots.svg';
import { ReactComponent as PlayIcon } from 'Assets/icons/play-button.svg';
import { ReactComponent as ReloadSvg } from 'Assets/icons/reload2.svg';
import { ReactComponent as PauseIcon } from 'Assets/icons/pause-button.svg';
import { ReactComponent as RemoveIcon } from 'Assets/icons/remove-icon.svg';
import { ReactComponent as PencilIcon } from 'Assets/icons/edit2.svg';
import { ReactComponent as DownloadSvg } from 'Assets/icons/download.svg';
import { ReactComponent as AttentionSvg } from 'Assets/icons/attention.svg';
import { b64toBlob, generateCoverImage } from 'utils/fuctions';
import { api, createSocket, getToken } from 'utils/Utils';
import { PAGECRAFT_API_URL } from 'GlobalConstants';
import { EpisodeStatus } from 'Pages/PodcastGenerator/constants';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import Loader from 'UILib/Loader/Loader';
import Button from 'UILib/Button/Button';
import CoverEditor from 'CoverEditor/CoverEditor';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';

import s from './Episode.module.scss';

interface IProps {
  id: string;
  episodeInfo: {
    episodeName: string;
    episodeNumber: number;
    episodeAudioUrl?: string;
  };
  podcastId: string;
  podcastName: string;
  imageUrl?: string;
  onDelete: () => void;
  cover?: any;
  status: EpisodeStatus;
}

const Episode = ({
  id,
  episodeInfo,
  podcastId,
  podcastName,
  imageUrl,
  onDelete,
  cover,
  status,
}: IProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  // const [showTranscription, setShowTranscription] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [reloadDependency, setReloadDependency] = useState(0);
  const [audioUrl, setAudioUrl] = useState(episodeInfo.episodeAudioUrl);
  const [episodeName, setEpisodeName] = useState(episodeInfo.episodeName);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    imageUrl
  );
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [finishedGeneration, setFinishedGeneration] = useState<boolean>(false);
  const [currentStatus, setCurrentStatus] = useState('');

  const history = useHistory();

  const audioRef = useRef<HTMLAudioElement>(null);
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    setCurrentStatus(status);
  }, [status]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const updateTime = () => {
        setCurrentTime(audio.currentTime);
        setDuration(audio.duration);
      };
      audio.addEventListener('timeupdate', updateTime);
      audio.addEventListener('loadedmetadata', updateTime);

      return () => {
        audio.removeEventListener('timeupdate', updateTime);
        audio.removeEventListener('loadedmetadata', updateTime);
        wavesurfer.current?.pause();
        wavesurfer.current?.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (currentStatus === EpisodeStatus.GENERATING_AUDIO && id) {
      const socket = createSocket();
      socket.emit('generate-episode-audio-2', {
        episodeId: id,
        token: getToken(),
      });

      socket.on('episode-audio-response-2', (res) => {
        if (res.episodeId === id && res.percent >= 100 && !!res.audioUrl) {
          setAudioUrl(res.audioUrl);
          setCurrentStatus(EpisodeStatus.AUDIO_GENERATED);
          socket.disconnect();
        }
      });
    }
  }, [currentStatus, id, audioUrl]);

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
      wavesurfer.current = null;
    }

    if (audioUrl && waveformRef.current) {
      setIsLoading(true);
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#a0a0a0',
        progressColor: '#3e3e3e',
        barWidth: 2,
        cursorWidth: 1,
        backend: 'MediaElement',
        height: 50,
        cursorColor: 'transparent',
        interact: true,
        normalize: true,
      });

      wavesurfer.current.load(audioUrl)!;

      wavesurfer.current.on('ready', () => {
        setIsLoading(false);
        setDuration(wavesurfer.current!.getDuration());
        setHasError(false);
      });

      wavesurfer.current.on('audioprocess', () => {
        setCurrentTime(wavesurfer.current!.getCurrentTime());
      });

      wavesurfer.current.on('error', (error: any) => {
        console.error('WaveSurfer error:', error);
        setHasError(true);
        // setIsLoading(false);
      });
    }

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
    };
  }, [audioUrl, reloadDependency]);

  const reloadEpisode = () => {
    setHasError(false);
    setReloadDependency((prev) => prev + 1);
  };

  const togglePlay = () => {
    if (isLoading || !finishedGeneration) return;
    setIsPlaying(!isPlaying);
    wavesurfer.current?.playPause();
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const remainingTime = duration - currentTime;

  const handleDownloadAudio = async (url: string) => {
    if (!url) return;
    setIsDownloading(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = `${podcastName} - ${episodeName}.mp3`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading the file:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const getLinkWithoutCDN = (link: string) => {
    if (link.includes('https://cdn.autofunnel.ai')) {
      return link.replace(
        'https://cdn.autofunnel.ai',
        'https://s3.us-east-2.amazonaws.com/cdn.autofunnel.ai'
      );
    }
    return link;
  };

  const downloadImage = async () => {
    try {
      let imageBlob: Blob | null = null;

      if (imagePreview) {
        const fileUrl = getLinkWithoutCDN(imagePreview);
        const response = await fetch(fileUrl);
        imageBlob = await response.blob();
      } else if (cover) {
        const element = document.getElementById(
          `episode-image-container-${id}`
        );
        const coverElement = element?.childNodes[0];

        const imageSrc = await generateCoverImage(1024, coverElement, 10);
        imageBlob = b64toBlob(imageSrc);
      }

      if (imageBlob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(imageBlob);
        link.download = `${podcastName} - ${episodeName}.jpeg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  const handleChangeImage = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isImageUploading) {
      try {
        setIsImageUploading(true);
        const image = event.target.files ? event.target.files[0] : null;
        if (image) {
          const request = new FormData();
          request.append('file', image);
          request.append('podcastId', podcastId);
          request.append('episodeId', id);

          await api(
            `${PAGECRAFT_API_URL}/podcasts/upload-episode-image?podcaster=${podcastId}&episodeId=${id}`,
            'POST',
            request
          );
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string);
          };
          reader.readAsDataURL(image);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsImageUploading(false);
      }
    }
  };

  const updateEpisodeName = () => {
    if (episodeInfo.episodeName === episodeName) return;

    graphQlCall({
      queryTemplateObject: queries.UPDATE_EPISODE_MUTATION,
      values: { id, name: episodeName },
      headerType: 'USER-AUTH',
    });
  };

  useEffect(() => {
    setFinishedGeneration(
      !!audioUrl ||
        (!!currentStatus &&
          (currentStatus === EpisodeStatus.AUDIO_GENERATED ||
            currentStatus === EpisodeStatus.COMPLETE_ALL))
    );
  }, [currentStatus, audioUrl]);

  return (
    <div className={s.main}>
      <div className={s.episodeContainer}>
        <input
          type="file"
          accept="image/*"
          id={`episode-image-${id}`}
          onChange={handleChangeImage}
          className={s.uploadImageInput}
          disabled={isImageUploading}
        />
        <label
          htmlFor={`episode-image-${id}`}
          id={`episode-image-container-${id}`}
          className={s.episodeCoverContainer}
        >
          {imagePreview ? (
            <>
              <img src={imagePreview} alt="cover" className={s.episodeCover} />
              {isImageUploading && (
                <div className={s.loader}>
                  <Loader size={12} />
                </div>
              )}
            </>
          ) : cover?.id ? (
            <CoverEditor
              titles={[
                episodeName,
                podcastName!,
                `Episode: ${episodeInfo.episodeNumber}`,
              ]}
              width={150}
              height={150}
              readOnly={true}
              data={cover}
            />
          ) : (
            <>
              <img src={coverImg} alt="cover" className={s.episodeCover} />
              {!imagePreview && (
                <div className={s.episodeCoverNames}>
                  <div className={s.episodeCoverNumber}>
                    Episode #{episodeInfo.episodeNumber}
                  </div>
                  <div className={s.episodeSeriesName}>{episodeName}</div>
                </div>
              )}
            </>
          )}
        </label>
        <div className={s.episodeContent}>
          <div className={s.topPlaceEpisod}>
            <div
              className={clsx(s.playPauseButton, {
                [s.disabled]: isLoading || !finishedGeneration,
              })}
              onClick={togglePlay}
            >
              {isPlaying ? (
                <PauseIcon fill="white" />
              ) : (
                <PlayIcon fill="white" style={{ marginLeft: 4 }} />
              )}
            </div>
            <div className={s.episodeNameBlock}>
              <div className={s.episodeNumber}>
                Episode #{episodeInfo.episodeNumber}
              </div>
              <EditableLabel
                size="medium"
                value={episodeName}
                onChange={setEpisodeName}
                onOutsideClick={updateEpisodeName}
              />
            </div>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'edit',
                    icon: (
                      <PencilIcon
                        fill={
                          isLoading ||
                          currentStatus === EpisodeStatus.GENERATING_AUDIO
                            ? '#A0A0A0'
                            : '#000000'
                        }
                      />
                    ),
                    disabled:
                      isLoading ||
                      currentStatus === EpisodeStatus.GENERATING_AUDIO,
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      history.push(`/console/podcaster/episode/${id}/edit`);
                    },
                    label: <div className={s.dropdownElement}>Edit</div>,
                  },
                  ...(imagePreview || cover
                    ? [
                        {
                          key: 'download_cover',
                          icon: <DownloadSvg fill="#000000" />,
                          onClick: (e: any) => {
                            e.domEvent.stopPropagation();
                            downloadImage();
                          },
                          label: (
                            <div className={s.dropdownElement}>
                              Download Artwork
                            </div>
                          ),
                        },
                      ]
                    : []),
                  {
                    key: 'delete',
                    icon: <RemoveIcon fill="#000000" />,
                    onClick: (e) => {
                      e.domEvent.stopPropagation();
                      onDelete();
                    },
                    label: <div className={s.dropdownElement}>Delete</div>,
                  },
                ],
              }}
              trigger={['click']}
            >
              <div className={s.edit}>
                <DotsIcon />
              </div>
            </Dropdown>
          </div>
          {!hasError ? (
            <>
              <div
                className={clsx(s.waveFormSceleton, {
                  [s.hiddenWaveForm]: duration && !isLoading,
                })}
              >
                {finishedGeneration ||
                currentStatus === EpisodeStatus.GENERATING_AUDIO ? (
                  <>
                    <Loader size={12} className={s.loader} color="#A0A0A0" />
                    <span className={s.loaderText}>
                      {currentStatus !== EpisodeStatus.GENERATING_AUDIO
                        ? 'Audio is loading'
                        : 'Audio Generation in Progress'}
                    </span>
                  </>
                ) : (
                  <span
                    className={s.loaderText}
                    onClick={() =>
                      history.push(`/console/podcaster/episode/${id}/edit`)
                    }
                  >
                    {currentStatus === EpisodeStatus.GENERATING_TEXT
                      ? 'Text Generation in Progress'
                      : currentStatus === EpisodeStatus.TEXT_GENERATED
                      ? 'Text is generated. Please complete the audio generating process'
                      : 'Please complete the text generating process.'}
                  </span>
                )}
              </div>
              <div
                className={clsx(s.waveForm, {
                  [s.hiddenWaveForm]: !duration && isLoading,
                })}
                ref={waveformRef}
              />
            </>
          ) : (
            <div className={clsx(s.hasError)} ref={waveformRef}>
              <div className={s.errorMessage}>
                <AttentionSvg />
                <span>Something went wrong!</span>
              </div>
              <div className={s.episodeRegenerate} onClick={reloadEpisode}>
                <ReloadSvg className={s.icon} />
                Regenerate
              </div>
            </div>
          )}
          <div className={s.bottomPlaceEpisod}>
            <div className={s.episodeOption}>
              <Button
                appearance="stroke"
                height={30}
                disabled={isDownloading || !duration}
                prefixIcon={<DownloadSvg />}
                onClick={() => {
                  if (audioUrl) handleDownloadAudio(audioUrl);
                }}
              >
                Download
              </Button>
            </div>
            <div className={s.timeBlock}>{formatTime(remainingTime)}</div>
          </div>
        </div>

        <audio ref={audioRef} src={audioUrl} />
      </div>
    </div>
  );
};

export default Episode;
