import clsx from 'clsx';
import { useCallback, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Slate,
  Editable,
  withReact,
  RenderElementProps,
  RenderLeafProps,
  ReactEditor,
} from 'slate-react';
import {
  Transforms,
  createEditor,
  Descendant,
  Editor,
  Selection,
  Element as SlateElement,
  Node,
  BaseEditor,
} from 'slate';
import { withHistory } from 'slate-history';
import { DispatchType, RootState } from 'store/rootReducer';
import { CustomText, ParagraphElement } from 'Editors/types';
import { CustomElement } from 'Components/TextEditorToolbar/types';
import { FontFamilies } from 'Components/FontSelector/FontFamilies';
import { updateCurrentAudioAction } from 'store/podcasts/podcastActions';
import { formatUrl, onTextKeyDown } from 'utils/helpers';
import AudioBlock from 'UILib/AudioBlock/AudioBlock';

import styles from './TextEditor.module.scss';

interface IProps {
  keepDefaultLayout?: boolean;
  hardRender?: boolean;
  editorClassName?: string;
  initialValue: Descendant[];
  onChange: (newValue: Descendant[]) => void;
  onHardRender?: () => void;
  currentPlayingAudio: string | null;
  updateCurrentAudio: (audioKey: string | null) => void;
  setEditor?: (editor?: Editor, selection?: Selection) => void;
  canEdit?: boolean;
  onKeydown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  customSelect?: boolean;
  textLimit?: number;
  canUpdateOnlyFirstParagraph?: boolean;
}

const withEmbeds = (editor: Editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) =>
    element.type === 'audio' ||
    element.type === 'line' ||
    element.type === 'break'
      ? true
      : isVoid(element);
  return editor;
};

const withLayout = (editor: Editor) => {
  const { normalizeNode } = editor;

  editor.normalizeNode = ([node, path]) => {
    if (path.length === 0) {
      for (const [child, childPath] of Node.children(editor, path)) {
        let type: 'paragraph' | 'title' | 'audio';
        const slateIndex = childPath[0];
        const enforceType = (type: 'paragraph' | 'title' | 'audio') => {
          if (SlateElement.isElement(child) && child.type !== type) {
            const newProperties: Partial<SlateElement> = { type };
            Transforms.setNodes<SlateElement>(editor, newProperties, {
              at: childPath,
            });
          }
        };

        switch (slateIndex) {
          case 0:
            type = 'title';
            enforceType(type);
            break;
          default:
            break;
        }
      }
    }

    return normalizeNode([node, path]);
  };

  return editor;
};

const withLimit = (editor: BaseEditor & ReactEditor, textLimit: number) => {
  const { insertText } = editor;

  editor.insertText = (text) => {
    if (!textLimit) {
      insertText.call(editor, text);
      return;
    }

    const currentTextLength = editor.children
      .filter((e) => (e as SlateElement).type === 'paragraph')
      .reduce(
        (acc, e) =>
          acc +
          (e as ParagraphElement).children.reduce(
            (length: number, el) => length + (el as CustomText).text.length,
            0
          ),
        0
      );

    if (currentTextLength + text.length > textLimit) {
      const allowedText = text.slice(0, textLimit - currentTextLength);
      if (allowedText.length > 0) {
        insertText.call(editor, allowedText);
      }
      return;
    }
    insertText.call(editor, text);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (!textLimit) {
      insertText.call(editor, text);
      return;
    }

    const currentTextLength = editor.children
      .filter((e) => (e as SlateElement).type === 'paragraph')
      .reduce(
        (acc, e) =>
          acc +
          (e as ParagraphElement).children.reduce(
            (length: number, el) => length + (el as CustomText).text.length,
            0
          ),
        0
      );

    if (currentTextLength < textLimit) {
      const allowedText = text.slice(0, textLimit - currentTextLength);
      if (allowedText.length > 0) {
        insertText.call(editor, allowedText);
      }
    }
  };

  return editor;
};

const TextEditor = ({
  keepDefaultLayout,
  initialValue,
  onChange,
  editorClassName,
  currentPlayingAudio,
  updateCurrentAudio,
  setEditor,
  canEdit = true,
  onKeydown,
  customSelect,
  textLimit,
  canUpdateOnlyFirstParagraph,
}: IProps) => {
  const editor = useMemo(() => {
    let finalEditor: any = withHistory(withReact(createEditor()));

    if (textLimit) {
      finalEditor = withLimit(finalEditor, textLimit);
    }
    if (keepDefaultLayout) {
      finalEditor = withLayout(finalEditor);
    }

    return withEmbeds(finalEditor);
  }, [keepDefaultLayout, textLimit]);

  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      let target = event.target as HTMLElement | null;

      while (target && target.nodeName !== 'BODY') {
        if (
          ['BUTTON', 'INPUT', 'TEXTAREA', 'SELECT'].includes(target.nodeName)
        ) {
          return;
        }

        if (target.nodeName === 'DIV' && target.id?.includes('header')) {
          event.preventDefault();
          break;
        }

        target = target.parentElement;
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  useEffect(() => {
    if (initialValue.length <= 0) return;

    const handleUpdateItems = () => {
      const totalNodes = editor.children;

      initialValue.forEach((value: any, index) => {
        if (totalNodes[index]) {
          const existingNode: any = totalNodes[index];

          if (existingNode.type !== value.type) {
            Transforms.removeNodes(editor, { at: [index] });
            Transforms.insertNodes(editor, value as any, { at: [index] });
          } else {
            if (value.type === 'paragraph' || value.type === 'title') {
              if (
                canUpdateOnlyFirstParagraph &&
                value.children[0].text !== existingNode.children[0].text
              ) {
                Transforms.insertText(editor, value.children[0].text as any, {
                  at: [index],
                });
              } else if (
                JSON.stringify(value.children) !==
                JSON.stringify(existingNode.children)
              ) {
                Transforms.removeNodes(editor, { at: [index] });
                Transforms.insertNodes(editor, value, { at: [index] });
              }
            } else if (JSON.stringify(existingNode) !== JSON.stringify(value)) {
              Transforms.setNodes(editor, value as any, {
                at: [index],
              });
            }
          }
        } else {
          Transforms.insertNodes(editor, value as any, { at: [index] });
        }
      });

      if (totalNodes.length > initialValue.length) {
        for (let i = totalNodes.length - 1; i >= initialValue.length; i--) {
          Transforms.removeNodes(editor, { at: [i] });
        }
      }
    };

    handleUpdateItems();
  }, [editor, initialValue, canUpdateOnlyFirstParagraph]);

  const handleSelectSameTypeElements = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
      event.preventDefault();

      const { selection } = editor;

      if (selection) {
        const [currentBlock, currentPath] =
          Editor.above(editor, {
            match: (n) => SlateElement.isElement(n),
          }) || [];

        if (currentBlock && currentPath) {
          const currentType = (currentBlock as SlateElement).type;

          const nodes = Array.from(Node.descendants(editor));

          const sameTypeNodes = nodes.filter(
            ([node]) =>
              SlateElement.isElement(node) && node.type === currentType
          );

          if (sameTypeNodes.length > 0) {
            const firstPath = sameTypeNodes[0][1];
            const lastPath = sameTypeNodes[sameTypeNodes.length - 1][1];

            const anchor = Editor.start(editor, firstPath);
            const focus = Editor.end(editor, lastPath);

            Transforms.select(editor, { anchor, focus });
          }
        }
      }
    }
  };

  const handleDOMBeforeInput = (event: InputEvent) => {
    if (!textLimit) return;
    const inputType = event.inputType;
    if (
      inputType === 'insertText' ||
      inputType === 'insertParagraph' ||
      inputType === 'insertLineBreak'
    ) {
      const currentTextLength = editor.children
        .filter((e) => (e as SlateElement).type === 'paragraph')
        .reduce(
          (acc, e) =>
            acc +
            (e as ParagraphElement).children.reduce(
              (length: number, el) => length + (el as CustomText).text.length,
              0
            ),
          0
        );
      const pastedTextLength =
        event.dataTransfer?.getData('text/plain')?.length ?? 0;
      if (currentTextLength + pastedTextLength >= textLimit) {
        event.preventDefault();
        return;
      }
    }
  };

  const handleHideLimitText = () => {
    const splitNode = editor.operations.some((op) => op.type === 'split_node');
    const removeNode = editor.operations.some(
      (op) => op.type === 'remove_node'
    );
    const mergeNode = editor.operations.some((op) => op.type === 'merge_node');
    if (splitNode || removeNode || mergeNode) {
      const domEditor = ReactEditor.toDOMNode(editor, editor);
      const limitTexts = domEditor.getElementsByClassName(styles.limit);
      if (splitNode) {
        for (let i = 0; i < limitTexts.length; i++) {
          if (!limitTexts[i]?.classList?.contains(styles.hide)) {
            limitTexts[i]?.classList?.add(styles.hide);
          }
        }
      } else if (removeNode) {
        limitTexts[limitTexts.length - 1]?.classList?.remove(styles.hide);
      } else if (mergeNode && limitTexts[limitTexts.length - 2]) {
        limitTexts[limitTexts.length - 2]?.classList?.remove(styles.hide);
      }
    }
  };

  const renderElement = useCallback(
    (props: RenderElementProps) => (
      <Element
        {...props}
        currentPlayingAudio={currentPlayingAudio}
        updateCurrentAudio={updateCurrentAudio}
        contentEditable={canEdit}
        textLimit={textLimit}
        editor={editor}
      />
    ),
    [currentPlayingAudio]
  );

  const renderLeaf = useCallback(
    (props: RenderLeafProps) => <Leaf {...props} />,
    []
  );

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={(e) => {
        if (editor.operations.every((op) => op.type === 'set_selection'))
          return;

        if (textLimit) {
          handleHideLimitText();
        }
        onChange(e);
      }}
    >
      <Editable
        renderElement={renderElement}
        className={clsx(styles.editor, editorClassName)}
        onDOMBeforeInput={handleDOMBeforeInput}
        renderLeaf={renderLeaf}
        spellCheck
        onDrop={() => null}
        onSelect={() => {
          if (setEditor) setEditor(editor, editor.selection);
        }}
        onKeyDown={(event) => {
          if (customSelect) {
            handleSelectSameTypeElements(event);
          }
          onTextKeyDown(event, editor);
        }}
      />
    </Slate>
  );
};

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  if ((leaf as CustomText).bold) {
    children = <strong>{children}</strong>;
  }

  if ((leaf as CustomText).italic) {
    children = <em>{children}</em>;
  }

  if ((leaf as any).underline) {
    children = <u>{children}</u>;
  }

  if ((leaf as any).link) {
    children = (
      <a className={styles.link} href={formatUrl((leaf as any).link)}>
        {children}
      </a>
    );
  }

  if ((leaf as any).type === 'break') {
    children = (
      <>
        <br />
        {children}
      </>
    );
  }

  const style = {
    color: (leaf as any).color || 'inherit',
    fontFamily: FontFamilies.find((e) => e.value === (leaf as any)?.font)
      ?.label,
    fontSize: (leaf as any)?.fontSize,
    fontWeight: (leaf as any)?.weight,
    lineHeight: (leaf as any)?.lineHeight,
    textShadow: (leaf as any)?.textShadow,
    letterSpacing: (leaf as any)?.letterSpacing,
  };

  return (
    <span {...attributes} style={style}>
      {children}
    </span>
  );
};

const Element = (
  props: RenderElementProps & {
    textLimit?: number;
    editor: BaseEditor & ReactEditor;
    contentEditable?: boolean;
    currentPlayingAudio: string | null;
    updateCurrentAudio: (audio: string | null) => void;
  }
) => {
  const {
    attributes,
    children,
    element,
    editor,
    textLimit,
    contentEditable,
  } = props;

  const marginBottom = editor.children?.length > 1 ? '1em' : '0';

  const style = {
    textAlign:
      (element as CustomElement).align || children?.[0]?.props?.text?.align,
    lineHeight:
      (element as CustomElement).lineHeight ||
      children?.[0]?.props?.text?.lineHeight,
  } as React.CSSProperties;

  switch (element.type) {
    case 'title':
      switch (element.depth) {
        case 1:
          return (
            <h1
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h1>
          );

        case 3:
          return (
            <h3
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h3>
          );
        case 4:
          return (
            <h4
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h4>
          );
        case 5:
          return (
            <h5
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h5>
          );
        case 6:
          return (
            <h6
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h6>
          );
        case 2:
        default:
          return (
            <h2
              className={styles.title}
              contentEditable={contentEditable === false ? false : undefined}
              style={style}
              {...attributes}
            >
              {children}
            </h2>
          );
      }
    case 'break':
      return (
        <>
          <br />
          {children}
        </>
      );
    case 'line':
      return (
        <>
          <hr />
          {children}
        </>
      );
    case 'table':
      return (
        <table className={styles.table}>
          <tbody {...attributes}>{children}</tbody>
        </table>
      );
    case 'tableRow':
      return (
        <tr className={styles.table} {...attributes}>
          {children}
        </tr>
      );
    case 'tableCell':
      return (
        <td className={styles.table} {...attributes}>
          {children}
        </td>
      );
    case 'paragraph':
      const textLength = editor.children
        .filter((e) => (e as SlateElement).type === 'paragraph')
        .reduce(
          (acc, e) =>
            acc +
            (e as ParagraphElement).children.reduce(
              (length: number, el) => length + (el as CustomText).text.length,
              0
            ),
          0
        );

      return (
        <>
          <p
            className={styles.paragraph}
            style={{ ...style, marginBottom }}
            contentEditable={contentEditable === false ? false : undefined}
            {...attributes}
          >
            {children}
          </p>
          {textLimit && (
            <div
              contentEditable={false}
              className={clsx(styles.limit, {
                [styles.error]: textLength >= textLimit,
              })}
            >
              {textLength} of {textLimit}
            </div>
          )}
        </>
      );
    case 'list':
      return <li {...attributes}>{children}</li>;
    case 'list-item':
      return (
        <li
          className={styles.listItem}
          style={style}
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </li>
      );
    case 'number-list':
      return (
        <ol
          style={style}
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </ol>
      );
    case 'bulleted-list':
      return (
        <ul
          {...attributes}
          contentEditable={contentEditable === false ? false : undefined}
        >
          {children}
        </ul>
      );
    case 'audio':
      return (
        <div className={styles.audio} contentEditable={false}>
          <AudioBlock
            {...element.data}
            currentPlayingAudio={props.currentPlayingAudio}
            updateCurrentAudio={props.updateCurrentAudio}
            audioId={element.data.id}
          />
          {children}
        </div>
      );
    case 'image':
      return (
        <div className={styles.image} contentEditable={false}>
          <img src={element.src} alt="" />
        </div>
      );
    default:
      return null;
  }
};

const mapStateToProps = (state: RootState) => ({
  currentPlayingAudio: state.podcasts.currentPlayingAudio,
});

const mapDispatchToProps = (dispatch: DispatchType) => ({
  updateCurrentAudio: (audio: string | null) =>
    dispatch(updateCurrentAudioAction(audio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
