import { IDomain, ISender } from 'types';
import {
  SELECT_SENDER,
  DOMAIN_LOADING,
  SENDERS_FETCHED,
  SENDERS_LOADING,
  ADD_SENDER_POPUP,
  DNS_RECORDS_POPUP,
  REMOVE_SENDER_POPUP,
  MAIL_DOMAINS_FETCHED,
  SENDER_UPDATE,
} from './sendersActions';
import { PayloadAction } from '@reduxjs/toolkit';

export interface ISendersState {
  loading: boolean;
  domainLoading: boolean;
  selectedSender?: string;
  senders: ISender[];
  domains: IDomain[];
  openDnsPopup: boolean;
  openAddSenderPopup: boolean;
  openRemoveSenderPopup: boolean;
}

const initialState: ISendersState = {
  loading: false,
  domainLoading: false,
  selectedSender: '',
  senders: [],
  domains: [],
  openDnsPopup: false,
  openAddSenderPopup: false,
  openRemoveSenderPopup: false,
};

const senderReducer = (
  state = initialState,
  action: PayloadAction<ISender[] | ISender | IDomain[] | boolean | string>
): ISendersState => {
  switch (action.type) {
    case SENDERS_FETCHED:
      const senders = action.payload as ISender[];
      return {
        ...state,
        senders,
        loading: false,
      };
    case SENDER_UPDATE:
      return {
        ...state,
        senders: state.senders.map((item) =>
          item._id === (action.payload as ISender)._id
            ? (action.payload as ISender)
            : item
        ),
      };
    case MAIL_DOMAINS_FETCHED:
      const domains = action.payload as IDomain[];
      return {
        ...state,
        domains,
        domainLoading: false,
      };
    case SENDERS_LOADING:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case DOMAIN_LOADING:
      return {
        ...state,
        domainLoading: action.payload as boolean,
      };
    case SELECT_SENDER:
      return {
        ...state,
        selectedSender: action.payload as string,
      };
    case ADD_SENDER_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openAddSenderPopup: action.payload,
        };
      }
      return state;
    case DNS_RECORDS_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openDnsPopup: action.payload,
        };
      }
      return state;
    case REMOVE_SENDER_POPUP:
      if (typeof action.payload === 'boolean') {
        return {
          ...state,
          openRemoveSenderPopup: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
};

export { initialState, senderReducer };
