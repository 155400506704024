import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { graphQlCall } from 'graphql/utils';
import { ReactComponent as Eye } from 'Assets/icons/eye2.svg';
import { ReactComponent as Rename } from 'Assets/icons/rename.svg';
import { ReactComponent as Finger } from 'Assets/icons/finger.svg';
import { ReactComponent as ExitTag } from 'Assets/icons/tagRemove.svg';
import { ReactComponent as EntryTag } from 'Assets/icons/tagAdd.svg';
import { ReactComponent as Delivered } from 'Assets/icons/conversionsIconLight.svg';
import { ReactComponent as DuplicateIcon } from 'Assets/icons/clone.svg';
import { ReactComponent as MailSequenceIcon } from 'Assets/icons/mailSequence.svg';
import { IAutoResponder, IAutoResponderStats } from 'types';
import queries from 'graphql/queries';
import OptionMenu from 'Components/OptionMenu/OptionMenu';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import CircleLoader from 'UILib/CircleLoader/CircleLoader';
import EditableLabel from 'UILib/EditableLabel/EditableLabel';
import ConfirmArchivationPopup from 'Components/Domains/ConfirmArchivationPopup';
import { ReactComponent as DeleteIcon } from '../../Accounts/icons/delete.svg';

import styles from './AutoResponderItem.module.scss';

enum MenuItems {
  DELETE,
  CLONE,
  RENAME,
}

interface IAutoResponderItemProps {
  item: IAutoResponder;
  stats: IAutoResponderStats;
  fetchProjects: () => void;
}

const AutoResponderItem = (props: IAutoResponderItemProps) => {
  const [itemName, setItemName] = useState(props.item?.name || 'Project Name');
  const [isRename, setIsRename] = useState<boolean>(false);
  const [archiveFunnelOpen, setArchiveFunnelOpen] = useState<boolean>(false);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(
    !!props.item.thumbnailUrl
  );
  const [isLive, setIsLive] = useState<boolean>(props.item.isActive);

  const history = useHistory();

  const handleNavigate = () => {
    history.push(`/console/auto-responders/${props.item._id}/details`);
  };

  const deleteProject = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.DELETE_AUTO_RESPONDER,
        values: {
          autoresponderId: props.item._id,
        },
        headerType: 'USER-AUTH',
      });
      props.fetchProjects();
      setArchiveFunnelOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const copyAutoResponder = async () => {
    try {
      await graphQlCall({
        queryTemplateObject: queries.COPY_AUTO_RESPONDER,
        values: {
          id: props.item._id,
        },
        headerType: 'USER-AUTH',
      });
      props.fetchProjects();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateName = async () => {
    setIsRename(false);
    if (itemName === props.item?.name) return;

    try {
      await graphQlCall({
        queryTemplateObject: queries.UPDATE_AUTO_RESPONDER,
        values: {
          id: props.item._id,
          name: itemName,
        },
        headerType: 'USER-AUTH',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateLiveStatus = async (value: boolean) => {
    setIsLive(value);
    try {
      await graphQlCall({
        queryTemplateObject: queries.UPDATE_AUTO_RESPONDER,
        values: {
          id: props.item._id,
          isLive: value,
        },
        headerType: 'USER-AUTH',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleMenuClick = useCallback(
    (e: any) => {
      e.domEvent.stopPropagation();
      switch (+e.key) {
        case MenuItems.DELETE: {
          setArchiveFunnelOpen(true);
          break;
        }
        case MenuItems.CLONE: {
          copyAutoResponder();
          break;
        }
        case MenuItems.RENAME: {
          setIsRename(true);
          break;
        }
      }
    },
    [props.item]
  );

  const menuItems = useMemo(
    () => [
      {
        label: 'Rename',
        key: MenuItems.RENAME,
        icon: <Rename />,
      },
      {
        label: 'Clone',
        key: MenuItems.CLONE,
        icon: <DuplicateIcon />,
      },
      {
        label: 'Delete',
        key: MenuItems.DELETE,
        icon: <DeleteIcon />,
      },
    ],
    []
  );

  const menuProps = useMemo(() => {
    return {
      items: menuItems,
      onClick: handleMenuClick,
    };
  }, [handleMenuClick, menuItems]);

  return (
    <>
      <ConfirmArchivationPopup
        open={archiveFunnelOpen}
        description="Are you sure you want to delete this project?"
        onDonePressed={deleteProject}
        onClose={() => setArchiveFunnelOpen(false)}
      />

      <div className={styles.container} onClick={handleNavigate}>
        <div className={styles.left}>
          {props.item.thumbnailUrl ? (
            <div className={styles.image}>
              <img
                className={styles.previewImage}
                src={props.item.thumbnailUrl}
                alt="preview"
                onLoad={() => setIsImageLoading(false)}
                onError={() => setIsImageLoading(false)}
                style={{ display: isImageLoading ? 'none' : 'block' }}
              />
              {isImageLoading && <CircleLoader color="#d0d0d0" />}
            </div>
          ) : (
            <div className={styles.cover}>
              <MailSequenceIcon className={styles.mailSequenceIcon} />
            </div>
          )}

          <div className={styles.info}>
            <div className={styles.line}>
              <div className={styles.name}>
                <EditableLabel
                  size="large"
                  value={itemName}
                  onChange={setItemName}
                  onOutsideClick={handleUpdateName}
                  isInputActive={isRename}
                />
              </div>
            </div>
            <div className={styles.line}>
              <div className={styles.tag}>
                <EntryTag className={styles.tagIcon} />
                {props.item?.entryTag?.name || 'Not Selected'}
              </div>
              <div className={styles.tag}>
                <ExitTag className={styles.tagIcon} />
                {props.item?.exitTag?.name || 'Not Selected'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <Delivered className={styles.statIcon} />
              {props.stats?.delivered || '-'}
            </div>
            <div className={styles.stat}>
              <Eye className={styles.statIcon} />
              {props.stats?.opened || '-'}
            </div>
            <div className={styles.stat}>
              <Finger className={styles.statIcon} />
              {props.stats?.clicked || '-'}
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <ToggleSwitch
              isBinary
              checkValue={isLive}
              onToggle={handleUpdateLiveStatus}
            />
            <div className={styles.options}>
              <OptionMenu menuProps={menuProps} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AutoResponderItem;
