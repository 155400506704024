import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import { IAutoResponder, ISender } from 'types';
import { graphQlCall } from 'graphql/utils';
import { Tag } from 'Pages/PageContacts/Helper/types';
import { ReactComponent as Attention } from 'Assets/icons/attention2.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';
import queries from 'graphql/queries';
import CustomDropdown from 'Components/CustomDropdown/CustomDropdown';
import ToggleSwitch from 'UILib/ToggleSwitch/ToggleSwitch';
import Input from 'UILib/Input/Input';
import Popup from 'UILib/Popup/Popup';
import Button from 'UILib/Button/Button';

import styles from './AutoResponderProjectSettingsModal.module.scss';

interface IProps {
  open: boolean;
  onClose: () => void;
  autoResponder?: IAutoResponder;
  setAutoResponder: (value: IAutoResponder) => void;
}

const AutoResponderProjectSettingsModal = ({
  open,
  onClose,
  autoResponder,
  setAutoResponder,
}: IProps) => {
  const [senders, setSenders] = useState<ISender[]>([]);
  const [domains, setDomains] = useState<ISender[]>([]);
  const [selectedSender, setSelectedSender] = useState<string>('');
  const [entryTagsSearch, setEntryTagsSearch] = useState<string>('');
  const [exitTagsSearch, setExitTagsSearch] = useState<string>('');
  const [uniqueTags, setUniqueTags] = useState<Tag[]>([]);
  const [entryTag, setEntryTag] = useState<string | null>();
  const [exitTag, setExitTag] = useState<string | null>();
  const [isExclusive, setIsExclusive] = useState<boolean>(false);

  const filteredEntryTags = useMemo(() => {
    return uniqueTags.filter((e) => e.name.includes(entryTagsSearch));
  }, [entryTagsSearch, uniqueTags]);

  const filteredExitTags = useMemo(() => {
    return uniqueTags.filter((e) => e.name.includes(exitTagsSearch));
  }, [exitTagsSearch, uniqueTags]);

  const history = useHistory();

  useEffect(() => {
    if (autoResponder) {
      setEntryTag(autoResponder?.entryTag?._id);
      setExitTag(autoResponder?.exitTag?._id);
      setSelectedSender(autoResponder?.sender?._id);
      setIsExclusive(autoResponder.isExclusive);
    }
  }, [autoResponder, open]);

  const verifiedSenders = useMemo(() => {
    return senders
      .filter((sender) =>
        domains.some(
          (domain) =>
            domain._id === sender.mailDomain?._id &&
            domain.status === 'Verified'
        )
      )
      .map((sender) => ({
        label: sender.email,
        value: sender._id,
      }));
  }, [senders, domains]);

  useEffect(() => {
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_SENDERS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setSenders);
    graphQlCall({
      queryTemplateObject: queries.GET_MAIL_DOMAINS_QUERY,
      headerType: 'USER-AUTH',
    }).then(setDomains);
    graphQlCall({
      queryTemplateObject: queries.GET_USER_CONTACT_TAGS,
      headerType: 'USER-AUTH',
    }).then(setUniqueTags);
  }, []);

  const handleClose = () => {
    setSelectedSender('');
    setEntryTagsSearch('');
    setExitTagsSearch('');
    setEntryTag(null);
    setExitTag(null);
    onClose();
  };

  if (!open) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      const response = await graphQlCall({
        queryTemplateObject: queries.UPDATE_AUTO_RESPONDER,
        values: {
          ...(autoResponder?._id && { id: autoResponder._id }),
          ...(selectedSender && { senderId: selectedSender }),
          ...(exitTag && { exitTagId: exitTag }),
          ...(entryTag && { entryTagId: entryTag }),
          isExclusive,
        },
        headerType: 'USER-AUTH',
      });
      setAutoResponder(response);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup onClose={handleClose} wrapperClassName={styles.popup}>
      <div className={styles.container}>
        <div className={styles.title}>Project Settings</div>
        <div className={styles.section}>
          <div className={styles.subtitle}>Sending Domain</div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.input}>
                <CustomDropdown
                  label="Select Email"
                  value={selectedSender}
                  className={styles.dropdown}
                  options={verifiedSenders}
                  onChange={(item) => {
                    setSelectedSender(item);
                  }}
                  footer={
                    <div
                      className={styles.addSender}
                      onClick={() =>
                        history.push('/console/settings/domains-senders')
                      }
                    >
                      <PlusIcon className={styles.plusIcon} />
                      Add sending domain
                    </div>
                  }
                  hasErrors={!selectedSender}
                />
                {!selectedSender && <Attention className={styles.attention} />}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.section}>
          <div className={styles.subtitle}>Tags</div>
          <div className={styles.inputsContainer}>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>Entry Tag</div>
              <div className={styles.inputSubtitle}>
                When this tag is added to a contact, they will start receiving
                emails from this project.
              </div>
              <div className={styles.input}>
                <CustomDropdown
                  label="Select Tags"
                  size="medium"
                  header={
                    <div className={styles.searchContainer}>
                      <Input
                        placeholder="Search"
                        border="stroke"
                        className={styles.search}
                        onChange={(e) => setEntryTagsSearch(e.target.value)}
                        value={entryTagsSearch}
                      />
                    </div>
                  }
                  options={filteredEntryTags.map((tag) => ({
                    label: tag.name,
                    value: tag._id,
                  }))}
                  onChange={(selectedTagId) => {
                    setEntryTag(selectedTagId);
                  }}
                  value={entryTag}
                  hasErrors={!entryTag}
                />
                {!entryTag && <Attention className={styles.attention} />}
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputTitle}>Exit Tags</div>
              <div className={styles.inputSubtitle}>
                When this tag is added to a contact, they will stop receiving
                emails from this project.
              </div>
              <div className={styles.input}>
                <CustomDropdown
                  label="Select Tags"
                  size="medium"
                  header={
                    <div className={styles.searchContainer}>
                      <Input
                        placeholder="Search"
                        border="stroke"
                        className={styles.search}
                        onChange={(e) => setExitTagsSearch(e.target.value)}
                        value={exitTagsSearch}
                      />
                    </div>
                  }
                  options={filteredExitTags.map((tag) => ({
                    label: tag.name,
                    value: tag._id,
                  }))}
                  onChange={(selectedTagId) => {
                    setExitTag(selectedTagId);
                  }}
                  value={exitTag}
                  hasErrors={!exitTag}
                />
                {!exitTag && <Attention className={styles.attention} />}
              </div>
            </div>
          </div>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.section}>
          <div className={styles.toggleTitle}>
            <ToggleSwitch
              isBinary
              checkValue={isExclusive}
              onToggle={setIsExclusive}
            />{' '}
            <span>Remove from all other autoresponders on entry</span>
          </div>
          <div className={styles.toggleDescription}>
            When this option is selected, the contact will automatically be
            removed from all other active autoresponders upon joining this one,
            ensuring they only receive emails from the current sequence.
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button appearance="stroke" onClick={handleClose}>
            Cancel
          </Button>
          <Button appearance="solid" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default AutoResponderProjectSettingsModal;
