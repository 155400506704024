export const COPY_AUTO_RESPONDER_SEQUENCE_ITEM = {
  operationName: 'copyAutoresponderSequence',
  query: `mutation copyAutoresponderSequence($autoresponderId: String!, $sequenceItemId: String!) {
                copyAutoresponderSequence(autoresponderId: $autoresponderId, sequenceItemId: $sequenceItemId) {
                    mailDraft {
                        _id
                        data
                        subject
                        preheader
                    }
                    wait {
                        seconds
                        periodType
                        periodAmount
                    }
                }
          }`,
};
