export const COPY_AUTO_RESPONDER = {
  operationName: 'copyAutoresponder',
  query: `mutation copyAutoresponder($id: String!) {
                copyAutoresponder(id: $id) {
                  autoresponder {
                    _id
                    name
                    description
                    createdAt
                    updatedAt
                    thumbnailUrl
                    isActive
                    entryTag{
                        _id
                        name
                    }
                    exitTag{
                        _id
                        name
                    }
                }
            }
        }`,
};
