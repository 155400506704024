import { connect } from 'react-redux';
import { ReactComponent as AlertIcon } from 'Assets/icons/alert.svg';
import { deleteSender, removeSenderPopup } from 'store/senders/sendersActions';
import { RootState } from 'store/rootStore';
import Button from 'UILib/Button/Button';
import Popup from 'UILib/Popup/Popup';

import styles from './RemoveSenderPopup.module.scss';

interface IProps {
  openRemoveSenderPopup: boolean;
  selectedSender?: string;
  deleteSender: (id: string) => void;
  toggleRemoveSenderPopup: (payload: boolean) => void;
}

const RemoveSenderPopup = ({
  openRemoveSenderPopup,
  selectedSender,
  deleteSender,
  toggleRemoveSenderPopup,
}: IProps) => {
  if (!openRemoveSenderPopup) return null;

  return (
    <Popup
      onClose={() => toggleRemoveSenderPopup(false)}
      wrapperClassName={styles.container}
    >
      <div className={styles.content}>
        <AlertIcon />
        <div className={styles.title}>Are You Sure?</div>
        <div className={styles.subtitle}>
          You are going to remove this mail sending domain!
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          height={40}
          appearance="stroke"
          onClick={() => toggleRemoveSenderPopup(false)}
        >
          Cancel
        </Button>
        <Button
          height={40}
          appearance="highlighted"
          onClick={() => {
            if (selectedSender) {
              deleteSender(selectedSender);
              toggleRemoveSenderPopup(false);
            }
          }}
        >
          Proceed
        </Button>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedSender: state.senders.selectedSender,
  openRemoveSenderPopup: state.senders.openRemoveSenderPopup,
});

const mapDispatchToProps = {
  deleteSender: (id: string) => deleteSender(id),
  toggleRemoveSenderPopup: (payload: boolean) => removeSenderPopup(payload),
};

export default connect(mapStateToProps, mapDispatchToProps)(RemoveSenderPopup);
