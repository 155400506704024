import { BookleTemplateBlock } from 'types';
import SectionBlock from './SectionBlock';

import styles from './SectionBlock.module.scss';

const SectionBlocks = ({ item }: { item: BookleTemplateBlock }) => {
  return (
    <div
      className={styles.container}
      style={{ ...(item.hasColor ? { backgroundColor: item.color } : {}) }}
    >
      {item?.items?.map((e: any) => {
        return (
          <SectionBlock
            item={e}
            sectionsCount={item?.items?.length || 1}
            key={e.id}
            style={{
              ...(item.hasPadding
                ? {
                    paddingBottom: item.styles?.blockSpacing?.paddingBottom,
                    paddingTop: item.styles?.blockSpacing?.paddingTop,
                    paddingLeft: item.styles?.blockSpacing?.paddingLeft,
                    paddingRight: item.styles?.blockSpacing?.paddingRight,
                  }
                : {}),
            }}
          />
        );
      })}
    </div>
  );
};

export default SectionBlocks;
